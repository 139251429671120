import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setDeletingUserAccount,
  setEditingUserProfile,
  setGettingUserProfile,
  setGettingUserProfileDetails,
  setUserProfile,
  setUserProfileDetails,
} from "../../ReduxStore/features/User/UserProfileSlice";
import api from "./../baseUrl";
import { removeUser } from "../../ReduxStore/features/userSlice";

export const updateUserDateOfBirth = async (birthday: string) => {
  store.dispatch(setEditingUserProfile(true));

  const body = {
    birthday,
  };
  await api
    .post("user/profile/update-dob", body)
    .then(() => {
      store.dispatch(setEditingUserProfile(false));
      toast?.success("Profile Saved");
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingUserProfile(false));
    });
};

export const updateUserProfile = async (
  firstName: string | null,
  lastName: string | null,
  phoneNo: string | null,
  dob: string | null,
  gender: string | null,
  file: string | null
) => {
  store.dispatch(setEditingUserProfile(true));
  const body: any = {
    firstName,
    lastName,
    phoneNo,
    dob,
    gender,
    file,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .patch("user/profile", body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(() => {
      store.dispatch(setEditingUserProfile(false));
      toast?.success("Profile Saved");
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingUserProfile(false));
    });
};

export const getUserActiveChurch = async () => {
  store.dispatch(setGettingUserProfile(true));
  await api
    .get("user/profile/active-church")
    .then((res) => {
      const { data } = res;
      store.dispatch(setUserProfile(data?.data));
      store.dispatch(setGettingUserProfile(false));
    })
    .catch((err) => {
      store.dispatch(setGettingUserProfile(false));
    });
};

export const getUserProfileDetails = async () => {
  store.dispatch(setGettingUserProfileDetails(true));
  await api
    .get("user/profile")
    .then((res) => {
      const { data } = res;
      store.dispatch(setUserProfileDetails(data?.data));
      store.dispatch(setGettingUserProfileDetails(false));
    })
    .catch((err) => {
      store.dispatch(setGettingUserProfileDetails(false));
    });
};

export const checkIfUserJoinedChurch = async () => {
  const res = await api.get("user/profile/active-church");
  return res;
};

export const changeChurch = async (churchId: string) => {
  store.dispatch(setEditingUserProfile(true));
  const body = {
    churchId,
  };
  await api
    .post("user/profile/switch-church", body)
    .then((res) => {
      const { data } = res;
      store.dispatch(setEditingUserProfile(false));
      toast.success(data?.message);
      // window.location.reload();
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingUserProfile(false));
    });
};

export const deleteUserAccount = async (email: string, password: string) => {
  store.dispatch(setDeletingUserAccount(true));
  const body = {
    email,
    password,
  };
  await api
    .post("user/auth/delete", body)
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setDeletingUserAccount(false));
      store.dispatch(removeUser());
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingUserAccount(false));
    });
};
