import moment from "moment";
import { Avatar } from "../../Components/Nav/styles";
import { Checkbox } from "./styles";
import { RiDeleteBinLine } from "react-icons/ri";
import { deleteAdminUsers } from "../../Apis/AdminApis/userManager";
import { store } from "../../ReduxStore/app/store";
import { theme } from "../../theme";
import Loader from "../../Components/Loaders/MoonLoader";

export const financialReportTableRow = () => {
  const rows = [
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Offering",
      transactionid: "12345678976",
      date: moment().format("DD-MM-YYYY"),
      amount: "3500",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Offering",
      transactionid: "12345678976",
      date: moment().format("DD-MM-YYYY"),
      amount: "3500",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Levy",
      transactionid: "12345678976",
      date: moment().format("DD-MM-YYYY"),
      amount: "3500",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Tithe",
      transactionid: "12345678976",
      date: moment().format("DD-MM-YYYY"),
      amount: "3500",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Offering",
      transactionid: "12345678976",
      date: moment().format("DD-MM-YYYY"),
      amount: "3500",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Tithe",
      transactionid: "98765432123",
      date: moment().format("DD-MM-YYYY"),
      amount: "2500",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      type: "Church Bulding",
      transactionid: "08055688457",
      date: moment().format("DD-MM-YYYY"),
      amount: "4000",
    },
  ];

  return rows;
};

export const membershipReportTableRow = () => {
  const rows = [
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      email: "achodobismark@gmail.com",
      phone: "08032477382",
      date: moment().format("DD-MM-YYYY"),
      group: "choir",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      email: "achodobismark@gmail.com",
      phone: "08032477382",
      date: moment().format("DD-MM-YYYY"),
      group: "choir",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      email: "achodobismark@gmail.com",
      phone: "08032477382",
      date: moment().format("DD-MM-YYYY"),
      group: "choir",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      name: "Bismark Achodo",
      email: "achodobismark@gmail.com",
      phone: "08032477382",
      date: moment().format("DD-MM-YYYY"),
      group: "choir",
    },
  ];

  return rows;
};

export const ManageUserTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          name: (
            <span>
              {item?.userId?.lastName} {item?.userId?.firstName}
            </span>
          ),
          email: <span>{item?.userId?.email}</span>,
          phone: <span>{item?.userId?.phoneNo}</span>,
          church: <span>{item?.churchId?.name}</span>,
          role: <span>{item?.roles[0]?.title}</span>,
          // edit: <RiEdit2Line style={{ fontSize: "20px", cursor: "pointer" }} />,
          delete: store.getState().adminUserManager.deletingChurchAdmin ? (
            <Loader color={theme.colors.primaryColor} size={10} />
          ) : (
            <>
              {item?.roles[0]?.title === "Church Admin" ? (
                ""
              ) : (
                <RiDeleteBinLine
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  color={theme.colors.primaryColor}
                  onClick={() =>
                    deleteAdminUsers(
                      item?.roles[0]?._id,
                      item?._id
                    )
                  }
                />
              )}
            </>
          ),
        };
      })
    : [];

  return rows;
};
