import moment from "moment";
import { toast } from "react-toastify";

export const validateemail = (value: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

export const validatePassword = (psw: string) => {
  const re = /^(?=.*\d)(?=.*[ -\/:-@\[-`{-~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(psw);
};

export const updateArrayElement = (
  array: string[],
  index: number,
  newElement: any
) => {
  Object.freeze(array);
  var newArray = [...array];
  newArray[index] = newElement;
  return newArray;
};

export const filterCurrentData = (data: any) => {
  const today = moment().format("DD MM YYYY");
  const filterByExpiration = data?.filter((items: any) => {
    return moment(items.createdAt).format("DD MM YYYY") === today;
  });
  return filterByExpiration;
};

export const getToday = () => {
  const today: any = new Date();
  return today;
};

export const getImagePreviewFile = (imgData: any) => {
  const url = URL.createObjectURL(imgData[0]);
  return url;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  // Define the regex for phone number validation
  const phoneRegex = /^0\d{10}$/;

  // Check if the input matches the regex
  if (!phoneRegex.test(phoneNumber)) {
    toast.error(
      "Invalid phone number. Please enter a valid phone number with up to 15 digits"
    );
    return false;
  } else {
    return true;
  }
};

// Email Validation
export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(email)) {
    toast.error(
      "Invalid email address. Please enter a valid email in the format 'example@example.com'."
    );
    return false;
  } else {
    return true;
  }
};

export const validateName = (name: string) => {
  if (name.length > 20) {
    toast.error("Name should not be more that 20 characters");
    return false;
  } else {
    return true;
  }
};

// Password Validation (at least 8 characters, 1 uppercase, 1 lowercase, 1 digit, 1 special character)
export const validatePass = (password: string) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[ -\/:-@\[-`{-~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (!passwordRegex.test(password)) {
    toast.error(
      "Invalid password. Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character."
    );
    return false;
  } else {
    return true;
  }
};

export function handleFileUpload(changeEvent: any): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async function (onLoadEvent: any) {
      const formData = new FormData();
      formData.append("file", onLoadEvent.target.result);
      formData.append("upload_preset", "my-uploads");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dztnevagf/image/upload",
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        if (data && data.secure_url) {
          resolve(data.secure_url); // Return secure_url when upload is successful
        } else {
          toast.error(`File not uploaded, try again`);
          reject(null); // Return null in case of failure
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error(`Error uploading file, try again`);
        reject(null); // Return null in case of failure
      }
    };

    reader.readAsDataURL(changeEvent.target.files[0]);
  });
}

export function formatDateTime(dateString: any) {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return "";
  }

  return date.toISOString().slice(0, 16); // "YYYY-MM-DDTHH:MM" format
}

export function formatDate(dateString: any) {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return "";
  }

  return date.toISOString().slice(0, 10); // "YYYY-MM-DD" format
}

export function validateDate(inputDate: any) {
  // Parse input date
  const date = new Date(inputDate);
  // Get today's date without the time
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Check if the date is valid and not in the past
  if (isNaN(date.getTime())) {
    return "Invalid date format.";
  } else if (date < today) {
    return "Date cannot be in the past.";
  } else {
    return "Date is valid.";
  }
}
