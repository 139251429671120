import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface LiveStreamState {
  gettingLiveStreamLinks: boolean;
  creatingLiveStreamLinks: boolean;
  editingLiveStream: boolean;
  deletingLiveStreamLink: boolean;
  editingLiveStreamLink: boolean;
  LiveStreamLinks: any;
  selectedLiveStream: any;
  gettingLiveStreamEvent: boolean;
  creatingLiveStreamEvent: boolean;
  editingLiveStreamEvent: boolean;
  deletingLiveStreamEvent: boolean;
  LiveStreamEvents: any;
  selectedLiveStreamEvent: any;
  selectedLiveStreamLink: any;

  //   states
  groupId: string;
  LiveStreamTitle: string;
  LiveStreamText: string;

  //creating live stream link
  livestreamId: string;
  livestreamProviderId: string;
  livestreamDescription: string;
  link: string;

  // crating live stream event
  title: string;
  autoGenerateLink: boolean;
  startTime: Date;
  visibility: string;
  linkProviderList: [
    {
      link: string;
      livestreamProviderId: string;
    }
  ];
  description: string;
}

const initialState: LiveStreamState = {
  gettingLiveStreamLinks: false,
  creatingLiveStreamLinks: false,
  editingLiveStream: false,
  deletingLiveStreamLink: false,
  editingLiveStreamLink: false,
  LiveStreamLinks: null,
  selectedLiveStream: null,
  gettingLiveStreamEvent: false,
  creatingLiveStreamEvent: false,
  editingLiveStreamEvent: false,
  deletingLiveStreamEvent: false,
  LiveStreamEvents: null,
  selectedLiveStreamEvent: null,
  selectedLiveStreamLink: null,

  //   state
  groupId: "",
  LiveStreamTitle: "",
  LiveStreamText: "",

  //creating live stream
  livestreamId: "",
  livestreamProviderId: "",
  livestreamDescription: "",
  link: "",

  // creating live stream event
  title: "",
  autoGenerateLink: false,
  startTime: new Date(),
  visibility: "",
  linkProviderList: [
    {
      link: "",
      livestreamProviderId: "",
    },
  ],
  description: "",
};

export const LiveStreamSlice = createSlice({
  name: "LiveStream",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingLiveStreamLinks: (state, action) => {
      state.gettingLiveStreamLinks = action.payload;
    },
    setLiveStreamLinks: (state, action) => {
      state.LiveStreamLinks = action.payload;
    },
    setCreatingLiveStreamLinks: (state, action) => {
      state.creatingLiveStreamLinks = action.payload;
    },
    setEditingLiveStream: (state, action) => {
      state.editingLiveStream = action.payload;
    },
    setDeletingLiveStreamLink: (state, action) => {
      state.deletingLiveStreamLink = action.payload;
    },
    setGettingLiveStreamEvent: (state, action) => {
      state.gettingLiveStreamEvent = action.payload;
    },
    setLiveStreamEvents: (state, action) => {
      state.LiveStreamEvents = action.payload;
    },
    setCreatingLiveStreamEvent: (state, action) => {
      state.creatingLiveStreamEvent = action.payload;
    },
    setEditingLiveStreamEvent: (state, action) => {
      state.editingLiveStreamEvent = action.payload;
    },
    setDeletingLiveStreamEvent: (state, action) => {
      state.deletingLiveStreamEvent = action.payload;
    },
    setEditingLiveStreamLink: (state, action) => {
      state.editingLiveStreamLink = action.payload;
    },
    setSelectedLiveStream: (state, action) => {
      state.LiveStreamTitle = action.payload.title;
      state.LiveStreamText = action.payload.message;
      state.selectedLiveStream = action.payload;
    },
    getAdminLiveStreamFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    setSelectedLiveStreamEvent: (state, action) => {
      state.selectedLiveStreamEvent = action.payload;
      state.title = action?.payload?.title;
      state.description = action?.payload?.description;
      state.startTime = action?.payload?.startTime;
      state.visibility = action?.payload?.visibility;
    },
    setSelectedLiveStreamLink: (state, action) => {
      state.selectedLiveStreamLink = action.payload;
    },
  },
});

export const {
  setGettingLiveStreamLinks,
  setLiveStreamLinks,
  setCreatingLiveStreamLinks,
  setDeletingLiveStreamLink,
  setEditingLiveStream,
  getAdminLiveStreamFormDetails,
  setSelectedLiveStream,
  setEditingLiveStreamLink,
  setCreatingLiveStreamEvent,
  setEditingLiveStreamEvent,
  setDeletingLiveStreamEvent,
  setGettingLiveStreamEvent,
  setLiveStreamEvents,
  setSelectedLiveStreamEvent,
  setSelectedLiveStreamLink,
} = LiveStreamSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminLiveStream = (state: RootState) =>
  state.adminLiveStreams;

export default LiveStreamSlice.reducer;
