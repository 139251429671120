import { MdOutlineDeleteOutline } from "react-icons/md";
import { BottomContainer, CenterContainer, GroupDescription, GroupDetailsContainer, GroupDetailsSectionTitle, GroupName, MemberCardContainer, TopContainer } from "./style";
import { BiEditAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import MemberCardLayout from "../../../../Layouts/MemberCardLayout";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectAdminGroups } from "../../../../ReduxStore/features/Admin/GroupSlice";
import { deleteGroup, getSingleGroup } from "../../../../Apis/AdminApis/churchGroup";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";

const GroupDetails = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { groupDetails, selectedGroup, editingGroup, deletingGroup, deletingGroupMembers } = useAppSelector(selectAdminGroups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    selectedGroup && getSingleGroup(selectedGroup?.churchGroupId);
  }, [selectedGroup, editingGroup, deletingGroupMembers]);

  return (
    <GroupDetailsContainer>
      <TopContainer>
        <div className="left-content">
          <GroupDetailsSectionTitle>Group Name</GroupDetailsSectionTitle>
          <GroupName>{groupDetails?.churchGroupDetails?.name}</GroupName>
        </div>
        <div className="right-content">
          <div style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, width: `100%`, height: `100%`, gap: `5px` }} onClick={() => setShowDropdown(!showDropdown)}>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <div className="drop-down" style={{ display: `${showDropdown ? "flex" : "none"}` }}>
            <p onClick={() => dispatch(handleModal(15))}>
              <BiEditAlt />
              <span>Edit</span>
            </p>
            {deletingGroup ? (
              <Loader size={10} color={theme?.colors.primaryColor} />
            ) : (
              <p onClick={() => deleteGroup(groupDetails?.churchGroupDetails?._id)} className="text-red">
                <MdOutlineDeleteOutline />
                <span>Delete</span>
              </p>
            )}
          </div>
        </div>
      </TopContainer>
      <CenterContainer>
        <GroupDetailsSectionTitle>Group Description</GroupDetailsSectionTitle>
        <GroupDescription>{groupDetails?.churchGroupDetails?.description}</GroupDescription>
      </CenterContainer>
      <BottomContainer>
        <GroupDetailsSectionTitle>Group Members</GroupDetailsSectionTitle>
        <MemberCardContainer>
          {groupDetails?.groupMembers?.map((member: any) => (
            <MemberCardLayout name={member?.memberId?.lastName + " " + member?.memberId?.firstName} role={member?.memberId?.gender} memberId={member?._id} churchGroupId={groupDetails?.churchGroupDetails?._id} />
          ))}
        </MemberCardContainer>
        <PrimaryButton outlined={true} text="Invite Member" size="medium" handleClick={() => dispatch(handleModal(26))} />
      </BottomContainer>
    </GroupDetailsContainer>
  );
};

export default GroupDetails;
