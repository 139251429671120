import { useEffect } from "react";
import { CardsContainer } from "./style";
import PrayerRequestCard from "../../../../Layouts/PrayerRequestCard";
import moment from "moment";
import Loader from "../../../../Components/Loaders/MoonLoader";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import {
  getADminChurchPrayerFormDetails,
  selectAdminChurchPrayerWall,
} from "../../../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import {
  approveTestimony,
  getAllChurchTestimonies,
} from "../../../../Apis/AdminApis/PrayerWalll";
import { theme } from "../../../../theme";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { selectUser } from "../../../../ReduxStore/features/userSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const Testimonies = () => {
  const {
    ChurchTestimonies,
    gettingChurchTestimonies,
    approvingTestimony,
    page,
    pageSize,
  } = useAppSelector(selectAdminChurchPrayerWall);
  const { currentUser } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getADminChurchPrayerFormDetails({
        name: "page",
        value: e.selected + 1,
      })
    );
  };

  useEffect(() => {
    getAllChurchTestimonies(
      page,
      pageSize,
      currentUser?.roles[0]?.churchId?._id
    );
  }, [currentUser, page]);
  return (
    <>
      {gettingChurchTestimonies ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <>
          {ChurchTestimonies?.itemsList.length > 0 ? (
            <CardsContainer>
              {ChurchTestimonies?.itemsList.map((testimony: any) => (
                <PrayerRequestCard
                  user={
                    testimony?.userId?.lastName +
                    " " +
                    testimony?.userId?.firstName
                  }
                  date={moment(testimony?.createdAt).format("MMM Do YYYY")}
                  prayerPoint={testimony?.title}
                  reading={testimony?.testimony}
                  handleApprove={() => {
                    approveTestimony(
                      testimony?._id,
                      currentUser?.roles[0]?.churchId?._id
                    );
                  }}
                  // handleDiscard={() => {}}
                  status={testimony?.status}
                  updating={approvingTestimony}
                  visible={testimony?.visible}
                />
              ))}
            </CardsContainer>
          ) : (
            <NoRecordDisplay
              message="No testimonies at the moment"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}
      <Paginator
        currentPage={ChurchTestimonies?.paginator?.currentPage}
        pageCount={ChurchTestimonies?.paginator?.pageCount}
        itemCount={ChurchTestimonies?.paginator?.itemCount}
        hasNextPage={ChurchTestimonies?.paginator?.hasNextPage}
        hasPrevPage={ChurchTestimonies?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default Testimonies;
