import { FC } from "react";
import Loader from "../../Loaders/MoonLoader";
import { PrimaryButtonContainer } from "./styles";

interface ButtonI {
  text: string | undefined;
  handleClick?: any;
  outlined?: boolean | undefined;
  size?: string;
  loading?: Boolean;
  icon?: any;
  disabled?: boolean;
}
const PrimaryButton: FC<ButtonI> = ({
  text,
  handleClick,
  size,
  loading,
  outlined,
  icon,
  disabled,
}) => {
  return (
    <PrimaryButtonContainer
      onClick={handleClick}
      outlined={outlined}
      size={size}
      type="submit"
      disabled={disabled}
      isDisabled={disabled}
    >
      {loading ? (
        <Loader size={10} color={outlined && `#B603C9`} />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <span>{text}</span>
          {icon && <span>{icon}</span>}
        </div>
      )}
    </PrimaryButtonContainer>
  );
};

export default PrimaryButton;
