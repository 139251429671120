/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import Header from "../Components/Nav/Header";
import Sidebar from "../Components/Nav/Sidebar";
import { useAppSelector } from "../ReduxStore/app/hooks";
import { selectUser } from "../ReduxStore/features/userSlice";
import { rolesRouters } from "../Utils/config";
import { AppBody, AppContainer, RouteBody } from "./styles";
import {
  checkIfUserJoinedChurch,
  getUserActiveChurch,
  getUserProfileDetails,
} from "../Apis/UserApis/userProfile";
import { useEffect, useState } from "react";
import { getAdminChurch } from "../Apis/AdminApis/ChurchActivities";
import FindChurch from "../Pages/Auth/Signup/FindChurch";
import { selectAdminActivities } from "../ReduxStore/features/Admin/ActivitiesSlice";
import { selectNav } from "../ReduxStore/features/navSlice";

const PrivateRoutes = () => {
  const { currentUser } = useAppSelector(selectUser);
  const { adminChurch } = useAppSelector(selectAdminActivities);
  const { collapseSidebar } = useAppSelector(selectNav);
  const [churchId, setChurchId] = useState<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const callCheckIfUserJoinedChurch = async () => {
    const res: any = await checkIfUserJoinedChurch();
    if (res?.data?.data?.churchId) {
      setChurchId(res?.data?.data?.churchId);
    } else {
      setChurchId(null);
      navigate("/find-church");
    }
  };

  useEffect(() => {
    if (pathname !== "/find-church") {
      if (currentUser?.userType === "user") {
        callCheckIfUserJoinedChurch();
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (currentUser?.userType === "churchadmin") {
      if (!adminChurch?.verified) {
        navigate("/");
      }
    }
  }, [pathname, adminChurch]);

  useEffect(() => {
    if (currentUser?.userType === "user") {
      getUserActiveChurch();
      getUserProfileDetails();
    }
    if (currentUser?.userType === "churchadmin") getAdminChurch();
  }, [currentUser]);

  useEffect(() => {
    getAdminChurch();
  }, []);

  return (
    <AppContainer>
      <Header />
      <AppBody>
        <Sidebar />
        <RouteBody collapsedSidebar={collapseSidebar}>
          <Routes>
            {currentUser?.userType === "user" ? (
              <>
                {rolesRouters?.user?.map((item: any) => (
                  <>
                    {churchId ? (
                      <Route
                        path={`/${item?.route}`}
                        element={item?.component}
                        key={item}
                      />
                    ) : (
                      <Route path="/find-church" element={<FindChurch />} />
                    )}
                  </>
                ))}
              </>
            ) : currentUser?.userType === "superuser" ? (
              <>
                {rolesRouters?.superAdmin?.map((item: any) => (
                  <Route
                    path={`/${item?.route}`}
                    element={item?.component}
                    key={item}
                  />
                ))}
              </>
            ) : (
              <>
                {rolesRouters?.admin?.map((item: any) => (
                  <Route
                    path={`/${item?.route}`}
                    element={item?.component}
                    key={item}
                  />
                ))}
              </>
            )}
          </Routes>
        </RouteBody>
      </AppBody>
    </AppContainer>
  );
};

export default PrivateRoutes;
