import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import { setGettingChurchDonations } from "../../ReduxStore/features/Admin/DonationsSlice";
import { setDonation } from "../../ReduxStore/features/User/DonationSlice";
import api from "../baseUrl";

export const getChurchDonations = async (
  typeOfDonation: string,
  churchGroupId: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingChurchDonations(true));
  const params = {
    typeOfDonation,
    churchGroupId,
  };
  await api
    .get("user/church/donations", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingChurchDonations(false));
      store.dispatch(setDonation(data?.data));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingChurchDonations(false));
    });
};
