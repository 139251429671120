import { useEffect, useState } from "react";
import { VideoSermonCardWrap } from "./style";
import { FaShareAlt } from "react-icons/fa";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { getSermons } from "../../../../Apis/AdminApis/Media";
import { selectAdminChurchSermon } from "../../../../ReduxStore/features/Admin/SermonSlice";
import VideoSermonCard from "./VideoSermonCard";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";

const AudioSermon = () => {
  const {
    gettingAdminChurchSermons,
    adminChurchSermons,
    creatingChurchSermon,
  } = useAppSelector(selectAdminChurchSermon);
  const [audioSermons, setAudioSermons] = useState<any>();

  useEffect(() => {
    const audioSermonFilter = adminChurchSermons?.itemsList?.filter(
      (x: any) => x.sermonType === "audio"
    );
    setAudioSermons(audioSermonFilter);
  }, [adminChurchSermons]);

  return (
    <VideoSermonCardWrap>
      {gettingAdminChurchSermons ? (
        <Loader color={theme.colors.primaryColor} size={20} />
      ) : audioSermons?.length > 0 ? (
        audioSermons?.map((item: any) => (
          <VideoSermonCard
            videoCaption={item?.title}
            shareIcon={FaShareAlt}
            link={item?.link}
          />
        ))
      ) : (
        <NoRecordDisplay
          message="Audio Sermon not Available"
          src="/assets/images/svg/working-remotly.svg"
        />
      )}
    </VideoSermonCardWrap>
  );
};

export default AudioSermon;
