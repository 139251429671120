import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import {
  AdDurationWrap,
  Duration,
  FormContainer,
  PubButtonContainer,
  PubButtonWrap,
} from "../../Pages/Private/Admin/PostAds/style";
import Select from "react-select";
import { selectStyles } from "../../Components/Form/inputs/styles";
import { FaArrowRight } from "react-icons/fa";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getAdminActivitiesDetails,
  selectAdminActivities,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { ToggleSwitch } from "../../Pages/Private/Admin/Subscription/Switch";
import {
  getEventLocations,
  CreateEvent,
} from "../../Apis/AdminApis/ChurchActivities";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import { getUserActiveChurch } from "../../Apis/UserApis/userProfile";
import { getGroupList } from "../../Apis/AdminApis/churchGroup";
import { selectAdminGroups } from "../../ReduxStore/features/Admin/GroupSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";
import FilePicker from "../../Components/Form/inputs/FilePicker";
import { toast } from "react-toastify";
import { formatDateTime } from "../../Components/Helpers/Generals";

const inviteTypeOptions = [
  {
    label: "Groups",
    value: "Group",
  },
  {
    label: "Members",
    value: "Members",
  },
];

const CreateEventModal = () => {
  const {
    eventTitle,
    eventMessage,
    eventStartDate,
    eventEndDate,
    eventAllDay,
    eventRepeat,
    eventLocation,
    eventInviteType,
    eventLivestream,
    eventFileUrl,
    eventGroupIds,
    eventLocations,
    creatingEvent,
  } = useAppSelector(selectAdminActivities);
  const { userProfile } = useAppSelector(selectUserProfile);
  const [locations, setLocations] = useState<any>();
  const [groups, setGroups] = useState<any>();
  const [selectedGroups, setSelectedGroups] = useState<any>();
  const { churchGroups } = useAppSelector(selectAdminGroups);

  const dispatch = useAppDispatch();

  const handleSwitch = (e: any, name: string) => {
    e.preventDefault();
    dispatch(
      getAdminActivitiesDetails({
        name,
        value: e.target.checked,
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };
  const handleSelect = (e: any, name: any) => {
    if (name === "activityInviteType" && e.value === "Members") {
      dispatch(
        getAdminActivitiesDetails({ name: "activityGroupIds", value: [] })
      );
      setSelectedGroups([]);
    }
    dispatch(getAdminActivitiesDetails({ name, value: e.value }));
  };

  const handleMultiSelect = (value: any, name: any) => {
    setSelectedGroups(value);

    const realValue = value.map((item: any) => {
      return item?.value;
    });
    dispatch(getAdminActivitiesDetails({ name, value: realValue }));
  };

  function handleFileUpload(changeEvent: any) {
    const reader = new FileReader();

    reader.onload = async function (onLoadEvent: any) {
      const formData = new FormData();

      formData.append("file", onLoadEvent.target.result);
      formData.append("upload_preset", "my-uploads");

      const data = await fetch(
        "https://api.cloudinary.com/v1_1/dztnevagf/image/upload",
        {
          method: "POST",
          body: formData,
        }
      ).then((response) => {
        return response.json();
      });

      if (data?.secure_url) {
        dispatch(
          getAdminActivitiesDetails({
            name: `eventFileUrl`,
            value: data?.secure_url,
          })
        );
      } else {
        toast.error(`File not uploaded, try again`);
      }
    };
    reader.readAsDataURL(changeEvent.target.files[0]);
  }

  const handleUpload = (e: any) => {
    e.preventDefault();
    if (
      eventTitle &&
      eventMessage &&
      eventStartDate &&
      eventEndDate &&
      eventFileUrl
    ) {
      CreateEvent(
        eventTitle,
        eventMessage,
        eventStartDate,
        eventEndDate,
        eventAllDay,
        eventRepeat,
        eventLocation,
        eventLivestream,
        eventFileUrl,
        eventGroupIds,
        eventInviteType
      );
    } else {
      toast.error(
        "please fill at least the title, event_message, start_date, end_date and event_poster to create event"
      );
    }
  };

  useEffect(() => {
    const Locations = eventLocations?.itemsList?.map((item: any) => {
      return {
        value: item._id,
        label: item.address,
      };
    });
    setLocations(Locations);
  }, [eventLocations]);
  useEffect(() => {
    const group = churchGroups?.map((item: any) => {
      return {
        value: item.churchGroupId,
        label: item.name,
      };
    });
    setGroups(group);
  }, [churchGroups]);

  useEffect(() => {
    getEventLocations(userProfile?.churchId?._id);
  }, [userProfile]);

  useEffect(() => {
    getUserActiveChurch();
  }, []);

  useEffect(() => {
    getGroupList();
  }, []);

  return (
    <CustomModal
      id={21}
      content={
        <FormContainer>
          <form>
            <input
              className="titleinput"
              placeholder="Add Title"
              value={eventTitle}
              name="eventTitle"
              onChange={handleChange}
            />
            <Select
              options={inviteTypeOptions}
              placeholder="Invite groups or members"
              // name={input?.name}
              onChange={(e: any) => handleSelect(e, "eventInviteType")}
              styles={selectStyles}
              value={inviteTypeOptions?.filter(
                (x: any) => x?.value === eventInviteType
              )}
            />
            <Select
              isMulti
              options={groups}
              placeholder="Groups"
              // name={input?.name}
              onChange={(e: any) => handleMultiSelect(e, "eventGroupIds")}
              styles={selectStyles}
              isDisabled={eventInviteType === `Members` ? true : false}
              value={selectedGroups}
            />
            <AdDurationWrap>
              <Duration>
                <input
                  type="datetime-local"
                  className="date"
                  placeholder="State Date"
                  value={formatDateTime(eventStartDate)}
                  name="eventStartDate"
                  onChange={handleChange}
                />
                <span>
                  <FaArrowRight color="grey" />
                </span>
                <input
                  type="datetime-local"
                  className="date"
                  placeholder="End Date"
                  value={formatDateTime(eventEndDate)}
                  name="eventEndDate"
                  onChange={handleChange}
                />
                {/* <span className="smalldate">1d</span> */}
              </Duration>
            </AdDurationWrap>
            <FilePicker
              text="Upload event poster"
              handleChange={handleFileUpload}
              ImageUrl={eventFileUrl}
            />
            <Select
              options={locations}
              placeholder="Location"
              // name={input?.name}
              onChange={(e: any) => handleSelect(e, "eventLocation")}
              styles={selectStyles}
              value={locations?.filter((x: any) => x?.value === eventLocation)}
            />
            <textarea
              className="detailinput"
              placeholder="Enter details for this event"
              value={eventMessage}
              name="eventMessage"
              onChange={handleTextAreaChange}
            />
            <Duration style={{ flexDirection: `row` }}>
              <div>
                <ToggleSwitch
                  switchState={eventAllDay}
                  handleChange={(e: any) => handleSwitch(e, "eventAllDay")}
                  id="eventAllDayId"
                />
                <span>All day</span>
              </div>
              <div>
                <ToggleSwitch
                  switchState={eventRepeat}
                  handleChange={(e: any) => handleSwitch(e, "eventRepeat")}
                  id="eventRepeatId"
                />
                <span>Repeat</span>
              </div>
              <div>
                <ToggleSwitch
                  switchState={eventLivestream}
                  handleChange={(e: any) => handleSwitch(e, "eventLivestream")}
                  id="eventLivestreamId"
                />
                <span>LiveStream</span>
              </div>
            </Duration>
            <PubButtonWrap>
              <PubButtonContainer>
                <PrimaryButton
                  text="Publish"
                  size="small"
                  handleClick={handleUpload}
                  loading={creatingEvent}
                />
                <PrimaryButton
                  text="Close"
                  size="small"
                  outlined={true}
                  handleClick={(e: any) => {
                    e.preventDefault();
                    dispatch(handleModal(0));
                  }}
                  loading={creatingEvent}
                />
              </PubButtonContainer>
            </PubButtonWrap>
          </form>
        </FormContainer>
      }
    />
  );
};

export default CreateEventModal;
