import React from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { EditDailyVerseInput } from "../../Utils/authInput";
import { selectStyles } from "../../Components/Form/inputs/styles";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { editDailyScriptures } from "../../Apis/UserApis/DailyScriptures";
import {
  getDailyScripturesFormDetails,
  selectUserDailyScriptures,
} from "../../ReduxStore/features/User/DailyScripturesSlice";

const EditDailyScripturesModal = () => {
  const {
    editingDailyScripture,
    bibleName,
    chapter,
    verse,
    scriptureDate,
    scriptureId,
  } = useAppSelector(selectUserDailyScriptures);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDailyScripturesFormDetails({ name, value }));
  };

  const handleSelect = (value: any, name: string) => {
    dispatch(getDailyScripturesFormDetails({ name, value: value?.value }));
  };

  return (
    <CustomModal
      id={3}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {EditDailyVerseInput?.map((input: any) => (
            <>
              {input?.type === "select" ? (
                <Select
                  placeholder={input.placeholder}
                  name={input?.name}
                  styles={selectStyles}
                  options={input?.options()}
                  onChange={(value: any) => handleSelect(value, input?.name)}
                  defaultValue={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              editDailyScriptures(
                bibleName,
                chapter,
                verse,
                scriptureDate,
                scriptureId
              );
            }}
            loading={editingDailyScripture}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditDailyScripturesModal;
