import styled from "styled-components";
import { media } from "./../../../../Screens";

export const ActivityContainer = styled.div`
  width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  gap: 20px;

  ${media.phone} {
    // margin-left: 30px;
  }
  .left-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    flex: 0.8;
  }

  .right-content {
    flex: 0.2;
  }

  div.ad {
    // position: absolute;
    // margin-left: 730px;
    margin-top: 20px;
    border: 2px solid;
    height: 174px;
    width: 174px;
    border-radius: 5%;

    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }
`;

export const CalendarContainer = styled.div`
  margin-top: 5px;
  margin-left: -180px;
`;

export const ShortCalendarGlass = styled.div`
  display: flex;
  width: 400px;
  height: 70px;
  flex-direction: column;
  background: rgb(235, 234, 234);
  // margin-left: -20%;
  border-radius: 5px;

  ${media.phone} {
    width: 300px;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  font-size: 10px;
  font-weight: 500;
  padding: 10px 10px;

  .faangle {
    margin-top: -2px;
    cursor: pointer;
    font-size: 15px;
  }
`;

export const Month = styled.div`
  font-size: 5px;
`;

export const Year = styled.div``;

export const DateShowCase = styled.div`
  display: flex;
  gap: 60px;
  margin-top: -10px;
  padding: 5px 10px;

  ${media.phone} {
    gap: 50px;
  }
`;

export const DateAndDay = styled.div``;

export const DateOne = styled.div`
  background: white;
  padding: 1px 3px;
`;

export const Date = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: 1px;
`;

export const Day = styled.div`
  display: flex;
  margin-top: 0px;
  font-size: 8px;
  font-weight: 500;
`;

export const EventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  // position: absolute;
  // margin-left: 730px;
  // margin-top: -1100px;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;

export const EventCaption = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -20px;
`;

export const EventIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventTopic = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const EventName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;
export const Sponsor = styled.div`
  position: absolute;
  margin-left: 612px;
  margin-top: -890px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 610px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: -820px;
  ${media.phone} {
    display: none;
  }
`;

export const EventsContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
