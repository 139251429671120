export const churchMenbersTableCols = [
  { label: "", field: "check", sort: "asc" },
  { label: "", field: "avatar", sort: "asc" },
  { label: "Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone", field: "phone", sort: "asc" },
  { label: "Church", field: "church", sort: "asc" },
];

export const usersTableCols = [
  { label: "Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone", field: "phone", sort: "asc" },
  { label: "User Type", field: "userType", sort: "asc" },
  { label: "Is Verified", field: "isVerified", sort: "asc" },
  { label: "Gender", field: "gender", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
];

export const manageUserTableCols = [
  { label: "Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone", field: "phone", sort: "asc" },
  { label: "Role", field: "role", sort: "asc" },
  { label: "", field: "edit", sort: "asc" },
  // { label: "", field: "delete", sort: "asc" },
];

export const churchAdminsTableCols = [
  { label: "", field: "check", sort: "asc" },
  { label: "", field: "avatar", sort: "asc" },
  { label: "Admin Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone", field: "phone", sort: "asc" },
  { label: "Church", field: "church", sort: "asc" },
];

export const SubScriptionsTableCols = [
  // { label: "", field: "check", sort: "asc" },
  // { label: "", field: "avatar", sort: "asc" },
  // { label: "Church", field: "church", sort: "asc" },
  { label: "Admin Email", field: "email", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
  { label: "Duration", field: "duration", sort: "asc" },
  // { label: "Exp", field: "expiration", sort: "asc" },
  { label: "Title", field: "title", sort: "asc" },
  { label: "Desc", field: "desc", sort: "asc" },
  { label: "Features", field: "features", sort: "asc" },
  { label: "", field: "edit", sort: "asc" },
  { label: "", field: "delete", sort: "asc" },
];
export const DonationsTableCols = [
  { label: "", field: "check", sort: "asc" },
  { label: "", field: "avatar", sort: "asc" },
  { label: "Donor", field: "donor", sort: "asc" },
  { label: "Description", field: "desc", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
];

export const verifiedChurchesTableCols = [
  // { label: "", field: "check", sort: "asc" },
  // { label: "", field: "avatar", sort: "asc" },
  { label: "Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone NO", field: "phone", sort: "asc" },
  { label: "Address", field: "address", sort: "asc" },
  { label: "Landmark", field: "landMark", sort: "asc" },
  { label: "City", field: "city", sort: "asc" },
  { label: "State", field: "state", sort: "asc" },
  { label: "Country", field: "country", sort: "asc" },
  { label: "Registered", field: "registered", sort: "asc" },
  // { label: "Head Clergy", field: "headClergy", sort: "asc" },
  // { label: "No of Admins", field: "adminCount", sort: "asc" },
  // { label: "No of Members", field: "membersCount", sort: "asc" },
];
export const unVerifiedChurchesTableCols = [
  { label: "Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone NO", field: "phone", sort: "asc" },
  { label: "Address", field: "address", sort: "asc" },
  { label: "Landmark", field: "landMark", sort: "asc" },
  { label: "City", field: "city", sort: "asc" },
  { label: "State", field: "state", sort: "asc" },
  { label: "Country", field: "country", sort: "asc" },
  { label: "Registered", field: "registered", sort: "asc" },
  // { label: "Head Clergy", field: "headClergy", sort: "asc" },
  // { label: "No of Admins", field: "adminCount", sort: "asc" },
  // { label: "No of Members", field: "membersCount", sort: "asc" },
  { label: "", field: "verify", sort: "asc" },
];
export const TransactionLogsTableCols = [
  { label: "", field: "check", sort: "asc" },
  { label: "", field: "avatar", sort: "asc" },
  { label: "Ref Id", field: "ref", sort: "" },
  { label: "Transaction Info", field: "info", sort: "" },
  { label: "Channel", field: "channel", sort: "" },
  { label: "Date", field: "date", sort: "" },
];
export const WalletTableCols = [
  { label: "Currency Id", field: "currencyId", sort: "" },
  { label: "Symbol", field: "symbol", sort: "" },
  { label: "Name", field: "name", sort: "" },
  { label: "Image", field: "image", sort: "" },
  { label: "", field: "edit", sort: "" },
  { label: "", field: "delete", sort: "" },
];

export const financialReportTableCols = [
  // { label: "", field: "check", sort: "asc" },
  // { label: "", field: "avatar", sort: "asc" },
  { label: "", field: "typeSymbol", sort: "asc" },
  { label: "Name", field: "name", sort: "asc" },
  { label: "Type", field: "type", sort: "asc" },
  { label: "Reference", field: "reference", sort: "asc" },
  { label: "Purpose", field: "purpose", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
];

export const membershipReportTableCols = [
  // { label: "", field: "check", sort: "asc" },
  // { label: "", field: "avatar", sort: "asc" },
  { label: "Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Phone", field: "phone", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
  { label: "", field: "delete", sort: "asc" },

  // { label: "Group", field: "group", sort: "asc" },
];

export const addUserTableCols = [
  { label: "", field: "check", sort: "asc" },
  { label: "", field: "avatar", sort: "asc" },
  { label: "Church", field: "church", sort: "asc" },
  { label: "Admin Name", field: "name", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
  { label: "Duration", field: "duration", sort: "asc" },
  { label: "Exp", field: "expiration", sort: "asc" },
];

export const churchActivitiesTableCols = [
  { label: "Title", field: "title", sort: "asc" },
  { label: "Message", field: "message", sort: "asc" },
  { label: "Banner", field: "banner", sort: "asc" },
  { label: "Location", field: "location", sort: "asc" },
  { label: "AllDay", field: "allDay", sort: "asc" },
  { label: "Repeat", field: "repeat", sort: "asc" },
  { label: "LiveStream", field: "liveStream", sort: "asc" },
  { label: "InviteType", field: "inviteType", sort: "asc" },
  { label: "Start Date", field: "startDate", sort: "asc" },
  { label: "End Date", field: "endDate", sort: "asc" },
  { label: "", field: "delete", sort: "asc" },
  { label: "", field: "edit", sort: "asc" },
];

export const churchEventsTableCols = [
  { label: "Title", field: "title", sort: "asc" },
  { label: "Message", field: "message", sort: "asc" },
  { label: "Banner", field: "banner", sort: "asc" },
  { label: "Location", field: "location", sort: "asc" },
  { label: "AllDay", field: "allDay", sort: "asc" },
  { label: "Repeat", field: "repeat", sort: "asc" },
  { label: "LiveStream", field: "liveStream", sort: "asc" },
  { label: "InviteType", field: "inviteType", sort: "asc" },
  { label: "Start Date", field: "startDate", sort: "asc" },
  { label: "End Date", field: "endDate", sort: "asc" },
  { label: "", field: "delete", sort: "asc" },
  { label: "", field: "edit", sort: "asc" },
];
