import { useRef, useEffect } from "react";
import { MessageSection } from "./styles";
import SingleMessage from "./SingleMessage";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserCgroup } from "../../../../ReduxStore/features/User/UserGroupSlice";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import moment from "moment";

const MessageBox = () => {
  const { messages, sendingMessage } = useAppSelector(selectUserCgroup);
  const { userProfile } = useAppSelector(selectUserProfile);
  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [sendingMessage]);

  return (
    <MessageSection>
      {messages
        ?.slice()
        ?.sort((x: any, y: any) => {
          return new Date(x.createAt) < new Date(y.createAt) ? 1 : -1;
        })
        ?.map((message: any) => (
          <SingleMessage
            // avatar={message?.fileUrl}
            isSender={message?.userId?._id === userProfile?.userId?._id}
            messageText={message?.message}
            timeStamp={moment(message?.createdAt).startOf("minutes").fromNow()}
            imgSrc={message?.fileUrl}
            username={
              message?.userId?.lastName + " " + message?.userId?.firstName
            }
          />
        ))}
      <div ref={messagesEndRef} style={{ marginTop: "50px;" }}></div>
    </MessageSection>
  );
};

export default MessageBox;
