import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import TabSwitch from "./../../../../Components/Nav/TabSwitch";
import PrayerRequest from "./PrayerRequest";
import Testimonies from "./Testimonies";
import ChurchPrayers from "./ChurchPrayers";
import DailyVerses from "./DailyReflection";
import { SuperAdminPrayersTabs } from "./TabNavs";

const Prayers = () => {
  const { currentTab } = useAppSelector(selectNav);
  return (
    <>
      <TabSwitch listItems={SuperAdminPrayersTabs} />
      {currentTab === 1 ? (
        <PrayerRequest />
      ) : currentTab === 2 ? (
        <Testimonies />
      ) : currentTab === 3 ? (
        <ChurchPrayers />
      ) : (
        <DailyVerses />
      )}
    </>
  );
};

export default Prayers;
