import { FC } from "react";
import ReactAudioPlayer from "react-audio-player";
import styled from "styled-components";
import { FaItunesNote } from "react-icons/fa";
import { media } from "./../../../../Screens";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

const AudioCard = styled.div`
  width: 300px;
  height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  ${media.phone} {
    margin-left: 0px;
  }
`;

const Caption = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 33px;
  color: #151c4d;
`;

const Minister = styled.div`
  margin-top: -15px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 26px;
  color: #151c4d;
`;

const FaItune = styled.div`
  margin-left: 130px;
  margin-top: 40px;
`;

interface AudioProps {
  audioSrc: string;
  audioTitle: string;
}

const AudioSermonCard: FC<AudioProps> = ({ audioSrc, audioTitle }) => {
  return (
    <Container>
      <AudioCard>
        <ReactAudioPlayer
          src={audioSrc}
          // autoPlay
          controls
          style={{ width: 100, height: 30 }}
          className="reactaudioplayer"
        />
        <FaItune>
          <FaItunesNote width={30} color="gray" fontSize={40} />
        </FaItune>
        <Caption>{audioTitle}</Caption>
        <Minister>Pst Emeka</Minister>
      </AudioCard>
    </Container>
  );
};

export default AudioSermonCard;
