import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface PostState {
  creatingUserPost: boolean;
  updatingUserPost: boolean;
  likingAndDislikingUserPOst: boolean;
  commentingUserPost: boolean;
  gettingApprovedPost: boolean;
  approvedPosts: any;
  allPosts: any;
  gettingPostsByChurch: boolean;
  postsByChurch: any;
  gettingTrendingPosts: boolean;
  trendingPosts: any;
  gettingHashTagPosts: boolean;
  hashTagPosts: any;
  gettingMyPosts: boolean;
  myPosts: any;
  gettingSinglePost: any;
  singlePost: any;

  // posts states
  postId: string;
  churchId: string;
  contentType: string;
  hashtag: string;
  title: string;
  description: string;
  hashtags: string[];
  file: any;
  action: string;
  comment: string;
  pageSize: number;
  page: number;
}

const initialState: PostState = {
  creatingUserPost: false,
  updatingUserPost: false,
  likingAndDislikingUserPOst: false,
  commentingUserPost: false,
  gettingApprovedPost: false,
  approvedPosts: [],
  allPosts: null,
  gettingPostsByChurch: false,
  postsByChurch: [],
  gettingTrendingPosts: false,
  trendingPosts: [],
  gettingHashTagPosts: false,
  hashTagPosts: [],
  gettingMyPosts: false,
  myPosts: [],
  gettingSinglePost: false,
  singlePost: [],

  // post states
  postId: "",
  churchId: "",
  contentType: "",
  hashtag: "",
  title: "",
  description: "",
  hashtags: [],
  file: null,
  action: "",
  comment: "",
  pageSize: 5,
  page: 1,
};

export const PostSlice = createSlice({
  name: "Post",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCreatingUserPost: (state, action) => {
      state.creatingUserPost = action.payload;
    },
    setUpdatingUserPost: (state, action) => {
      state.updatingUserPost = action.payload;
    },
    setLikingAndDislikingUserPost: (state, action) => {
      state.likingAndDislikingUserPOst = action.payload;
    },
    setCommentingUserPost: (state, action) => {
      state.commentingUserPost = action.payload;
    },
    setGettingApprovedUserPost: (state, action) => {
      state.gettingApprovedPost = action.payload;
    },
    appendPost: (state, action) => {
      state.approvedPosts = [...action.payload.docs];
      state.allPosts = action.payload;
    },
    setGettingUserPostByChurch: (state, action) => {
      state.gettingPostsByChurch = action.payload;
    },
    setUserPostByChurch: (state, action) => {
      state.postsByChurch = [...action.payload.docs];
      state.allPosts = action.payload;
    },
    setGettingTrendingUserPost: (state, action) => {
      state.gettingTrendingPosts = action.payload;
    },
    setTrendingUserPost: (state, action) => {
      state.trendingPosts = [...action.payload.docs];
      state.allPosts = action.payload;
    },
    setGettingHashTagPosts: (state, action) => {
      state.gettingHashTagPosts = action.payload;
    },
    setHashtagPosts: (state, action) => {
      state.hashTagPosts = action.payload;
    },
    setGettingMyPosts: (state, action) => {
      state.gettingMyPosts = action.payload;
    },
    setMyPosts: (state, action) => {
      state.myPosts = [...action.payload.docs];
      state.allPosts = action.payload;
    },
    setGettingSinglePost: (state, action) => {
      state.gettingSinglePost = action.payload;
    },
    setSinglePost: (state, action) => {
      state.singlePost = action.payload;
    },
    clearPosts: (state) => {
      state.myPosts = [];
      state.allPosts = null;
      state.approvedPosts = [];
      state.postsByChurch = [];
      state.trendingPosts = [];
    },
    getPostFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  getPostFormDetails,
  setCommentingUserPost,
  setCreatingUserPost,
  setGettingApprovedUserPost,
  setGettingHashTagPosts,
  setGettingMyPosts,
  setGettingSinglePost,
  setGettingUserPostByChurch,
  setHashtagPosts,
  setLikingAndDislikingUserPost,
  setTrendingUserPost,
  setUpdatingUserPost,
  setUserPostByChurch,
  setMyPosts,
  setSinglePost,
  setGettingTrendingUserPost,
  appendPost,
  clearPosts,
} = PostSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserPost = (state: RootState) => state.userPosts;

export default PostSlice.reducer;
