import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const VideoCarousel = ({ videoSources }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRefs = useRef([]);

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
  };

  useEffect(() => {
    const videoElement = videoRefs.current[currentVideoIndex];
    if (videoElement) {
      videoElement.play();
    }
  }, [currentVideoIndex]);

  return (
    <Carousel
      selectedItem={currentVideoIndex}
      onChange={(index) => setCurrentVideoIndex(index)}
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      infiniteLoop
      autoPlay={false}
      stopOnHover={false}
      swipeable={false}
      emulateTouch={false}
      showIndicators={false}
    >
      {videoSources.map((source, index) => (
        <div key={index}>
          <video
            ref={(el) => (videoRefs.current[index] = el)}
            src={source}
            muted
            width="100%"
            height="200"
            onEnded={handleVideoEnd}
            autoPlay
            controls
          />
        </div>
      ))}
    </Carousel>
  );
};

export default VideoCarousel;
