import { FC } from "react";
import {
  SowSeedWrap,
  SowSeedContainer,
  GiveOfferingWrap,
  GiveOffering,
  Tithe,
  OfferringsContainer,
} from "./styles";
import IconSeedling from "./images/IconSeedling.png";
import IconPayment from "./images/IconPayment.png";
import { FaAngleRight, FaGift, FaHandHoldingHeart } from "react-icons/fa";
import GivingCard from "../../../../Layouts/GivingCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getDonationFormDetails,
  selectDonations,
} from "../../../../ReduxStore/features/User/DonationSlice";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";
import { getSermonDetails } from "../../../../ReduxStore/features/Admin/SermonSlice";

interface SowSeedI {}

const SowSeed: FC<SowSeedI> = () => {
  const { donations } = useAppSelector(selectDonations);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getDonationFormDetails({ name: "pageId", value: e.selected + 1 }));
  };

  return (
    <SowSeedWrap>
      <p
        className="historyspan"
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(handleModal(2));
        }}
      >
        View History
      </p>
      <SowSeedContainer
        onClick={() => {
          dispatch(handleModal(1));
          dispatch(
            getDonationFormDetails({ name: "typeOfPayment", value: "sow-seed" })
          );
        }}
      >
        <img src={IconSeedling} alt="seedlingImage" />
        <span
          className="caption"
          onClick={() => {
            dispatch(handleModal(1));
          }}
        >
          Sow a seed
        </span>
        <FaAngleRight color="white" fontSize={20} cursor="pointer" />
      </SowSeedContainer>

      {/* give offering */}
      <GiveOfferingWrap>
        <GiveOffering
          onClick={() => {
            dispatch(handleModal(1));
            dispatch(
              getDonationFormDetails({
                name: "typeOfPayment",
                value: "offering",
              })
            );
          }}
        >
          <FaGift color="#B603C9" />
          <span className="caption">Give your offering</span>
        </GiveOffering>

        {/* give Tithe */}
        <Tithe
          onClick={() => {
            dispatch(handleModal(1));
            dispatch(
              getDonationFormDetails({
                name: "typeOfPayment",
                value: "tithe",
              })
            );
          }}
        >
          <img className="image" src={IconPayment} alt="icon" />
          <span className="caption">Tithe</span>
        </Tithe>
      </GiveOfferingWrap>

      {/* church donations */}
      <OfferringsContainer>
        {donations?.itemsList?.map((donation: any) => (
          <GivingCard
            cardImage={
              <img
                src="assets/images/GivingImage.png"
                alt="Giving"
                width={400}
              />
            }
            title={donation?.title}
            quote={donation?.description}
            givingIcon={<FaHandHoldingHeart color="white" />}
            bankDetails={donation?.bankAccountId}
            currencyDetails={donation?.currencyId}
            target={donation?.targetAmount}
            amountInPlace={donation?.currentAmount}
          />
        ))}
      </OfferringsContainer>

      <Paginator
        currentPage={donations?.paginator?.currentPage}
        pageCount={donations?.paginator?.pageCount}
        itemCount={donations?.paginator?.itemCount}
        hasNextPage={donations?.paginator?.hasNextPage}
        hasPrevPage={donations?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </SowSeedWrap>
  );
};

export default SowSeed;
