import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface AuthState {
  memberDetails: any;
  creatingChurch: boolean;
  confirmingEmailForReset: boolean;
  resettingPassword: boolean;
  email: string;
  otp: string;
  verifyingEmail: Boolean;

  // find schurch state
  name: string;
  denomination: string;
  country: string;
  state: string;
  findingChurch: boolean;
  foundChurches: any;
}

const initialState: AuthState = {
  memberDetails: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    password: "",
    otp: "",
  },
  creatingChurch: false,
  confirmingEmailForReset: false,
  resettingPassword: false,
  email: "",
  otp: "",
  verifyingEmail: false,

  // find schurch state
  name: "",
  denomination: "",
  country: "",
  state: "",
  findingChurch: false,
  foundChurches: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCreatingChurch: (state, action) => {
      state.creatingChurch = action.payload;
    },

    setConfirmingEmailForReset: (state, action) => {
      state.confirmingEmailForReset = action.payload;
    },

    setResettingPassword: (state, action) => {
      state.resettingPassword = action.payload;
    },
    setVerifyingEmail: (state, action) => {
      state.verifyingEmail = action.payload;
    },
    setFindingChurch: (state, action) => {
      state.findingChurch = action.payload;
    },
    setFoundChurches: (state, action) => {
      state.foundChurches = action.payload;
    },
    getAuthFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    getMemberDetails: (state, action) => {
      state.memberDetails = {
        ...state.memberDetails,
        [action.payload.name]: action.payload.value,
      };
    },
  },
});

export const {
  getMemberDetails,
  setCreatingChurch,
  setConfirmingEmailForReset,
  setResettingPassword,
  setVerifyingEmail,
  getAuthFormDetails,
  setFindingChurch,
  setFoundChurches,
} = authSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
