/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "./Button";
import { BsFillChatFill } from "react-icons/bs";
import RouteWrapper from "../../../../Layouts/RouteWrapper";
import PrayerRequestCard from "../../../../Layouts/TestimonyCard";
// import EditScripture from "./EditScripture";
import { DailyScripturesContainer, TestimonyContainer } from "./style";
import AddTestimony from "./AddTestimony";
import { getAllTestimonies } from "../../../../Apis/UserApis/prayerRequests";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getPrayerWallFormDetails,
  selectUserPrayerWall,
} from "../../../../ReduxStore/features/User/PrayerWallSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import moment from "moment";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const PrayerRequest = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const {
    gettingTestimonies,
    testimonies,
    pageId,
    pageSize,
    creatingTestimonies,
  } = useAppSelector(selectUserPrayerWall);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getPrayerWallFormDetails({ name: "pageId", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getAllTestimonies(pageId, pageSize, userProfile?.churchId?._id);
  }, [pageId, creatingTestimonies]);

  return (
    <RouteWrapper>
      <DailyScripturesContainer>
        <Button
          text="Add a Testimony"
          size="medium"
          handleClick={() => {
            setShowAdd(true);
            setShowEdit(false);
          }}
        />
        {showAdd && !showEdit && <AddTestimony setState={setShowAdd} />}
        {/* {showEdit && !showAdd && <EditScripture setState={setShowEdit} />} */}
        <>
          {gettingTestimonies ? (
            <Loader color={theme?.colors.primaryColor} size={20} />
          ) : (
            <TestimonyContainer>
              {testimonies?.itemsList?.length > 0 ? (
                testimonies?.itemsList?.map((testimony: any) => (
                  <PrayerRequestCard
                    user={
                      testimony?.userId?.lastName +
                      " " +
                      testimony?.userId?.firstName
                    }
                    date={moment(testimony?.createdAt).format("MMM Do YYYY")}
                    reading={testimony?.testimony}
                    replyIcon={<BsFillChatFill />}
                    setEdit={setShowEdit}
                    setAdd={setShowAdd}
                    title={testimony?.title}
                    key={testimony}
                  />
                ))
              ) : (
                <NoRecordDisplay
                  src={"/assets/images/svg/working-remotly.svg"}
                  message="No testimonies available"
                />
              )}
            </TestimonyContainer>
          )}
        </>
      </DailyScripturesContainer>

      <Paginator
        currentPage={testimonies?.paginator?.currentPage}
        pageCount={testimonies?.paginator?.pageCount}
        itemCount={testimonies?.paginator?.itemCount}
        hasNextPage={testimonies?.paginator?.hasNextPage}
        hasPrevPage={testimonies?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </RouteWrapper>
  );
};

export default PrayerRequest;
