import React from "react";
import ChurchActivity from "./ChurchActivity";
import TabSwitch from "../../../../Components/Nav/TabSwitch";
import { userActivitiesTab } from "./util";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import ChurchEvents from "./ChurchEvents";

const ChurchEventsAndActivities = () => {
  const { currentTab } = useAppSelector(selectNav);

  return (
    <div>
      <TabSwitch listItems={userActivitiesTab} />
      {currentTab === 1 ? <ChurchEvents /> : <ChurchActivity />}
    </div>
  );
};

export default ChurchEventsAndActivities;
