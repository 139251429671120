export const SuperAdminPrayersTabs = [
  {
    id: 1,
    tabName: "Prayer Request",
  },
  {
    id: 2,
    tabName: "Testimonies",
  },
  {
    id: 3,
    tabName: "Church Prayers",
  },
  {
    id: 4,
    tabName: "Daily Reflections",
  },
];
