import styled from "styled-components";
import { media } from "../../../../../Screens";

export const CenterContentContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  height: 80vh;
  overflow-y: auto;

  ${media.phone} {
    flex: 1;
  }
`;

export const Post = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;

  h4 {
    margin-top: 0;
  }
`;
