import React, { useEffect } from "react";
import {
  BottomLeftContainer,
  ChurchFinanceContainer,
  LeftContent,
  RightContent,
  TopLeftContainer,
} from "./style";
import Donations from "./Donations";
import Accounts from "./Accounts";
import Wallet from "./Wallet";
import { getUserWalletBalance } from "../../../../Apis/AdminApis/payments";

const ChurchFinance = () => {
  useEffect(() => {
    getUserWalletBalance();
  }, []);

  return (
    <ChurchFinanceContainer>
      <LeftContent>
        <TopLeftContainer>
          <Accounts />
        </TopLeftContainer>
        <BottomLeftContainer>
          <Donations />
        </BottomLeftContainer>
      </LeftContent>
      <RightContent>
        <Wallet />
      </RightContent>
    </ChurchFinanceContainer>
  );
};

export default ChurchFinance;
