import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface mediaState {
  gettingMedia: boolean;
  media: any;
  type: string | null;

  // pagination
  page: number;
  pageSize: number;
}

const initialState: mediaState = {
  gettingMedia: false,
  media: null,
  type: null,

  // pagination
  page: 1,
  pageSize: 30,
};

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingMedia: (state, action) => {
      state.gettingMedia = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
    getMediaFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const { setGettingMedia, setMedia, getMediaFormDetails } =
  mediaSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserMedia = (state: RootState) => state.userMedia;

export default mediaSlice.reducer;
