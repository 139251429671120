import CustomModal from "../CustomModal";
import styled from "styled-components";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  PinterestShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  RedditIcon,
  PinterestIcon,
  EmailIcon,
} from "react-share";
import { media } from "../../Screens";

const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  flex-wrap: wrap;
  gap: 10px;

  ${media.phone} {
    width: 90%;
  }
`;

const SharePostModal = () => {
  const shareUrl = window.location.href;

  return (
    <CustomModal
      id={6}
      content={
        <ShareContainer>
          {/* Facebook */}
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          {/* Twitter */}
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          {/* LinkedIn */}
          <LinkedinShareButton
            url={shareUrl}
            summary="Check out this post on LinkedIn!"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          {/* WhatsApp */}
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          {/* Telegram */}
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          {/* Reddit */}
          <RedditShareButton url={shareUrl}>
            <RedditIcon size={32} round />
          </RedditShareButton>

          {/* Email */}
          <EmailShareButton
            url={shareUrl}
            subject={"Post on the faithfuls"}
            body="Check out this amazing content: "
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </ShareContainer>
      }
    />
  );
};

export default SharePostModal;
