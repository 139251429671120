import { FC } from "react";
import styled from "styled-components";
import { media } from "./../Screens";
import moment from "moment";

const AnnouncementCardContainer = styled.div`
  display: flex;
  background: white;
  padding: 10px 15px;
  flex-direction: column;
  width: 350px;
  // margin-top: 40px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // margin-left: -200px;

  ${media.phone} {
    width: 300px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #b603c9;

  span {
    font-size: 10px;
  }
`;
const DetailsContainer = styled.div`
  font-size: 16px;
  color: #151c4d;
  margin-top: 10px;
`;

interface IAnnoucement {
  title: any;
  details: string;
  date: string;
}

const AnnouncementCard: FC<IAnnoucement> = ({ title, details, date }) => {
  return (
    <AnnouncementCardContainer>
      <TitleContainer>
        {title} : <span>Announced on {moment(date).format("Do MMM YYYY")}</span>
      </TitleContainer>
      <DetailsContainer>{details}</DetailsContainer>
    </AnnouncementCardContainer>
  );
};

export default AnnouncementCard;
