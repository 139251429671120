/* eslint-disable react-hooks/exhaustive-deps */
import { AuthSubTitle } from "../style";
import TextInput from "../../../Components/Form/inputs/TextInput";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../ReduxStore/features/authSlice";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import { toast } from "react-toastify";
import { findChurchToJoin, joinChurch } from "../../../Apis/Auth/signupApis";
import SelectBox from "../../../Components/Form/inputs/SelectBox";
import { churchDenominations } from "./utils";
import { ChurchFilterContainer, ChurchList } from "./styles";
import ChurchCard from "../../../Layouts/ChurchCard";
import NoRecordDisplay from "../../../Components/Messages/NoRecordDisplay";
import { useEffect } from "react";
import { checkIfUserJoinedChurch } from "../../../Apis/UserApis/userProfile";
import { selectUser } from "../../../ReduxStore/features/userSlice";
import { useNavigate } from "react-router-dom";

const FindChurch = () => {
  const dispatch = useAppDispatch();
  const { name, denomination, country, state, findingChurch, foundChurches } =
    useAppSelector(selectAuth);
  const { currentUser } = useAppSelector(selectUser);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (name || denomination || state || country) {
      findChurchToJoin(name, denomination, country, state);
    } else {
      toast.error("Filter box cannot be empty");
    }
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  const callCheckIfUserJoinedChurch = async () => {
    const res: any = await checkIfUserJoinedChurch();
    if (res?.data?.data?.churchId) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (currentUser?.userType === "user") {
      callCheckIfUserJoinedChurch();
    }
  }, []);

  return (
    <>
      <AuthSubTitle>Find a church to join</AuthSubTitle>
      <ChurchFilterContainer>
        <TextInput
          placeholder="Enter church name"
          handleChange={handleChange}
          name="name"
          type="text"
          value={name}
        />
        <SelectBox
          options={churchDenominations}
          name="denomination"
          handleSelect={handleSelect}
          value={denomination}
        />
        <TextInput
          placeholder="Country"
          handleChange={handleChange}
          name="country"
          type="text"
          value={country}
        />
        <TextInput
          placeholder="Enter church name"
          handleChange={handleChange}
          name="state"
          type="text"
          value={state}
        />
        <PrimaryButton
          text="Search"
          handleClick={handleSubmit}
          loading={findingChurch}
        />
      </ChurchFilterContainer>
      <ChurchList>
        {foundChurches?.length === 0 ? (
          <>
            <NoRecordDisplay
              src="/assets/images/svg/working-remotly.svg"
              message="Church not found"
            />
          </>
        ) : (
          foundChurches?.map((church: any) => (
            <ChurchCard
              churchLogo={church?.churchBanner}
              churchLocation={
                church?.address + " " + church?.state + " " + church?.country
              }
              churchTitle={church?.name}
              handleClick={() => {
                joinChurch(church?._id);
              }}
            />
          ))
        )}
      </ChurchList>
    </>
  );
};

export default FindChurch;
