import { FC } from "react";
import { ChatBoxHeader, HeaderAvatar } from "./styles";
import ChatDropDown from "./ChatDropDown";
import { HiUserGroup } from "react-icons/hi";

interface ChatHeaderProps {
  showSidebar: any;
  setShowSidebar: any;
  status?: string;
}
const ChatHeader: FC<ChatHeaderProps> = ({ showSidebar, setShowSidebar }) => {
  return (
    <ChatBoxHeader>
      <HeaderAvatar>LF</HeaderAvatar>
      <div className="end-content">
        <ChatDropDown />
        <HiUserGroup
          onClick={() => setShowSidebar(!showSidebar)}
          className="group-toggle"
        />
      </div>
    </ChatBoxHeader>
  );
};

export default ChatHeader;
