import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DailyReflectionsState {
  gettingDailyReflections: boolean;
  creatingDailyReflection: boolean;
  dailyReflections: any;
}

const initialState: DailyReflectionsState = {
  gettingDailyReflections: false,
  creatingDailyReflection: false,
  dailyReflections: null,
};

export const DailyReflectionsSlice = createSlice({
  name: "DailyReflections",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingDailyReflections: (state, action) => {
      state.gettingDailyReflections = action.payload;
    },
    setCreatingDailyReflections: (state, action) => {
      state.creatingDailyReflection = action.payload;
    },
    setDailyReflections: (state, action) => {
      state.dailyReflections = action.payload;
    },
    getDailyReflectionsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingDailyReflections,
  setDailyReflections,
  getDailyReflectionsFormDetails,
  setCreatingDailyReflections,
} = DailyReflectionsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserDailyReflections = (state: RootState) =>
  state.userDailyReflections;

export default DailyReflectionsSlice.reducer;
