import { FC } from "react";
import { MdFileUpload } from "react-icons/md";
import styled from "styled-components";
import { media } from "./../../../../Screens";
import { theme } from "../../../../theme";

const FileUploadContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
//   border: 1px dashed ${theme.colors.borderColor};
//   border-radius: 5px;
//    padding: 10px;
//   width: 0px;
//   height: -10px;  
//   gap: 10px;
  cursor: pointer;

 

  input {
    display: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82px;
    width: 82px;
    background: rgba(21, 28, 77, 0.1);
    border-radius: 50%;

    ${media.phone} {
      width: 50px; 
      height: 50px;
      margin-top: -5px;
    }
  }

  label {
    color: ${theme.colors.secondaryColor};};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  svg {
    cursor: pointer;
    color: ${theme.colors.bgBlack};
    font-size: 24px;
  }

  ${media.phone} {
    font-size: 14px;
    width: 300px;
    height: 45px;
  }

  .p1{

    font-size: 11px;
    ${media.phone} {
      font-size: 10px;
      border: none;
      margin-bottom: -40px;
      margin-top: -20px;
      } 
  }
  .p2{
    font-size: 8px;
    border: none;
    margin-bottom: -30px;
    margin-top: 0px;

    ${media.phone} {
    font-size: 5px;
    border: none;
    margin-bottom: -15px;
    margin-top: 20px;
    }
  }

  ${media.phone} {
    width: 100px; 
    height: 80px;
    align-items: center;
    justify-content: center;
  }
`;

interface IFilePicker {
  text: string;
  text2?: any;
  handleChange?: any;
  accepts?: string;
}
const FileUpload: FC<IFilePicker> = ({
  text,
  text2,
  handleChange,
  accepts,
}) => {
  return (
    <FileUploadContainer htmlFor="file">
      <div>
        <MdFileUpload height={10} width={10} />
      </div>
      <p className="p1">{text}</p>
      <p className="p2">{text2}</p>
      <input type="file" id="file" accept={accepts} onChange={handleChange} />
    </FileUploadContainer>
  );
};

export default FileUpload;
