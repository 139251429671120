import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import {
  getAdminActivitiesDetails,
  selectAdminActivities,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { editEventMedium } from "../../Apis/AdminApis/ChurchActivities";

const EditEventMediumModal = () => {
  const { editingEventMedium, eventMediumName, eventMediumId } = useAppSelector(
    selectAdminActivities
  );
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  return (
    <CustomModal
      id={18}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextInput
              placeholder="Event Medium Name"
              type="text"
              name="eventMediumName"
              handleChange={handleChange}
              value={eventMediumName}
            />
          </div>

          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              editEventMedium(eventMediumId, eventMediumName);
            }}
            loading={editingEventMedium}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditEventMediumModal;
