export const userActivitiesTab = [
  {
    id: 1,
    tabName: "Event",
  },
  {
    id: 2,
    tabName: "Activities",
  },
];
