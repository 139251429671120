import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setBankAccounts,
  setCreatingAccount,
  setGettingBankAccounts,
  setGettingWallet,
  setWallet,
  setAdminFinancialReport,
  setGettingAdminFinancialReport,
  clearWallet,
} from "../../ReduxStore/features/Admin/WalletSlice";
import api from "../baseUrl";
import { handleModal } from "../../ReduxStore/features/navSlice";
import {
  getWalletFormDetails,
  setDeletingWallet,
  setEditingWallet,
} from "../../ReduxStore/features/SuperAdmin/WalletSlice";
import axios from "axios";

export const getSingleUserWalletBalance = async (userId: string) => {
  const params = {
    userId,
  };
  api
    .get("admin/wallet/wallet-balance", { params })
    .then((res) => {
    })
    .catch((err) => {
    });
};
export const getInitiateFundWallet = async (
  amount: number,
  currencyId: string
) => {
  const body = {
    amount,
    currencyId,
  };
  api
    .post("admin/wallet/initiate-fund-wallet", body)
    .then((res) => {
    })
    .catch((err) => {
    });
};

export const addBankAccount = async (
  accountNumber: string,
  bankName: string,
  accountName: string,
  // visibility: string,
  description: string
) => {
  store.dispatch(setCreatingAccount(true));
  const body = {
    accountNumber,
    bankName,
    accountName,
    // visibility,
    description,
  };

  api
    .post("admin/wallet/bank-accounts", body)
    .then((res) => {
      toast.success("Successful");
      store.dispatch(setCreatingAccount(false));
      store.dispatch(handleModal(0));
      store.dispatch(clearWallet());
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingAccount(false));
    });
};

export const verifyBank = (accountNumber: string, bankName: any) => {
  axios
    .get(
      `https://nubapi.com/api/verify?account_number=${accountNumber}&bank_code=${bankName?.code}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer YMf57wQAbMOKTrAuUEmcewFvrp9aYltAKxnXVgdu6ff73f2a",
        },
      }
    )
    .then((response) => {
      if (response.status === 200 && response.data?.account_name) {
        toast.success("Bank account verified successfully!");
        store.dispatch(
          getWalletFormDetails({ name: "bankVerified", value: true })
        );
        store.dispatch(
          getWalletFormDetails({
            name: "accountName",
            value: response.data?.account_name,
          })
        );
      } else {
        toast.error("Bank verification failed.");
        store.dispatch(
          getWalletFormDetails({ name: "bankVerified", value: false })
        );
        store.dispatch(
          getWalletFormDetails({
            name: "accountName",
            value: "",
          })
        );
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(
          error.response.data?.message ||
            "Verification failed. Please try again."
        );
      } else if (error.request) {
        toast.error("No response from server. Please check your connection.");
      } else {
        toast.error(error.message || "An unknown error occurred.");
      }
    });
};

export const deleteBankAccount = async (bankAccountId: string) => {
  store.dispatch(setDeletingWallet(true));
  const params = {
    bankAccountId,
  };
  api
    .delete("admin/wallet/bank-accounts", { params })
    .then((res) => {
      store.dispatch(setDeletingWallet(false));
      toast.success("successful");
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingWallet(false));
    });
};

export const editBankAccount = async (
  bankAccountId: string,
  accountNumber: string,
  bankName: string,
  accountName: string,
  visibility: string,
  description: string
) => {
  store.dispatch(setEditingWallet(true));

  const body = {
    bankAccountId,
    accountNumber,
    bankName,
    accountName,
    visibility,
    description,
  };
  api
    .put("admin/wallet/bank-accounts", body)
    .then((res) => {
      store.dispatch(setEditingWallet(false));
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingWallet(false));
    });
};

export const getUserBankAccount = async (visibility: string) => {
  store.dispatch(setGettingBankAccounts(true));
  const params: any = {
    visibility,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  api
    .get("admin/wallet/bank-accounts", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBankAccounts(data?.data));
      store.dispatch(setGettingBankAccounts(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBankAccounts(false));
    });
};
export const getCurrencies = async (status?: string) => {
  store.dispatch(setGettingWallet(true));
  const params = {
    status,
  };
  api
    .get("admin/wallet/currency", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setWallet(data?.data));
    })
    .catch((err) => {
      store.dispatch(setGettingBankAccounts(false));
    });
};

export const getAdminFinancialReport = async (
  transactionType?: string,
  status?: string
) => {
  store.dispatch(setGettingAdminFinancialReport(true));
  const params = {
    transactionType,
    status,
  };
  api
    .get("admin/transactions", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdminFinancialReport(data?.data));
      store.dispatch(setGettingAdminFinancialReport(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBankAccounts(false));
      store.dispatch(setGettingAdminFinancialReport(false));
    });
};
