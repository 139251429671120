import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DailyScripturesState {
  gettingDailyScriptures: boolean;
  creatingDailyScripture: boolean;
  deletingDailyScripture: boolean;
  editingDailyScripture: boolean;
  dailyScriptures: any;
  gettingChurchPrayers: boolean;
  creatingChurchPrayer: boolean;
  churchPrayer: any;

  // scriptures states

  bibleName: string;
  chapter: number;
  verse: number;
  scriptureDate: string;
  scriptureId: string;

  // pagination
  pageId: number;
  pageSize: number;
}

const initialState: DailyScripturesState = {
  gettingDailyScriptures: false,
  creatingDailyScripture: false,
  deletingDailyScripture: false,
  editingDailyScripture: false,
  dailyScriptures: null,
  gettingChurchPrayers: false,
  creatingChurchPrayer: false,
  churchPrayer: null,

  bibleName: "",
  chapter: 1,
  verse: 1,
  scriptureDate: "",
  scriptureId: "",

  // pagination
  pageId: 1,
  pageSize: 30,
};

export const DailyScripturesSlice = createSlice({
  name: "DailyScriptures",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingDailyScriptures: (state, action) => {
      state.gettingDailyScriptures = action.payload;
    },
    setCreatingDailyScriptures: (state, action) => {
      state.creatingDailyScripture = action.payload;
    },
    setDeletingDailyScriptures: (state, action) => {
      state.deletingDailyScripture = action.payload;
    },
    setEditingDailyScriptures: (state, action) => {
      state.editingDailyScripture = action.payload;
    },
    setDailyScriptures: (state, action) => {
      state.dailyScriptures = action.payload;
    },
    setCreatingChurchPrayer: (state, action) => {
      state.creatingDailyScripture = action.payload;
    },
    setGettingChurchPrayer: (state, action) => {
      state.creatingDailyScripture = action.payload;
    },
    setChurchPrayer: (state, action) => {
      state.dailyScriptures = action.payload;
    },
    setSelectedScripture: (state, action) => {
      state.bibleName = action.payload?.bookName?._id;
      state.chapter = action.payload?.chapter;
      state.verse = action.payload?.verse;
      state.scriptureDate = action.payload?.scriptureDate;
      state.scriptureId = action.payload?.bibleId;
    },
    getDailyScripturesFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingDailyScriptures,
  setDailyScriptures,
  getDailyScripturesFormDetails,
  setCreatingDailyScriptures,
  setChurchPrayer,
  setCreatingChurchPrayer,
  setGettingChurchPrayer,
  setDeletingDailyScriptures,
  setSelectedScripture,
  setEditingDailyScriptures,
} = DailyScripturesSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserDailyScriptures = (state: RootState) =>
  state.userDailyScriptures;

export default DailyScripturesSlice.reducer;
