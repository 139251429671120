import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectAdminActivities } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import {
  getChurchActivities,
  getChurchEvents,
} from "../../../../Apis/AdminApis/ChurchActivities";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";

// Define types for events and activities
interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  description?: string;
}

// Styled modal component
const Modal = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Activities: React.FC = () => {
  const { churchEvents, churchActivities } = useAppSelector(
    selectAdminActivities
  );
  const [activities, setActivities] = useState<CalendarEvent[]>([]);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [eventData, setEventData] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const sortedEvents = churchEvents?.itemsList?.map((item: any) => ({
      title: item?.title,
      start: moment(item?.startDate).toDate(),
      end: moment(item?.endDate).toDate(),
      description: item?.message,
      location: item?.location?.address,
    }));
    setEvents(sortedEvents);

    const sortedActivities = churchActivities?.itemsList?.map((item: any) => ({
      title: item?.title,
      start: moment(item?.startDate).toDate(),
      end: moment(item?.endDate).toDate(),
      description: item?.message,
      location: item?.location?.address,
    }));
    setActivities(sortedActivities);
  }, [churchActivities, churchEvents]);

  useEffect(() => {
    if (events && activities) {
      setEventData([...activities, ...events]);
    }
  }, [activities, events]);

  const handleEventClick = (clickInfo: any) => {
    setSelectedEvent(clickInfo.event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  useEffect(() => {
    getChurchActivities();
    getChurchEvents();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "20px",
      }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={eventData?.length > 0 ? eventData : []}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventBackgroundColor="transparent"
      />

      {/* Modal to display event details */}
      <Overlay isOpen={isModalOpen} onClick={closeModal} />
      <Modal isOpen={isModalOpen}>
        {selectedEvent && (
          <>
            <h2>{selectedEvent.title}</h2>
            <p>
              <strong>Start: </strong>{" "}
              {moment(selectedEvent.start).format("MMMM Do YYYY, h:mm a")}
            </p>
            <p>
              <strong>End: </strong>{" "}
              {moment(selectedEvent.end).format("MMMM Do YYYY, h:mm a")}
            </p>
            <p>
              <strong>Description: </strong>
              <span>{selectedEvent.extendedProps.description}</span>
            </p>
            <p>
              <strong>Location: </strong>
              <span>{selectedEvent.extendedProps.location}</span>
            </p>
            <PrimaryButton text="Close" handleClick={closeModal} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default Activities;

function renderEventContent(eventInfo: any) {
  const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${theme?.colors?.primaryColor};
    width: 100%;
    flex-wrap: wrap;
  `;


  return (
    <Container>
      <b>{eventInfo?.timeText}</b>
      <b>{eventInfo?.event?.title}</b>
    </Container>
  );
}
