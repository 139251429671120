import React, { FC, useEffect, useState } from "react";
import { FilePickerContainer } from "./styles";
import Loader from "../../Loaders/MoonLoader";

interface IFilePicker {
  text: string;
  name?: string;
  handleChange?: any;
  ImageUrl?: string;
}
const FilePicker: FC<IFilePicker> = ({
  text,
  name,
  handleChange,
  ImageUrl,
}) => {
  const [uploading, setUploading] = useState(false);

  const handleUpload = (e: any) => {
    setUploading(true);
    handleChange(e);
  };

  useEffect(() => {
    setUploading(false);
  }, [ImageUrl]);

  return (
    <div>
      <FilePickerContainer>
        <label htmlFor="file">{uploading ? <Loader size="8" /> : text}</label>
        <input
          type="file"
          id="file"
          accept=".png,.svg,.jpeg,.peg,.webp,.jfif"
          name={name}
          onChange={handleUpload}
        />
        {ImageUrl && (
          <img
            src={ImageUrl}
            style={{ width: `50px`, height: " 50px", }}
            alt="uploaded_img"
          />
        )}
      </FilePickerContainer>
    </div>
  );
};

export default FilePicker;
