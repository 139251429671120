import React from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { CreateChurchPrayerInput } from "../../Utils/authInput";
import { TextArea } from "../../Components/Form/inputs/styles";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import {
  getADminChurchPrayerFormDetails,
  selectAdminChurchPrayerWall,
} from "../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import { editChurchPrayer } from "../../Apis/AdminApis/PrayerWalll";
import { toast } from "react-toastify";

const EditChurchPrayerModal = () => {
  const {
    title,
    text,
    status,
    updatingChurchPrayers,
    verse,
    selectedChurchPrayer,
  } = useAppSelector(selectAdminChurchPrayerWall);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getADminChurchPrayerFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getADminChurchPrayerFormDetails({ name, value }));
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    if (title && text) {
      editChurchPrayer(selectedChurchPrayer?._id, title, text, status, verse);
    } else {
      toast.error("Please Fill all fields");
    }
  };

  return (
    <CustomModal
      id={10.1}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {CreateChurchPrayerInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  value={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    value={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={handleCreate}
            loading={updatingChurchPrayers}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditChurchPrayerModal;
