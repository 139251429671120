import React, { useEffect } from "react";
import AnnouncementCard from "../../../../Layouts/AnnoucementCard";
import { Wrap, AccountCardContainer } from "./styles";
import { getAllAnnouncements } from "../../../../Apis/UserApis/churchAnnouncements";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getAnnouncementFormDetails,
  selectUserAnnouncements,
} from "../../../../ReduxStore/features/User/AnnouncementSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { theme } from "../../../../theme";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import { getUserActiveChurch } from "../../../../Apis/UserApis/userProfile";
import Ads from "../Ads";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const Announcement = () => {
  const { gettingAnnouncements, announcements, page, pageSize } =
    useAppSelector(selectUserAnnouncements);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getAnnouncementFormDetails({ name: "page", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getUserActiveChurch();
  }, []);

  useEffect(() => {
    userProfile &&
      getAllAnnouncements(userProfile?.churchId?._id, page, pageSize);
  }, [userProfile, page]);

  return (
    <>
      <Wrap>
        <div className="left-content">
          {gettingAnnouncements ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader color={theme?.colors.primaryColor} size={20} />
            </div>
          ) : (
            <>
              {announcements?.length > 0 ? (
                <AccountCardContainer>
                  {announcements?.map((item: any) => (
                    <AnnouncementCard
                      title={item?.title}
                      details={item?.message}
                      date={item?.createdAt}
                    />
                  ))}
                </AccountCardContainer>
              ) : (
                <NoRecordDisplay
                  message="No announcements at the moment"
                  src="/assets/images/svg/working-remotly.svg"
                />
              )}
            </>
          )}
        </div>
        {/* 
      <div className="right-content">
        <Ads />
      </div> */}
      </Wrap>

      {/* <Paginator
        currentPage={announcements?.paginator?.currentPage}
        pageCount={announcements?.paginator?.pageCount}
        itemCount={announcements?.paginator?.itemCount}
        hasNextPage={announcements?.paginator?.hasNextPage}
        hasPrevPage={announcements?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      /> */}
    </>
  );
};

export default Announcement;
