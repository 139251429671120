import React, { useState } from "react";
import { AvatarGlass, EditButton } from "./styles";
import Loader from "../../../../Components/Loaders/MoonLoader";

interface UserProfileProps {
  name: string;
  avatarUrl: string;
  loading?: boolean;
  handleUpload?: any;
}

const UserProfile: React.FC<UserProfileProps> = ({
  name,
  avatarUrl,
  loading,
  handleUpload,
}) => {
  const [img, setImage] = useState<string | null>(null); // State for the image preview
  const [file, setFile] = useState<File | null>(null); // State for the selected file

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string); // Set the preview image
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      <AvatarGlass htmlFor="profile">
        <div className="useravatar">
          <img
            className="avatar"
            src={img || avatarUrl || "assets/images/CamIcon.png"} // Show preview image if available
            alt={name}
          />
          <input
            type="file"
            name="profile"
            id="profile"
            style={{ display: "none" }}
            accept="image/*" // Accept all image types
            disabled={loading}
            onChange={handleChange}
          />
        </div>
      </AvatarGlass>
      {img && (
        <EditButton
          onClick={() => {
            handleUpload(file, setImage);
          }}
        >
          {loading ? <Loader size={8} /> : "Save Image"}
        </EditButton>
      )}
    </div>
  );
};

export default UserProfile;
