import { useEffect } from "react";
import { WalletTableCols } from "../../../Utils/SuperAdmin/tableCols";
import CustomTable from "../CustomRTable";
import { WalletTableRow } from "../../../Utils/SuperAdmin/tableRows";
import PrimaryButton from "../../Form/Buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import { IoMdAddCircleOutline } from "react-icons/io";
import { handleModal } from "../../../ReduxStore/features/navSlice";
import { selectSuperAdminWallet } from "../../../ReduxStore/features/SuperAdmin/WalletSlice";
import { getCurrencies } from "../../../Apis/SuperAdminApis/wallet";

const WalletTable = () => {
  const { gettingWallets, Wallets, editingWallet, deletingWallet } =
    useAppSelector(selectSuperAdminWallet);
  const rows = WalletTableRow(Wallets?.itemsList);
  const dispatch = useAppDispatch();

  const data = {
    columns: WalletTableCols,
    rows,
  };

  useEffect(() => {
    getCurrencies();
  }, [editingWallet, deletingWallet]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <PrimaryButton
          text="Create New"
          size="medium"
          icon={<IoMdAddCircleOutline />}
          handleClick={() => dispatch(handleModal(4))}
        />
      </div>
      <CustomTable
        data={data}
        loading={gettingWallets}
        noRecord={Wallets?.itemsList.length > 0 ? false : true}
        noRecordText="No  currency Available"
        currentPage={Wallets?.paginator?.currentPage}
        pageCount={Wallets?.paginator?.pageCount}
        itemCount={Wallets?.paginator?.itemCount}
        hasNextPage={Wallets?.paginator?.hasNextPage}
        hasPrevPage={Wallets?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default WalletTable;
