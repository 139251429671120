import "./App.css";
import Router from "./Router";
import { useEffect } from "react";
import { useAppDispatch } from "./ReduxStore/app/hooks";
import { restoreUser } from "./ReduxStore/features/userSlice";

function App() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(restoreUser(currentUser));
  }, [currentUser, dispatch]);

  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
