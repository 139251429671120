import { FC, useEffect, useState } from "react";
// import LiveStreamButton from "./LiveStreamButton";
// import { FaVideo } from "react-icons/fa";
import VideoAudio from "./VideoAudio";
import {
  AudioSermonCaption,
  TopItems,
  TopItemsWrap,
  VideoSermonCaption,
} from "./style";
import WrittenSermon from "./WrittenSermon";
import VideoSermon from "./VideoSermon";
import AudioSermon from "./AudioSermon";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import CreateTextSermonModal from "../../../../Modals/Admin/CreateTextSermonModal";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import CreateVideoAudioSermon from "../../../../Modals/Admin/CreateVideoAudioSermon";
import { getSermons } from "../../../../Apis/AdminApis/Media";
import {
  getSermonDetails,
  selectAdminChurchSermon,
} from "../../../../ReduxStore/features/Admin/SermonSlice";
import Pagintaor from "../../../../Components/Reusables/Pagintaor";

interface IndexI {}

const Index: FC<IndexI> = () => {
  const [uploadAudio, setUploadAudio] = useState(false);
  const { creatingChurchSermon, adminChurchSermons, pageId, perPage } =
    useAppSelector(selectAdminChurchSermon);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getSermonDetails({ name: "pageId", value: e.selected + 1 }));
  };

  useEffect(() => {
    getSermons(null, pageId, perPage);
  }, [creatingChurchSermon, pageId, perPage]);

  return (
    <>
      <TopItemsWrap>
        <TopItems>
          <PrimaryButton
            text="Create Sermon"
            handleClick={() => dispatch(handleModal(29))}
            size="medium"
          />
          {/* <TextInput placeholder={`Search Sermon`} /> */}
        </TopItems>
        <WrittenSermon />
        <CreateTextSermonModal />
      </TopItemsWrap>
      <TopItemsWrap>
        <TopItems>
          <PrimaryButton
            text="Upload Video / Audio"
            handleClick={() => dispatch(handleModal(30))}
            size="medium"
          />
          {/* <TextInput placeholder={`Search Sermon`} /> */}
        </TopItems>
        <VideoSermonCaption>Video Sermons</VideoSermonCaption>
        <VideoSermon />
        <CreateVideoAudioSermon />
      </TopItemsWrap>
      <TopItemsWrap>
        <TopItems>{/* <TextInput placeholder={`Search Sermon`} /> */}</TopItems>
        <AudioSermonCaption>Audio Sermons</AudioSermonCaption>
        <AudioSermon />
        {uploadAudio && <VideoAudio setState={setUploadAudio} />}
      </TopItemsWrap>
      <Pagintaor
        currentPage={adminChurchSermons?.paginator?.currentPage}
        pageCount={adminChurchSermons?.paginator?.pageCount}
        itemCount={adminChurchSermons?.paginator?.itemCount}
        hasNextPage={adminChurchSermons?.paginator?.hasNextPage}
        hasPrevPage={adminChurchSermons?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default Index;
