import styled from "styled-components";
import { media } from "../../../../Screens";
import { theme } from "../../../../theme";
import { Link } from "react-router-dom";
// Account
export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;
//Donations
export const DonationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

export const ChurchFinanceContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;

  ${media.tablet} {
    flex-direction: column;
    padding: 0;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.8;
  gap: 25px;
`;

export const TopLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-bottom: 1px solid ${theme.colors.secondaryColor};
  padding-bottom: 50px;
`;
export const BottomLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const RightContent = styled.div`
  position: fixed;
  right: 0;
  top: 10vh;
  height: 100vh;
  width: 20%;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  overflow-y: auto;

  ${media.tablet} {
    position: relative;
    width: 100%;
  }

  .wallet {
    position: relative;
    svg {
      position: absolute;
      color: ${theme.colors.primaryColor};
      font-size: 80px;
      top: 25%;
      left: 25%;
    }
  }
`;

export const SubParent = styled.div`
  // position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding: 10px;
  flex: 0.2;
  gap: 15px;
  overflow-y: auto;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
`;

export const SectionTitle = styled.p`
  font-size: 26px;
  font-weight: 400;
  color: ${theme.colors.primaryColor};
  //   font-weight: bold;

  &.wallet-title {
    font-size: 35px;
    font-weight: 500;
  }
`;

export const ActionSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }

  .button_area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    align-items: baseline;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;

export const WalletIcon = styled.img``;

export const SmallText = styled.p`
  line-height: 23px;
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.secondaryColor};
`;

export const SmallLinkText = styled(Link)`
  line-height: 23px;
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.secondaryColor};
  border-bottom: 1px solid ${theme.colors.secondaryColor};
  text-decoration: none;
`;

export const VerySmallText = styled.p`
  line-height: 23px;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.secondaryColor};

  &.bold {
    font-weight: bold;
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .right-content {
    display: flex;
  }
`;
