import { useEffect } from "react";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { addLocationInputs } from "../../Utils/authInput";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import {
  getAdminActivitiesDetails,
  selectAdminActivities,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { CreateEventLocation } from "../../Apis/AdminApis/ChurchActivities";
import { selectStyles } from "../../Components/Form/inputs/styles";
import Select from "react-select";
import { getUserActiveChurch } from "../../Apis/UserApis/userProfile";
import { toast } from "react-toastify";

const CreateEventLocationModal = () => {
  const { locationAddress, locationMedium, creatingEventLocation } =
    useAppSelector(selectAdminActivities);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  const handleSelect = (e: any, name: any) => {
    dispatch(getAdminActivitiesDetails({ name, value: e.value }));
  };

  const handleCreateLocation = (e: any) => {
    e.preventDefault();
    if (locationAddress && locationMedium) {
      CreateEventLocation(
        locationAddress,
        locationMedium,
        userProfile?.churchId?._id
      );
    } else {
      toast.error("Please fill all fields to create location")
    }
  };

  useEffect(() => {
    getUserActiveChurch();
  }, []);

  return (
    <CustomModal
      id={19}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {addLocationInputs?.map((input: any) => (
            <>
              {input?.type === "select" ? (
                <Select
                  options={input?.options()}
                  name={input?.name}
                  onChange={(e: any) => handleSelect(e, input?.name)}
                  styles={selectStyles}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={handleCreateLocation}
            loading={creatingEventLocation}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateEventLocationModal;
