import { useEffect } from "react";
import FreePlanCard from "./FreePlanCard";
import { media } from "./../../../../Screens";
import LastPaymentCard from "./LastPaymentCard";
import styled from "styled-components";
import ProPlanCard from "./ProPlanCard";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectSuperAdminSubscriptions } from "../../../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import {
  getCurrentSubscription,
  getUserSubscription,
} from "../../../../Apis/AdminApis/subscriptions";
import moment from "moment";

const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.phone} {
    flex-direction: column;
  }
`;

const RightWrap = styled.div`
  display: flex;
  // flex-direction: column;
  align-items: center;
  gap: 20px;

  ${media.phone} {
    flex-direction: column;
  }
`;

const PlansWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const Subscription = () => {
  const {
    gettingSubscriptions,
    subscriptions,
    gettingCurrentSubscription,
    currentSubscription,
  } = useAppSelector(selectSuperAdminSubscriptions);

  useEffect(() => {
    getUserSubscription();
    getCurrentSubscription();
  }, []);

  return (
    <MainWrap>
      <RightWrap>
        {currentSubscription && subscriptions?.itemsList?.length > 0 ? (
          subscriptions?.itemsList
            ?.slice(0, 1)
            ?.map((sub: any, index: number) => (
              <FreePlanCard
                key={index + 1}
                yearSub={
                  sub?.durationInDays < 365
                    ? "Your monthly subscription"
                    : "Your yearly subscription"
                }
                dateRenew={
                  "Renew date: " +
                  moment()
                    .add(sub?.durationInDays, "days")
                    .calendar(`Do MM YYYY`)
                }
                planLabel="Your Current Plan:"
                planName={sub.title}
                planAmount={sub.amount}
                loading={gettingCurrentSubscription}
              />
            ))
        ) : (
          // <NoRecordDisplay message="No Current Subscription" />
          <FreePlanCard
            yearSub={"Your plan Details"}
            planLabel="Your Current Plan:"
            planName={`Free Plan`}
            planAmount={`free`}
            loading={gettingCurrentSubscription}
          />
        )}
        <LastPaymentCard />
      </RightWrap>
      <PlansWrapper>
        {subscriptions?.itemsList?.length > 0 ? (
          subscriptions?.itemsList
            ?.slice()
            .sort((a: any, b: any) => a.amount - b.amount)
            ?.map((sub: any, index: number) => (
              <ProPlanCard
                details={sub}
                loading={gettingSubscriptions}
                key={index + 1}
              />
            ))
        ) : (
          <NoRecordDisplay message="Subscription plans not available" />
        )}
      </PlansWrapper>
    </MainWrap>
  );
};

export default Subscription;
