import { LeftContentContainer, UserProfile } from "./style";
import { useAppSelector } from "../../../../../ReduxStore/app/hooks";
import { selectUserProfile } from "../../../../../ReduxStore/features/User/UserProfileSlice";

const LeftContent = () => {
  const { userProfileDetails } = useAppSelector(selectUserProfile);

  return (
    <LeftContentContainer>
      <UserProfile>
        <img src={userProfileDetails?.profileUrl} alt="User Avatar" />
        <h3>
          {userProfileDetails?.firstName + " " + userProfileDetails?.lastName}
        </h3>
      </UserProfile>
    </LeftContentContainer>
  );
};

export default LeftContent;
