// import { getVerifiedChurch } from "../../../Apis/AdminApis/dashboard";
import { verifiedChurchesTableCols } from "../../../Utils/SuperAdmin/tableCols";
import { verifiedChurshesTableRow } from "../../../Utils/SuperAdmin/tableRows";
import CustomTable from "../CustomRTable";
import { useEffect } from "react";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
// import { selectSuperAdminManageChurches } from "../../../ReduxStore/features/SuperAdmin/manageChurchesSlice";
import { getChurches } from "../../../Apis/SuperAdminApis/dashboard";
import { selectSuperAdminOverview } from "../../../ReduxStore/features/SuperAdmin/OverviewSlice";

const VerifiedChurchesTable = () => {
  const { gettingChurches, verifiedChurches, churches } = useAppSelector(
    selectSuperAdminOverview
  );
  const rows = verifiedChurshesTableRow(verifiedChurches);

  const data = {
    columns: verifiedChurchesTableCols,
    rows,
  };

  useEffect(() => {
    getChurches();
  }, []);

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingChurches}
        noRecord={verifiedChurches?.length > 0 ? false : true}
        noRecordText="No  Verified Church Available"
        currentPage={churches?.paginator?.currentPage}
        pageCount={churches?.paginator?.pageCount}
        itemCount={churches?.paginator?.itemCount}
        hasNextPage={churches?.paginator?.hasNextPage}
        hasPrevPage={churches?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default VerifiedChurchesTable;
