import styled from "styled-components";
import { media } from "./../../../../Screens";
import { theme } from "../../../../theme";

interface StyleProp {
  showSidebar: boolean;
}

export const ChatWrapper = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  height: 100vh;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 100, 0.2);
  position: relative;
`;

export const LeftSection = styled.div<StyleProp>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-right: 3px solid ${theme?.colors?.primaryColor};
  flex: 0.2;
  gap: 10px;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;

  ${media.phone} {
    flex: 0;
    display: ${(props) => (props?.showSidebar ? "flex" : "none")};
    position: absolute;
    background: #fff;
    z-index: 22222222;
    height: 500px;
    box-shadow: 0px 7px 29px 19px rgba(100, 100, 100, 0.2);
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.8;
  ${media.phone} {
    flex: 1;
  }
`;

export const SingleGroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${theme.colors.lightBorderColor};
  width: 100%;
  padding-bottom: 20px;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.bgPrimary};
  }
`;

export const GroupLogo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid ${theme?.colors?.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 10px;
  color: ${theme.colors.textGreycolor};
  font-weight: 600;

  ${media.phone} {
    width: 30px;
    height: 30px;
    padding: 8px;
    font-size: 18px;
  }
`;

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const GroupName = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.textGreycolor};

  ${media.phone} {
    font-size: 14px;
  }
`;

export const LastMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.primaryColor};
  ${media.phone} {
    font-size: 10px;
  }
`;

// chat box

export const ChatBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChatBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0.1;
  width: 100%;
  filter: drop-shadow(0px 5px 10px rgba(65, 14, 80, 0.161));
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 0 10px;

  .end-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .group-toggle {
    display: none;

    ${media.phone} {
      display: block;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const HeaderAvatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #cfdbff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #4d79ff;
  text-transform: uppercase;
  position: relative;
  padding: 20px;

  &::after {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background: #9afca1;
    bottom: -7px;
    right: 7px;
  }
`;

export const MessageSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.8;
  width: 100%;
  padding: 20px;
  gap: 10px;
  overflow-y: auto;
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  flex: 0.1;
  width: 100%;
  filter: drop-shadow(0px 5px 10px rgba(65, 14, 80, 0.161));
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  gap: 10px;
  padding: 10px;

  svg {
    font-size: 20px;
    color: ${theme.colors.textGreycolor};
    cursor: pointer;
  }
`;

export const TextInput = styled.input`
  display: flex;
  background: #fff;
  border: 1px solid ${theme.colors.borderColor};
  width: 90%;
  height: 70%;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  resize: none;
`;

export const BlankContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(https://th.bing.com/th/id/OIP.gy3WSZmI-zc-ngq8CI7qTQHaHa?pid=ImgDet&w=188&h=188&c=7&dpr=1.3);
  background-size: cover;
  background-repeat: no-repeat;
`;
