import { useState, useEffect } from "react";
import {
  BlankContainer,
  ChatWrapper,
  LeftSection,
  RightSection,
} from "./styles";
import SingleGroup from "./SingleGroup";
import MessageBox from "./MessageBox";
import InputBox from "./InputBox";
import ChatHeader from "./ChatHeader";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserCgroup } from "../../../../ReduxStore/features/User/UserGroupSlice";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { joinGroup } from "../../../../Apis/UserApis/userChurchGroup";

const ChatSection = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [isGroupMember, setIsGroupMember] = useState<boolean>(false);
  const {
    userGroups,
    joinedGroups,
    churchGroupId,
    requestingToJoinGroup,
  } = useAppSelector(selectUserCgroup);

  useEffect(() => {
    const checkIfGroupMember =
      joinedGroups &&
      joinedGroups?.itemsList?.filter((x: any) => x?.groupId === churchGroupId);
    if (checkIfGroupMember?.length > 0) setIsGroupMember(true);
    else setIsGroupMember(false);
  }, [joinedGroups, churchGroupId, requestingToJoinGroup]);

  return (
    <ChatWrapper>
      <LeftSection showSidebar={showSidebar}>
        {userGroups?.map((group: any) => (
          <SingleGroup groupDetails={group} />
        ))}
      </LeftSection>
      <RightSection>
        {churchGroupId ? (
          <>
            {isGroupMember ? (
              <>
                <ChatHeader
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
                <MessageBox />
                <InputBox />
              </>
            ) : (
              <BlankContainer>
                <PrimaryButton
                  text="Join Group"
                  loading={requestingToJoinGroup}
                  handleClick={() => joinGroup(churchGroupId)}
                />
              </BlankContainer>
            )}
          </>
        ) : (
          <BlankContainer>
            <NoRecordDisplay message="Select a group to chat" />
          </BlankContainer>
        )}
      </RightSection>
    </ChatWrapper>
  );
};

export default ChatSection;
