import React from "react";
import { membershipReportTableCols } from "../../../Utils/SuperAdmin/tableCols";
import CustomTable from "../CustomRTable";
import { membershipReportTableRow } from "../../../Utils/SuperAdmin/tableRows";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
import { selectAdminManageChurches } from "../../../ReduxStore/features/Admin/manageChurchesSlice";

const MembershipReportLogs = () => {
  const { gettingAdminChurchMembers, adminChurchMembers } = useAppSelector(
    selectAdminManageChurches
  );
  const rows = membershipReportTableRow(adminChurchMembers?.itemsList);

  const data = {
    columns: membershipReportTableCols,
    rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingAdminChurchMembers}
        pageCount={adminChurchMembers?.paginator?.pageCount}
        itemCount={adminChurchMembers?.paginator?.itemCount}
        hasNextPage={adminChurchMembers?.paginator?.hasNextPage}
        hasPrevPage={adminChurchMembers?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default MembershipReportLogs;
