import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { TextArea } from "../../Components/Form/inputs/styles";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getSermonDetails,
  selectAdminChurchSermon,
} from "../../ReduxStore/features/Admin/SermonSlice";
import { createSermon } from "../../Apis/AdminApis/Media";

const CreateTextSermonModal = () => {
  const { title, sermon, fileUrl, creatingChurchSermon, errorMessage } =
    useAppSelector(selectAdminChurchSermon);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSermonDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSermonDetails({ name, value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault(title, "text", sermon, fileUrl);
    if (title && sermon) {
      dispatch(
        getSermonDetails({
          name: "errorMessage",
          value: "",
        })
      );
      createSermon(title, "text", sermon);
    } else {
      dispatch(
        getSermonDetails({
          name: "errorMessage",
          value: "Enter Title and Sermon",
        })
      );
    }
  };

  return (
    <CustomModal
      id={29}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <TextInput
            placeholder="Add title"
            value={title}
            name="title"
            handleChange={handleChange}
          />
          <TextArea
            placeholder="Enter Sermon"
            value={sermon}
            name="sermon"
            onChange={handleTextAreaChange}
          />
          <PrimaryButton
            text="Publish"
            handleClick={handleSubmit}
            size="medium"
            loading={creatingChurchSermon}
          />
          <span className="text-red">{errorMessage}</span>
        </FormWrapper>
      }
    />
  );
};

export default CreateTextSermonModal;
