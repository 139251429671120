import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ManageChurchesState {
  gettingAdminChurches: boolean;
  adminChurches: any;
  gettingAdminChurchMembers: boolean;
  deletingChurchMember: boolean;
  adminChurchMembers: any;
  gettingVerifiedChurches: boolean;
  verifiedChurches: any;
  gettingUnVerifiedChurches: boolean;
  unverifiedChurches: any;
  verifyingChurch: boolean;
  activeMembers: any;
  inActiveMembers: any;
}

const initialState: ManageChurchesState = {
  gettingVerifiedChurches: false,
  verifiedChurches: null,
  gettingAdminChurchMembers: false,
  deletingChurchMember: false,
  adminChurchMembers: null,
  gettingAdminChurches: false,
  adminChurches: null,
  gettingUnVerifiedChurches: false,
  unverifiedChurches: null,
  verifyingChurch: false,
  activeMembers: null,
  inActiveMembers: null,
};

export const ManageChurchesSlice = createSlice({
  name: "ManageChurches",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingVerifiedChurches: (state, action) => {
      state.gettingVerifiedChurches = action.payload;
    },
    setVerifiedChurches: (state, action) => {
      state.verifiedChurches = action.payload;
    },
    setGettingAdminChurches: (state, action) => {
      state.gettingAdminChurches = action.payload;
    },
    setAdminChurches: (state, action) => {
      state.adminChurches = action.payload;
    },
    setGettingAdminChurchMembers: (state, action) => {
      state.gettingAdminChurchMembers = action.payload;
    },
    setDeletingChurchMember: (state, action) => {
      state.deletingChurchMember = action.payload;
    },
    setAdminChurchMembers: (state, action) => {
      state.adminChurchMembers = action.payload;
      state.activeMembers = action.payload?.itemsList.filter((item: any) => item.status === "active");
      state.inActiveMembers = action.payload?.itemsList.filter((item: any) => item.status === "inactive");
    },
    setGettingUnVerifiedChurches: (state, action) => {
      state.gettingUnVerifiedChurches = action.payload;
    },
    setUnVerifiedChurches: (state, action) => {
      state.unverifiedChurches = action.payload;
    },
    setVerifyingChurch: (state, action) => {
      state.verifyingChurch = action.payload;
    },
  },
});

export const {
  setGettingVerifiedChurches,
  setVerifiedChurches,
  setGettingAdminChurchMembers,
  setAdminChurchMembers,
  setGettingUnVerifiedChurches,
  setUnVerifiedChurches,
  setVerifyingChurch,
  setGettingAdminChurches,
  setAdminChurches,
  setDeletingChurchMember,
} = ManageChurchesSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminManageChurches = (state: RootState) => state.adminManageChurches;

export default ManageChurchesSlice.reducer;
