import React from "react";
import Activities from "./Activities";
import TabSwitch from "../../../../Components/Nav/TabSwitch";
import { churchActivitiesTabs } from "../../../../Utils/tabsNav";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import ManageActivities from "./ManageActivities";
import ManageEvents from "./ManageEvents";
import ManageEventMedium from "./ManageEventMedium";
import ManageLiveStreams from "./ManageLiveStreams";

const App: React.FC = () => {
  const { currentTab } = useAppSelector(selectNav);

  return (
    <div>
      <TabSwitch listItems={churchActivitiesTabs} />

      {currentTab === 1 ? (
        <Activities />
      ) : currentTab === 2 ? (
        <ManageActivities />
      ) : currentTab === 3 ? (
        <ManageEvents />
      ) : currentTab === 4 ? (
        <ManageEventMedium />
      ) : (
        <ManageLiveStreams />
      )}
    </div>
  );
};

export default App;
