import { FC } from "react";
import {
  GroupLogo,
  GroupName,
  LastMessage,
  SingleGroupContainer,
  GroupDetails,
} from "./styles";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import { getUserGroupDetails } from "../../../../ReduxStore/features/User/UserGroupSlice";

interface groupDetailsProps {
  groupDetails: any;
}

const SingleGroup: FC<groupDetailsProps> = ({ groupDetails }) => {
  const dispatch = useAppDispatch();

  const handleGroupSelect = () => {
    dispatch(
      getUserGroupDetails({
        name: "churchGroupId",
        value: groupDetails?.churchGroupId,
      })
    );
  };

  return (
    <SingleGroupContainer onClick={handleGroupSelect}>
      <GroupLogo>{groupDetails?.name?.substring(0, 2)}</GroupLogo>
      <GroupDetails>
        <GroupName>{groupDetails?.name}</GroupName>
        <LastMessage>{groupDetails?.membersCount} Member(s)</LastMessage>
      </GroupDetails>
    </SingleGroupContainer>
  );
};

export default SingleGroup;
