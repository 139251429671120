import {
  setCreatingRole,
  setEditingRole,
  setGettingInternalSettings,
  setGettingRoles,
  setInternalSettings,
  setRoles,
  setUpdatingInternalSettings,
} from "../../ReduxStore/features/SuperAdmin/ConfigurationsSlice";
import api from "../baseUrl";
import { store } from "./../../ReduxStore/app/store";
import { toast } from "react-toastify";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const getInternalSettings = async () => {
  store.dispatch(setGettingInternalSettings(true));
  await api
    .get("super-admin/settings/internal-settings")
    .then((res) => {
      const { data } = res;
      store.dispatch(setInternalSettings(data?.data));
      store.dispatch(setGettingInternalSettings(false));
    })
    .catch(() => {
      store.dispatch(setGettingInternalSettings(false));
    });
};

export const editInternalSettings = async (
  defaultSignupSubscriptionPackageId: string,
  subscriptionPaymentMethod: string,
  subscriptionPaymentGracePeriodInGrace: number,
  defaultChurchCreatorRoleId: string,
) => {
  store.dispatch(setUpdatingInternalSettings(true));
  const body: any = {
    defaultSignupSubscriptionPackageId,
    subscriptionPaymentMethod,
    subscriptionPaymentGracePeriodInGrace,
    defaultChurchCreatorRoleId,
  };

  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .put("super-admin/settings/internal-settings", body)
    .then(() => {
      store.dispatch(setUpdatingInternalSettings(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingInternalSettings(false));
    });
};

export const createAppRole = async (title: string, description: string) => {
  store.dispatch(setCreatingRole(true));
  const body = {
    title,
    description,
  };
  await api
    .post("super-admin/settings/create-app-roles", body)
    .then((res) => {
      store.dispatch(setCreatingRole(false));
      toast.success("successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingRole(false));
    });
};

export const editAppRole = async (
  roleId: string,
  title: string,
  description: string,
  status: string,
) => {
  store.dispatch(setEditingRole(true));
  const body: any = {
    roleId,
    title,
    description,
    status,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .put("super-admin/settings/edit-role", body)
    .then((res) => {
      store.dispatch(setEditingRole(false));
      store.dispatch(handleModal(0));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingInternalSettings(false));
    });
};

export const getAppRoles = async () => {
  store.dispatch(setGettingRoles(true));
  await api
    .get("super-admin/settings/retrieve-app-roles")
    .then((res) => {
      const { data } = res;
      store.dispatch(setRoles(data?.data));
      store.dispatch(setGettingRoles(false));
    })
    .catch((err) => {
      store.dispatch(setGettingRoles(false));
    });
};
