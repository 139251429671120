import React from "react";
import styled from "styled-components";
import TextInput from "../../../../Components/Form/inputs/TextInput";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import { getUserProfileFormDetails } from "../../../../ReduxStore/features/User/UserProfileSlice";

const Modal = ({ show, onClose, onConfirm, deleting }: any) => {
  const dispatch = useAppDispatch();
  if (!show) {
    return null;
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(getUserProfileFormDetails({ name, value }));
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Confirm Account Deletion</h2>
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>

        <TextInput
          placeholder={"Enter password to confirm delete"}
          handleChange={handleChange}
          type="password"
          name="confirmDeletePassword"
        />
        <ModalButtons>
          <button className="cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="confirm" onClick={onConfirm}>
            {deleting ? "Deleting..." : "Delete"}
          </button>
        </ModalButtons>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;

  .cancel {
    background-color: #6e6e6e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      background-color: #5a5a5a;
    }
  }

  .confirm {
    background-color: #b603c9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;
