import React, { FC } from "react";
import ReactPaginate from "react-paginate";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { PageCount, PaginationContainer } from "../Tables/styles";

interface PaginatorProps {
  itemCount?: any;
  perPage?: any;
  pageCount?: any;
  currentPage?: any;
  pagingCounter?: any;
  hasPrevPage?: any;
  hasNextPage?: any;
  prev?: any;
  next?: any;
  handlePageChange?: any;
}

const Paginator: FC<PaginatorProps> = ({
  currentPage,
  hasNextPage,
  hasPrevPage,
  itemCount,
  next,
  pageCount,
  pagingCounter,
  perPage,
  prev,
  handlePageChange,
}) => {
  return (
    <PaginationContainer>
      <PageCount>Total Records: {itemCount}</PageCount>
      <ReactPaginate
        previousLabel={hasPrevPage && <PiCaretLeftBold />}
        nextLabel={hasNextPage && <PiCaretRightBold />}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={"paginationBttns"}
        activeClassName={"paginationActive"}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        initialPage={currentPage - 1}
        previousClassName={"prev"}
        nextClassName={"next"}
        breakLabel="..."
        breakClassName={"break-me"}
      />
    </PaginationContainer>
  );
};

export default Paginator;
