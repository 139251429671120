import React from "react";
import Group from "./Group";
import EditGroupModal from "../../../../Modals/Admin/EditGroupModal";
import CreateGroupModal from "../../../../Modals/Admin/CreateGroupModal";
import InviteGroupMember from "../../../../Modals/Admin/InviteGroupMemberModal";

const App: React.FC = () => {
  return (
    <div>
      <Group />
      <EditGroupModal />
      <CreateGroupModal />
      <InviteGroupMember />
    </div>
  );
};

export default App;
