import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { getTrendingPosts } from "./../../../../Apis/UserApis/Post";
import {
  getPostFormDetails,
  selectAdminPost,
} from "../../../../ReduxStore/features/Admin/AdminPostSlice";
import {
  approveAndDisapprovePost,
  getPendingPosts,
} from "../../../../Apis/AdminApis/Post";
import { theme } from "../../../../theme";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { CardsContainer } from "../Prayers/style";
import SinglePostCard from "../../../../Layouts/PostCard";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const PendingPosts = () => {
  const { pendingPosts, gettingPendingPosts, page, pageSize } =
    useAppSelector(selectAdminPost);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getPostFormDetails({ name: "page", value: e.selected + 1 }));
  };

  useEffect(() => {
    getPendingPosts(pageSize, page);
  }, [page]);

  return (
    <>
      {gettingPendingPosts ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <>
          {pendingPosts?.docs?.length > 0 ? (
            <CardsContainer>
              {pendingPosts?.docs?.map((post: any) => (
                <SinglePostCard
                  username={
                    post?.userId?.firstName + " " + post?.userId?.lastName
                  }
                  avatar={
                    "https://th.bing.com/th?id=OIP.L8bs33mJBAUBA01wBfJnjQHaHa&w=250&h=250&c=8&rs=1&qlt=30&o=6&pid=3.1&rm=2"
                  }
                  content={post?.description}
                  media={post?.contentUrl}
                  likes={post?.likes}
                  disLikes={post?.dislikes}
                  comments={post?.comments}
                  hashTags={post?.hashtags}
                  contentType={post?.contentType}
                  title={post?.title}
                  createdAt={post?.createdAt}
                  postId={post?._id}
                  approveFunction={() =>
                    approveAndDisapprovePost(post?._id, "true")
                  }
                  declineFunction={() =>
                    approveAndDisapprovePost(post?._id, "true")
                  }
                />
              ))}
            </CardsContainer>
          ) : (
            <NoRecordDisplay
              message="No declined post at the moment"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}

      <Paginator
        currentPage={pendingPosts?.page}
        pageCount={pendingPosts?.totalPages}
        itemCount={pendingPosts?.totalDocs}
        hasNextPage={pendingPosts?.hasNextPage}
        hasPrevPage={pendingPosts?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default PendingPosts;
