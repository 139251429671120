import { useEffect, useState } from "react";
import AudioSermonCard from "./AudioSermonCard";
import { AudioSermonContainer } from "./styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getSermonDetails,
} from "../../../../ReduxStore/features/Admin/SermonSlice";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import Paginator from "../../../../Components/Reusables/Pagintaor";
import { selectUserMedia } from "../../../../ReduxStore/features/User/MediaSlice";

const AudioSermon = () => {
  const { gettingMedia, media } = useAppSelector(selectUserMedia);
  const [audioSermons, setAudioSermons] = useState<any>();
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getSermonDetails({ name: "pageId", value: e.selected + 1 }));
  };

  useEffect(() => {
    const audioSermonFilter = media?.itemsList?.filter(
      (x: any) => x.sermonType === "audio"
    );
    setAudioSermons(audioSermonFilter);
  }, [media]);

  return (
    <>
      <AudioSermonContainer>
        {gettingMedia ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : audioSermons?.length > 0 ? (
          audioSermons?.map((item: any) => (
            <AudioSermonCard audioTitle={item?.title} audioSrc={item?.link} />
          ))
        ) : (
          <NoRecordDisplay
            message="Audio Sermon not Available"
            src="/assets/images/svg/working-remotly.svg"
          />
        )}
      </AudioSermonContainer>
      <Paginator
        currentPage={media?.paginator?.currentPage}
        pageCount={media?.paginator?.pageCount}
        itemCount={media?.paginator?.itemCount}
        hasNextPage={media?.paginator?.hasNextPage}
        hasPrevPage={media?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default AudioSermon;
