import { FC } from "react";
import styled from "styled-components";
import { completePaystackPayment } from "../../../../Apis/AdminApis/payments";

const ViewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-bottom: 15px;
  border-radius: 5px;
  background: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px 5px;
  align-items: center;
`;

const UserName = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

const HistoryType = styled.div`
  font-size: 12px;
`;

interface statusProp {
  status?: string;
}
const TransactionAmmount = styled.div<statusProp>`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) =>
    props?.status === "paid"
      ? "green"
      : props.status === "pending"
      ? "orange"
      : "red"};

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Date = styled.div`
  display: flex;
  font-size: 12px;
  color: #151c4d;
  font-weight: 500;
`;
const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7px 5px;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 50px;
`;

const FlexColContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
`;

interface IViewHistoryCard {
  userName: string;
  historyType: any;
  transAmmount: number;
  date?: string;
  status?: string;
  reference: string;
}

const ViewHistoryCard: FC<IViewHistoryCard> = ({
  userName,
  historyType,
  transAmmount,
  date,
  status,
  reference,
}) => {
  return (
    <ViewCardContainer>
      <Div>
        <Date>
          <div className="App">
            <p style={{ color: "#B603C9 " }}> {date}</p>
          </div>
        </Date>
        <Main>
          <FlexColContainer>
            <UserName>{userName} </UserName>
            <HistoryType>{historyType} </HistoryType>
          </FlexColContainer>
          <FlexColContainer>
            <TransactionAmmount status={status}>{status}</TransactionAmmount>
            <TransactionAmmount status={status}>
              ₦ {transAmmount}{" "}
              {status === "pending" && (
                <span
                  onClick={() =>
                    completePaystackPayment(transAmmount, userName, reference)
                  }
                >
                  (Complete Payment){" "}
                </span>
              )}
            </TransactionAmmount>
          </FlexColContainer>
        </Main>
      </Div>
    </ViewCardContainer>
  );
};

export default ViewHistoryCard;
