import CreateSubscriptionModal from "../../../../Modals/CreateSubscriptionModal";
import EditSubscriptionModal from "../../../../Modals/EditSubscriptionModal";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import SubscriptionsTable from "../../../../Components/Tables/SuperAdmin/SubscriptionTable";
import InnerHeader from "./../../../../Components/Nav/InnerHeader";
import { subscriptionInnerNav } from "./../../../../Utils/innerHeaderNavs";
import PaymentHistoryTable from "../../../../Components/Tables/SuperAdmin/PaymentHistoryTable";

const Subscription = () => {
  const { currentInnerNav } = useAppSelector(selectNav);

  return (
    <>
      <InnerHeader listItems={subscriptionInnerNav} />
      <div style={{ marginTop: "100px" }}></div>
      {currentInnerNav === 1 ? <SubscriptionsTable /> : <PaymentHistoryTable />}
      {/* <SubSearchBox /> */}
      <EditSubscriptionModal />
      <CreateSubscriptionModal />
    </>
  );
};

export default Subscription;
