import React from "react";
import {
  LogoutButton,
  LogoutContainer,
  Navlink,
  NavlinkContainer,
  SidebarCollapseIcon,
  SidebarContainer,
} from "./styles";
import { useAppSelector } from "./../../ReduxStore/app/hooks";
import { removeUser, selectUser } from "./../../ReduxStore/features/userSlice";
import { rolesRouters } from "./../../Utils/config";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectNav,
  SidebarCollapse,
  SidebarToggle,
} from "./../../ReduxStore/features/navSlice";
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { RxCaretLeft } from "react-icons/rx";
const Sidebar = () => {
  const { pathname } = useLocation();
  const { currentUser } = useAppSelector(selectUser);
  const { toggleSidebar, collapseSidebar } = useAppSelector(selectNav);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <SidebarContainer
      isToggled={toggleSidebar}
      collapseSidebar={collapseSidebar}
    >
      <SidebarCollapseIcon>
        <RxCaretLeft
          className={`${collapseSidebar && "collapse"}`}
          onClick={() => dispatch(SidebarCollapse(!collapseSidebar))}
        />
      </SidebarCollapseIcon>
      <NavlinkContainer>
        {currentUser?.userType === "user" ? (
          <>
            {rolesRouters?.user.map((nav) => (
              <Navlink
                to={nav?.route}
                className={`${pathname === nav?.route && "active"}`}
                onClick={() => {
                  dispatch(SidebarToggle(false));
                  dispatch(SidebarCollapse(true));
                }}
              >
                {nav?.icon}
                {!collapseSidebar && <span>{nav?.nav}</span>}
              </Navlink>
            ))}
          </>
        ) : currentUser?.userType === "superuser" ? (
          <>
            {rolesRouters?.superAdmin.map((nav) => (
              <Navlink
                to={nav?.route}
                className={`${pathname === nav?.route && "active"}`}
                onClick={() => {
                  dispatch(SidebarToggle(false));
                  dispatch(SidebarCollapse(true));
                }}
              >
                {nav?.icon}
                {!collapseSidebar && <span>{nav?.nav}</span>}
              </Navlink>
            ))}
          </>
        ) : (
          <>
            {rolesRouters?.admin.map((nav) => (
              <Navlink
                to={nav?.route}
                className={`${pathname === nav?.route && "active"}`}
                onClick={() => {
                  dispatch(SidebarToggle(false));
                  dispatch(SidebarCollapse(true));
                }}
              >
                {nav?.icon}
                {!collapseSidebar && <span>{nav?.nav}</span>}
              </Navlink>
            ))}
          </>
        )}
        <LogoutContainer>
          <LogoutButton
            onClick={() => {
              dispatch(removeUser());
              navigate("/");
            }}
          >
            <MdLogout />
            {!collapseSidebar && <span>Logout</span>}
          </LogoutButton>
        </LogoutContainer>
      </NavlinkContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
