import {
  setGettingUsers,
  setUsers,
} from "../../ReduxStore/features/SuperAdmin/userManagerSlice";
import api from "../baseUrl";
import { store } from "./../../ReduxStore/app/store";

export const getUsers = async () => {
  store.dispatch(setGettingUsers(true));
  await api
    .get("super-admin/users")
    .then((res) => {
      const { data } = res;
      if (data) {
        store.dispatch(setUsers(data?.data));
      }
      store.dispatch(setGettingUsers(false));
    })
    .catch((err) => {
      store.dispatch(setGettingUsers(false));
    });
};
