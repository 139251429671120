import React, { FC } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";

const EventButtonContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  background: #b603c9;
  color: #ffffff;
  width: 200px;
  padding: 5px 5px;
  border: solid #b603c9;
  border-radius: 5px;
  cursor: pointer;

  ${media.phone} {
    width: 100px;
    padding: 2px 2px;
    font-size: 12px;
  }
`;

interface ButtonA {
  text: string | undefined;
  handleClick?: any;
}

const ActivityButton: FC<ButtonA> = ({ text, handleClick }) => {
  return (
    <EventButtonContainer onClick={handleClick}>{text}</EventButtonContainer>
  );
};

export default ActivityButton;
