import { getChurchActivities } from "../../../Apis/AdminApis/ChurchActivities";
import { churchActivitiesTableCols } from "../../../Utils/SuperAdmin/tableCols";
import { churchActivitiesTableRow } from "../../../Utils/SuperAdmin/tableRows";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
import CustomTable from "../CustomRTable";
import { useEffect } from "react";
import { selectAdminActivities } from "../../../ReduxStore/features/Admin/ActivitiesSlice";

const ChurchActivitiesTable = () => {
  const {
    gettingChurchActivities,
    churchActivities,
    creatingActivity,
    deletingActivities,
  } = useAppSelector(selectAdminActivities);
  const rows = churchActivitiesTableRow(churchActivities?.itemsList);

  const data = {
    columns: churchActivitiesTableCols,
    rows,
  };

  useEffect(() => {
    getChurchActivities();
  }, [creatingActivity, deletingActivities]);

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingChurchActivities}
        pageCount={churchActivities?.paginator?.pageCount}
        itemCount={churchActivities?.paginator?.itemCount}
        hasNextPage={churchActivities?.paginator?.hasNextPage}
        hasPrevPage={churchActivities?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default ChurchActivitiesTable;
