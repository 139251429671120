import RouteWrapper from "../../../../Layouts/RouteWrapper";
import RoleCard from "../../../../Layouts/RoleCard";
import { DailyScripturesContainer, ScripturesContainer } from "./style";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { IoMdAddCircleOutline } from "react-icons/io";
import CreateRoleModal from "./../../../../Modals/CreateRoleModal";
import {
  useAppDispatch,
  useAppSelector,
} from "./../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import EditRoleModal from "../../../../Modals/EditRoleModal";
import { useEffect } from "react";
import { getAppRoles } from "./../../../../Apis/SuperAdminApis/settings";
import {
  selectSuperAdminConfigurations,
  setChosenRole,
} from "../../../../ReduxStore/features/SuperAdmin/ConfigurationsSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";

const Roles = () => {
  const dispatch = useAppDispatch();
  const { roles, editingRole, gettingRoles, creatingRole } = useAppSelector(
    selectSuperAdminConfigurations,
  );

  useEffect(() => {
    getAppRoles();
  }, [editingRole, creatingRole]);

  return (
    <RouteWrapper>
      <DailyScripturesContainer>
        <div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <PrimaryButton
            text="Create New"
            size="medium"
            icon={<IoMdAddCircleOutline />}
            handleClick={() => dispatch(handleModal(1))}
          />
        </div>
        <ScripturesContainer>
          {gettingRoles ? (
            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader color={theme?.colors?.primaryColor} size={20} />
            </div>
          ) : (
            roles?.itemsList?.map((role: any, index: any) => (
              <RoleCard
                setEdit={() => {
                  dispatch(handleModal(2));
                  dispatch(setChosenRole(role));
                }}
                title={role?.title}
                desc={role?.description}
                status={role?.status}
              />
            ))
          )}
        </ScripturesContainer>
        <CreateRoleModal />
        <EditRoleModal />
      </DailyScripturesContainer>
    </RouteWrapper>
  );
};

export default Roles;
