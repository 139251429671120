import { FC } from "react";
import styled from "styled-components";

const AdvertContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 6px;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  background: #b603c9;
  width: 15px;
  height: 15px;
  color: white;
  border-radius: 50%;
`;
const AdvertType = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  color: #151c4d;
`;

interface Ad {
  icon: any;
  advertType: string;
}

const Advert: FC<Ad> = ({ icon, advertType }) => {
  return (
    <AdvertContainer>
      <Icon>{icon}</Icon>
      <AdvertType>{advertType}</AdvertType>
    </AdvertContainer>
  );
};

export default Advert;
