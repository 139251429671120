import styled from "styled-components";
import { media } from "../../../../Screens";

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 30px 0;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;
