import WallHeader from "./WallHeader";
import { IndexWrap } from "./styles";
import BibleReading from "./BibleReading";
import { useEffect } from "react";
import {
  getBibleChapters,
  getBibleBooks,
  getBibleVersions,
  getBibleReading,
  // getBibleVerse,
} from "../../../../Apis/UserApis/Bible";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectBible } from "../../../../ReduxStore/features/BibleSlice";

const Bible = () => {
  const { versionId, bookId, chapterId } = useAppSelector(selectBible);

  useEffect(() => {
    getBibleVersions();
  }, []);

  useEffect(() => {
    versionId && getBibleBooks(versionId?.value);
  }, [versionId]);

  useEffect(() => {
    bookId && getBibleChapters(bookId?.value, versionId?.value);
  }, [bookId, versionId]);

  useEffect(() => {
    chapterId && getBibleReading(chapterId?.value, versionId?.value);
  }, [chapterId, versionId]);

  // useEffect(() => {
  //   verseId && getBibleVerse(verseId?.value);
  // }, [verseId]);

  return (
    <IndexWrap>
      <div className="left-content" style={{ width: "100%" }}>
        <WallHeader />
        <BibleReading />
      </div>
    </IndexWrap>
  );
};

export default Bible;
