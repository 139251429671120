import styled from "styled-components";
import { theme } from "./../../theme";
import { media } from "./../../Screens";

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 30px;
  overflow-x: hidden;

  ${media.phone} {
    margin-top: 15px;
  }
`;
export const TableTitle = styled.div``;
export const Table = styled.table`
  width: 100%;
  border-top: 1px solid ${theme.colors.borderprimaryColor};
  margin-top: 20px;
  border-collapse: collapse;
  overflow-x: auto;
  position: relative;
`;
export const TableHeader = styled.thead``;
export const TableHead = styled.th`
  font-style: normal;
  // font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  color: ${theme.colors.secondaryColor};
  padding: 10px;
  text-transform: uppercase;

  ${media.phone} {
    font-size: 12px;
    padding: 5px;
  }
`;
export const TableRow = styled.tr`
  border-bottom: 0.5px solid rgba(1, 192, 146, 0.2);
`;
export const TableBody = styled.tbody`
  background-color: ${theme.colors.bgWhite};
  //   border: none;
`;
export const TableData = styled.td`
  padding: 15px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: ${theme.colors.secondaryColor};

  ${media.phone} {
    font-size: 12px;
    padding: 10px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .paginationBttns {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    list-style-type: none;
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 2px;

    @media (min-width: 768px) {
      gap: 10px;
      font-size: 16px;
    }
  }

  .paginationActive {
    color: ${theme.colors.primaryColor};
    font-weight: bold;
  }

  .prev,
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  .break-me {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  svg {
    font-weight: 500;
    cursor: pointer;
    font-size: 20px;
  }
`;

export const PageCount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-bottom: 0;
  }
`;
