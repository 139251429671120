import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserManagerState {
  gettingUsers: boolean;
  users: any;
  gettingChurchMembers: boolean;
  churchMembers: any;
  gettingChurchAdmins: boolean;
  churchAdmins: any;
}

const initialState: UserManagerState = {
  gettingChurchMembers: false,
  churchMembers: null,
  gettingChurchAdmins: false,
  churchAdmins: null,
  gettingUsers: false,
  users: null,
};

export const UserManagerSlice = createSlice({
  name: "UserManager",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurchMembers: (state, action) => {
      state.gettingChurchMembers = action.payload;
    },
    setChurchMembers: (state, action) => {
      state.churchMembers = action.payload;
    },
    setGettingChurchAdmins: (state, action) => {
      state.gettingChurchAdmins = action.payload;
    },
    setChurchAdmins: (state, action) => {
      state.churchAdmins = action.payload;
    },
    setGettingUsers: (state, action) => {
      state.gettingUsers = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const {
  setGettingChurchMembers,
  setChurchMembers,
  setGettingChurchAdmins,
  setChurchAdmins,
  setGettingUsers,
  setUsers,
} = UserManagerSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminUserManager = (state: RootState) =>
  state.superAdminUserManager;

export default UserManagerSlice.reducer;
