import { toast } from "react-toastify";
import { loginCreateChurch } from "../../../Apis/Auth/loginApis";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../../Components/Form/inputs/TextInput";
import FormWrapper from "../../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getFormDetails,
  selectForm,
} from "../../../ReduxStore/features/formSlice";
import { loginInput } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import { AuthLogo, AuthTitle, AuthLink, AuthSmallText, AuthSubTitle } from "./../style";
import { validateemail } from "./../../../Components/Helpers/Generals";

const LoginCreateChurch = () => {
  const { email, password, loggingUser } = useAppSelector(selectForm);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (email && password) {
      if (validateemail(email)) {
        loginCreateChurch(email, password);
      } else {
        toast.warning("Incorrect email or password");
      }
    } else {
      toast.warning("Please fill all fields");
    }
  };

  return (
    <AuthMain placeItemsCenter={true}>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Login</AuthTitle>
      <AuthSubTitle>Login to create church</AuthSubTitle>
      <FormWrapper>
        {loginInput?.map((input) => (
          <TextInput
            placeholder={input.placeholder}
            type={input?.type}
            name={input?.name}
            handleChange={handleChange}
          />
        ))}
        {/* <AuthLink to="/auth/reset-password">Forgot Password </AuthLink> */}
        <PrimaryButton
          text="Login"
          handleClick={handleLogin}
          loading={loggingUser}
          // disabled={true}
        />
      </FormWrapper>
      <AuthSmallText>
        Don't have an account?{" "}
        <AuthLink to="/auth/signup">Sign Up here</AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default LoginCreateChurch;
