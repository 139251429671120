import styled from "styled-components";
import { media } from "./../../../../Screens";

export const ManageUsersActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 20px;

  ${media.tablet} {
    flex-direction: column;
  }
`;

export const RolesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;
