import { FC } from "react";
import VideoAudioUpload from "./VideoAudioUpload";
import { FaTimes } from "react-icons/fa";
import {
  PubButtonContainer,
  PubButtonWrap,
  VideoAudioCaption,
  VideoAudioContainer,
  VideoAudioWrap,
} from "./style";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getSermonDetails,
  selectAdminChurchSermon,
} from "../../../../ReduxStore/features/Admin/SermonSlice";
import { createSermon } from "../../../../Apis/AdminApis/Media";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";

interface VideoAudioI {
  setState: any;
}

const Audio: FC<VideoAudioI> = ({ setState }) => {
  const dispatch = useAppDispatch();
  const {
    videoTitle,
    videoSermon,
    creatingChurchSermon,
    errorMessage,
  } = useAppSelector(selectAdminChurchSermon);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSermonDetails({ name, value }));
  };

  const handleImgSelection = (e: any) => {
    e.preventDefault();
    const { name, files } = e?.target;
    const url = URL.createObjectURL(files[0]);
    dispatch(getSermonDetails({ name, url }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (videoTitle && videoSermon) {
      dispatch(
        getSermonDetails({
          name: "errorMessage",
          value: "",
        })
      );
      createSermon(videoTitle, "video", videoSermon, setState);
    } else {
      dispatch(
        getSermonDetails({
          name: "errorMessage",
          value: "Enter Title and Sermon",
        })
      );
    }
  };

  return (
    <VideoAudioWrap>
      <VideoAudioContainer>
        <div className="fatimeswrap">
          <FaTimes
            className="fatimes"
            color="#383838"
            onClick={() => setState(false)}
          />
        </div>
        <VideoAudioUpload
          text={"MP3, MP4, "}
          // text2={"Drag and drop video or audio files to upload"}
          handleChange={handleImgSelection}
          // accepts=".MPG,.MOV,.WMV,.RM,.WEBM,.MP2,.MPEG,.MPE"
        />
        <VideoAudioCaption>Upload Videos and Audios</VideoAudioCaption>
        <input
          className="input"
          placeholder="Add Title"
          onChange={handleChange}
          name="videoTitle"
        />
      </VideoAudioContainer>
      <PubButtonWrap>
        <PubButtonContainer>
          <PrimaryButton
            text="Publish"
            handleClick={handleSubmit}
            size="medium"
            loading={creatingChurchSermon}
          />
        </PubButtonContainer>
        <span className="discard" onClick={() => setState(false)}>
          Discard
        </span>
      </PubButtonWrap>
      <span className="text-red">{errorMessage}</span>
    </VideoAudioWrap>
  );
};

export default Audio;
