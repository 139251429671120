import { useEffect } from "react";
import { CardsContainer } from "./style";
import PrayerRequestCard from "../../../../Layouts/PrayerRequestCard";
import moment from "moment";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import {
  churchPrayerToEdit,
  getADminChurchPrayerFormDetails,
  selectAdminChurchPrayerWall,
} from "../../../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import {
  deleteChurchPrayers,
  getChurchPrayers,
} from "../../../../Apis/AdminApis/PrayerWalll";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import CreateChurchPrayerModal from "../../../../Modals/Admin/CreateChurchPrayerModal";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";
import EditChurchPrayerModal from "../../../../Modals/Admin/EditChurchPrayerModal";

const ChurchPrayers = () => {
  const {
    churchPrayers,
    gettingChurchPrayers,
    updatingChurchPrayers,
    page,
    pageSize,
  } = useAppSelector(selectAdminChurchPrayerWall);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getADminChurchPrayerFormDetails({ name: "page", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getChurchPrayers("", page, pageSize);
  }, [updatingChurchPrayers, page, pageSize]);

  return (
    <div style={{ marginTop: "20px" }}>
      <PrimaryButton
        text="Add Prayer"
        handleClick={() => dispatch(handleModal(10))}
        size="medium"
      />
      {gettingChurchPrayers ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <>
          {churchPrayers?.itemsList.length > 0 ? (
            <CardsContainer>
              {churchPrayers?.itemsList.map((prayer: any) => (
                <PrayerRequestCard
                  date={moment(prayer?.createdAt).format("MMM Do YYYY")}
                  prayerPoint={prayer?.title}
                  reading={prayer?.text}
                  prayerIcon={<img src="assets/images/prayicon.png" alt="" />}
                  editAction={() => {
                    dispatch(handleModal(10.1));
                    dispatch(churchPrayerToEdit(prayer));
                  }}
                  deleteAction={() => deleteChurchPrayers(prayer?._id)}
                  bibleVerse={prayer.verse}
                />
              ))}
            </CardsContainer>
          ) : (
            <NoRecordDisplay
              message="No announcements at the moment"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}
      <Paginator
        currentPage={churchPrayers?.paginator?.currentPage}
        pageCount={churchPrayers?.paginator?.pageCount}
        itemCount={churchPrayers?.paginator?.itemCount}
        hasNextPage={churchPrayers?.paginator?.hasNextPage}
        hasPrevPage={churchPrayers?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
      <CreateChurchPrayerModal />
      <EditChurchPrayerModal />
    </div>
  );
};

export default ChurchPrayers;
