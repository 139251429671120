import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import { setGettingMedia, setMedia } from "../../ReduxStore/features/User/MediaSlice";
import api from "../baseUrl";

export const getUserSermons = async (
  type?: string | null,
  pageId?: number,
  perPage?: number
) => {
  store.dispatch(setGettingMedia(true));
  const params: any = {
    type,
    pageId,
    perPage,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/sermon", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setMedia(data?.data));
      store.dispatch(setGettingMedia(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingMedia(false));
    });
};
