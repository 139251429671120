import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AdvertState {
  gettingAdminChurchAdverts: boolean;
  creatingChurchAdvert: boolean;
  deletingChurchAdvert: boolean;
  adminChurchAdverts: any;

  //   states
  title: string;
  imageUrl: any;
  status: string;
  description: string;
  link: string;
  currencyId: string;
  startDate: string;
  endDate: string;
  advertType: string;
  paymentRef: string;

  // pagination
  page: number;
  pageSize: number;
}

const initialState: AdvertState = {
  gettingAdminChurchAdverts: false,
  creatingChurchAdvert: false,
  deletingChurchAdvert: false,
  adminChurchAdverts: null,

  //   states
  title: "",
  imageUrl: "",
  status: "",
  description: "",
  link: "",
  currencyId: "",
  startDate: "",
  endDate: "",
  advertType: "",
  paymentRef: "",

  // pagination
  page: 1,
  pageSize: 30,
};

export const AdvertSlice = createSlice({
  name: "Advert",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingAdminChurchAdverts: (state, action) => {
      state.gettingAdminChurchAdverts = action.payload;
    },
    setCreatingAdminChurchAdverts: (state, action) => {
      state.creatingChurchAdvert = action.payload;
    },
    setDeletingAdminChurchAdverts: (state, action) => {
      state.deletingChurchAdvert = action.payload;
    },
    setAdminChurchAdverts: (state, action) => {
      state.adminChurchAdverts = action.payload;
    },
    getAdvertDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingAdminChurchAdverts,
  setAdminChurchAdverts,
  setCreatingAdminChurchAdverts,
  getAdvertDetails,
  setDeletingAdminChurchAdverts,
} = AdvertSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminChurchAdvert = (state: RootState) =>
  state.adminChurchAdvert;

export default AdvertSlice.reducer;
