import React, { FC } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";

const SermonCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid #b603c9;
  width: 100%;
  /* min-width: 500px; */
  margin-top: 25px;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  ${media.phone} {
    margin-top: 10px;
    margin-bottom: -20px;
    /* width: 100%; */
  }
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #151c4d;
`;
const Pastor = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  color: #151c4d;
`;
const Sermon = styled.div`
  font-style: normal;
  font-weight: 500;
  padding: -6px;
  font-size: 12px;
  // height: 2px;
  color: #151c4d;
`;

interface SermonI {
  title: string;
  pastor: string;
  sermon: string;
  by: string;
}

const SermonCard: FC<SermonI> = ({ title, pastor, sermon, by }) => {
  return (
    <SermonCardContainer>
      <Title>{title}</Title>
      <Pastor>
        {by} {pastor}
      </Pastor>
      <Sermon>{sermon}</Sermon>
    </SermonCardContainer>
  );
};

export default SermonCard;
