import React, { useState, useEffect } from "react";
import {
  UserProfileContainer,
  GroupContainer,
  FirstSection,
  SecondSection,
  ActiveGroupContainer,
  Avatar,
  SwitchChurch,
  DeleteButton,
} from "./styles";
import {
  FaUser,
  FaChurch,
  FaBirthdayCake,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import EditableName from "./EditableName";
import EditableChurch from "./EditableChurch";
import EditableDateOfBirth from "./EditableDateofBirth";
import EditableEmail from "./EditableEmail";
import EditablePhone from "./EditablePhone";
import UserAvatar1 from "./UserAvatar1";
import ChurchList from "./ChurchList";
import {
  deleteUserAccount,
  getUserActiveChurch,
  getUserProfileDetails,
  updateUserProfile,
} from "../../../../Apis/UserApis/userProfile";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getUserProfileFormDetails,
  selectUserProfile,
} from "../../../../ReduxStore/features/User/UserProfileSlice";
import SingleProfileDetail from "./SingleProfileDetail";
import { getJoinedGroups } from "../../../../Apis/UserApis/userChurchGroup";
import {
  getUserGroupDetails,
  selectUserCgroup,
} from "../../../../ReduxStore/features/User/UserGroupSlice";
import { useNavigate } from "react-router-dom";
import Modal from "./ConfirmDeleteAccount";
import { toast } from "react-toastify";
import moment from "moment";
import { formatDate } from "../../../../Components/Helpers/Generals";

const UserProfile = () => {
  const [churchList, setChurchList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    userProfile,
    editingUserProfile,
    confirmDeletePassword,
    deletingUserAccount,
    userProfileDetails,
  } = useAppSelector(selectUserProfile);
  const { joinedGroups } = useAppSelector(selectUserCgroup);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getUserProfileFormDetails({ name, value }));
  };

  const handleProfileUpload = (file: any, clearImage: any) => {
    updateUserProfile(null, null, null, null, null, file).then(() => {
      clearImage(null);
    });
  };

  const handleDeleteAccount = () => {
    if (!confirmDeletePassword) {
      toast.error("Please enter password to delete account");
    } else {
      deleteUserAccount(userProfileDetails?.email, confirmDeletePassword);
    }
  };

  useEffect(() => {
    getUserActiveChurch();
    getUserProfileDetails();
  }, [editingUserProfile]);

  useEffect(() => {
    getJoinedGroups();
  }, []);

  return (
    <UserProfileContainer>
      <>
        <Avatar>
          <UserAvatar1
            name={
              userProfileDetails?.lastName + " " + userProfileDetails?.firstName
            }
            avatarUrl={userProfileDetails?.profileUrl}
            handleUpload={handleProfileUpload}
            loading={editingUserProfile}
          />
        </Avatar>
        <SwitchChurch>
          <button
            className="switchchurchbutton"
            onClick={() => {
              setChurchList(true);
            }}
          >
            Switch Church
          </button>
          {churchList && <ChurchList setState={setChurchList} />}
        </SwitchChurch>

        <SingleProfileDetail
          cardIcon={<FaUser fontSize={19} className="fauser" />}
          editForm={
            <EditableName
              defaultValue={userProfileDetails?.firstName}
              name="firstName"
              handleChange={handleChange}
            />
          }
          profileProp="First Name"
          profileValue={userProfileDetails?.firstName}
          showEditPen={true}
        />
        <SingleProfileDetail
          cardIcon={<FaUser fontSize={19} className="fauser" />}
          editForm={
            <EditableName
              defaultValue={userProfileDetails?.lastName}
              name="lastName"
              handleChange={handleChange}
            />
          }
          profileProp="Last Name"
          profileValue={userProfileDetails?.lastName}
          showEditPen={true}
        />

        <SingleProfileDetail
          cardIcon={<FaChurch fontSize={19} className="fachurch" />}
          editForm={<EditableChurch user={userProfile?.churchId?.name} />}
          profileProp="Church"
          profileValue={userProfile?.churchId?.name}
          showEditPen={false}
        />

        <SingleProfileDetail
          cardIcon={<FaBirthdayCake fontSize={19} className="fauser" />}
          editForm={
            <EditableDateOfBirth
              value={formatDate(userProfileDetails?.dob)}
              name="dob"
              handleChange={handleChange}
              type="date"
            />
          }
          profileProp="Birthday"
          profileValue={moment(userProfileDetails?.dob).format("Do MMM YYYY")}
          showEditPen={true}
        />

        <SingleProfileDetail
          cardIcon={<FaEnvelope fontSize={19} className="fauser" />}
          editForm={
            <EditableEmail
              defaultValue={userProfileDetails?.email}
              handleChange={handleChange}
              name="email"
            />
          }
          profileProp="Email"
          profileValue={userProfileDetails?.email}
          showEditPen={false}
        />

        <SingleProfileDetail
          cardIcon={<FaPhoneAlt fontSize={19} className="fauser" />}
          editForm={
            <EditablePhone
              defaultValue={userProfileDetails?.phoneNo}
              name="phoneNo"
              handleChange={handleChange}
            />
          }
          profileProp="Phone"
          profileValue={userProfileDetails?.phoneNo}
          showEditPen={true}
        />
      </>
      <GroupContainer>
        <FirstSection>
          <p className="groups">Joined Groups</p>
          <button
            className="joingroup"
            onClick={() => {
              navigate("/groups");
            }}
          >
            Join Group
          </button>
        </FirstSection>

        {joinedGroups?.itemsList?.map((group: any) => (
          <SecondSection key={group}>
            <ActiveGroupContainer>
              <p>{group?.name}</p>
              <p>Member</p>
            </ActiveGroupContainer>
            <BsBoxArrowRight
              color="gray"
              fontSize={25}
              fontWeight={600}
              cursor={"pointer"}
              onClick={() => {
                navigate("/groups");
                dispatch(
                  getUserGroupDetails({
                    name: "churchGroupId",
                    value: group?.churchGroupId,
                  })
                );
              }}
            />
          </SecondSection>
        ))}
      </GroupContainer>
      <DeleteButton onClick={() => setShowModal(true)}>
        Delete Account
      </DeleteButton>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)} // Close modal on cancel
        onConfirm={handleDeleteAccount}
        deleting={deletingUserAccount} // Handle account deletion
      />
    </UserProfileContainer>
  );
};

export default UserProfile;
