import { useEffect } from "react";
import { ActivityContainer, EventsContainer } from "./styles";
import ChurchActivityCard from "../../../../Layouts/ChurchActivityCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getChurchActivitiesFormDetails,
  selectUserChurchActivities,
} from "../../../../ReduxStore/features/User/ChurchActivitiesSlice";
import { getUserChurchActivities } from "../../../../Apis/UserApis/ChurchActivities";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Ads from "../Ads";
import moment from "moment";
import { IoMdCheckmark } from "react-icons/io";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const Activities = () => {
  const { churchActivities, gettingChurchActivities, page, pageSize } =
    useAppSelector(selectUserChurchActivities);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getChurchActivitiesFormDetails({ name: "page", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getUserChurchActivities(page, pageSize);
  }, [page]);

  return (
    <>
      <ActivityContainer>
        {gettingChurchActivities ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Loader color={theme?.colors.primaryColor} size={20} />
          </div>
        ) : (
          <EventsContainer>
            {churchActivities?.itemsList?.length > 0 ? (
              <>
                {churchActivities?.itemsList?.map((activity: any) => (
                  <ChurchActivityCard
                    icon={<IoMdCheckmark />}
                    title={activity?.activityId?.title}
                    time={
                      moment(activity?.activityId?.startingDate)?.format(
                        "Do MMM YYYY"
                      ) +
                      " : " +
                      activity?.activityId?.startTime +
                      " - " +
                      moment(activity?.activityId?.endingDate)?.format(
                        "Do MMM YYYY"
                      ) +
                      " : " +
                      activity?.activityId?.endTime
                    }
                    quote={activity?.activityId?.message}
                    choice="Interested"
                    mode={activity?.activityId?.liveStream}
                    allDay={activity?.activityId?.allDay}
                    repeat={activity?.activityId?.repeat}
                  />
                ))}
              </>
            ) : (
              <NoRecordDisplay
                src="/assets/images/svg/working-remotly.svg"
                message="No event at the moment"
              />
            )}
          </EventsContainer>
        )}
        {/* <Ads /> */}
      </ActivityContainer>
      <Paginator
        currentPage={churchActivities?.paginator?.currentPage}
        pageCount={churchActivities?.paginator?.pageCount}
        itemCount={churchActivities?.paginator?.itemCount}
        hasNextPage={churchActivities?.paginator?.hasNextPage}
        hasPrevPage={churchActivities?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default Activities;
