/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Button from "./Button";
import RouteWrapper from "../../../../Layouts/RouteWrapper";
import PrayerRequestCard from "../../../../Layouts/PrayerRequestCard";
import AddRequest from "./AddRequest";
// import EditScripture from "./EditScripture";
import { DailyScripturesContainer, PrayerRequestContainer } from "./style";
import {
  getAllPrayerRequest,
  likeOrDislikeRequest,
} from "../../../../Apis/UserApis/prayerRequests";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { getPrayerWallFormDetails, selectUserPrayerWall } from "../../../../ReduxStore/features/User/PrayerWallSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import moment from "moment";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const PrayerRequest = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const {
    prayerRequests,
    gettingPrayerRequests,
    creatingPrayerRequests,
    likingPrayerRequests,
    pageId,
    pageSize,
  } = useAppSelector(selectUserPrayerWall);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getPrayerWallFormDetails({ name: "pageId", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getAllPrayerRequest(userProfile?.churchId?._id, pageId, pageSize);
  }, [creatingPrayerRequests, pageId]);

  return (
    <RouteWrapper>
      <DailyScripturesContainer>
        <Button
          text="Add a Prayer"
          size="medium"
          handleClick={() => {
            setShowAdd(true);
            setShowEdit(false);
          }}
        />

        {showAdd && !showEdit && <AddRequest setState={setShowAdd} />}
        {/* {showEdit && !showAdd && <EditScripture setState={setShowEdit} />} */}
        <PrayerRequestContainer>
          {gettingPrayerRequests ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Loader color={theme?.colors.primaryColor} size={20} />
            </div>
          ) : (
            <>
              {prayerRequests?.itemsList.length > 0 ? (
                <>
                  {prayerRequests?.itemsList?.map((request: any) => (
                    <PrayerRequestCard
                      user={
                        request?.userId?.lastName +
                        " " +
                        request?.userId?.firstName
                      }
                      date={moment(request.createdAt).format("MMM Do YYYY")}
                      prayerPoint={request?.title}
                      reading={request?.description}
                      userProfilePhoto={
                        <img
                          src="assets/images/img1.png"
                          alt="userPhoto"
                          width={25}
                          height={25}
                        />
                      }
                      userProfilePhoto2={
                        <img
                          src="assets/images/img3.png"
                          alt="userPhoto"
                          width={25}
                          height={25}
                        />
                      }
                      peoplePrayed={16}
                      prayerIcon={
                        likingPrayerRequests ? (
                          <Loader
                            size={10}
                            color={theme?.colors?.primaryColor}
                          />
                        ) : (
                          <img
                            src="assets/images/prayicon.png"
                            alt=""
                            onClick={() => likeOrDislikeRequest(request?._id)}
                          />
                        )
                      }
                      setEdit={setShowEdit}
                      setAdd={setShowAdd}
                      requestId={request?._id}
                    />
                  ))}
                </>
              ) : (
                <NoRecordDisplay
                  message="No Prayer Request Available"
                  src="/assets/images/svg/working-remotly.svg"
                />
              )}
            </>
          )}
        </PrayerRequestContainer>
      </DailyScripturesContainer>
      <Paginator
        currentPage={prayerRequests?.paginator?.currentPage}
        pageCount={prayerRequests?.paginator?.pageCount}
        itemCount={prayerRequests?.paginator?.itemCount}
        hasNextPage={prayerRequests?.paginator?.hasNextPage}
        hasPrevPage={prayerRequests?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </RouteWrapper>
  );
};

export default PrayerRequest;
