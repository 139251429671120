import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../Components/Form/inputs/TextInput";
import FormWrapper from "../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import { selectAuth } from "../../ReduxStore/features/authSlice";
import {
  getFormDetails,
  selectForm,
} from "../../ReduxStore/features/formSlice";
import AuthMain from "./AuthMain";
import { AuthLogo, AuthTitle, AuthLink, AuthSmallText } from "./style";
import { toast } from "react-toastify";
import { confirmEmail, resetUserPassword } from "../../Apis/Auth/resetPassword";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ChangePassword = () => {
  const { resetPassword, resetCode, resetEmail } = useAppSelector(selectForm);
  const { resettingPassword } = useAppSelector(selectAuth);
  const [viewPassword, setViewPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // State for managing the resend OTP button and timer
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleResendOTP = () => {
    confirmEmail(resetEmail);
    setIsResendDisabled(true); // Disable the button
    setCountdown(60); // Start countdown from 60 seconds
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (resetPassword && resetCode) {
      resetUserPassword(resetPassword, resetCode, navigate);
    } else {
      toast.error("Please fill in the new password and the code");
    }
  };

  // useEffect for handling countdown
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isResendDisabled && countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => (prev ? prev - 1 : 0));
      }, 1000);
    }

    if (countdown === 0) {
      setIsResendDisabled(false);
      setCountdown(null);
    }

    return () => clearInterval(timer);
  }, [isResendDisabled, countdown]);

  useEffect(() => {
    if (!resetEmail) {
      navigate("/");
    }
  }, []);

  return (
    <AuthMain placeItemsCenter={true}>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Reset Password</AuthTitle>
      <FormWrapper>
        <TextInput
          placeholder="Enter New Password"
          name="resetPassword"
          value={resetPassword}
          handleChange={handleChange}
          showStrengthSignal={true}
          type={viewPassword ? "text" : "password"}
          altIcon={<AiFillEyeInvisible />}
          iconState={viewPassword}
          handleIconClick={() => setViewPassword(true)}
          handleAltIconClick={() => setViewPassword(false)}
          icon={<AiFillEye />}
        />
        <TextInput
          placeholder="Enter Code"
          name="resetCode"
          value={resetCode}
          handleChange={handleChange}
        />
        <PrimaryButton
          text="Reset"
          loading={resettingPassword}
          handleClick={handleSubmit}
        />
      </FormWrapper>
      <AuthSmallText>
        <AuthLink
          as="button"
          onClick={handleResendOTP}
          disabled={isResendDisabled}
          style={{
            cursor: isResendDisabled ? "not-allowed" : "pointer",
            opacity: isResendDisabled ? 0.6 : 1,
            background: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          {isResendDisabled ? `Resend OTP (${countdown}s)` : "Resend OTP"}
        </AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default ChangePassword;
