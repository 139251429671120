import styled from "styled-components";
import { media } from "../../../../Screens";
import { theme } from "../../../../theme";

export const ChatWrap = styled.div`
  ${media.phone} {
    width: 500px;
  }
  div.ad {
    position: absolute;
    margin-left: 800px;
    margin-top: -380px;
    border: 2px solid;
    height: 173px;
    width: 173px;
    border-radius: 5%;

    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 820px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: -380px;
  ${media.phone} {
    display: none;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  .left-content {
    display: flex;
    gap: 20px;
    flex: 1;
    padding-right: 50px;
  }
  .right-content {
    display: flex;
    flex-direction: column
    flex: 0.7;
  }
`;

export const GroupCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
`;

export const GroupDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 50px;
`;

export const GroupDetailsSectionTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.textGreycolor};
`;

export const GroupName = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.secondaryColor};
`;

export const GroupDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${theme.colors.textGreycolor};
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorderColor};
  padding-bottom: 20px;
  justify-content: space-between;

  .left-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .right-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
    width: 59px;
    border-radius: 50%;
    background-color: ${theme.colors.primaryColor};
    color: ${theme.colors.textWhite};
    font-size: 20px;
    gap: 5px;
    cursor: pointer;
    position: relative;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${theme.colors.bgWhite};
    }

    .drop-down {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      height: auto;
      width: 92px;
      background-color: ${theme.colors.bgWhite};
      border-radius: 5px;
      position: absolute;
      top: 110%;
      filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.102));
      color: ${theme.colors.secondaryColor};

      p {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        cursor: pointer;
      }

      svg {
        color: ${theme.colors.secondaryColor};
        font-size: 14px;
      }
    }
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.lightBorderColor};
  padding-bottom: 20px;
  gap: 10px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  // border-bottom: 1px solid ${theme.colors.lightBorderColor};
  padding-bottom: 20px;
  gap: 10px;
`;

export const MemberCardContainer = styled.div`
  display: flex;
  // align-items: center;
  // flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: auto;
`;
