import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DonationState {
  gettingDonations: boolean;
  makingPayments: boolean;
  donations: any;
  gettingTransactionInvoices: boolean;
  transactionInvoices: any;

  // donations State
  typeOfDonation: any;
  churchGroupId: any;

  // pay states
  amountToPay: number;
  typeOfPayment: string;
  reason: string;

  // pagination
  page: number;
  pageSize: number;
}

const initialState: DonationState = {
  gettingDonations: false,
  makingPayments: false,
  donations: null,
  gettingTransactionInvoices: false,
  transactionInvoices: null,

  // donations State
  typeOfDonation: null,
  churchGroupId: null,

  // pay states
  amountToPay: 0,
  typeOfPayment: "",
  reason: "",

  // pagination
  page: 1,
  pageSize: 30,
};

export const DonationSlice = createSlice({
  name: "Donation",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingDonations: (state, action) => {
      state.gettingDonations = action.payload;
    },
    setDonation: (state, action) => {
      state.donations = action.payload;
    },
    setMakingPayments: (state, action) => {
      state.makingPayments = action.payload;
    },
    setGettingTransactionInvoices: (state, action) => {
      state.gettingTransactionInvoices = action.payload;
    },
    setTransactionInvoices: (state, action) => {
      state.transactionInvoices = action.payload;
    },
    getDonationFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingDonations,
  setDonation,
  getDonationFormDetails,
  setMakingPayments,
  setGettingTransactionInvoices,
  setTransactionInvoices,
} = DonationSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDonations = (state: RootState) => state.donations;

export default DonationSlice.reducer;
