import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface FormState {
  //   login state
  email: string;
  password: string;
  loggingUser: boolean;

  //member signup state
  firstName: string;
  lastName: string;
  registerEmail: string;
  phoneNo: string;
  registerPassword: string;
  gender: string;
  resetEmail: string;
  resetPassword: string;
  resetCode: string;

  // admin signup state
  adminPassword: string;
  adminGender: string;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  adminPhone: string;
  denomination: string;
  churchPhoneNo: string;
  town: string;
  churchAddress: string;
  churchName: string;
  landmark: string;
  city: string;
  state: string;
  country: string;
  HQID: string;
  clergyName: string;
  clergyRole: string;
  clergyPhoneNo: string;
  churchId: string;
  churchLogoUrl: string;
  churchMotto: string;

  // loadinig
  signingupAdmin: boolean;
  loading: boolean;
}

const initialState: FormState = {
  //   login state
  email: "",
  password: "",
  loggingUser: false,

  //member  signup state
  firstName: "",
  lastName: "",
  registerEmail: "",
  phoneNo: "",
  registerPassword: "",
  gender: "M",
  resetEmail: "",
  resetPassword: "",
  resetCode: "",

  // admin signup state
  adminPassword: "",
  adminGender: "M",
  adminFirstName: "",
  adminLastName: "",
  adminEmail: "",
  adminPhone: "",
  denomination: "",
  churchPhoneNo: "",
  churchAddress: "",
  churchName: "",
  landmark: "",
  city: "",
  state: "",
  country: "",
  town: "",
  HQID: "",
  clergyName: "",
  clergyRole: "",
  clergyPhoneNo: "",
  churchId: "",
  churchLogoUrl: "",
  churchMotto: "",

  // loading
  signingupAdmin: false,
  loading: false,
};

export const userSlice = createSlice({
  name: "form",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    setLoggingUser: (state, action) => {
      state.loggingUser = action?.payload;
    },
    setSigningUpAdmin: (state, action) => {
      state.signingupAdmin = action?.payload;
    },
    setSending: (state, action) => {
      state.loading = action?.payload;
    },
  },
});

export const { getFormDetails, setLoggingUser, setSigningUpAdmin, setSending } =
  userSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectForm = (state: RootState) => state.form;

export default userSlice.reducer;
