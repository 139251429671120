import CustomModal from "./CustomModal";
import FormWrapper from "./../Layouts/FormWrapper";
import TextInput from "./../Components/Form/inputs/TextInput";
import PrimaryButton from "./../Components/Form/Buttons/PrimaryButton";
import { editCurrencyInputs } from "../Utils/authInput";
import FileUpload from "./../Components/Form/inputs/FileUpload";
import { useAppSelector } from "../ReduxStore/app/hooks";
import {
  getWalletFormDetails,
  selectSuperAdminWallet,
} from "../ReduxStore/features/SuperAdmin/WalletSlice";
import { useAppDispatch } from "./../ReduxStore/app/hooks";
import { editCurrency } from "../Apis/SuperAdminApis/wallet";

const EditCurrencyModal = () => {
  const {
    currencySymbol,
    currencyName,
    currencyUrl,
    currencyId,
    editingWallet,
  } = useAppSelector(selectSuperAdminWallet);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  return (
    <CustomModal
      id={3}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editCurrencyInputs?.map((input) => (
            <>
              {input?.type === "file" ? (
                <FileUpload text="Currency Icon" />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    defaultValue={input?.defaultValue()}
                    handleChange={handleChange}
                    readonly={input?.readonly}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Update"
            handleClick={(e: any) => {
              e.preventDefault();
              editCurrency(
                currencyId,
                currencySymbol,
                currencyName,
                currencyUrl
              );
            }}
            loading={editingWallet}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditCurrencyModal;
