import styled from "styled-components";
import { media } from "./../../../../Screens";

export const Wrap = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  width: 100%;

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    flex: 0.8;

    ${media.phone} {
      flex: 1;
    }
  }
  .right-content {
    flex: 0.2;
    ${media.phone} {
      flex: 0;
    }
  }

  div.ad {
    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }
`;

export const EventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  // // position: absolute;
  // margin-left: 720px;
  // margin-top: -650px;
  margin-bottom: 20px;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;

export const EventCaption = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -20px;
`;

export const EventIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventTopic = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const EventName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;
export const Sponsor = styled.div`
  position: absolute;
  margin-left: 610px;
  margin-top: -430px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;

  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 610px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: -340px;
  ${media.phone} {
    display: none;
  }
`;

export const AccountCardContainer = styled.div`
  display: grid;
  // with: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;
