import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "../../../../Screens";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectPayment } from "../../../../ReduxStore/features/paymentSlice";
import PaystackButton from "../../../../Components/Paystack/PaystackButton";
import { selectAdminActivities } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import { checkVerifyPaymentMade } from "../../../../Apis/AdminApis/payments";

const ProfileCardContainer = styled.div`
  display: flex;
  // flex-direction: column;
  // width: 600px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  padding-top: 15px;
  padding: 20px;
  flex: 0.7;
  justify-content: space-between;
  ${media.phone} {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  .link {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Caption = styled.div`
  display: flex;
  font-size: 24px;
  padding: 5px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
`;

interface ProfileI {
  caption: string;
  link: string;
}

const ProfileCard: FC<ProfileI> = ({ caption, link }) => {
  const { makingPayment } = useAppSelector(selectPayment);
  const { adminChurch } = useAppSelector(selectAdminActivities);
  const [verificationPaid, setVerificationPaid] = useState(false);

  const callVerificationCheck = async () => {
    const res = await checkVerifyPaymentMade();
    if (res === "Error: Chuch verification already paid for") {
      setVerificationPaid(true);
    }
  };

  useEffect(() => {
    callVerificationCheck();
  }, []);

  return (
    <ProfileCardContainer>
      <div className="left-content">
        <Caption>{caption}</Caption>
        <span className="link">{link}</span>
        <ButtonContainer>
          {adminChurch?.email && (
            <>
              {verificationPaid ? (
                <span style={{ color: "green" }}>
                  You have paid for your church verification. Please be patient
                  as the verification process on ongoing
                </span>
              ) : (
                <PaystackButton
                  amount={100}
                  btnText="Verify Church"
                  loading={makingPayment}
                  paymentType="church-verification"
                  email={adminChurch?.email}
                />
              )}
            </>
          )}
        </ButtonContainer>
      </div>
      <div className="right-content">
        <img src="/assets/images/svg/verify.svg" alt="" />
      </div>
    </ProfileCardContainer>
  );
};

export default ProfileCard;
