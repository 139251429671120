// import { getUserSubscription } from "../../../Apis/AdminApis/subscriptions";
import { SubScriptionsTableCols } from "../../../Utils/SuperAdmin/tableCols";
import { SubscriptionsTableRow } from "../../../Utils/SuperAdmin/tableRows";
import CustomTable from "../CustomRTable";
import { useEffect } from "react";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
import { selectSuperAdminSubscriptions } from "../../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import TextInput from "../../Form/inputs/TextInput";
import { RiSearchLine } from "react-icons/ri";
import PrimaryButton from "../../Form/Buttons/PrimaryButton";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useAppDispatch } from "../../../ReduxStore/app/hooks";
import { handleModal } from "../../../ReduxStore/features/navSlice";
import { getAllSubscription } from "../../../Apis/SuperAdminApis/subscriptions";

const SubscriptionsTable = () => {
  const {
    gettingSubscriptions,
    subscriptions,
    editingSubscription,
    deletingSubscription,
    creatingSubscription,
  } = useAppSelector(selectSuperAdminSubscriptions);
  const rows = SubscriptionsTableRow(subscriptions?.itemsList);
  const dispatch = useAppDispatch();

  const data = {
    columns: SubScriptionsTableCols,
    rows,
  };

  useEffect(() => {
    getAllSubscription();
  }, [editingSubscription, deletingSubscription, creatingSubscription]);

  return (
    <>
      <CustomTable
        data={data}
        filter={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <TextInput placeholder={"Search"} leftIcon={<RiSearchLine />} />
            <PrimaryButton
              text="Create New"
              size="medium"
              icon={<IoMdAddCircleOutline />}
              handleClick={() => dispatch(handleModal(6))}
            />{" "}
          </div>
        }
        showFilter={true}
        loading={gettingSubscriptions}
        noRecord={subscriptions?.itemsList?.length > 0 ? false : true}
        noRecordText="No Subscriptions Available"
        currentPage={subscriptions?.paginator?.currentPage}
        pageCount={subscriptions?.paginator?.pageCount}
        itemCount={subscriptions?.paginator?.itemCount}
        hasNextPage={subscriptions?.paginator?.hasNextPage}
        hasPrevPage={subscriptions?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default SubscriptionsTable;
