import { useEffect, useState } from "react";
import { VideoSermonCardWrap } from "./style";
import { FaShareAlt } from "react-icons/fa";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { getSermons } from "../../../../Apis/AdminApis/Media";
import { selectAdminChurchSermon } from "../../../../ReduxStore/features/Admin/SermonSlice";
import VideoSermonCard from "./VideoSermonCard";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";

const VideoSermon = () => {
  const { gettingAdminChurchSermons, adminChurchSermons, creatingChurchSermon } = useAppSelector(
    selectAdminChurchSermon
  );
  const [videoSermon, setVideoSermon] = useState<any>();

  useEffect(() => {
    const videoSermonFilter = adminChurchSermons?.itemsList?.filter(
      (x: any) => x.sermonType === "video"
    );
    setVideoSermon(videoSermonFilter);
  }, [adminChurchSermons]);

  return (
    <VideoSermonCardWrap>
      {gettingAdminChurchSermons ? (
        <Loader color={theme.colors.primaryColor} size={20} />
      ) : videoSermon?.length > 0 ? (
        videoSermon?.map((item: any, index: number) => (
          <VideoSermonCard
            videoCaption={item?.title}
            shareIcon={FaShareAlt}
            link={item?.link}
            key={index + 1}
          />
        ))
      ) : (
        <NoRecordDisplay
          message="Video Sermon not Available"
          src="/assets/images/svg/working-remotly.svg"
        />
      )}
    </VideoSermonCardWrap>
  );
};

export default VideoSermon;
