import React, { FC } from "react";
import styled from "styled-components";
import { media } from "../../../../Screens";
import { Link } from "react-router-dom";

const FreeplanContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #b603c9;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 10px;
  height: 100%;
  min-height: 30vh;
  flex: 0.3;
  gap: 20px;
  position: relative;

  img {
    width: 30px;
  }
`;
const PlanStatus = styled.div`
  display: flex;
  color: #ffffff;
  font-weight: 500;
`;
const UpgradeLink = styled(Link)`
  display: flex;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 10%;

  ${media.phone} {
    position: relative;
  }
`;

interface FreePlanI {
  planStatus: string;
  upgradeLink: string;
}

const FreePlan: FC<FreePlanI> = ({ planStatus, upgradeLink }) => {

  return (
    <FreeplanContainer>
      <img src="/assets/images/svg/free.svg" alt="" />
      <PlanStatus>{planStatus}</PlanStatus>
      <UpgradeLink to="/subscription">{upgradeLink}</UpgradeLink>
    </FreeplanContainer>
  );
};

export default FreePlan;
