// Carousel.tsx
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

interface CarouselProps {
  images: string[];
  showThumbs?: boolean;
  showArrows?: boolean;
  showStatus?: boolean;
  infiniteLoop?: boolean;
  autoPlay?: boolean;
  interval?: number;
}

const ImageCarousel: React.FC<CarouselProps> = ({
  images,
  showThumbs = false,
  showArrows = false,
  showStatus = false,
  infiniteLoop = true,
  autoPlay,
  interval,
}) => {
  return (
    <Carousel
      showThumbs={showThumbs}
      showArrows={showArrows}
      showStatus={showStatus}
      infiniteLoop={infiniteLoop}
      autoPlay={autoPlay}
      interval={interval}
      showIndicators={false}
    >
      {images.map((src, index) => (
        <div key={index}>
          <img src={src} alt={`Slide ${index}`} height={250}/>
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
