import styled from "styled-components";
import { theme } from "../../theme";

export const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  color: ${theme.colors.textBlue};
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
