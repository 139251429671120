import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface PrayerWallState {
  gettingPrayerRequests: boolean;
  creatingPrayerRequests: boolean;
  likingPrayerRequests: boolean;
  prayerRequests: any;
  gettingTestimonies: boolean;
  creatingTestimonies: boolean;
  testimonies: any;

  // creating prayer request states
  prayerTitle: string;
  prayerDescription: string;
  testimonyTitle: string;
  testimonyDescription: string;

  // data count
  pageId: number;
  pageCount: number;
  pageSize: number;
}

const initialState: PrayerWallState = {
  gettingPrayerRequests: false,
  creatingPrayerRequests: false,
  likingPrayerRequests: false,
  prayerRequests: null,
  gettingTestimonies: false,
  creatingTestimonies: false,
  testimonies: null,

  // creating prayer request states
  prayerTitle: "",
  prayerDescription: "",
  testimonyTitle: "",
  testimonyDescription: "",

  // data count
  pageId: 1,
  pageCount: 20,
  pageSize: 30,
};

export const PrayerWallSlice = createSlice({
  name: "PrayerWall",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingPrayerRequests: (state, action) => {
      state.gettingPrayerRequests = action.payload;
    },
    setLikingPrayerRequests: (state, action) => {
      state.likingPrayerRequests = action.payload;
    },
    setCreatingPrayerRequests: (state, action) => {
      state.creatingPrayerRequests = action.payload;
    },
    setPrayerRequests: (state, action) => {
      state.prayerRequests = action.payload;
    },
    setGettingTestimonies: (state, action) => {
      state.gettingTestimonies = action.payload;
    },
    setCreatingTestimonies: (state, action) => {
      state.creatingTestimonies = action.payload;
    },
    setTestimonies: (state, action) => {
      state.testimonies = action.payload;
    },
    getPrayerWallFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingPrayerRequests,
  setPrayerRequests,
  setCreatingPrayerRequests,
  setGettingTestimonies,
  setTestimonies,
  setCreatingTestimonies,
  getPrayerWallFormDetails,
  setLikingPrayerRequests,
} = PrayerWallSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserPrayerWall = (state: RootState) => state.userPrayerWall;

export default PrayerWallSlice.reducer;
