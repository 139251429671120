import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ConfigurationsState {
  gettingInternalSettings: boolean;
  internalSettings: any;
  updatingInternalSettings: boolean;
  gettingRoles: boolean;
  editingRole: boolean;
  creatingRole: boolean;
  roles: any;

  //   internal settings states
  defaultSignupSubscriptionPackageId: string;
  subscriptionPaymentMethod: string;
  subscriptionPaymentGracePeriodInGraceInDays: number;

  //   role settings states
  roleId: string;
  roleTitle: string;
  roleDescription: string;
  roleStatus: string;
}

const initialState: ConfigurationsState = {
  gettingInternalSettings: false,
  internalSettings: null,
  updatingInternalSettings: false,
  gettingRoles: false,
  editingRole: false,
  creatingRole: false,
  roles: null,

  //   internal settings states
  defaultSignupSubscriptionPackageId: "",
  subscriptionPaymentMethod: "",
  subscriptionPaymentGracePeriodInGraceInDays: 0,

  //   role settings states
  roleId: "",
  roleTitle: "",
  roleDescription: "",
  roleStatus: "",
};

export const ConfigurationsSlice = createSlice({
  name: "Configurations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingInternalSettings: (state, action) => {
      state.gettingInternalSettings = action.payload;
    },
    setInternalSettings: (state, action) => {
      state.internalSettings = action.payload;
    },
    setGettingRoles: (state, action) => {
      state.gettingRoles = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setEditingRole: (state, action) => {
      state.editingRole = action.payload;
    },
    setCreatingRole: (state, action) => {
      state.creatingRole = action.payload;
    },
    setUpdatingInternalSettings: (state, action) => {
      state.updatingInternalSettings = action.payload;
    },
    setChosenRole: (state, action) => {
      state.roleId = action.payload._id;
      state.roleTitle = action.payload.title;
      state.roleDescription = action.payload.description;
      state.roleStatus = action.payload.status;
    },
    getConfigurationsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingInternalSettings,
  setInternalSettings,
  setGettingRoles,
  setRoles,
  setUpdatingInternalSettings,
  setChosenRole,
  setEditingRole,
  setCreatingRole,
  getConfigurationsFormDetails,
} = ConfigurationsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminConfigurations = (state: RootState) =>
  state.superAdminConfigurations;

export default ConfigurationsSlice.reducer;
