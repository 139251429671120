import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import moment from "moment";
import {
  BibleBook,
  Date,
  ReflectionDisplay,
  RightItems,
  Slate,
  TopItems,
  StudyVerse,
  ActionContainer,
} from "../../User/DailyReflection/styles";
import ReflectionView from "../../User/DailyReflection/ReflectionView";
import styled from "styled-components";
import { media } from "../../../../Screens";
import CreateDailyVerseModal from "../../../../Modals/Admin/CreateDailyVerseModal";
import {
  getADminChurchPrayerFormDetails,
  selectAdminChurchPrayerWall,
} from "../../../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import {
  getChurchDailyReflection,
} from "../../../../Apis/AdminApis/DailyReflection";
import { ReflectionTitle } from "./styles";
import EditDailyReflectionModal from "../../../../Modals/Admin/EditDailyVerseModal";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;

const DailyReflection = () => {
  const {
    gettingChurchReflections,
    churchReflections,
    updatingChurchReflection,
    page,
    pageSize,
  } = useAppSelector(selectAdminChurchPrayerWall);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getADminChurchPrayerFormDetails({ name: "page", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getChurchDailyReflection("", "", "", "", page, pageSize);
  }, [updatingChurchReflection, page, pageSize]);

  return (
    <div style={{ marginTop: "20px" }}>
      {gettingChurchReflections ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : churchReflections?.itemsList.length > 0 ? (
        <Container>
          {churchReflections?.itemsList.map((item: any) => (
            <Slate>
              <TopItems>
                <RightItems>
                  <ReflectionTitle>{item?.title}</ReflectionTitle>
                  <StudyVerse>
                    Study Verses: {"   "}
                    <span>
                      {item?.studyVerses?.map((verse: any) => (
                        <span>{verse} ,</span>
                      ))}
                    </span>
                  </StudyVerse>
                  <BibleBook>
                    {item?.bookName?.book} {item?.chapter}:{item?.verse}
                  </BibleBook>
                </RightItems>
              </TopItems>
              <ReflectionDisplay>
                <ReflectionView text={item?.text} />
              </ReflectionDisplay>
              <Date>{moment(item?.createdAt).format("MMM Do YYYY")}</Date>
              <ActionContainer>
                {/* <FaEdit
                  onClick={() => {
                    dispatch(handleModal(12));
                    dispatch(reflectionToEdit(item));
                  }}
                />
                <MdDelete
                  onClick={() => {
                    deleteChurchDailyReflection(item?._id);
                  }}
                /> */}
              </ActionContainer>
            </Slate>
          ))}
        </Container>
      ) : (
        <NoRecordDisplay
          src="/assets/images/svg/working-remotly.svg"
          message="No Reflection Available"
        />
      )}
      <Paginator
        currentPage={churchReflections?.paginator?.currentPage}
        pageCount={churchReflections?.paginator?.pageCount}
        itemCount={churchReflections?.paginator?.itemCount}
        hasNextPage={churchReflections?.paginator?.hasNextPage}
        hasPrevPage={churchReflections?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
      <CreateDailyVerseModal />
      <EditDailyReflectionModal />
    </div>
  );
};

export default DailyReflection;
