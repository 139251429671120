import React from "react";
import Reflection from "./Reflection";

const index = () => {
  return (
    <div>
      <Reflection />
    </div>
  );
};

export default index;
