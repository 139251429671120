import styled from "styled-components";
import { theme } from "../../../../theme";
import { media } from "../../../../Screens";

export const CreatePostIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  border-radius: 50%;
  padding: 10px;
  svg {
    font-size: 40px;
    color: ${theme.colors.primaryColor};
  }
`;

export const PostTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 0%; */
  position: fixed;
  top: 75px;
  z-index: 1000000;
`;

export const PostType = styled.p`
  font-size: 16px;
  cursor: pointer;

  &.active {
    color: ${theme.colors.primaryColor};
    border-bottom: 1px solid ${theme.colors.primaryColor};
  }
`;
export const PostTypeWrapper = styled.div`
  width: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  min-width: 100%;
`;

export const HomeContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  flex: 1;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  ${media.phone} {
    overflow: hidden;
  }
  .avatarphoto {
    border-radius: 10px;
  }
`;

export const AdsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Date = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: ${theme.colors.secondaryColor};
  ${media.phone} {
    margin-left: 0px;
    font-size: 20px;
  }
`;

export const WelcomeText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 50px;
  line-height: 60px;
  color: ${theme.colors.secondaryColor};
  ${media.phone} {
    margin-left: 0px;
    font-size: 30px;
  }
`;

export const AdAvatar = styled.div`
  position: absolute;
  left: 76.72%;
  right: 7.55%;
  top: 30%;
  bottom: 59.51%;
  border: 2px solid;
  border-radius: 20%;
  ${media.phone} {
    display: none;
    margin-left: 5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    opacity: 0;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  ${media.phone} {
    flex-direction: column;
  }
`;

export const SlideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid black; */
  height: 200px;
  width: 100%;
  gap: 30px;
  margin-top: 50px;
`;

export const FirstSlateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0.5px 1px 4px 1px;
  margin-top: 70px;
  padding: 10px;
`;

export const SlateChildCenter = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  background: #b603c9;
  opacity: 0.46;
  margin-top: 20px;

  ${media.phone} {
    margin-left: 10px;
    margin-top: 20px;
    width: 320px;
  }
`;

export const FirstChildCenterBibleVerse = styled.div`
  font-family: "Great Vibes", cursive;
  width: 100%;
  margin-top: 22px;
  font-size: 20px;
  font-weight: 300px;
  color: #ffffff;
  text-align: center;
`;

export const FirstChildCenterBibleChapter = styled.div`
  margin-left: -40px;
  margin-top: 20px;
  font-size: 10px;
  color: #ffffff;
`;

export const SlateChildEnd = styled.div`
  height: 50px;
  width: 119px;
  background: #b603c9;
  opacity: 0.46;
  margin-top: 30px;
  margin-left: 280px;

  ${media.phone} {
    margin-left: 190px;
    margin-top: 20px;
  }
`;

export const SlateChildLast = styled.div`
  width: px;
  height: 47%;
  padding: 20px;
  ${media.phone} {
    padding: 5px;
    font-weight: 200;
    font-size: 9px;
  }
`;

export const Quote = styled.div`
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #151c4d;
`;

export const BibleChapter = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #151c4d;
  margin-top: 10px;
`;

export const BibleVerse = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #151c4d;
  margin-top: 15px;
  ${media.phone} {
    margin-left: 5px;
    width: 300px;
  }
`;

export const SecondSlateWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const ReflectionSlateWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface IContainer {
  bg?: string;
}

export const SlateContainer = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  background: #ffffff;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0.5px 1px 4px 1px;
  ${media.phone} {
    padding: 5px;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 10px;
  ${media.phone} {
    width: 300px;
    display: flex;
    flex-direction: row;
  }
`;

export const UserName = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;

  color: #151c4d;
  ${media.phone} {
    font-size: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const SlateDate = styled.div`
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  color: #151c4d;
`;

export const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrayerPoint = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    margin-top: 0px;
  }
`;

export const Prayer = styled.div`
  margin-top: 10px;
  margin-right: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
`;

export const ThirdSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
`;

export const UserProfilePhoto = styled.div`
  display: flex;
  border-radius: 50%;
  border: 2px solid white;
  align-items: center;
  ${media.phone} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`;
export const UserProfilePhoto2 = styled.div`
  display: flex;
  margin-left: -7px;
  border-radius: 50%;
  border: 2px solid white;
  ${media.phone} {
    display: flex;
    flex-direction: row;
  }
`;

export const PeoplePrayed = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #151c4d;
  ${media.phone} {
    display: flex;
    flex-direction: row;
  }
`;

export const PrayerIcon = styled.div`
  ${media.phone} {
    margin-left: 150px;
  }
`;
