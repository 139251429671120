import React from "react";
import { SuperAdminBookingTabs } from "./../../../../Utils/tabsNav";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import TabSwitch from "./../../../../Components/Nav/TabSwitch";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import Thansgiving from "./Thansgiving";
import MarraigeCounselling from "./MarraigeCounselling";
import Others from "./Others";

const Booking = () => {
  const { currentTab } = useAppSelector(selectNav);
  <PrimaryButton text="Add Booking" size="medium" />;
  return (
    <>
      <TabSwitch listItems={SuperAdminBookingTabs} />
      {currentTab === 1 ? (
        <Thansgiving />
      ) : currentTab === 2 ? (
        <MarraigeCounselling />
      ) : (
        <Others />
      )}
    </>
  );
};

export default Booking;
