import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import { TextArea } from "../../Components/Form/inputs/styles";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { editDonationInput } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import {
  getDonationDetails,
  selectAdminDonations,
} from "../../ReduxStore/features/Admin/DonationsSlice";
import { editDonation } from "../../Apis/AdminApis/donations";

const EditDonationModal = () => {
  const {
    typeOfDonation,
    description,
    targetAmount,
    currencyId,
    bankAccountId,
    churchGroupId,
    editingDonation,
    selectedDonation,
  } = useAppSelector(selectAdminDonations);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationDetails({ name, value }));
  };

  const handleEdit = () => {
    editDonation(
      selectedDonation?._id,
      bankAccountId,
      currencyId,
      churchGroupId,
      description,
      targetAmount,
      typeOfDonation
    );
  };

  return (
    <CustomModal
      id={14}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editDonationInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  value={input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options()}
                  name={input?.name}
                  handleSelect={handleSelect}
                  value={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    value={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              handleEdit();
            }}
            loading={editingDonation}
            disabled={editingDonation}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditDonationModal;
