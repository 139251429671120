import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setExitingGroup,
  setGettingJoinedGroup,
  setGettingMessages,
  setGettingUserGroups,
  setJoinedGroups,
  setMessages,
  setRequestingToJoinGroup,
  setSendingMessage,
  setUserGroups,
} from "../../ReduxStore/features/User/UserGroupSlice";
import api from "../baseUrl";

export const getAllMyChurchGroups = async (churchId: string) => {
  store.dispatch(setGettingUserGroups(true));
  const params: any = {
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/user-group", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setUserGroups(data?.data));
      store.dispatch(setGettingUserGroups(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingUserGroups(false));
    });
};

export const joinGroup = async (churchGroupId: string) => {
  store.dispatch(setRequestingToJoinGroup(true));
  const body = {
    churchGroupId,
  };
  await api
    .post("user/user-group/request-join", body)
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setRequestingToJoinGroup(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setRequestingToJoinGroup(false));
    });
};

export const exitGroup = async (churchGroupId: string) => {
  store.dispatch(setExitingGroup(true));
  await api
    .delete(`user/profile/church-group/${churchGroupId}/membership`)
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setExitingGroup(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setExitingGroup(false));
    });
};

export const getJoinedGroups = async (status?: string) => {
  store.dispatch(setGettingJoinedGroup(true));
  const params: any = {
    status,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/user-group/user-joined", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setJoinedGroups(data?.data));
      store.dispatch(setGettingJoinedGroup(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingJoinedGroup(false));
    });
};

export const sendChatMessage = async (
  churchGroupId: string,
  message: string,
  fileUrl: any
) => {
  store.dispatch(setSendingMessage(true));
  const body: any = {
    churchGroupId,
    message,
    fileUrl,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("user/user-group/chat-group", body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(() => {
      store.dispatch(setSendingMessage(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSendingMessage(false));
    });
};

export const getChatMessages = async (churchGroupId: string) => {
  store.dispatch(setGettingMessages(true));
  const params: any = {
    churchGroupId,
  };
  Object.keys(params)?.forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  await api
    .get("user/user-group/messages", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setMessages(data?.data));
      store.dispatch(setGettingMessages(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingMessages(false));
    });
};
