import { getChurchDonations } from "../../../../Apis/UserApis/donations";
import PayToChurchMOdal from "../../../../Modals/user/PayToChurchModal";
import ViewDonationHistoryModal from "../../../../Modals/user/ViewDontaionsHistoryModal";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectDonations } from "../../../../ReduxStore/features/User/DonationSlice";
import SowSeed from "./SowSeed";
import { GivingContainer } from "./styles";
import { useEffect } from "react";

const Giving = () => {
  const { churchGroupId, typeOfDonation, page, pageSize } =
    useAppSelector(selectDonations);

  useEffect(() => {
    getChurchDonations(typeOfDonation, churchGroupId, page, pageSize);
  }, [typeOfDonation, churchGroupId, page]);

  return (
    <GivingContainer>
      <SowSeed />
      <PayToChurchMOdal />
      <ViewDonationHistoryModal />
    </GivingContainer>
  );
};

export default Giving;
