import { useEffect } from "react";
import CustomModal from "./CustomModal";
import FormWrapper from "./../Layouts/FormWrapper";
import TextInput from "./../Components/Form/inputs/TextInput";
import PrimaryButton from "./../Components/Form/Buttons/PrimaryButton";
import { TextArea } from "./../Components/Form/inputs/styles";
import { editRoleInput } from "../Utils/authInput";
import SelectBox from "./../Components/Form/inputs/SelectBox";
import {
  getConfigurationsFormDetails,
  selectSuperAdminConfigurations,
} from "../ReduxStore/features/SuperAdmin/ConfigurationsSlice";
import { useAppSelector } from "../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import { editAppRole } from "../Apis/SuperAdminApis/settings";

const EditRoleModal = () => {
  const { roleTitle, roleDescription, roleStatus, roleId, editingRole } =
    useAppSelector(selectSuperAdminConfigurations);
  const dispatch = useDispatch();

  useEffect(() => {}, [roleTitle, roleDescription, roleStatus, editingRole]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getConfigurationsFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getConfigurationsFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getConfigurationsFormDetails({ name, value }));
  };

  return (
    <CustomModal
      id={2}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editRoleInput?.map((input) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  defaultValue={input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options}
                  name={input?.name}
                  defaultValue={input?.defaultValue()}
                  handleSelect={handleSelect}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Update"
            handleClick={(e: any) => {
              e.preventDefault();
              editAppRole(roleId, roleTitle, roleDescription, roleStatus);
            }}
            loading={editingRole}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditRoleModal;
