import React from "react";
import styled from "styled-components";
import { BiError } from "react-icons/bi";
import { media } from "../../../Screens";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(255, 92, 111, 0.2);
  color: #393939;
  border-radius: 0.92em;
  position: relative;
  padding: 0.76em;
  font-size: 13px;
  margin: 15px 0;
  width: 340px;

  svg {
    color: #f14668;
    font-size: 18px;
  }

  ${media.phone} {
    width: 300px;
  }
`;

const ErrorMessage = ({ message }) => {
  return (
    <Container>
      <BiError />
      <div>{message}</div>
    </Container>
  );
};

export default ErrorMessage;
