import React, { FC } from "react";
import styled from "styled-components";

// const LastPayContainer = styled.div`
// display: flex;
// padding: 15px 25px;
// margin-top: 30px;
// flex-direction: column;
// width: 600px;
// height: 150px;
// background: #FFFFFF;
// box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
// `;
const FirstWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  border-bottom: 1px grey;
  box-shadow:
    rgba(27, 31, 35, 0.2) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
`;
const Basic = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #151c4d;
`;
const Date = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #151c4d;
`;
const Ammount = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #b603c9;
`;

interface LastPay {
  basicPlan: string;
  paydate: any;
  paidAmmount: any;
}

const LastPaymentCard: FC<LastPay> = ({ basicPlan, paydate, paidAmmount }) => {
  return (
    <>
      <FirstWrap>
        <Basic>{basicPlan}</Basic>
        <Date>{paydate}</Date>
        <Ammount>{paidAmmount}</Ammount>
      </FirstWrap>
    </>
  );
};

export default LastPaymentCard;
