import React, { FC } from "react";
import styled from "styled-components";

const SermonCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:300px;
  // height: 200px;
  margin-top: 30px;
  border-radius: 5px;
`;
// const ShareIcon = styled.div`
//   margin-top: 80px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 30px;
//   margin-left: 10px;
//   background: #b603c9;
//   padding: 2px 2px;
//   width: 25px;
//   height: 25px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   color: #ffffff;
// `;

const VideoContainer = styled.video``;

const VideoCaption = styled.div`
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  color: #151c4d;
  margin-top: 10px;
`;

interface SermonI {
  shareIcon: any;
  videoCaption: any;
  link: string;
}

const SermonCard: FC<SermonI> = ({ shareIcon, videoCaption, link }) => {
  return (
    <SermonCardContainer>
      <VideoContainer src={link} controls></VideoContainer>
      <VideoCaption>{videoCaption}</VideoCaption>
    </SermonCardContainer>
  );
};

export default SermonCard;
