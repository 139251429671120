// import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import SelectBox from "../../../Components/Form/inputs/SelectBox";
import TextInput from "../../../Components/Form/inputs/TextInput";
import FormWrapper from "../../../Layouts/FormWrapper";
import { getFormDetails } from "../../../ReduxStore/features/formSlice";
import { adminSignUpInputs } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import {
  useAppDispatch,
  useAppSelector,
} from "./../../../ReduxStore/app/hooks";
import {
  AuthLogo,
  AuthTitle,
  AuthLink,
  AuthSmallText,
  AuthSubTitle,
} from "../style";
import { selectAuth } from "../../../ReduxStore/features/authSlice";
import { createChurch } from "../../../Apis/Auth/signupApis";
import { toast } from "react-toastify";
import CountryStateCitySelector from "../../../Components/Form/Selector/CountryStateCitySelector";
import { useState } from "react";
import ErrorMessage from "../../../Components/Reusables/MessagePopups/ErrorMessage";
import { validateemail } from "../../../Components/Helpers/Generals";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
const SignupAsAdmin = () => {
  const dispatch = useAppDispatch();
  const {
    adminEmail,
    denomination,
    churchAddress,
    city,
    state,
    country,
    landmark,
    churchName,
    churchPhoneNo,
    town
  } = useAppSelector((state) => state.form);
  const { creatingChurch } = useAppSelector(selectAuth);
  const [errors, setErrors] = useState<any>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleLocationSelect = (name: any, value: any) => {
    setErrors([]);
    dispatch(getFormDetails({ name, value }));
  };

  const handleCreateChurchAd = () => {
    setErrors([]);
    if (!churchName) {
      setErrors([...errors, "ChurchName cannot be empty"]);
    } else if (!denomination) {
      setErrors([...errors, "Denomination cannot be empty"]);
    } else if (!adminEmail) {
      setErrors([...errors, "Admin email cannot be empty"]);
    } else if (!validateemail(adminEmail)) {
      setErrors([...errors, "Please enter a valid email"]);
    } else if (!churchPhoneNo) {
      setErrors([...errors, "Church Phone No cannot be empty"]);
    } else if (!churchAddress) {
      setErrors([...errors, "Church address cannot be empty"]);
    } else if (!landmark) {
      setErrors([...errors, "Landmark cannot be empty"]);
    } else if (!city) {
      setErrors([...errors, "City cannot be empty"]);
    } else if (!state) {
      setErrors([...errors, "State cannot be empty"]);
    } else if (!country) {
      setErrors([...errors, "Country cannot be empty"]);
    }
    else if (!town) {
      setErrors([...errors, "Town cannot be empty"]);
    } else {
      createChurch(
        churchName,
        denomination,
        adminEmail,
        churchPhoneNo,
        churchAddress,
        landmark,
        city,
        state,
        country,
        town
      );
    }
    // navigate("/auth/signup/role/admin/about-church");
  };

  return (
    <AuthMain placeItemsCenter={true}>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>
        <Link to="/auth/signup">
          <IoArrowBackCircle />
        </Link>
        Sign Up
      </AuthTitle>
      <AuthSubTitle>Register your church</AuthSubTitle>
      <FormWrapper
        style={{
          flexDirection: `row`,
          flexWrap: `wrap`,
          alignItems: `center`,
          justifyContent: `center`,
        }}
      >
        {adminSignUpInputs?.map((input) => (
          <>
            {(input?.type === "text" ||
              input?.type === "email" ||
              input?.type === "phone") && (
              <TextInput
                placeholder={input?.placeholder}
                icon={input?.ActiveIcon}
                handleChange={handleChange}
                name={input?.name}
                type={input?.type}
              />
            )}
            {input?.type === "country-select" && (
              <CountryStateCitySelector
                handleSelect={handleLocationSelect}
                cityName={input?.cityName}
                countryName={input?.countryName}
                stateName={input?.stateName}
                handleChange={handleChange}
              />
            )}
            {input?.type === "select" && (
              <SelectBox
                options={input?.options}
                name={input?.name}
                handleSelect={handleSelect}
              />
            )}
          </>
        ))}
      </FormWrapper>
      {errors?.length > 0 && (
        <ErrorMessage
          message={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {errors?.map((error: any) => (
                <span key={error}>{error}</span>
              ))}
            </div>
          }
        />
      )}
      <PrimaryButton
        text="Submit"
        handleClick={handleCreateChurchAd}
        loading={creatingChurch}
      />
      <AuthSmallText>
        Already have an account? <AuthLink to="/">Login here</AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default SignupAsAdmin;
