import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setBookings,
  setGettingBookings,
  setUpdatingBookings,
} from "../../ReduxStore/features/Admin/BookingSlice";
import api from "../baseUrl";

export const getChurchBookings = async (
  user: string,
  type: string,
  churchId: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingBookings(true));

  const params = {
    type,
    churchId,
    page,
    pageSize,
  };
  await api
    .get(`${user}/booking`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setBookings(data));
      store.dispatch(setGettingBookings(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBookings(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const updateChurchBooking = async (
  churchId: string,
  bookingId: string,
  status: string,
  user: string
) => {
  store.dispatch(setUpdatingBookings(true));

  const body = {
    bookingId,
    status,
    churchId,
    user,
  };
  await api
    .post(`${user}/booking`, body)
    .then(() => {
      store.dispatch(setUpdatingBookings(false));
      toast.success(`Successful`);
    })
    .catch((err) => {
      store.dispatch(setUpdatingBookings(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};
