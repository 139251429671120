import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface OverviewState {
  gettingChurches: boolean;
  verifiedChurches: any;
  unVerifiedChurches: any;
  churches: any;
  verifyingChurch: boolean;
}

const initialState: OverviewState = {
  gettingChurches: false,
  verifiedChurches: null,
  unVerifiedChurches: null,
  verifyingChurch: false,
  churches: null,
};

export const OverviewSlice = createSlice({
  name: "Overview",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurches: (state, action) => {
      state.gettingChurches = action.payload;
    },
    setVerifyingChurches: (state, action) => {
      state.verifyingChurch = action.payload;
    },
    setChurches: (state, action) => {
      state.churches = action.payload?.itemsList;
      state.verifiedChurches = action.payload?.itemsList?.filter(
        (item: any) => item?.verified === true,
      );
      state.unVerifiedChurches = action.payload?.itemsList?.filter(
        (item: any) => item?.verified === false,
      );
    },
  },
});

export const { setGettingChurches, setChurches, setVerifyingChurches } =
  OverviewSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminOverview = (state: RootState) =>
  state.superAdminOverview;

export default OverviewSlice.reducer;
