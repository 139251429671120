import React, { useEffect, useState } from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { editUserPostInputs } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { TextArea } from "../../Components/Form/inputs/styles";
import {
  getPostFormDetails,
  selectUserPost,
} from "../../ReduxStore/features/User/UserPostSlice";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { getSinglePost, updatePost } from "../../Apis/UserApis/Post";
import { VideoAudioContainer } from "../../Pages/Private/Admin/Media/style";
import { MdOutlineAttachFile } from "react-icons/md";
import { theme } from "../../theme";

const EditUserPostModal = () => {
  const dispatch = useDispatch();
  const [fileSource, setFileSource] = useState();
  const [fileType, setFileType] = useState("");
  const {
    contentType,
    title,
    description,
    hashtags,
    file,
    updatingUserPost,
    postId,
    singlePost,
  } = useAppSelector(selectUserPost);

  const handleFileUpload = (e: any) => {
    e.preventDefault();
    const file = e?.target?.files[0];
    const name = e?.target?.name;
    if (file) {
      const extractedText = file.type.split("/")[0];
      setFileType(extractedText);
      dispatch(getPostFormDetails({ name, value: file }));
      dispatch(
        getPostFormDetails({ name: "contentType", value: extractedText })
      );
      const reader: any = new FileReader();
      reader.onload = (e: any) => {
        const src = e.target.result;
        setFileSource(src);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPostFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const tags = value.match(/#[a-zA-Z0-9_]+/g) || [];
    dispatch(getPostFormDetails({ name: "hashtags", value: tags }));
    dispatch(getPostFormDetails({ name, value }));
  };

  const handleUpdateModal = () => {
    updatePost(postId, contentType, title, description, hashtags, file);
  };

  useEffect(() => {
    getSinglePost(postId);
  }, [postId]);

  useEffect(() => {
    dispatch(getPostFormDetails({ name: "title", value: singlePost?.title }));
    dispatch(
      getPostFormDetails({
        name: "description",
        value: singlePost?.description,
      })
    );
    dispatch(
      getPostFormDetails({ name: "file", value: singlePost?.contentUrl })
    );
    dispatch(
      getPostFormDetails({
        name: "contentType",
        value: singlePost?.contentType,
      })
    );
  }, [singlePost]);

  useEffect(() => {}, [title, description, file]);


  return (
    <CustomModal
      id={5}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editUserPostInputs?.map((input: any) => (
            <>
              {input?.type === "file" ? (
                <>
                  {fileSource && (fileType || contentType === "video") ? (
                    <VideoAudioContainer>
                      <video
                        src={fileSource || input?.defaultValue()}
                        controls
                        typeof="video/mp4"
                        autoPlay
                      ></video>
                    </VideoAudioContainer>
                  ) : fileSource && (fileType || contentType === "audio") ? (
                    <audio
                      src={fileSource || input?.defaultValue()}
                      controls
                      style={{ width: "340px" }}
                    />
                  ) : (
                    <img
                      src={fileSource || input?.defaultValue()}
                      width={340}
                    />
                  )}
                  <div>
                    <label
                      htmlFor="file"
                      style={{
                        width: "90%",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    >
                      <span>
                        <MdOutlineAttachFile
                          size={20}
                          color={theme.colors.primaryColor}
                        />
                      </span>
                      <span>Attach File</span>
                    </label>
                    <input
                      type="file"
                      accept={
                        ".mp4, .avi, .mkv, .mov, .wmv, .flv, .webm, .mpeg, .mpg, .mp3, .wav, .aac, .flac, .ogg, .wma, .m4a, .alac, .aiff, .opus,.png, .jpg, .jpeg, .gif, .svg, .webp"
                      }
                      style={{ display: "none" }}
                      id="file"
                      name={input?.name}
                      onChange={handleFileUpload}
                    />
                  </div>
                </>
              ) : input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  style={{ width: "100%" }}
                  defaultValue={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Update Post"
            handleClick={(e: any) => {
              e.preventDefault();
              handleUpdateModal();
            }}
            loading={updatingUserPost}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditUserPostModal;
