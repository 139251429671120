import React, { useEffect } from "react";
import { SummaryContainer } from "./style";
import CashSummaryCard from "../../../../Layouts/CashSummaryCard";
import { FaUsers, FaUsersSlash } from "react-icons/fa";
import MembershipReportLogs from "./../../../../Components/Tables/Admin/MembershipReportTable";
import SearchBox from "../../../../Components/Reusables/SearchBox";
import { getAdminChurcheMembers } from "../../../../Apis/AdminApis/ChurchManager";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectAdminManageChurches } from "../../../../ReduxStore/features/Admin/manageChurchesSlice";

const MembershipReport = () => {
  const { gettingAdminChurchMembers, adminChurchMembers, activeMembers, inActiveMembers, deletingChurchMember } =
    useAppSelector(selectAdminManageChurches);

  useEffect(() => {
    getAdminChurcheMembers();
  }, [deletingChurchMember]);
  return (
    <div>
      <SummaryContainer>
        <CashSummaryCard
          icon={<FaUsers />}
          title="Total Members"
          value={adminChurchMembers?.itemsList?.length}
          background="rgba(182, 3, 201, 0.25)"
          loading={gettingAdminChurchMembers}
        />
        <CashSummaryCard
          icon={<FaUsers />}
          title="Total Active Members"
          value={activeMembers?.length}
          background="rgba(246, 185, 88,0.25)"
          loading={gettingAdminChurchMembers}
        />
        <CashSummaryCard
          icon={<FaUsersSlash />}
          title="Total Inactive Members"
          value={inActiveMembers?.length}
          background="rgba(105, 199, 112, 0.25)"
          loading={gettingAdminChurchMembers}
        />
      </SummaryContainer>
      {/* <SearchBox /> */}
      <MembershipReportLogs />
    </div>
  );
};

export default MembershipReport;
