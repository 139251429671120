import { useEffect } from "react";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import {
  LocationCardContainer,
  LocationMediumContainer,
  SectionTitle,
} from "./style";
import RoleCard from "../../../../Layouts/RoleCard";
import {
  selectAdminActivities,
  setSelectedEventLocation,
  setSelectedEventMedium,
} from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  deleteEventLocation,
  deleteEventMedium,
  getEventLocations,
  getEventMedium,
} from "../../../../Apis/AdminApis/ChurchActivities";
import { getUserActiveChurch } from "../../../../Apis/UserApis/userProfile";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import CreateEventMedium from "../../../../Modals/Admin/CreateEventMedium";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import EditEventMediumModal from "../../../../Modals/Admin/EditEventMedium";
import CreateEventLocationModal from "../../../../Modals/Admin/CreateEventLocationModal";
import EditEventLocationModal from "../../../../Modals/Admin/EditEventLocationModal";

const ManageEventMedium = () => {
  const {
    gettingEventMedium,
    gettingEventLocation,
    eventMediums,
    eventLocations,
    deletingEventLocation,
    deletingEventMedium,
    creatingEventMedium,
    creatingEventLocation,
    editingEventLocation,
    editingEventMedium,
  } = useAppSelector(selectAdminActivities);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUserActiveChurch();
  }, [
    creatingEventMedium,
    creatingEventLocation,
    editingEventLocation,
    editingEventMedium,
  ]);

  useEffect(() => {
    getEventMedium();
    getEventLocations(userProfile?.churchId?._id);
  }, [
    userProfile,
    deletingEventMedium,
    deletingEventLocation,
    creatingEventMedium,
    creatingEventLocation,
    editingEventLocation,
    editingEventMedium,
  ]);

  return (
    <LocationMediumContainer>
      <div className="top-content">
        <SectionTitle>Locations</SectionTitle>
        <PrimaryButton
          text="Add Location"
          size="medium"
          handleClick={() => {
            dispatch(handleModal(19));
          }}
        />
        {gettingEventLocation ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : (
          <LocationCardContainer>
            {eventLocations?.itemsList?.length > 0 ? (
              eventLocations?.itemsList?.map((location: any, index: any) => (
                <RoleCard
                  title={
                    eventMediums?.itemsList?.filter(
                      (item: any) => item?._id === location?.medium
                    )[0]?.name
                  }
                  desc={location?.address}
                  status="active"
                  setDelete={() => {
                    deleteEventLocation(location?._id);
                  }}
                  setEdit={() => {
                    dispatch(setSelectedEventLocation(location));
                    dispatch(handleModal(20));
                  }}
                  customWidth="100%"
                />
              ))
            ) : (
              <NoRecordDisplay message="No Locations Yet" />
            )}
          </LocationCardContainer>
        )}
      </div>
      <div className="bottom-content">
        <SectionTitle>Mediums</SectionTitle>
        <PrimaryButton
          text="Add Medium"
          size="medium"
          handleClick={() => dispatch(handleModal(17))}
        />
        {gettingEventMedium ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : (
          <LocationCardContainer>
            {eventMediums?.itemsList?.length > 0 ? (
              eventMediums.itemsList.map((medium: any, index: any) => (
                <RoleCard
                  title={`Medium ${index + 1}`}
                  desc={medium?.name}
                  status="active"
                  setDelete={() => {
                    deleteEventMedium(medium?._id);
                  }}
                  setEdit={() => {
                    dispatch(handleModal(18));
                    dispatch(setSelectedEventMedium(medium));
                  }}
                  customWidth="100%"
                />
              ))
            ) : (
              <NoRecordDisplay message="No Mediums Yet" />
            )}
          </LocationCardContainer>
        )}
        <CreateEventMedium />
        <EditEventMediumModal />
        <CreateEventLocationModal />
        <EditEventLocationModal />
      </div>
    </LocationMediumContainer>
  );
};

export default ManageEventMedium;
