import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import { TextArea } from "../../Components/Form/inputs/styles";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { createDonationInput } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { toast } from "react-toastify";
import { AddDonation } from "../../Apis/AdminApis/donations";
import {
  getDonationDetails,
  selectAdminDonations,
} from "../../ReduxStore/features/Admin/DonationsSlice";

const CreateDonationModal = () => {
  const {
    title,
    typeOfDonation,
    description,
    targetAmount,
    currencyId,
    bankAccountId,
    churchGroupId,
    creatingChurchDonation,
  } = useAppSelector(selectAdminDonations);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationDetails({ name, value }));
  };

  const handleCreateDonation = (e: any) => {
    e.preventDefault();
    if (
      title &&
      typeOfDonation &&
      description &&
      targetAmount &&
      currencyId &&
      bankAccountId &&
      churchGroupId
    ) {
      AddDonation(
        title,
        typeOfDonation,
        description,
        targetAmount,
        currencyId,
        bankAccountId,
        churchGroupId
      );
    } else {
      toast.error("Please fill all fields");
    }
  };
  return (
    <CustomModal
      id={13}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {createDonationInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  value={input?.defaultValue && input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options()}
                  name={input?.name}
                  handleSelect={handleSelect}
                  value={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    value={input?.defaultValue && input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={handleCreateDonation}
            loading={creatingChurchDonation}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateDonationModal;
