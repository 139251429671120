import styled from "styled-components";
// import { theme } from "../../../../theme";
import { media } from "./../../../../Screens";

export const IndexWrap = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
  .left-content {

    ${media.phone} {
      flex: 1;
      width: 100%;
    }
  }

  .right-content {
    flex: 0.2;
  }

  ${media.phone} {
    width: 200px;
    // margin-left: 40px;
  }

  div.ad {
    // position: absolute;
    // margin-left: 800px;
    // margin-top: 220px;
    border: 2px solid;
    height: 173px;
    width: 173px;
    border-radius: 5%;

    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }

  .booksortdown {
    cursor: pointer;
  }
  .bookfatimes {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  ${media.phone} {
    margin-left: 0px;
    width: 100%;
  }
`;

export const HeaderContainerWrap = styled.div`
  display: flex;
  justify: center;
  align-items: center;
  margin-left: 0px;
  gap: 40px;
  width: auto;
  border-bottom: 1px #707070;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  // padding-left: 70px;
  padding-bottom: 20px;

  ${media.phone} {
    width: 100%;
  }
`;
export const BookList = styled.div`
  display: flex;
  padding: 0px 10px;
`;
export const BibleBook = styled.div`
display: flex;
justify: content: center;

${media.phone}{
  width: 100%;
}
`;
export const Selection = styled.div`
  display: flex;
`;
export const BibleVersionInner = styled.div`
  display: flex;
`;
export const VersionList = styled.div`
  display: flex;
  padding: 0px 10px;
`;
export const BibleFont = styled.div`
  display: flex;
  // margin-left: 100px;
`;

export const EventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  // position: absolute;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;

export const EventCaption = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -20px;
`;

export const EventIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventTopic = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const EventName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;
export const Sponsor = styled.div`
  // position: absolute;
  // margin-left: 700px;
  // margin-top: 180px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 700px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: 220px;
  ${media.phone} {
    display: none;
  }
`;

export const Display = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 500px;
  margin-top: 30px;
  margin-left: -130px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #151c4d;

  ${media.phone} {
    width: 330px;
    margin-left: -40px;
  }
`;

export const Books = styled.div`
  position: relative;
  width: 100%;

  .list {
    :hover {
      width: 100px;
      background: white;
      color: #b603c9;
      cursor: pointer;
    }
  }
  .h2 {
    color: #b603c9;
    margin-top: -10px;
  }

  .bookfatimes {
    font-size: 14px;
    color: #b603c9;
  }
`;

export const BibleVersions = styled.div`
  position: absolute;
  justify-content: center;
  width: 500px;
  margin-top: 30px;
  margin-left: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #151c4d;
`;

export const Select = styled.div`
  :hover {
    width: 100px;
    background: white;
    color: #b603c9;
    cursor: pointer;
  }
`;
export const ListWrap = styled.div`
  margin-top: 30px;
  margin-left: 150px;
`;
export const VersionDisplay = styled.div`
  margin-top: -170px;
  margin-left: 0px;
`;
