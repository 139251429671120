import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";
// import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAppSelector } from "../ReduxStore/app/hooks";
import { selectAdminGroups } from "../ReduxStore/features/Admin/GroupSlice";
import Loader from "../Components/Loaders/MoonLoader";
import { deleteGroupMember } from "../Apis/AdminApis/churchGroup";

const Container = styled.div`
  display: flex;
  align-items: center;
  //   justify-content: center;
  //   justify-content: space-between;
  padding: 10px;
  gap: 10px;
  height: 100px;
  width: 350px;
  background: ${theme.colors.bgWhite};
  gap: 20px;
  cursor: pointer;
`;

const LeftContent = styled.div`
  margin-right: 20px;
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #cfdbff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #4d79ff;
  text-transform: uppercase;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background: #9afca1;
    bottom: -7px;
    right: 7px;
  }
`;

const RightContent = styled.div`
  display: flex;
  // align-items: center;
  gap: 10px;
  flex-direction: column;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  svg {
    font-size: 16px;
  }
`;

const GroupName = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #383838;
`;

const GroupStatus = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #383838;
`;

// const MemberCount = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 18px;
//   color: #383838;
// `;

interface GroupCardI {
  name: string;
  role: string;
  //   memberCount: number | string;
  memberId: string;
  churchGroupId: string;
}

const MemberCardLayout: FC<GroupCardI> = ({
  name,
  role,
  memberId,
  churchGroupId,
  //   memberCount,
}) => {
  const { deletingGroupMembers } = useAppSelector(selectAdminGroups);

  return (
    <Container>
      <LeftContent>
        <Avatar>{name.slice(0, 2)}</Avatar>
      </LeftContent>
      <RightContent>
        <GroupName>{name}</GroupName>
        <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {/* <FiEdit /> */}
          <GroupStatus>{role}</GroupStatus>
          {deletingGroupMembers ? <Loader size={10} color={theme.colors.primaryColor} /> : <RiDeleteBin6Line onClick={() => deleteGroupMember(memberId, churchGroupId)} />}
          {/* <MemberCount>{role} Members</MemberCount> */}
        </span>
      </RightContent>
    </Container>
  );
};

export default MemberCardLayout;
