import React from "react";
import { TransactionLogsTableCols } from "../../../Utils/SuperAdmin/tableCols";
import CustomTable from "../CustomRTable";
import { transactionsTableRow } from "../../../Utils/SuperAdmin/tableRows";

const TransactionLogs = () => {
  const rows = transactionsTableRow();

  const data = {
    columns: TransactionLogsTableCols,
    rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        // pageCount={churchActivities?.paginator?.pageCount}
        // itemCount={churchActivities?.paginator?.itemCount}
        // hasNextPage={churchActivities?.paginator?.hasNextPage}
        // hasPrevPage={churchActivities?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default TransactionLogs;
