import { useAppSelector } from "../../../ReduxStore/app/hooks";
import { unVerifiedChurshesTableRow } from "../../../Utils/SuperAdmin/tableRows";
import CustomTable from "../CustomRTable";
import { unVerifiedChurchesTableCols } from "../../../Utils/SuperAdmin/tableCols";
import { useEffect } from "react";
import { getChurches } from "../../../Apis/SuperAdminApis/dashboard";
import { selectSuperAdminOverview } from "../../../ReduxStore/features/SuperAdmin/OverviewSlice";

const UnVerifiedChurchesTable = () => {
  const { gettingChurches, unVerifiedChurches, churches, verifyingChurch } =
    useAppSelector(selectSuperAdminOverview);
  const rows = unVerifiedChurshesTableRow(unVerifiedChurches);

  const data = {
    columns: unVerifiedChurchesTableCols,
    rows,
  };

  useEffect(() => {
    getChurches();
  }, [verifyingChurch]);

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingChurches}
        noRecord={unVerifiedChurches?.length > 0 ? false : true}
        noRecordText="No  Unverified Church Available"
        currentPage={churches?.paginator?.currentPage}
        pageCount={unVerifiedChurches?.paginator?.pageCount}
        itemCount={unVerifiedChurches?.paginator?.itemCount}
        hasNextPage={unVerifiedChurches?.paginator?.hasNextPage}
        hasPrevPage={unVerifiedChurches?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default UnVerifiedChurchesTable;
