import React, { FC, useState } from "react";
import {
  InputContainer,
  InputText,
  Label,
  // Message,
  ParentContainer,
  StrengthBar,
  StrengthLevel,
} from "./styles";

interface TextInputI {
  placeholder?: any;
  value?: any;
  icon?: any;
  altIcon?: any;
  leftIcon?: any;
  name?: string;
  type?: string;
  readonly?: boolean;
  handleChange?: any;
  defaultValue?: any;
  iconState?: any;
  handleIconClick?: any;
  handleAltIconClick?: any;
  min?: any;
  required?: any;
  error?: any;
  strength?: any;
  showStrengthSignal?: boolean;
  disabled?: boolean;
  customStyle?: any;
}

const TextInput: FC<TextInputI> = ({
  value,
  placeholder,
  icon,
  altIcon,
  name,
  type,
  readonly,
  leftIcon,
  handleChange,
  defaultValue,
  iconState,
  handleIconClick,
  handleAltIconClick,
  min,
  error,
  required,
  showStrengthSignal,
  customStyle,
  disabled,
}) => {
  const [strength, setStrength] = useState(0);

  const calculateStrength = () => {
    let strength = 0;

    if (value.length >= 8) strength += 30;
    if (/[A-Z]/.test(value)) strength += 20;
    if (/[0-9]/.test(value)) strength += 20;
    if (/[\W]/.test(value)) strength += 30; // special characters

    return strength;
  };

  const handlePasswordChange = () => {
    setStrength(calculateStrength());
  };

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    // Handle number type input validation
    if (type === "number") {
      const numberRegex = /^[1-9]\d*$/; // Regex to ensure no 0 or negative numbers
      if (!numberRegex.test(inputValue)) {
        return; // Prevent update if input starts with 0 or is negative
      } else {
        handleChange && handleChange(e);
      }
    } else {
      handleChange && handleChange(e);
    }
    showStrengthSignal && handlePasswordChange();
  };

  return (
    <ParentContainer style={customStyle}>
      <Label>{placeholder}:</Label>
      <InputContainer error={required && error}>
        <span>{leftIcon}</span>
        <InputText
          placeholder={placeholder}
          name={name}
          type={type}
          defaultValue={defaultValue}
          readOnly={readonly}
          onChange={handleInputChange}
          value={value}
          // min="1"
          disabled={disabled}
        />
        {icon && !iconState && <span onClick={handleIconClick}>{icon}</span>}
        {altIcon && iconState && (
          <span onClick={handleAltIconClick}>{altIcon}</span>
        )}
      </InputContainer>
      {showStrengthSignal && (
        <>
          <StrengthBar>
            <StrengthLevel strength={strength} />
          </StrengthBar>
          {/* <Message strength={strength}>
            {strength === 0
              ? "Enter a password"
              : strength > 80
              ? "Strong"
              : strength > 50
              ? "Medium"
              : "Weak"}
          </Message> */}
        </>
      )}
    </ParentContainer>
  );
};

export default TextInput;
