import { useEffect } from "react";
import { CardsContainer } from "./style";
import PrayerRequestCard from "../../../../Layouts/PrayerRequestCard";
import moment from "moment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getADminChurchPrayerFormDetails,
  selectAdminChurchPrayerWall,
} from "../../../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import { getAllChurchPrayerRequest } from "../../../../Apis/AdminApis/PrayerWalll";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { selectUser } from "../../../../ReduxStore/features/userSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const PrayerRequest = () => {
  const { ChurchPrayerRequest, gettingChurchPrayerRequest, page, pageSize } =
    useAppSelector(selectAdminChurchPrayerWall);
  const { currentUser } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getADminChurchPrayerFormDetails({ name: "page", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getAllChurchPrayerRequest(
      currentUser?.roles[0]?.churchId?._id,
      page,
      pageSize
    );
  }, [currentUser, page]);

  return (
    <>
      {gettingChurchPrayerRequest ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <>
          {ChurchPrayerRequest?.itemsList.length > 0 ? (
            <CardsContainer>
              {ChurchPrayerRequest?.itemsList.map((request: any) => (
                <PrayerRequestCard
                  user={
                    request?.userId?.lastName + " " + request?.userId?.firstName
                  }
                  date={moment(request?.createdAt).format("MMM Do YYYY")}
                  prayerPoint={request?.title}
                  reading={request?.description}
                  prayerIcon={<img src="assets/images/prayicon.png" alt="" />}
                />
              ))}
            </CardsContainer>
          ) : (
            <NoRecordDisplay
              message="No prayer requests at the moment"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}

      <Paginator
        currentPage={ChurchPrayerRequest?.paginator?.currentPage}
        pageCount={ChurchPrayerRequest?.paginator?.pageCount}
        itemCount={ChurchPrayerRequest?.paginator?.itemCount}
        hasNextPage={ChurchPrayerRequest?.paginator?.hasNextPage}
        hasPrevPage={ChurchPrayerRequest?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default PrayerRequest;
