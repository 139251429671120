export const adminConfigInnerNav = [
  {
    id: 1,
    nav: "Internal Settings",
  },
  {
    id: 2,
    nav: "Roles",
  },
  // {
  //   id: 3,
  //   nav: "Webhook",
  // },
];

export const subscriptionInnerNav = [
  {
    id: 1,
    nav: "All Subscriptions",
  },
  {
    id: 2,
    nav: "Payment History",
  },
  // {
  //   id: 3,
  //   nav: "Webhook",
  // },
];

export const bibleConfigInnerNav = [
  {
    id: 1,
    nav: "Prayer Request",
  },
  {
    id: 2,
    nav: "Testimonies",
  },
  {
    id: 3,
    nav: "Church Prayers",
  },
];

export const sermonConfigInnerNav = [
  {
    id: 1,
    nav: "Written Sermon",
  },
  {
    id: 2,
    nav: "Video Sermon",
  },
  {
    id: 3,
    nav: "Audio Sermon",
  },
];

export const userConfigInnerNav = [
  {
    id: 1,
    nav: "Prayer Request",
  },
  {
    id: 2,
    nav: "Testimonies",
  },
  {
    id: 3,
    nav: "Church Prayers",
  },
  {
    id: 4,
    nav: "Daily Scriptures",
  },
];
