import { FC } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";

export const ViewContainer = styled.div`
  display: flex;
  width: 100%;

  ${media.phone} {
    width: 300px;
  }
`;

interface IReflectionList {
  text?: string;
}

const ReflectionView: FC<IReflectionList> = ({ text }) => {
  return (
    <div>
      <ViewContainer>{text}</ViewContainer>
    </div>
  );
};

export default ReflectionView;
