import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import {
  AdvertDuration,
  Duration,
  FormContainer,
  PubButtonContainer,
  PubButtonWrap,
} from "../../Pages/Private/Admin/PostAds/style";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getAdvertDetails,
  selectAdminChurchAdvert,
} from "../../ReduxStore/features/Admin/AdvertSlice";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import {
  completeAdsPayment,
  createAdvert,
  getAdsSettings,
} from "../../Apis/AdminApis/Adverts";
import { toast } from "react-toastify";
import FileUpload from "../../Components/Form/inputs/FileUpload";
import Select from "react-select";
import { selectStyles } from "../../Components/Form/inputs/styles";
import { getAdminChurch } from "../../Apis/AdminApis/ChurchActivities";
import { selectAdminActivities } from "../../ReduxStore/features/Admin/ActivitiesSlice";

const advertTypes = [
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
];

const PostAddModal = () => {
  const {
    imageUrl,
    description,
    startDate,
    endDate,
    creatingChurchAdvert,
    advertType,
    paymentRef,
  } = useAppSelector(selectAdminChurchAdvert);
  const dispatch = useAppDispatch();
  const { adminChurch } = useAppSelector(selectAdminActivities);
  const [advertFee, setAdvertFee] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdvertDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdvertDetails({ name, value }));
  };

  const handleSelect = (value: any, name: string) => {
    dispatch(getAdvertDetails({ name, value: value?.value }));
  };

  const handlePublish = (e: any) => {
    e.preventDefault();
    if (description && imageUrl && startDate && endDate && advertType) {
      if (
        new Date(startDate).getTime() < Date.now() ||
        new Date(endDate).getTime() < Date.now()
      ) {
        toast.error("Back-dating is not allowed");
      } else if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
        toast.error("End Date cannot be earlier tan start date");
      } else if (advertFee && adminChurch) {
        createAdvert(advertFee, adminChurch?.email);
      } else {
        toast.error("cant get the fee for this advert, try again later");
      }
    } else {
      toast.error("cant get the fee for this advert, try again later");
    }
  };

  const handleCompletePublish = (e: any) => {
    e.preventDefault();
    if (
      description &&
      imageUrl &&
      startDate &&
      endDate &&
      advertType &&
      paymentRef
    ) {
      completeAdsPayment(
        imageUrl,
        description,
        startDate,
        endDate,
        advertType,
        paymentRef
      );
    }
  };

  const handleImageSelect = (e: any) => {
    const { files } = e.target;

    if (files.length > 0) {
      const file = files[0];
      const fileSize = file.size / 1024 / 1024; // Convert from bytes to MB

      if (fileSize > 5) {
        toast.error("File size must be less than 5MB");
      } else {
        // Proceed with the dispatch if file size is valid
        dispatch(getAdvertDetails({ name: "imageUrl", value: file }));
      }
    }
  };

  useEffect(() => {
    const getAdvertFee = async () => {
      const { data }: any = await getAdsSettings();
      setAdvertFee(data);
    };
    getAdminChurch();
    getAdvertFee();
  }, []);

  useEffect(() => {
    const getAdvertFee = async () => {
      const { data }: any = await getAdsSettings();
      setAdvertFee(data);
    };
    getAdminChurch();
    getAdvertFee();
  }, []);

  return (
    <CustomModal
      id={31}
      content={
        <FormContainer>
          <form>
            <label>Advert Type</label>
            <Select
              placeholder="Select the advert type"
              options={advertTypes}
              name="advertType"
              onChange={(e: any) => {
                dispatch(getAdvertDetails({ name: "imageUrl", value: "" }));
                handleSelect(e, "advertType");
              }}
              styles={selectStyles}
            />
            <label>Poster Url:</label>
            <FileUpload
              text="Upload Image"
              accepts={
                advertType === "image"
                  ? ".jpg, .jpeg, .png, .webp, .svg, .jfif"
                  : ".mp4, .avi, .mov, .wmv, .flv, .mkv, .webm, .mpeg, .mpg, .3gp, .m4v, .m2ts, .vob"
              }
              handleChange={handleImageSelect}
              imgUrl={imageUrl}
              disabled={!advertType}
            />
            <label>Poster Description</label>
            <textarea
              className="detailinput"
              placeholder="Enter details for this post"
              value={description}
              onChange={handleTextAreaChange}
              name="description"
            />
            <AdvertDuration>
              <p className="durationcaption">Set Advert duration</p>
            </AdvertDuration>
            <Duration>
              <label>Start Date:</label>
              <input
                className="titleinput"
                placeholder="Enter The posting date"
                value={startDate}
                onChange={handleChange}
                name="startDate"
                type="date"
              />
              <label>End Date:</label>
              <input
                className="titleinput"
                placeholder="Enter poster expiry date"
                value={endDate}
                onChange={handleChange}
                name="endDate"
                type="date"
              />
            </Duration>
            <PubButtonWrap>
              <PubButtonContainer>
                <PrimaryButton
                  text="Publish"
                  outlined
                  size="small"
                  handleClick={handlePublish}
                  loading={creatingChurchAdvert}
                />
                <PrimaryButton
                  text="Complete"
                  size="small"
                  handleClick={handleCompletePublish}
                  loading={creatingChurchAdvert}
                />
              </PubButtonContainer>
            </PubButtonWrap>
          </form>
        </FormContainer>
      }
    />
  );
};

export default PostAddModal;
