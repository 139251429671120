import { FC } from "react";
import styled from "styled-components";
import { media } from "../Screens";
import PrimaryButton from "../Components/Form/Buttons/PrimaryButton";
import { getDonationFormDetails } from "../ReduxStore/features/User/DonationSlice";
import { handleModal } from "../ReduxStore/features/navSlice";
import { useAppDispatch } from "../ReduxStore/app/hooks";

const GivingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 10px;

  ${media.phone} {
    width: 100%;
  }
`;

const CardImageContainer = styled.div`
  display: flex;
  width: 100%;

  img {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #151c4d;
  margin-top: 10px;
`;

const QuoteContainer = styled.div`
  font-size: 15px;
  color: #151c4d;
  margin-top: 10px;
`;

const GivingIconContainer = styled.div`
  display: flex;
  position: absolute;
  background: #b603c9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-left: 290px;
`;

// const BankDetailsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 5px;
// `;

// const DetailsContainer = styled.div`
//   display: flex;
//   align-items: center;
//   // justify-content: space-around;
//   gap: 15px;
//   font-size: 12px;
// `;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IGivingCard {
  cardImage: any;
  title: string;
  quote: string;
  givingIcon: any;
  bankDetails?: any;
  currencyDetails?: any;
  target?: any;
  amountInPlace?: any;
}

const GivingCard: FC<IGivingCard> = ({
  cardImage,
  title,
  quote,
  givingIcon,
  bankDetails,
  currencyDetails,
  target,
  amountInPlace,
}) => {
  const dispatch = useAppDispatch();

  return (
    <GivingCardContainer>
      <CardImageContainer>{cardImage}</CardImageContainer>
      <TitleContainer>{title}</TitleContainer>
      <AmountContainer>
        <h4 style={{ fontWeight: "400" }}>
          Target: {currencyDetails?.symbol} {target}
        </h4>
        <h4 style={{ fontWeight: "400" }}>
          Collected: {currencyDetails?.symbol} {amountInPlace}
        </h4>
      </AmountContainer>
      <QuoteContainer>{quote}</QuoteContainer>
      <GivingIconContainer>{givingIcon}</GivingIconContainer>
      {/* <BankDetailsContainer>
        <h4>Bank Details</h4>
        <DetailsContainer>
          <span>Bank:</span>
          <span>{bankDetails?.bankName}</span>
        </DetailsContainer>
        <DetailsContainer>
          <span>Acc Name:</span>
          <span>{bankDetails?.accountName}</span>
        </DetailsContainer>
        <DetailsContainer>
          <span>Acc No.:</span>
          <span>{bankDetails?.accountNumber}</span>
        </DetailsContainer>
      </BankDetailsContainer> */}
      <PrimaryButton
        text="Pay"
        size="full"
        handleClick={(e: any) => {
          e.preventDefault();
          dispatch(handleModal(1));
          dispatch(
            getDonationFormDetails({ name: "reason", value: `${title}` })
          );
          dispatch(
            getDonationFormDetails({ name: "typeOfPayment", value: "donation" })
          );
        }}
      />
    </GivingCardContainer>
  );
};

export default GivingCard;
