import moment from "moment";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { Avatar } from "../../Components/Nav/styles";
import { Checkbox, Image } from "./styles";
import { store } from "../../ReduxStore/app/store";
import { handleModal } from "../../ReduxStore/features/navSlice";
import { deleteSubscription } from "../../Apis/SuperAdminApis/subscriptions";
// import Selector from "./../../Components/Form/inputs/Selector";
import {
  // selectSuperAdminSubscriptions,
  setChosenSubscription,
} from "../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import { setChosenwallet } from "../../ReduxStore/features/SuperAdmin/WalletSlice";
import { deleteCurrency } from "../../Apis/SuperAdminApis/wallet";
import { verifyChurch } from "../../Apis/SuperAdminApis/dashboard";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { theme } from "../../theme";
import Loader from "../../Components/Loaders/MoonLoader";
import {
  deleteChurchActivity,
  deleteChurchEvent,
} from "../../Apis/AdminApis/ChurchActivities";
import { deleteChurchMember } from "../../Apis/AdminApis/ChurchManager";
import {
  setSelectedActivity,
  setSelectedEvent,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";

export const churchAdminsTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          name: (
            <span>
              {item?.userId?.lastName} {item?.userId?.firstName}
            </span>
          ),
          email: <span>{item?.userId?.email}</span>,
          phone: <span>{item?.userId?.phoneNo}</span>,
          church: <span>{item?.churchId?.name}</span>,
        };
      })
    : [];
  return rows;
};
export const financialReportTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          // check: <Checkbox type="checkbox" />,
          // avatar: <Avatar>HE</Avatar>,
          typeSymbol: (
            <span>
              {item?.transactionType === "credit" && (
                <img src="/assets/images/svg/credit.svg" alt="credit" />
              )}
              {item?.transactionType === "debit" && (
                <img src="/assets/images/svg/debit.svg" alt="credit" />
              )}
            </span>
          ),
          name: "Hillary",
          type: (
            <span
              style={{
                color: item?.transactionType === "credit" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {item?.transactionType}
            </span>
          ),
          date: moment(item?.invoiceId?.updatedAt).format("Do MMM YYYY"),
          amount: <span>₦ {item?.amount}</span>,
          status: <span>{item?.invoiceId?.status}</span>,
          purpose: <span>{item?.invoiceId?.purpose}</span>,
          reference: <span>{item?.invoiceId?.reference}</span>,
        };
      })
    : [];

  return rows;
};

export const churchMembersTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          name: (
            <span>
              {item?.userId?.lastName} {item?.userId?.firstName}
            </span>
          ),
          email: <span>{item?.userId?.email}</span>,
          phone: <span>{item?.userId?.phoneNo}</span>,
          church: <span>{item?.churchId?.name}</span>,
        };
      })
    : [];
  return rows;
};

export const churchActivitiesTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          title: <span>{item?.title}</span>,
          message: <span>{item?.message}</span>,
          location: <span>{item?.location?.address}</span>,
          liveStream: (
            <span className={`${item?.liveStream ? "text-green" : "text-red"}`}>
              {item?.liveStream ? "Yes" : "No"}
            </span>
          ),
          allDay: (
            <span className={`${item?.allDay ? "text-green" : "text-red"}`}>
              {item?.allDay ? "Yes" : "No"}
            </span>
          ),
          repeat: (
            <span className={`${item?.repeat ? "text-green" : "text-red"}`}>
              {item?.repeat ? "Yes" : "No"}
            </span>
          ),
          inviteType: <span>{item?.inviteType}</span>,
          startDate: (
            <span>{moment(item?.startDate)?.format("MMM Do YYYY")}</span>
          ),
          endDate: <span>{moment(item?.endDate)?.format("MMM Do YYYY")}</span>,
          banner: item?.bannerUrl ? (
            <Image src={item?.bannerUrl} alt="banner" />
          ) : (
            `N/A`
          ),
          delete: store.getState().adminActivities.deletingActivities ? (
            <Loader color={theme?.colors?.primaryColor} size={10} />
          ) : (
            <RiDeleteBin6Line
              color={theme?.colors?.primaryColor}
              onClick={() => {
                deleteChurchActivity(item?._id);
              }}
              size={20}
              style={{ cursor: `pointer` }}
            />
          ),
          edit: store.getState().adminActivities.deletingActivities ? (
            <Loader color={theme?.colors?.primaryColor} size={10} />
          ) : (
            <RiEditLine
              color={theme?.colors?.primaryColor}
              onClick={() => {
                store.dispatch(handleModal(28));
                store.dispatch(setSelectedActivity(item));
              }}
              size={20}
              style={{ cursor: `pointer` }}
            />
          ),
        };
      })
    : [];
  return rows;
};

export const churchEventsTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          title: <span>{item?.title}</span>,
          message: <span>{item?.message}</span>,
          location: <span>{item?.location?.address}</span>,
          liveStream: (
            <span className={`${item?.liveStream ? "text-green" : "text-red"}`}>
              {item?.liveStream ? "Yes" : "No"}
            </span>
          ),
          allDay: (
            <span className={`${item?.allDay ? "text-green" : "text-red"}`}>
              {item?.allDay ? "Yes" : "No"}
            </span>
          ),
          repeat: (
            <span className={`${item?.repeat ? "text-green" : "text-red"}`}>
              {item?.repeat ? "Yes" : "No"}
            </span>
          ),
          inviteType: <span>{item?.inviteType}</span>,
          banner: item?.bannerUrl ? (
            <Image src={item?.bannerUrl} alt="banner" />
          ) : (
            `N/A`
          ),
          startDate: (
            <span>{moment(item?.startDate)?.format("MMM Do YYYY")}</span>
          ),
          endDate: <span>{moment(item?.endDate)?.format("MMM Do YYYY")}</span>,
          delete: store.getState().adminActivities.deletingEvents ? (
            <Loader color={theme?.colors?.primaryColor} size={10} />
          ) : (
            <RiDeleteBin6Line
              color={theme?.colors?.primaryColor}
              onClick={() => deleteChurchEvent(item?._id)}
              size={20}
              style={{ cursor: `pointer` }}
            />
          ),
          edit: store.getState().adminActivities.deletingEvents ? (
            <Loader color={theme?.colors?.primaryColor} size={10} />
          ) : (
            <RiEditLine
              color={theme?.colors?.primaryColor}
              onClick={() => {
                store.dispatch(handleModal(27));
                store.dispatch(setSelectedEvent(item));
              }}
              size={20}
              style={{ cursor: `pointer` }}
            />
          ),
        };
      })
    : [];
  return rows;
};

export const membershipReportTableRow = (data: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          name: (
            <span>
              {item?.userId?.lastName} <span>{item?.userId?.firstName}</span>
            </span>
          ),
          email: <span>{item?.userId?.email}</span>,
          phone: <span>{item?.userId?.phoneNo}</span>,
          status: (
            <span
              className={`${
                item?.status === "active" ? "text-green" : "text-red"
              }`}
            >
              {item?.status}
            </span>
          ),
          date: moment(item?.createdAt).format("MMM Do YYYY"),
          group: "choir",
          delete: (
            <PrimaryButton
              text="Delete"
              size="very-small"
              handleClick={() => deleteChurchMember(item?._id)}
              loading={
                store.getState().adminManageChurches.deletingChurchMember
              }
            />
          ),
        };
      })
    : [];

  return rows;
};

export const userTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          name: (
            <span>
              {item?.lastName} {item?.firstName}
            </span>
          ),
          email: <span>{item?.email}</span>,
          phone: <span>{item?.phoneNo}</span>,
          church: <span>{item?.name}</span>,
          isVerified: (
            <span className={`${item?.isVerified ? "text-green" : "text-red"}`}>
              {item?.isVerified ? "Verified" : "Unverified"}
            </span>
          ),
          gender: <span>{item?.gender}</span>,
          status: (
            <span
              className={`${
                item?.status === "active" ? "text-green" : "text-red"
              }`}
            >
              {item?.status}
            </span>
          ),
          userType: <span>{item?.userType}</span>,
        };
      })
    : [];

  return rows;
};

export const SubscriptionsTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          // check: <Checkbox type="checkbox" />,
          // avatar: <Avatar>HE</Avatar>,
          email: <span>{item?.lastUpdatedBy?.email}</span>,
          amount: <span>{item?.amount}</span>,
          desc: <span>{item?.description}</span>,
          duration: moment().add(item?.durationInDays, "days").calendar(),
          title: <span>{item?.title}</span>,
          features: (
            <ul>
              {item?.features?.map((e: any) => (
                <li>{e}</li>
              ))}
            </ul>
          ),
          date: moment(item?.createdAt).format("MMM Do YYYY"),
          edit: (
            <FiEdit
              onClick={(e: any) => {
                store.dispatch(setChosenSubscription(item));
                store.dispatch(handleModal(5));
              }}
              color={theme?.colors?.primaryColor}
              size={20}
            />
          ),
          delete: (
            <RiDeleteBin6Line
              onClick={() => deleteSubscription(item?._id)}
              color={theme?.colors?.primaryColor}
              size={20}
            />
          ),
          // expiration: moment().format("DD-MM-YYYY"),
        };
      })
    : [];

  return rows;
};
export const PaymentHistoryTableRow = (data: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          donor: "Hillary",
          amount: <span>{item?.amount}</span>,
          desc: <span>church donation</span>,
          date: moment(item?.createdAt).format("MMM Do YYYY"),
          edit: (
            <PrimaryButton
              text="Verify"
              size="very-small"
              // handleClick={() => store.dispatch(handleModal(5))}
            />
          ),
          delete: <PrimaryButton text="Complete" size="very-small" />,
          // expiration: moment().format("DD-MM-YYYY"),
        };
      })
    : [];

  return rows;
};
export const DonationsTableRow = (data: any) => {
  const rows = data
    ? data?.map((item: any) => {
        return {
          check: <Checkbox type="checkbox" />,
          avatar: <Avatar>HE</Avatar>,
          name: "Hillary",
          church: "The failthfuls",
          amount: <span>{item?.amount}</span>,
          date: moment(item?.createdAt).format("MMM Do YYYY"),
          duration: "2 Months",
          expiration: moment().format("DD-MM-YYYY"),
        };
      })
    : [];

  return rows;
};

export const verifiedChurshesTableRow = (data?: any) => {
  const rows = data
    ? data.map((item: any) => {
        return {
          // check: <Checkbox type="checkbox" />,
          // avatar: <Avatar>HE</Avatar>,
          name: <span>{item?.name}</span>,
          email: <span>{item?.email}</span>,
          phone: <span>{item?.churchPhoneNo}</span>,
          city: <span>{item?.city}</span>,
          state: <span>{item?.state}</span>,
          country: <span>{item?.country}</span>,
          registered: moment(item?.createdAt).format("MMM Do YYYY"),
          address: <span>{item?.address}</span>,
          landMark: <span>{item?.landMark}</span>,
          superAdmin: <span>{item?.superAdmin}</span>,
          headClergy: <span>{item?.HeadClergy?.length}</span>,
          adminCount: <span>{item?.NoOfAdmin}</span>,
          membersCount: <span>{item?.NoOfMembers}</span>,
        };
      })
    : [];

  return rows;
};

export const unVerifiedChurshesTableRow = (data?: any) => {
  const rows = data
    ? data.map((item: any) => {
        return {
          // check: <Checkbox type="checkbox" />,
          // avatar: <Avatar>HE</Avatar>,
          name: <span>{item?.name}</span>,
          email: <span>{item?.email}</span>,
          phone: <span>{item?.churchPhoneNo}</span>,
          city: <span>{item?.city}</span>,
          state: <span>{item?.state}</span>,
          country: <span>{item?.country}</span>,
          registered: moment(item?.createdAt).format("MMM Do YYYY"),
          address: <span>{item?.address}</span>,
          landMark: <span>{item?.landMark}</span>,
          superAdmin: <span>{item?.superAdmin}</span>,
          headClergy: <span>{item?.HeadClergy?.length}</span>,
          adminCount: <span>{item?.NoOfAdmin}</span>,
          membersCount: <span>{item?.NoOfMembers}</span>,
          verify: (
            <PrimaryButton
              text="verify"
              size="very-small"
              handleClick={(e: any) => {
                e.preventDefault();
                verifyChurch(item?._id);
              }}
            />
          ),
        };
      })
    : [];
  return rows;
};
export const transactionsTableRow = () => {
  const rows = [
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      ref: "1234ghg234589",
      channel: "offering",
      info: "NGN35000frombismark@gmail.com",
      date: moment().format("DD-MM-YYYY"),
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      ref: "1234ghg234589",
      channel: "offering",
      info: "NGN35000frombismark@gmail.com",
      date: moment().format("DD-MM-YYYY"),
    },
  ];

  return rows;
};
export const WalletTableRow = (data?: any) => {
  const rows = data
    ? data?.map((item: any) => {
        const deletingCurrency =
          store.getState().superAdminWallet?.deletingWallet;
        return {
          currencyId: <span>{item?._id}</span>,
          symbol: <span>{item?.symbol}</span>,
          name: <span>{item?.name}</span>,
          image: (
            <img
              src={item?.iconUrl}
              alt="currency img"
              style={{ width: "30px" }}
            />
          ),
          edit: (
            <PrimaryButton
              text="Edit"
              size="very-small"
              handleClick={() => {
                store.dispatch(handleModal(3));
                store.dispatch(
                  setChosenwallet({
                    symbol: item?.symbol,
                    name: item?.name,
                    iconUrl: item?.iconUrl,
                    id: item?._id,
                  })
                );
              }}
            />
          ),
          delete: (
            <PrimaryButton
              text="Delete"
              size="very-small"
              handleClick={() => deleteCurrency(item?._id)}
              loading={deletingCurrency}
            />
          ),
        };
      })
    : [];

  return rows;
};
export const addUserTableRow = () => {
  const rows = [
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      Name: "Bismark Akodo",
      email: "achodobismark@gmail.com",
      phone: "+234897246882",
      role: "editors",
    },
    {
      check: <Checkbox type="checkbox" />,
      avatar: <Avatar>HE</Avatar>,
      Name: "Bismark Akodo",
      email: "achodobismark@gmail.com",
      phone: "+234897246882",
      role: "editor",
    },
  ];

  return rows;
};
