import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getSermonDetails,
  selectAdminChurchSermon,
} from "../../ReduxStore/features/Admin/SermonSlice";
import { createAudioVideoSermon } from "../../Apis/AdminApis/Media";
import FileUpload from "../../Components/Form/inputs/FileUpload";
import { useState } from "react";
import styled from "styled-components";

const VideoAudioContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  max-width: 100%;
  video {
    width: 300px;
  }
  audio {
    width: 300px;
  }
`;

const CreateVideoAudioSermon = () => {
  const dispatch = useAppDispatch();
  const { videoTitle, creatingChurchSermon, errorMessage } = useAppSelector(
    selectAdminChurchSermon
  );
  const [rawFile, setRawFile] = useState(null);
  const [fileSource, setFileSource] = useState(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSermonDetails({ name, value }));
  };

  const handleImgSelection = (e: any) => {
    e.preventDefault();
    const file = e?.target?.files[0];
    if (file) {
      setRawFile(file);
      const reader: any = new FileReader();
      reader.onload = (e: any) => {
        const src = e.target.result;
        setFileSource(src);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (videoTitle && rawFile) {
      dispatch(
        getSermonDetails({
          name: "errorMessage",
          value: "",
        })
      );
      createAudioVideoSermon(videoTitle, "video", rawFile).then(() => {
        setRawFile(null);
        setFileSource(null);
      });
    } else {
      dispatch(
        getSermonDetails({
          name: "errorMessage",
          value: "Enter Title and Sermon",
        })
      );
    }
  };

  console.log(fileSource)

  return (
    <CustomModal
      id={30}
      closeFunctionAdded={() => {
        setRawFile(null);
        setFileSource(null);
      }}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <FileUpload
            text={"MP3, MP4, "}
            handleChange={handleImgSelection}
            accepts=".mp3, .wav, .aac, .flac, .alac, .ogg, .wma, .aiff, .dsd, .pcm, .mp4, .mov, .avi, .wmv, .flv, .mkv, .webm, .mpeg, .3gp, .m4v"
          />
          {fileSource && (
            <VideoAudioContainer>
              <video
                src={fileSource}
                controls
                typeof="video/mp4"
                autoPlay
              ></video>
            </VideoAudioContainer>
          )}
          <TextInput
            placeholder="Add Title"
            handleChange={handleChange}
            name="videoTitle"
          />
          <PrimaryButton
            text="Publish"
            handleClick={handleSubmit}
            size="medium"
            loading={creatingChurchSermon}
          />
          <span className="text-red">{errorMessage}</span>
        </FormWrapper>
      }
    />
  );
};

export default CreateVideoAudioSermon;
