import React from "react";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import TabSwitch from "./../../../../Components/Nav/TabSwitch";
import { AdminReportTabs } from "./../../../../Utils/tabsNav";
import FinancialReport from "./FinancialReport";
import MembershipReport from "./MembershipReport";

const Report = () => {
  const { currentTab } = useAppSelector(selectNav);

  return (
    <>
      <TabSwitch listItems={AdminReportTabs} />
      {currentTab === 1 ? <FinancialReport /> : <MembershipReport />}
    </>
  );
};

export default Report;
