import React from "react";
import TransactionLogs from "../../../../Components/Tables/SuperAdmin/TransactionLogs";

const index = () => {
  return (
    <>
      <TransactionLogs />
    </>
  );
};

export default index;
