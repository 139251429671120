import { useEffect } from "react";
import { Container, WelcomeText, CardContainer } from "./styles";
import ColoredCard from "../../../../Layouts/ColoredCard";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectSuperAdminOverview } from "../../../../ReduxStore/features/SuperAdmin/OverviewSlice";
import { getChurches } from "../../../../Apis/SuperAdminApis/dashboard";
const Dashboard = () => {
  const { gettingChurches, churches, verifiedChurches, unVerifiedChurches } =
    useAppSelector(selectSuperAdminOverview);

  useEffect(() => {
    getChurches();
  }, []);

  return (
    <Container>
      <WelcomeText>Welcome to Global Admin Dashboard</WelcomeText>
      <CardContainer>
        <ColoredCard
          icon={
            <img
              src="/assets/images/png/verified.png"
              alt="verified churches"
            />
          }
          bigText={verifiedChurches?.length || 0}
          desc="Verified Churches"
          bg="#B603C9"
          loading={gettingChurches}
        />
        <ColoredCard
          icon={<img src="assets/images/png/members.png" alt="members" />}
          bigText={churches?.length || 0}
          desc="Registered Churches"
          bg="#F6B958"
          loading={gettingChurches}
        />
        <ColoredCard
          icon={
            <img
              src="assets/images/png/unverified.png"
              alt="unverified churches"
            />
          }
          bigText={unVerifiedChurches?.length || 0}
          desc="Unverified Churches"
          bg="#151C4D"
          loading={gettingChurches}
        />
      </CardContainer>
    </Container>
  );
};

export default Dashboard;
