import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import { TextArea } from "../../Components/Form/inputs/styles";
import { editGroupInputs } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import {
  getGroupDetails,
  selectAdminGroups,
} from "../../ReduxStore/features/Admin/GroupSlice";
import { updateGroup } from "../../Apis/AdminApis/churchGroup";

const EditGroupModal = () => {
  const dispatch = useAppDispatch();
  const { name, description, editingGroup, groupDetails } =
    useAppSelector(selectAdminGroups);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  return (
    <CustomModal
      id={15}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editGroupInputs?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  defaultValue={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              updateGroup(
                groupDetails?.churchGroupDetails?._id,
                description,
                name,
              );
            }}
            loading={editingGroup}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditGroupModal;
