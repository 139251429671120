import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface TransactionsState {
  gettingTransactions: boolean;
  Transactions: any;
}

const initialState: TransactionsState = {
  gettingTransactions: false,
  Transactions: null,
};

export const TransactionsSlice = createSlice({
  name: "Transactions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingTransactions: (state, action) => {
      state.gettingTransactions = action.payload;
    },
    setTransactions: (state, action) => {
      state.Transactions = action.payload;
    },
  },
});

export const { setGettingTransactions, setTransactions } =
  TransactionsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminTransactions = (state: RootState) =>
  state.superAdminTransactions;

export default TransactionsSlice.reducer;
