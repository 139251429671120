import { useState } from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import {
  CreatePostIcon,
  HomeContainer,
  PostType,
  PostTypeContainer,
  PostTypeWrapper,
} from "./styles";
// import OtherContents from "./Others";
import { BsPlusCircleFill } from "react-icons/bs";
import CreateUserPostsModal from "../../../../Modals/user/CreatePostModal";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import EditUserPostModal from "../../../../Modals/user/EditPostModal";
import SharePostModal from "../../../../Modals/user/SharePostModal";
import TrendingPosts from "./CenterContent/TrendingPosts";
import AllPosts from "./CenterContent/AllPosts";
import MyPosts from "./CenterContent/MyPosts";
import MyChurchPosts from "./CenterContent/MyChurchPosts";
import {
  clearPosts,
  getPostFormDetails,
} from "../../../../ReduxStore/features/User/UserPostSlice";

const Home = () => {
  const [currentPost, setCurrentPost] = useState(1);
  const dispatch = useAppDispatch();

  return (
    <>
      <PostTypeContainer>
        <PostTypeWrapper>
          <PostType
            className={`${currentPost === 1 && "active"}`}
            onClick={() => {
              setCurrentPost(1);
              dispatch(getPostFormDetails({ name: "page", value: 1 }));
              dispatch(clearPosts());
            }}
          >
            All
          </PostType>
          <PostType
            className={`${currentPost === 2 && "active"}`}
            onClick={() => {
              setCurrentPost(2);
              dispatch(getPostFormDetails({ name: "page", value: 1 }));
              dispatch(clearPosts());
            }}
          >
            Trending
          </PostType>
          <PostType
            className={`${currentPost === 3 && "active"}`}
            onClick={() => {
              setCurrentPost(3);
              dispatch(getPostFormDetails({ name: "page", value: 1 }));
              dispatch(clearPosts());
            }}
          >
            My Posts
          </PostType>
          <PostType
            className={`${currentPost === 4 && "active"}`}
            onClick={() => {
              setCurrentPost(4);
              dispatch(getPostFormDetails({ name: "page", value: 1 }));
              dispatch(clearPosts());
            }}
          >
            My Church Posts
          </PostType>
        </PostTypeWrapper>
      </PostTypeContainer>
      <HomeContainer>
        <LeftContent />
        {currentPost === 1 && <AllPosts />}
        {currentPost === 2 && <TrendingPosts />}
        {currentPost === 3 && <MyPosts />}
        {currentPost === 4 && <MyChurchPosts />}
        <RightContent />
      </HomeContainer>
      {/* <OtherContents /> */}
      <CreatePostIcon onClick={() => dispatch(handleModal(4))}>
        <BsPlusCircleFill />
      </CreatePostIcon>

      {/* modal */}
      <CreateUserPostsModal />
      <EditUserPostModal />
      <SharePostModal />
    </>
  );
};

export default Home;
