import { useNavigate } from "react-router-dom";
import { signupAsAdmin } from "../../../Apis/Auth/signupApis";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import FilePicker from "../../../Components/Form/inputs/FilePicker";
import TextInput from "../../../Components/Form/inputs/TextInput";
import FormWrapper from "../../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import { getFormDetails } from "../../../ReduxStore/features/formSlice";
import { AboutChurchInputs } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import { AuthLogo, AuthTitle, AuthLink, AuthSmallText, AuthSubTitle } from "../style";
const AboutChurch = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { churchMotto, clergyName, clergyRole, clergyPhoneNo, signingupAdmin } = useAppSelector((state) => state.form);
  const {
    adminEmail,
    adminGender,
    adminLastName,
    adminPassword,
    adminPhone,
    denomination,
    churchAddress,
    city,
    state,
    country,
    landmark,
    churchName,
    churchPhoneNo,
  } = JSON.parse(localStorage.getItem("adminSignUpDetails") || "{}");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = () => {
    signupAsAdmin(
      churchName,
      adminLastName,
      adminEmail,
      adminPhone,
      adminPassword,
      adminGender,
      denomination,
      churchPhoneNo,
      churchAddress,
      landmark,
      city,
      state,
      country,
      clergyName,
      clergyRole,
      clergyPhoneNo,
      churchMotto,
      null,
      dispatch,
      navigate
    );
  };

  return (
    <AuthMain>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Sign Up</AuthTitle>
      <AuthSubTitle>Tell us about your church</AuthSubTitle>
      <FormWrapper>
        {AboutChurchInputs?.map((input: any) => (
          <>
            {input?.type === "button" ? (
              <PrimaryButton text={input?.text} />
            ) : input?.type === "file" ? (
              <FilePicker text={input?.text} />
            ) : (
              <TextInput
                placeholder={input?.placeholder}
                // icon={input?.ActiveIcon}
                handleChange={handleChange}
                name={input?.name}
              />
            )}
          </>
        ))}{" "}
        <PrimaryButton text="Send" handleClick={handleSubmit} loading={signingupAdmin} />
        <AuthSmallText>
          <AuthLink to="#">Skip and Proceed</AuthLink>
        </AuthSmallText>
        <AuthSmallText>
          Already have an account? <AuthLink to="/"> Login here</AuthLink>
        </AuthSmallText>
      </FormWrapper>
    </AuthMain>
  );
};

export default AboutChurch;
