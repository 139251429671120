import { ManageUsersActionContainer } from "./style";
import PrimaryButton from "./../../../../Components/Form/Buttons/PrimaryButton";
import ManageUserTable from "../../../../Components/Tables/Admin/ManageUserTable";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import CreateUserModal from "../../../../Modals/Admin/CreateUserModal";

const ManageUsers = () => {
  const dispatch = useAppDispatch();

  return (
    <div>
      <ManageUsersActionContainer>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <PrimaryButton
            text="Add Admin"
            size="medium"
            handleClick={(e: any) => {
              e.preventDefault();
              dispatch(handleModal(7));
            }}
          />
        </div>
      </ManageUsersActionContainer>
      <CreateUserModal />
      <ManageUserTable />
    </div>
  );
};

export default ManageUsers;
