import React from "react";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import { postsTabs } from "../../../../Utils/tabsNav";
import TabSwitch from "../../../../Components/Nav/TabSwitch";
import AllPosts from "./AllPosts";
import PendingPosts from "./PendingPosts";
import DeclinedPosts from "./DeclinedPosts";

const Posts = () => {
  const { currentTab } = useAppSelector(selectNav);
  return (
    <>
      <TabSwitch listItems={postsTabs} />
      {currentTab === 1 ? (
        <AllPosts />
      ) : currentTab === 2 ? (
        <PendingPosts />
      ) : (
        <DeclinedPosts />
      )}
    </>
  );
};

export default Posts;
