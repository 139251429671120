import React, { useEffect } from "react";
import { ActionSection, CardsContainer, SectionTitle } from "./style";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import DonationCard from "../../../../Layouts/DonationsCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  clearDonation,
  selectAdminDonations,
} from "../../../../ReduxStore/features/Admin/DonationsSlice";
import { getDonation } from "../../../../Apis/AdminApis/donations";
import CreateDonationModal from "../../../../Modals/Admin/CreateDonationModal";
import EditDonationModal from "../../../../Modals/Admin/EditDonationModal";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";

const Donations = () => {
  const {
    churchDonations,
    gettingChurchDonations,
    creatingChurchDonation,
    editingDonation,
  } = useAppSelector(selectAdminDonations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getDonation();
  }, [creatingChurchDonation, editingDonation]);

  return (
    <>
      <SectionTitle>Donations</SectionTitle>
      <ActionSection>
        <div className="button_area">
          <span>.</span>
          <PrimaryButton
            text="Setup Donation"
            size="medium"
            handleClick={(e: any) => {
              e.preventDefault();
              dispatch(handleModal(13));
              dispatch(clearDonation());
            }}
          />
        </div>
        {/* <TextInput placeholder={"Search"} leftIcon={<RiSearchLine />} /> */}
      </ActionSection>
      <CardsContainer>
        {gettingChurchDonations ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color={theme?.colors.primaryColor} size={20} />
          </div>
        ) : (
          <>
            {churchDonations?.itemsList?.length > 0
              ? churchDonations?.itemsList?.map((donation: any) => (
                  <DonationCard donationDetails={donation} />
                ))
              : null}
          </>
        )}
      </CardsContainer>
      <CreateDonationModal />
      <EditDonationModal />
    </>
  );
};

export default Donations;
