import api from "../baseUrl";
import { toast } from "react-toastify";
import {
  setSending,
  setSigningUpAdmin,
} from "../../ReduxStore/features/formSlice";
import { store } from "../../ReduxStore/app/store";
import {
  setCreatingChurch,
  setFindingChurch,
  setFoundChurches,
  setVerifyingEmail,
} from "../../ReduxStore/features/authSlice";
import { getUserActiveChurch } from "../UserApis/userProfile";
import { login, loginCreateChurch } from "./loginApis";
import cryptojs from "crypto-js";
const secretPass = "XkhZG4fW2t2W";

export const signupAsMember = async (
  firstName: string,
  lastName: string,
  email: string,
  phoneNo: string,
  password: string,
  gender: string,
  dispatch: any,
  navigate: any,
  type: string
) => {
  const signupDetails = {
    email,
    gateCode: cryptojs.AES.encrypt(
      JSON.stringify(password),
      secretPass
    ).toString(),
    firstName,
    lastName,
    phoneNo,
  };
  localStorage.setItem("signDeals", JSON.stringify(signupDetails));

  const body = {
    firstName,
    lastName,
    phoneNo,
    gender,
    email,
    password,
  };
  dispatch(setSending(true));
  await api
    .post(`${type}/auth/${type}-signup`, body)
    .then((res: any) => {
      toast.info(res?.data?.message);
      navigate(`/auth/signup/role/${type}/verify-otp`);
      dispatch(setSending(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      dispatch(setSending(false));
    });
};

export const signupAsAdmin = async (
  firstName: string,
  lastName: string,
  email: string,
  phoneNo: number,
  password: number,
  gender: string,
  denomination: string,
  churchPhoneNo: string,
  address: string,
  landMark: string,
  city: string,
  state: string,
  country: string,
  clergyName: string,
  role: string,
  clergyPhone: string,
  churchId: string,
  HQID?: string | null,
  dispatch?: any,
  navigate?: any
) => {
  dispatch(setSigningUpAdmin(true));
  const body = {
    firstName,
    lastName,
    phoneNo,
    gender,
    email,
    password,
    denomination,
    churchPhoneNo,
    address,
    landMark,
    city,
    state,
    country,
    HQID,
    clergyName,
    role,
    clergyPhone,
    churchId,
  };
  await api
    .post("auth/user/admin-signup", body)
    .then((res) => {
      dispatch(setSigningUpAdmin(true));
      toast.success("Successfully signed up");
      navigate("/auth/signup/role/admin/verify-church");
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      dispatch(setSigningUpAdmin(false));
    });
};

export const foundAndJoinChurch = async (
  name: string,
  denomination: string,
  state: string,
  country: string
) => {
  const body = {
    name,
    denomination,
    state,
    country,
  };
  await api
    .post("auth/user/user-church", body)
    .then((res) => {
    })
    .catch((err) => {
    });
};
export const joinChurch = async (churchId: string) => {
  store.dispatch(setFindingChurch(true));

  const body = {
    churchId,
  };
  await api
    .post("user/church/join-church", body)
    .then((res) => {
      getUserActiveChurch().then(() => {
        window.location.href = "/";
      });
      store.dispatch(setFindingChurch(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setFindingChurch(false));
    });
};

export const updateUserDOB = async (userId: string, dob: string) => {
  const body = {
    userId,
    dob,
  };
  await api
    .post("auth/user/update-user-dob", body)
    .then((res) => {
    })
    .catch((err) => {
    });
};

export const createChurch = async (
  name: string,
  denomination: string,
  email: string,
  churchPhoneNo: string,
  address: string,
  landMark: string,
  city: string,
  state: string,
  country: string,
  town: string
) => {
  store.dispatch(setCreatingChurch(true));
  const adminCred = JSON.parse(localStorage.getItem("signDeals") || "{}");
  const bytes = cryptojs.AES.decrypt(adminCred.pass, secretPass);
  const password = JSON.parse(bytes.toString(cryptojs.enc.Utf8));

  const body = {
    firstName: adminCred?.firstName,
    lastName: adminCred?.lastName,
    phoneNo: adminCred?.phoneNo,
    name,
    denomination,
    email,
    churchPhoneNo,
    address,
    landMark,
    city,
    state,
    country,
    town,
  };
  await api
    .post("admin/church", body, {
      headers: {
        Authorization: `Bearer ${adminCred.token}`,
      },
    })
    .then((res) => {
      toast.success("church created successfully, login in");
      login(adminCred?.email, password);
      localStorage.removeItem("signDeals");
      store.dispatch(setCreatingChurch(false));
      // window.location.href = "/";
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingChurch(false));
    });
};

export const verifyEmail = async (
  email: string,
  code: string,
  type: string
) => {
  const loginDetails = JSON.parse(localStorage.getItem("signDeals") || "{}");
  const bytes = cryptojs.AES.decrypt(loginDetails.gateCode, secretPass);
  const password = JSON.parse(bytes.toString(cryptojs.enc.Utf8));
  store.dispatch(setVerifyingEmail(true));
  const params = {
    email,
    code,
  };
  await api
    .get(`${type}/auth/verify-${type}-signup`, { params })
    .then((res: any) => {
      toast.success(res?.message);
      store.dispatch(setVerifyingEmail(false));
      if (type === "admin") {
        loginCreateChurch(email, password);
      } else {
        store.dispatch(setVerifyingEmail(false));
        localStorage.removeItem("signDeals");
        login(email, password);
      }
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setVerifyingEmail(false));
    });
};

export const findChurchToJoin = async (
  name?: string,
  denomination?: string,
  country?: string,
  state?: string
) => {
  store.dispatch(setFindingChurch(true));
  const params = {
    name,
    denomination,
    state,
    country,
  };
  await api
    .get("user/church/find-church", { params })
    .then((res: any) => {
      setFindingChurch(false);
      const { data } = res;
      store.dispatch(setFoundChurches(data?.data));
      store.dispatch(setFindingChurch(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setFindingChurch(false));
    });
};
