import styled from "styled-components";
import { theme } from "../../../../theme";
import { media } from "../../../../Screens";

export const AdsContainar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 946px;
  min-height: 300px;
  background: ${theme.colors.bgWhite};
  padding: 50px;

  div.selectContainer {
    display: flex;
    align-items: center;
    gap: 30px;
  }
`;
export const AdsContainerCaption = styled.div`
font-size: 21px;
  line-height: 26px;
  Line height: 100%
Align: Left
Vertical align: Top
  color: ${theme.colors.secondaryColor};
}
`;

export const TestimonyInstructionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 70px;
  //   align-items: center;
  //   border: 1px solid ${theme.colors.borderColor};
  //   justify-content: center;
  //   padding: 0 10px;
  width: 300px;
  height: 50px;
  //   background: ${theme.colors.bgWhite};
  //   gap: 10px;

  //   &:focus {
  //     border: 1px solid ${theme.colors.primaryColor};
  //   }
`;
export const TestimonyInstruction = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #151c4d;
`;

export const InputText = styled.input`  
  border:none;
  width:200%;
  color: ${theme.colors.inputTextColor};}
  font-size: 16px;  
  box-shadow: .5px .5px 0px 2px rgba(0, 0, 0, .2);
  padding: 0;
  border-radius: 5px;

  ${media.phone} {
    width:96%;
    margin-left:7px;   
  }

`;

// church verses
export const VerseoftheDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const VersesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 50px;
  grid-auto-flow: dense;
  grid-template-rows: masonry;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;

export const ReflectionTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`;
