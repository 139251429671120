import styled from "styled-components";
import { theme } from "../../../../theme";
import { media } from "./../../../../Screens";

export const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.phone} {
    overflow: hidden;
  }
`;

export const InnerHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 60%;
  margin-left: -20%;
  padding: 0 50px;
  gap: 30px;
  // position: absolute;
  margin-top: 4%;
  left: 0;
  border-bottom: 1px solid;
  ${media.phone} {
    margin-left: 30px;
  }
`;

export const InnerNavlink = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: ${theme.colors.secondaryColor};
  cursor: pointer;
  text-decoration: none;
  position: relative;
  width: auto;
  height: 100%;
  text-align: center;
  padding: 10px;

  &.active {
    color: ${theme.colors.primaryColor};
  }

  // &.active::after {
  //   content: "";
  //   background: ${theme.colors.primaryColor};
  //   width: 100%;
  //   height: 4px;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  // }

  ${media.phone} {
    font-size: 16px;
    padding-bottom: 10px;
  }
`;

export const VideoWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 20px 10px;
  ${media.phone} {
    width: 300px;
  }
`;

export const Player = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  // margin-left: 7%;
  margin-top: 20px;

  width: 100%;
  ${media.phone} {
    margin-top: -60%;
  }
`;

export const Prompt = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #b603c9;
  display: flex;
  margin-left: -24%;
`;

export const VideoGlass = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const VideoDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: 70px;
  padding: 10px;
  gap: 10px;
`;
export const VideoCaption = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    margin-top: 30px;
  }
`;
export const VideoAuthor = styled.div`
  font-style: normal;
  // font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  color: #151c4d;
  margin-bottom: 5px;
`;

export const EventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  box-shadow: 0.5px 2px 1px 0.5px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 760px;
  margin-top: -175%;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;

export const EventCaption = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -20px;
`;

export const EventIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventTopic = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const EventName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;
export const Sponsor = styled.div`
  position: absolute;
  margin-left: 640px;
  margin-top: -156%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 650px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  font-size: 10px;
  margin-top: -150%;
  ${media.phone} {
    display: none;
  }
`;

export const VideoSermonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 20px;
  /* overflow-x: auto; */
  margin-top: 50px;
  /* grid-gap: 20px; */
  height: 100%;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const VideoSermonEventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  box-shadow: 0.5px 2px 1px 0.5px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 800px;
  margin-top: -1600px;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;
export const VideoAdText = styled.div`
  position: absolute;
  margin-left: 690px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  font-size: 10px;
  margin-top: -1320px;
  ${media.phone} {
    display: none;
  }
`;
export const VideoSermonGlass = styled.div`
  margin-left: -130px;
  display: flex;
  ${media.phone} {
    margin-left: 40px;
    width: ;
  }
`;
export const VideoSponsor = styled.div`
  position: absolute;
  margin-left: 690px;
  margin-top: -1390px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
`;

export const AudioSermonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: 30px;
  grid-gap: 20px;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const WrittenSermonContainerWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 5%;
  grid-gap: 20px;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const WrittenSermonContainer = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${media.phone} {
  }
`;

export const Sermon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: #151c4d;
`;

export const Minister = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #151c4d;
  font-weight: bold;
  margin-bottom: 20px;
`;
