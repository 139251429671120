import styled from "styled-components";
import { theme } from "./../../../theme";
import { media } from "./../../../Screens";

interface buttonI {
  outlined?: boolean;
  size?: string;
  isDisabled?: boolean;
}

export const PrimaryButtonContainer = styled.button<buttonI>`
  outline: none;
  background: ${(props) =>
    props.outlined ? theme.colors.bgWhite : theme.colors.primaryColor};
  width: ${(props) =>
    props.size === "very-small"
      ? "100px"
      : props.size === "small"
      ? "139px"
      : props.size === "medium"
      ? "235px"
      : props.size === "full"
      ? "100%"
      : "340px"};
  min-height: ${(props) =>
    props.size === "very-small"
      ? "45px"
      : props.size === "small"
      ? "48px"
      : props.size === "medium"
      ? "50px"
      : "50px"};
  color: ${(props) =>
    props.outlined ? theme.colors.primaryColor : theme.colors.textWhite};
  border-radius: 5px;
  opacity: ${(props) => (props?.isDisabled ? "0.5" : "1.0")};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) =>
    props.size === "very-small"
      ? "16px"
      : props.size === "small"
      ? "18px"
      : props.size === "medium"
      ? "20px"
      : "20px"};
  cursor: pointer;
  border: ${(props) =>
    props.outlined ? `2px solid ${theme.colors.primaryColor}` : "none"};
  ${media.phone} {
    font-size: 14px;
    width: ${(props) =>
      props.size === "very-small"
        ? "50px"
        : props.size === "small"
        ? "98px"
        : props.size === "medium"
        ? "193px"
        : "300px"};
    height: ${(props) =>
      props.size === "very-small"
        ? "36px"
        : props.size === "small"
        ? "40px"
        : props.size === "medium"
        ? "42px"
        : "45px"};
  }

  &:focus {
    outline: 1px solid ${theme.colors.primaryColor};
  }
`;

interface Ibutton {
  outlined?: boolean;
  size?: string;
}

export const FaTimeButton = styled.button<Ibutton>`
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  position: absolute;
  margin-left: 450px;
  margin-top: -20px;
  background: #b603c9;
  color: white;
  border-radius: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  cursor: pointer;
  ${media.phone} {
    width: 25px;
    height: 25px;
    margin-left: 300px;
    margin-top: -400px;
  }
  &:focus {
    outline: 1px solid #343232;
  }
`;
