import styled from "styled-components";
import { media } from "../../../../../Screens";

export const RightContentContainer = styled.div`
  flex: 0.3;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 0px 1px rgb(140 140 140/0.2);
  height: 90vh;
  position: sticky;
  top: 0;
  height: 80vh;
  overflow-y: auto;

  ${media.phone} {
    display: none;
  }
`;

export const ContentHeader = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px;
`;
