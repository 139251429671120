import React from "react";
import FormWrapper from "../Layouts/FormWrapper";
import CustomModal from "./CustomModal";
import { editSubscriptionInputs } from "./../Utils/authInput";
import { TextArea, selectStyles } from "./../Components/Form/inputs/styles";
import TextInput from "./../Components/Form/inputs/TextInput";
import PrimaryButton from "./../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../ReduxStore/app/hooks";
import {
  getSubscriptionFormDetails,
  selectSuperAdminSubscriptions,
} from "../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import { useAppDispatch } from "./../ReduxStore/app/hooks";
import { updateArrayElement } from "../Components/Helpers/Generals";
import { editSubscriptionPack } from "../Apis/SuperAdminApis/subscriptions";
import Select from "react-select";

const EditSubscriptionModal = () => {
  const {
    subscriptionTitle,
    subscriptionAmount,
    subscriptionDescription,
    subscriptionFeatures,
    editingSubscription,
    currencyId,
    subscriptionPackageId,
  } = useAppSelector(selectSuperAdminSubscriptions);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSubscriptionFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSubscriptionFormDetails({ name, value }));
  };
  const handleSelect = (e: any, name: any) => {
    dispatch(getSubscriptionFormDetails({ name, value: e.value }));
  };

  const handleArrayChange = (e: any, index: number) => {
    e.preventDefault();
    const { name, value } = e.target;
    const updatedArray = updateArrayElement(subscriptionFeatures, index, value);
    dispatch(getSubscriptionFormDetails({ name, value: updatedArray }));
  };

  return (
    <CustomModal
      id={5}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editSubscriptionInputs?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  defaultValue={input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <Select
                  options={input?.options()}
                  name={input?.name}
                  onChange={(e: any) => handleSelect(e, input?.name)}
                  styles={selectStyles}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {input?.isArray && input?.canIncrement ? (
                    <>
                      {input
                        ?.defaultValue()
                        ?.map((item: any, index: number) => (
                          <TextInput
                            // placeholder={item}
                            type={input?.type}
                            name={input?.name}
                            handleChange={(e: any) =>
                              handleArrayChange(e, index)
                            }
                            defaultValue={item}
                          />
                        ))}
                      <div
                        style={{
                          display: `flex`,
                          gap: `10px`,
                          alignItems: `center`,
                        }}
                      >
                        {input?.incrementButton}
                        {input?.decrementButton}
                      </div>
                    </>
                  ) : (
                    <TextInput
                      placeholder={input.placeholder}
                      type={input?.type}
                      name={input?.name}
                      handleChange={handleChange}
                      defaultValue={input?.defaultValue()}
                    />
                  )}
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Update"
            handleClick={(e: any) => {
              e.preventDefault();
              editSubscriptionPack(
                subscriptionTitle,
                subscriptionDescription,
                subscriptionFeatures,
                subscriptionAmount,
                currencyId,
                subscriptionPackageId
              );
            }}
            loading={editingSubscription}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditSubscriptionModal;
