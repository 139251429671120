import { store } from "../../ReduxStore/app/store";
import { toast } from "react-toastify";
import api from "../baseUrl";
import {
  setChurches,
  setGettingChurches,
  setVerifyingChurches,
} from "../../ReduxStore/features/SuperAdmin/OverviewSlice";

export const getChurches = async (
  verified?: boolean,
  email?: string,
  city?: string,
  state?: string,
  country?: string,
  deleted?: boolean,
  HQID?: string,
  denomination?: string,
) => {
  store.dispatch(setGettingChurches(true));
  const params: any = {
    verified,
    email,
    city,
    state,
    country,
    deleted,
    HQID,
    denomination,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("super-admin/churches", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setChurches(data?.data));
      store.dispatch(setGettingChurches(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingChurches(false));
    });
};

export const verifyChurch = async (churchId: string) => {
  store.dispatch(setVerifyingChurches(true));
  const body = {
    churchId,
  };
  await api
    .post("super-admin/churches/verify-church", body)
    .then((res) => {
      store.dispatch(setVerifyingChurches(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setVerifyingChurches(false));
    });
};
