import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "./../theme";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: ${theme.colors.bgWhite};
  padding: 20px 0px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

interface FormWrapperI {
  children: any;
  style?: any;
}

const FormWrapper: FC<FormWrapperI> = ({ children, style }) => {
  return <Wrapper style={style}>{children}</Wrapper>;
};

export default FormWrapper;
