import { useEffect } from "react";

import {
  deleteDailyReflection,
  getUserDailyScriptures,
} from "../../../../Apis/UserApis/DailyScriptures";
import {
  getDailyScripturesFormDetails,
  selectUserDailyScriptures,
  setSelectedScripture,
} from "../../../../ReduxStore/features/User/DailyScripturesSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import moment from "moment";
import {
  BibleBook,
  Date,
  ReflectionDisplay,
  RightItems,
  Slate,
  TopItems,
  StudyVerse,
} from "../../User/DailyReflection/styles";
import ReflectionView from "../../User/DailyReflection/ReflectionView";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import {
  getBibleChapters,
  // getBibleNames,
} from "../../../../Apis/UserApis/Bible";
// import { selectBible } from "../../../../ReduxStore/features/BibleSlice";
import { ActionContainer, CardsContainer, DailySCriptures } from "./style";
import CreateDailySCripturesModal from "../../../../Modals/user/CreateDailyScriptures";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import EditDailyScripturesModal from "../../../../Modals/user/EditDailyScriptures";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const DailyVerses = () => {
  const {
    gettingDailyScriptures,
    dailyScriptures,
    creatingDailyScripture,
    editingDailyScripture,
    deletingDailyScripture,
    bibleName,
    pageId,
    pageSize,
  } = useAppSelector(selectUserDailyScriptures);
  // const { book } = useAppSelector(selectBible);

  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getDailyScripturesFormDetails({ name: "pageId", value: e.selected + 1 })
    );
  };

  // useEffect(() => {
  //   getBibleNames(1, 66);
  // }, []);

  useEffect(() => {
    getBibleChapters(bibleName);
  }, [bibleName]);

  useEffect(() => {
    getUserDailyScriptures(pageId, pageSize);
  }, [
    creatingDailyScripture,
    editingDailyScripture,
    deletingDailyScripture,
    pageId,
  ]);

  return (
    <DailySCriptures>
      <PrimaryButton
        text="Create Verse"
        size="medium"
        handleClick={() => {
          dispatch(handleModal(2));
        }}
      />
      {gettingDailyScriptures ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : dailyScriptures?.itemsList.length > 0 ? (
        <CardsContainer>
          {dailyScriptures?.itemsList.map((item: any) => (
            <Slate>
              <TopItems>
                <RightItems>
                  <StudyVerse>Study Verse</StudyVerse>
                  <BibleBook>
                    {item?.bookName?.book} {item?.chapter}:{item?.verse}
                  </BibleBook>
                </RightItems>
              </TopItems>
              <ReflectionDisplay>
                <ReflectionView text={item?.text} />
              </ReflectionDisplay>
              <Date>{moment(item?.createdAt).format("MMM Do YYYY")}</Date>
              <ActionContainer>
                <FaEdit
                  onClick={() => {
                    dispatch(handleModal(3));
                    dispatch(setSelectedScripture(item));
                  }}
                />
                <MdDelete onClick={() => deleteDailyReflection(item?._id)} />
              </ActionContainer>
            </Slate>
          ))}
        </CardsContainer>
      ) : (
        <NoRecordDisplay
          src="/assets/images/svg/working-remotly.svg"
          message="No Reflection Available"
        />
      )}
      <CreateDailySCripturesModal />
      <EditDailyScripturesModal />

      <Paginator
        currentPage={dailyScriptures?.paginator?.currentPage}
        pageCount={dailyScriptures?.paginator?.pageCount}
        itemCount={dailyScriptures?.paginator?.itemCount}
        hasNextPage={dailyScriptures?.paginator?.hasNextPage}
        hasPrevPage={dailyScriptures?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </DailySCriptures>
  );
};

export default DailyVerses;
