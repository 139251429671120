import { AnnouncementCardContainer, DailyAnnouncementContainer } from "./style";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import AdminAnnouncementCard from "../../../../Layouts/AdminAnnouncmentCard";
import CreateAnnouncementModal from "../../../../Modals/Admin/CreateAnnouncementModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import EditAnnouncementModal from "../../../../Modals/Admin/EditAnnouncementModal";
import {
  clearAnnouncementState,
  selectAdminAnnouncements,
} from "../../../../ReduxStore/features/Admin/AnnouncementSlice";
import { useEffect } from "react";
import { getUserActiveChurch } from "../../../../Apis/UserApis/userProfile";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import { getAnnouncements } from "../../../../Apis/AdminApis/churchAnnouncement";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { getGroupList } from "../../../../Apis/AdminApis/churchGroup";

const Announcement = () => {
  const {
    gettingAnnouncements,
    announcements,
    deletingAnnouncement,
    creatingAnnouncement,
    editingAnnouncement,
  } = useAppSelector(selectAdminAnnouncements);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUserActiveChurch();
    getGroupList();
  }, []);

  useEffect(() => {
    getAnnouncements(userProfile?.churchId?._id);
  }, [
    deletingAnnouncement,
    creatingAnnouncement,
    editingAnnouncement,
    userProfile?.churchId?._id,
  ]);

  return (
    <DailyAnnouncementContainer>
      <PrimaryButton
        text="Create Announcement"
        size="medium"
        handleClick={(e: any) => {
          e.preventDefault();
          dispatch(handleModal(8));
          dispatch(clearAnnouncementState());
        }}
      />
      {gettingAnnouncements ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <>
          <AnnouncementCardContainer>
            {announcements?.length > 0 &&
              announcements?.map((announce: any) => (
                <AdminAnnouncementCard details={announce} />
              ))}
          </AnnouncementCardContainer>
          {announcements?.length === 0 && (
            <NoRecordDisplay
              message="No announcements at the moment"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}
      <CreateAnnouncementModal />
      <EditAnnouncementModal />
    </DailyAnnouncementContainer>
  );
};

export default Announcement;
