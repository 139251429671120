import { useState } from "react";
import { InputSection, TextInput } from "./styles";
import { MdAttachFile } from "react-icons/md";
import { BsFillSendFill } from "react-icons/bs";
// import { FaMicrophone } from "react-icons/fa";
import { sendChatMessage } from "../../../../Apis/UserApis/userChurchGroup";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserCgroup } from "../../../../ReduxStore/features/User/UserGroupSlice";

const InputBox = () => {
  const [message, setMessage] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [imgPreview, setImagePreview] = useState("");
  const { churchGroupId } = useAppSelector(selectUserCgroup);

  const handleSendMessage = () => {
    if (message) {
      sendChatMessage(churchGroupId, message, fileUrl).then(() => {
        setMessage("");
        setFileUrl("");
        setImagePreview("");
      });
    }
  };

  const handleImgSelection = (e: any) => {
    e.preventDefault();
    const { files } = e?.target;
    const url = URL.createObjectURL(files[0]);
    setFileUrl(files[0]);
    setImagePreview(url);
  };

  const sendOnEnter = (e: any) => {
    if (e.which === 13) {
      if (message) {
        sendChatMessage(churchGroupId, message, fileUrl).then(() => {
          setMessage("");
          setFileUrl("");
          setImagePreview("");
        });
      }
    }
  };

  return (
    <InputSection>
      <label htmlFor="file">
        <MdAttachFile />
      </label>
      {imgPreview && <img src={imgPreview} alt="img" width={50} height={50} />}
      <input
        type="file"
        name="file"
        id="file"
        style={{ display: "none" }}
        accept=".jpeg, .png, .webp, .gif, .jfif"
        onChange={handleImgSelection}
      />
      <TextInput
        placeholder="Type your message here..."
        onChange={(e: any) => {
          setMessage(e.target.value);
        }}
        onKeyDown={sendOnEnter}
        value={message}
      />
      {/* <FaMicrophone /> */}
      {message && <BsFillSendFill onClick={handleSendMessage} />}
    </InputSection>
  );
};

export default InputBox;
