import styled from "styled-components";
import { media } from "./../../../../Screens";
import { theme } from "../../../../theme";

export const SowSeedWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  span.historyspan {
    width: 100px;
    margin-left: 100px;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  .caption {
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f5f9;
  width: 100%;
`;

export const CardGlass = styled.div``;

export const Date = styled.div``;

export const SowSeedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 50px;
  background: #151c4d;
  padding-left: 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  ${media.phone} {
    width: 100%;
  }

  span.caption {
    color: white;
    margin-left: -40px;
  }
`;
export const GiveOfferingWrap = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;

  ${media.phone} {
    width: 100%;
  }
`;
export const GiveOffering = styled.div`
  display: flex;
  flex-direction: column;
  width: 165px;
  background: #151c4d;
  margin-top: 10px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  span.caption {
    color: white;
    font-size: 10px;
    margin-top: 5px;
  }
`;
export const Tithe = styled.div`
  display: flex;
  flex-direction: column;
  width: 165px;
  background: #151c4d;
  margin-top: 10px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  span.caption {
    color: white;
    font-size: 10px;
    margin-top: 5px;
  }
  img.image {
    width: 15px;
  }
`;

export const GivingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Sponsor = styled.div`
  position: absolute;
  margin-left: 550px;
  margin-top: -630px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 570px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: -570px;
  ${media.phone} {
    display: none;
  }
`;

export const DonationContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  margin-left: -10%;
  margin-top: -470px;
  width: 520px;
  min-height: 300px;
  background: #fcf9f9;
  padding: 30px;
  position: absolute;
  z-index: 1011;
  ${media.phone} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-top: 30px;
    width: 340px;
  }
  .proceedbutton {
    margin-left: 120px;
    margin-top: 20px;
  }
  div.selectContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    gap: 30px;

    ${media.phone} {
    }
  }
`;

export const FaTimesWrap = styled.div`
  display: flex;
  ${media.phone} {
    display: flex;
    position: absolute;
    z-index: 1000;
    margin-top: 510px;
    margin-left: -330px;
  }
`;
export const DonationContainerCaption = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #b603c9;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  ${media.phone} {
    display: flex;
    font-weight: 400;
    font-size: 20px;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 0 10px;
  width: 300px;
  height: 50px;
`;
export const InputText = styled.input`
  border: none;
  width: 200%;
  color: ${theme.colors.inputTextColor};
  font-size: 16px;
  box-shadow: 0.5px 0.5px 0px 2px rgba(0, 0, 0, 0.2);
  padding: 0;
  border-radius: 5px;

  ${media.phone} {
    width: 96%;
    margin-left: 7px;
  }
`;

export const Proceed = styled.div``;

export const OfferringsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
