import React from "react";
import { financialReportTableCols } from "../../../Utils/SuperAdmin/tableCols";
import CustomTable from "../CustomRTable";
import { financialReportTableRow } from "../../../Utils/SuperAdmin/tableRows";
import { selectAdminWallet } from "../../../ReduxStore/features/Admin/WalletSlice";
import { useAppSelector } from "../../../ReduxStore/app/hooks";

const FinancialReportTable = () => {
  const { gettingAdminFinancialReport, adminFinancialReport } =
    useAppSelector(selectAdminWallet);
  const rows = financialReportTableRow(adminFinancialReport?.itemsList);

  const data = {
    columns: financialReportTableCols,
    rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingAdminFinancialReport}
        pageCount={adminFinancialReport?.paginator?.pageCount}
        itemCount={adminFinancialReport?.paginator?.itemCount}
        hasNextPage={adminFinancialReport?.paginator?.hasNextPage}
        hasPrevPage={adminFinancialReport?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default FinancialReportTable;
