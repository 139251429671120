export const purposeOfPayment = [
  {
    id: 1,
    name: "Donation",
    value: "donation",
  },
  {
    id: 2,
    name: "Levy",
    value: "levy",
  },
  {
    id: 3,
    name: "Tithe",
    value: "tithe",
  },
  {
    id: 4,
    name: "Offering",
    value: "offering",
  },
  {
    id: 5,
    name: "Sow Seed",
    value: "sow-seed",
  },
];
