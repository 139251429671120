import { SubScriptionsTableCols } from "../../../Utils/SuperAdmin/tableCols";
import { PaymentHistoryTableRow } from "../../../Utils/SuperAdmin/tableRows";
import CustomTable from "../CustomRTable";
import { useEffect } from "react";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
import { selectSuperAdminSubscriptions } from "../../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import { getSubscriptionsPaymentHistory } from "../../../Apis/SuperAdminApis/subscriptions";
const PaymentHistoryTable = () => {
  const { gettingPaymentHistory, paymentHistory } = useAppSelector(
    selectSuperAdminSubscriptions,
  );
  const rows = PaymentHistoryTableRow(paymentHistory?.itemsList);

  const data = {
    columns: SubScriptionsTableCols,
    rows,
  };

  useEffect(() => {
    getSubscriptionsPaymentHistory();
  }, []);

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingPaymentHistory}
        noRecord={paymentHistory?.itemsList?.length > 0 ? false : true}
        noRecordText="No Payment History Available"
        currentPage={paymentHistory?.paginator?.currentPage}
        pageCount={paymentHistory?.paginator?.pageCount}
        itemCount={paymentHistory?.paginator?.itemCount}
        hasNextPage={paymentHistory?.paginator?.hasNextPage}
        hasPrevPage={paymentHistory?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default PaymentHistoryTable;
