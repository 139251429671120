import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { purposeOfPayment } from "./data";
import {
  getDonationFormDetails,
  selectDonations,
} from "../../ReduxStore/features/User/DonationSlice";
import { paystackFunction } from "../../Apis/AdminApis/payments";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import { toast } from "react-toastify";

const PayToChurchMOdal = () => {
  const dispatch = useAppDispatch();
  const { amountToPay, typeOfPayment, reason, makingPayments } =
    useAppSelector(selectDonations);
  const { userProfile } = useAppSelector(selectUserProfile);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDonationFormDetails({ name, value }));
  };

  const handlePay = (e: any) => {
    if (amountToPay && typeOfPayment) {
      paystackFunction(amountToPay, typeOfPayment, userProfile?.userId?.email);
    } else {
      toast.error("Amount fields cannot be empty");
    }
    e.preventDefault();
  };

  return (
    <CustomModal
      id={1}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <SelectBox
            options={purposeOfPayment}
            name="typeOfPayment"
            handleSelect={handleSelect}
            value={typeOfPayment}
          />
          {typeOfPayment === "donation" && (
            <TextInput
              placeholder="Reason"
              type="text"
              name="reason"
              handleChange={handleChange}
              value={reason}
            />
          )}
          <TextInput
            placeholder="Amount"
            type="number"
            name="amountToPay"
            handleChange={handleChange}
            value={amountToPay}
          />
          <PrimaryButton
            text="Pay"
            handleClick={handlePay}
            loading={makingPayments}
          />
        </FormWrapper>
      }
    />
  );
};

export default PayToChurchMOdal;
