/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import PrimaryButton from "../Form/Buttons/PrimaryButton";
import api from "../../Apis/baseUrl";
import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import { setMakingPayments } from "../../ReduxStore/features/User/DonationSlice";
import { setMakingPayment } from "../../ReduxStore/features/paymentSlice";
import { usePaystackPayment } from "react-paystack";
import { verifyPayment } from "../../Apis/AdminApis/payments";

const onSuccess: any = (transactionDetails: any) => {
  verifyPayment(transactionDetails?.reference).then(() => {
    toast.success(
      "Your church is undergoing verification. This may take some time."
    );
  });
};
const onClose = () => {};

interface PaystackButtonProp {
  btnText: string;
  loading: Boolean;
  paymentType: string;
  amount: any;
  email: string;
  btnSize?: string;
}
const PaystackButton: FC<PaystackButtonProp> = ({
  btnText,
  loading,
  paymentType,
  amount,
  email,
  btnSize,
}) => {
  const [config, setConfig] = useState<any>({
    reference: "",
    email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  });
  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    // Check if a reference has been set before initializing the payment
    if (config.reference !== "") {
      initializePayment(onSuccess, onClose);
    }
  }, [config]);

  const generateInvoice = async () => {
    store.dispatch(setMakingPayment(true));

    const body = {
      amount,
      purposeOfPayment: paymentType,
    };
    await api
      .post("user/payment-invoices", body)
      .then(async (res) => {
        const { data } = res;

        setConfig((prevConfig: any) => ({
          ...prevConfig,
          reference: data?.data?.invoiceReference,
          amount: data?.data?.amount * 100,
        }));
        store.dispatch(setMakingPayment(false));
      })
      .catch((err: any) => {
        if (err?.response)
          toast.error(
            err?.response?.data?.errorDetails || err?.response?.data?.data
          );
        if (err?.message === "Network Error") toast.error(err?.message);
        store.dispatch(setMakingPayments(false));
        store.dispatch(setMakingPayment(false));
      });
  };

  return (
    <PrimaryButton
      text={btnText}
      handleClick={() => generateInvoice()}
      loading={loading}
      size={btnSize}
    />
  );
};

export default PaystackButton;
