/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import { useState } from "react";
import { Label, ParentContainer, ReactSelect } from "../inputs/styles";
import { Country, State, City } from "country-state-city";
import TextInput from "../inputs/TextInput";

interface CountryStateCitySelectorProps {
  handleSelect?: any;
  handleChange?: any;
  countryName?: any;
  stateName?: any;
  cityName?: any;
}

const CountryStateCitySelector: FC<CountryStateCitySelectorProps> = ({
  cityName,
  countryName,
  handleSelect,
  stateName,
  handleChange,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>(null);

  useEffect(() => {
    handleSelect && handleSelect(countryName, selectedCountry?.name);
  }, [selectedCountry]);

  useEffect(() => {
    handleSelect && handleSelect(stateName, selectedState?.name);
  }, [selectedState]);

  useEffect(() => {
    handleSelect && handleSelect(cityName, selectedCity?.name);
  }, [selectedCity]);

  return (
    <>
      <ParentContainer>
        <Label>Country:</Label>
        <ReactSelect
          options={Country.getAllCountries()}
          getOptionLabel={(options: any) => {
            return options["name"];
          }}
          getOptionValue={(options: any) => {
            return options["name"];
          }}
          value={selectedCountry}
          onChange={(item: any) => {
            setSelectedCountry(item);
          }}
          placeholder="Country"
        />
      </ParentContainer>
      <ParentContainer>
        <Label>State:</Label>
        <ReactSelect
          options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
          getOptionLabel={(options: any) => {
            return options["name"];
          }}
          getOptionValue={(options: any) => {
            return options["name"];
          }}
          value={selectedState}
          onChange={(item: any) => {
            setSelectedState(item);
          }}
          placeholder="State"
        />
      </ParentContainer>
      <ParentContainer>
        <Label>City:</Label>
        <ReactSelect
          options={City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          )}
          getOptionLabel={(options: any) => {
            return options["name"];
          }}
          getOptionValue={(options: any) => {
            return options["name"];
          }}
          value={selectedCity}
          onChange={(item: any) => {
            setSelectedCity(item);
          }}
          placeholder="City"
        />
      </ParentContainer>
      <TextInput
        placeholder="Town"
        handleChange={handleChange}
        name="town"
        type="text"
      />
    </>
  );
};

export default CountryStateCitySelector;
