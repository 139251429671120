/* eslint-disable @typescript-eslint/no-unused-vars */
import Select from "react-select";
import { HeaderContainer, HeaderContainerWrap } from "./styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getBibleFormDetails,
  selectBible,
} from "../../../../ReduxStore/features/BibleSlice";

const WallHeader = () => {
  const {
    bibleVersions,
    gettingBibleVersions,
    gettingBibleNames,
    bibleNames,
    bibleChapters,
    gettingBibleChapters,
  } = useAppSelector(selectBible);
  const dispatch = useAppDispatch();

  const handleChange = (value: any, name: string) => {
    dispatch(getBibleFormDetails({ name, value }));
  };

  return (
    <HeaderContainer>
      <HeaderContainerWrap>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={bibleVersions && bibleVersions[0]}
          isLoading={gettingBibleVersions}
          isClearable={true}
          isSearchable={true}
          options={bibleVersions && bibleVersions}
          onChange={(value) => handleChange(value, "versionId")}
          isDisabled={bibleVersions?.length === 0 || gettingBibleVersions}
        />
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={bibleNames && bibleNames[0]}
          isLoading={gettingBibleNames}
          isClearable={true}
          isSearchable={true}
          options={bibleNames && bibleNames}
          onChange={(value) => handleChange(value, "bookId")}
          isDisabled={bibleNames?.length === 0 || gettingBibleNames}
        />
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={bibleChapters && bibleChapters[0]}
          isLoading={gettingBibleChapters}
          isClearable={true}
          isSearchable={true}
          options={bibleChapters && bibleChapters}
          onChange={(value) => handleChange(value, "chapterId")}
          isDisabled={bibleChapters?.length === 0 || gettingBibleChapters}
        />
        {/* <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={bibleReading && bibleReading[0]}
          isLoading={gettingBibleReading}
          isClearable={true}
          isSearchable={true}
          options={bibleReading && bibleReading}
          onChange={(value) => handleChange(value, "verseId")}
          isDisabled={bibleReading?.length === 0 || gettingBibleReading}
        /> */}
      </HeaderContainerWrap>
    </HeaderContainer>
  );
};

export default WallHeader;
