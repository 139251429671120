import { NameInputContainer } from "./styles";
import React from "react";

interface Props {
  defaultValue?: any;
  name?: string;
  handleChange?: any;
  value?: any;
  type?: any;
}

const EditableUserProfile: React.FC<Props> = ({
  defaultValue,
  name,
  handleChange,
  value,
  type
}) => {
  return (
    <NameInputContainer>
      <input
        className="nameinput"
        type={type}
        defaultValue={defaultValue}
        onChange={handleChange}
        name={name}
        value={value}
      />
    </NameInputContainer>
  );
};

export default EditableUserProfile;
