export const churchDenominations = [
  {
    id: 0,
    name: "Select church",
    value: null,
  },
  {
    id: 1,
    name: "Catholic",
    value: "Catholic",
  },
  {
    id: 2,
    name: "Anglican",
    value: "anglican",
  },
  {
    id: 3,
    name: "Methodist",
    value: "methodist",
  },
  {
    id: 4,
    name: "Pentecostal",
    value: "pentecostal",
  },
];
