import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface WalletState {
  gettingWallets: boolean;
  Wallets: any;
  editingWallet: boolean;
  deletingWallet: boolean;

  // editing form states: any;
  currencyId: string;
  currencySymbol: string;
  currencyName: string;
  currencyUrl: string;
}

const initialState: WalletState = {
  gettingWallets: false,
  Wallets: null,
  editingWallet: false,
  deletingWallet: false,

  // editing form states
  currencyId: "",
  currencySymbol: "",
  currencyName: "",
  currencyUrl: "",
};

export const WalletSlice = createSlice({
  name: "Wallet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingWallet: (state, action) => {
      state.gettingWallets = action.payload;
    },
    setWallet: (state, action) => {
      state.Wallets = action.payload;
    },
    setEditingWallet: (state, action) => {
      state.editingWallet = action.payload;
    },
    setDeletingWallet: (state, action) => {
      state.deletingWallet = action.payload;
    },
    setChosenwallet: (state, action) => {
      state.currencySymbol = action.payload.symbol;
      state.currencyName = action.payload.name;
      state.currencyUrl = action.payload.iconUrl;
      state.currencyId = action.payload.id;
    },
    getWalletFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingWallet,
  setWallet,
  setEditingWallet,
  setDeletingWallet,
  getWalletFormDetails,
  setChosenwallet,
} = WalletSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminWallet = (state: RootState) =>
  state.superAdminWallet;

export default WalletSlice.reducer;
