import { NameInputContainer } from "./styles";
import React from "react";

interface Props {
  defaultValue: any;
  name?: string;
  handleChange?: any;
}

const EditableUserProfile: React.FC<Props> = ({
  defaultValue,
  name,
  handleChange,
}) => {
  return (
    <NameInputContainer>
      <input
        className="nameinput"
        type="number"
        defaultValue={defaultValue}
        onChange={handleChange}
        name={name}
      />
    </NameInputContainer>
  );
};

export default EditableUserProfile;
