import { useState } from "react";
import { MdDelete, MdMoreVert } from "react-icons/md";
import styled from "styled-components";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserCgroup } from "../../../../ReduxStore/features/User/UserGroupSlice";
import { exitGroup } from "../../../../Apis/UserApis/userChurchGroup";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  svg {
    cursor: pointer;
  }
`;

const PopOverContainer = styled.div`
  gap: 10px;
  align-items: center;
  min-height: 50px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: -100px;
  display: flex;

  p {
    text-align: center;
    cursor: pointer;
    width: auto;
  }
`;

const ChatDropDown = () => {
  const [showPopOver, setShowPopOver] = useState(false);
  const { churchGroupId, exitingGroup } = useAppSelector(selectUserCgroup);

  return (
    <DropDownContainer>
      <MdMoreVert onClick={() => setShowPopOver(!showPopOver)} />
      {showPopOver && (
        <PopOverContainer className="popover">
          {exitingGroup ? (
            <Loader size={10} color={theme?.colors.primaryColor} />
          ) : (
            <p
              onClick={() => {
                exitGroup(churchGroupId);
              }}
            >
              <MdDelete /> <span>Exit Group</span>
            </p>
          )}
        </PopOverContainer>
      )}
    </DropDownContainer>
  );
};

export default ChatDropDown;
