import { FC } from "react";
import styled from "styled-components";
import { media } from "../../Screens";
import { theme } from "../../theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 50px;
`;

const Image = styled.img`
  width: 20%;
`;

const Message = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  color: ${theme.colors.primaryColor};

  ${media.phone} {
    font-size: 16px;
  }
`;

interface NoRecordI {
  src?: string;
  message?: string;
}

const NoRecordDisplay: FC<NoRecordI> = ({ src, message }) => {
  return (
    <Container>
      <Image src={src} />
      <Message>{message}</Message>
    </Container>
  );
};

export default NoRecordDisplay;
