import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AnnouncementsState {
  gettingAnnouncements: boolean;
  announcements: any;

  // pagination
  page: number;
  pageSize: number;
}

const initialState: AnnouncementsState = {
  gettingAnnouncements: false,
  announcements: null,

  // pagination
  page: 1,
  pageSize: 30,
};

export const AnnouncementsSlice = createSlice({
  name: "Announcements",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingAnnouncements: (state, action) => {
      state.gettingAnnouncements = action.payload;
    },
    setAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    getAnnouncementFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingAnnouncements,
  setAnnouncements,
  getAnnouncementFormDetails,
} = AnnouncementsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserAnnouncements = (state: RootState) =>
  state.userAnnouncements;

export default AnnouncementsSlice.reducer;
