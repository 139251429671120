import {
  getAllMyChurchGroups,
  getChatMessages,
  getJoinedGroups,
} from "../../../../Apis/UserApis/userChurchGroup";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserCgroup } from "../../../../ReduxStore/features/User/UserGroupSlice";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import ChatSection from "./ChatSection";
import { useEffect } from "react";

const App: React.FC = () => {
  const { userProfile } = useAppSelector(selectUserProfile);
  const {
    churchGroupId,
    sendingMessage,
    exitingGroup,
    requestingToJoinGroup,
    joinedGroups,
  } = useAppSelector(selectUserCgroup);

  const checkIfInGroup = () => {
    const res = joinedGroups?.itemsList?.filter(
      (item: any) => item?.groupId === churchGroupId
    );
    if (res?.length > 0) return true;
    else return false;
  };

  useEffect(() => {
    getAllMyChurchGroups(userProfile?.churchId?._id);
    getJoinedGroups();
  }, [
    userProfile,
    churchGroupId,
    sendingMessage,
    exitingGroup,
    requestingToJoinGroup,
  ]);

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      if (checkIfInGroup()) {
        getChatMessages(churchGroupId);
      }
    }, 1000);

    // Cleanup function to clear the previous interval when churchGroupId changes
    return () => clearInterval(intervalId);
  }, [churchGroupId]);

  return <ChatSection />;
};

export default App;
