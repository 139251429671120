import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SermonState {
  gettingAdminChurchSermons: boolean;
  creatingChurchSermon: boolean;
  adminChurchSermons: any;

  //   states
  title: string;
  fileUrl: string;
  type: string;
  sermon: string;
  videoSermon: any;
  videoTitle: string;
  errorMessage: string;

  // pagination
  pageId: number;
  perPage: number;
}

const initialState: SermonState = {
  gettingAdminChurchSermons: false,
  creatingChurchSermon: false,
  adminChurchSermons: null,

  //   states
  title: "",
  fileUrl: "",
  type: "",
  sermon: "",
  errorMessage: "",
  videoSermon: null,
  videoTitle: "",

  // pagination
  pageId: 1,
  perPage: 20,
};

export const SermonSlice = createSlice({
  name: "Sermon",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingAdminChurchSermons: (state, action) => {
      state.gettingAdminChurchSermons = action.payload;
    },
    setCreatingAdminChurchSermons: (state, action) => {
      state.creatingChurchSermon = action.payload;
    },
    setAdminChurchSermons: (state, action) => {
      state.adminChurchSermons = action.payload;
    },
    getSermonDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingAdminChurchSermons,
  setAdminChurchSermons,
  setCreatingAdminChurchSermons,
  getSermonDetails,
} = SermonSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminChurchSermon = (state: RootState) =>
  state.adminChurchSermon;

export default SermonSlice.reducer;
