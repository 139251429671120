import { FC } from "react";
import {
  VideoWrap,
  PlayerContainer,
  VideoDetail,
  VideoCaption,
  // VideoAuthor,
  Player,
} from "./styles";
import ReactPlayer from "react-player";

interface VideoProps {
  _id: any;
  videoSrc: string;
  sermonTitle: string;
}

const VideoSermonCard: FC<VideoProps> = ({ videoSrc, sermonTitle, _id }) => {
  // const [play, setPlay] = useState(null);

  return (
    <VideoWrap>
      <PlayerContainer>
        <Player>
          <ReactPlayer
            controls={true}
            id={_id}
            url={videoSrc}
            width={300}
            height={140}
            light={<img src="" alt="" />}
            // onClickPreview={() => setPlay(_id)}
          />
        </Player>
        <VideoDetail>
          <VideoCaption>{sermonTitle}</VideoCaption>
          {/* <VideoAuthor>Pst Emeka</VideoAuthor> */}
        </VideoDetail>
      </PlayerContainer>
    </VideoWrap>
  );
};

export default VideoSermonCard;
