import api from "../baseUrl";
import { store } from "./../../ReduxStore/app/store";
import {
  setDeletingWallet,
  setEditingWallet,
  setGettingWallet,
  setWallet,
} from "../../ReduxStore/features/SuperAdmin/WalletSlice";
import { toast } from "react-toastify";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const createCurrency = async (
  symbol: string,
  name: string,
  iconUrl: string,
) => {
  store.dispatch(setEditingWallet(true));
  const body = { symbol, name, iconUrl };

  await api
    .post("super-admin/wallet/currency", body)
    .then(() => {
      toast.success("Successful");
      store.dispatch(setEditingWallet(false));
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingWallet(false));
    });
};

export const editCurrency = async (
  currencyId: string,
  symbol: string,
  name: string,
  iconUrl: string,
) => {
  store.dispatch(setEditingWallet(true));

  const body: any = { currencyId, symbol, name, iconUrl };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "" || " ")) {
      delete body[key];
    }
  });

  await api
    .put("super-admin/wallet/currency", body)
    .then((res) => {
      toast.success("successful");
      store.dispatch(handleModal(0));
      store.dispatch(setEditingWallet(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingWallet(false));
    });
};

export const getCurrencies = async (status?: string) => {
  store.dispatch(setGettingWallet(true));
  const params = { status };

  await api
    .get("super-admin/wallet/currency", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setWallet(data?.data));
      store.dispatch(setGettingWallet(false));
    })
    .catch((err) => {
      store.dispatch(setGettingWallet(false));
    });
};

export const deleteCurrency = async (currencyId: string) => {
  store?.dispatch(setDeletingWallet(true));
  const params = { currencyId };

  await api
    .delete("super-admin/wallet/currency", { params })
    .then(() => {
      toast.success("successful");
      store?.dispatch(setDeletingWallet(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store?.dispatch(setDeletingWallet(false));
    });
};
