import { FC } from "react";
import styled from "styled-components";

const ActivityCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 100, 0.2);
`;

const TopDiv = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  background: #b603c9;
  height: 50px;
`;

const Title = styled.div`
  padding: 10px 15px;
  font-weight: 500;
  font-size: 15px;
`;

const Time = styled.div`
  padding: 0px 15px;
  font-size: 12px;
`;

const Quote = styled.div`
  padding: 10px 15px;
  font-size: 13px;
  margin-bottom: 70px;
`;

const LastDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Interested = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #b603c9;
  color: white;
  border-radius: 10px;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 10px;
  cursor: pointer;
`;

const Icon = styled.div``;

const Span = styled.div``;

const Live = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff0000;
  border-radius: 15px;
  padding: 0px 10px;
  color: white;
  font-size: 10px;
  cursor: pointer;
  height: 30px;
  width: 50px;
`;

interface IActivityCard {
  icon: any;
  title: string;
  time: any;
  quote: string;
  choice: string;
  mode: boolean;
  repeat?: boolean;
  allDay?: boolean;
}

const ActivityCard: FC<IActivityCard> = ({
  icon,
  title,
  time,
  quote,
  choice,
  mode,
  repeat,
  allDay,
}) => {
  return (
    <ActivityCardContainer>
      <TopDiv></TopDiv>
      <Title>{title}</Title>
      <Time>{time}</Time>
      <Quote>{quote}</Quote>
      <LastDiv>
        <div style={{ display: "flex", gap: "10px" }}>
          {repeat && (
            <Interested>
              <Icon>{icon}</Icon>
              <Span>repeat</Span>
            </Interested>
          )}{" "}
          {allDay && (
            <Interested>
              <Icon>{icon}</Icon>
              <Span>Add Day</Span>
            </Interested>
          )}
        </div>

        {mode && <Live>live</Live>}
      </LastDiv>
    </ActivityCardContainer>
  );
};

export default ActivityCard;
