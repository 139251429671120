import { Link, useNavigate } from "react-router-dom";
import { signupAsMember } from "../../../Apis/Auth/signupApis";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import SelectBox from "../../../Components/Form/inputs/SelectBox";
import TextInput from "../../../Components/Form/inputs/TextInput";
import {
  validateemail,
  validatePassword,
} from "../../../Components/Helpers/Generals";
import FormWrapper from "../../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getFormDetails,
  selectForm,
} from "../../../ReduxStore/features/formSlice";
import { memberSignUpInputs } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import {
  AuthLogo,
  AuthTitle,
  AuthLink,
  AuthSmallText,
  AuthSubTitle,
} from "../style";
import { useState } from "react";
import ErrorMessage from "../../../Components/Reusables/MessagePopups/ErrorMessage";
import { IoArrowBackCircle } from "react-icons/io5";

const AdminSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    registerEmail,
    registerPassword,
    phoneNo,
    firstName,
    lastName,
    gender,
    loading,
  } = useAppSelector(selectForm);
  const [viewPassword, setViewPassword] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSignup = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();
    if (!registerEmail) {
      setErrors([...errors, "Email cannot be empty"]);
    } else if (!registerPassword) {
      setErrors([...errors, "Password cannot be empty"]);
    } else if (!phoneNo) {
      setErrors([...errors, "Phone No cannot be empty"]);
    } else if (!firstName) {
      setErrors([...errors, "FirstName cannot be empty"]);
    } else if (!lastName) {
      setErrors([...errors, "LastName cannot be empty"]);
    } else if (!gender) {
      setErrors([...errors, "Gender cannot be empty"]);
    } else if (!validateemail(registerEmail)) {
      setErrors([...errors, "Please enter a valid email"]);
    } else if (!validatePassword(registerPassword)) {
      setErrors([
        ...errors,
        "password should be more than 8 characters with alphabet, numeric and special characters",
      ]);
    } else {
      signupAsMember(
        firstName,
        lastName,
        registerEmail,
        phoneNo,
        registerPassword,
        gender,
        dispatch,
        navigate,
        "admin"
      );
    }
  };

  return (
    <AuthMain>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>
        <Link to="/auth/signup">
          <IoArrowBackCircle />
        </Link>
        Sign Up
      </AuthTitle>
      <AuthSubTitle>Register as an admin by</AuthSubTitle>
      <FormWrapper>
        {memberSignUpInputs?.map((input: any) => (
          <>
            {input?.type !== "select" ? (
              <TextInput
                placeholder={input?.placeholder}
                icon={input?.ActiveIcon}
                name={input?.name}
                type={
                  input?.type === "password" && viewPassword
                    ? "text"
                    : input?.type
                }
                handleChange={handleChange}
                altIcon={input?.InActiveIcon}
                iconState={viewPassword}
                handleIconClick={() => setViewPassword(true)}
                handleAltIconClick={() => setViewPassword(false)}
                showStrengthSignal={input?.showStrengthSignal}
                value={input?.value()}
              />
            ) : (
              <SelectBox
                options={input?.options}
                name={input?.name}
                handleSelect={handleSelect}
              />
            )}
          </>
        ))}
        <AuthSmallText>
          By signing up you accept the
          <AuthLink to="https://thefaithfuls.com/terms-and-conditions">
            Terms of service
          </AuthLink>{" "}
          &
          <AuthLink
            to="https://thefaithfuls.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </AuthLink>
        </AuthSmallText>
        {errors?.length > 0 && (
          <ErrorMessage
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {errors?.map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
        <PrimaryButton
          text="Next"
          handleClick={handleSignup}
          loading={loading}
        />
        <AuthSmallText>
          Already have an account? <AuthLink to="/">Login here</AuthLink>
        </AuthSmallText>
      </FormWrapper>
    </AuthMain>
  );
};

export default AdminSignUp;
