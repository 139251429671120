import {
  setCreatingSubscription,
  setDeletingSubsription,
  setEditingSubscription,
  setGettingPaymentHistory,
  setGettingSubscriptions,
  setPaymentHistory,
  setSubscriptions,
} from "../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import api from "../baseUrl";
import { store } from "./../../ReduxStore/app/store";
import { toast } from "react-toastify";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const createSubscriptionPack = async (title: string, description: string, features: string[], amount: number, currencyId: string, durationInDays:number) => {
  store.dispatch(setCreatingSubscription(true));
  const body: any = {
    title,
    description,
    features,
    amount,
    currencyId,
    durationInDays,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("super-admin/subscription", body)
    .then(() => {
      store.dispatch(setCreatingSubscription(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingSubscription(false));
    });
};

export const editSubscriptionPack = async (
  title: string,
  description: string,
  features: string[],
  amount: number,
  currencyId: string,
  subscriptionPackageId: string,
) => {
  store.dispatch(setEditingSubscription(true));
  const body: any = {
    title,
    description,
    features,
    amount,
    currencyId,
    subscriptionPackageId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .put("super-admin/subscription", body)
    .then(() => {
      store.dispatch(setEditingSubscription(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingSubscription(false));
    });
};

export const deleteSubscription = async (subscriptionPackageId: string) => {
  store.dispatch(setDeletingSubsription(true));
  const params = { subscriptionPackageId };

  await api
    .delete("super-admin/subscription", { params })
    .then((res) => {
      toast.success(res?.data?.message);
      store.dispatch(setDeletingSubsription(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingSubsription(false));
    });
};

export const getAllSubscription = async (status?: string) => {
  store.dispatch(setGettingSubscriptions(true));
  const params = { status };

  await api
    .get("super-admin/subscription", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingSubscriptions(false));
      store.dispatch(setSubscriptions(data?.data));
    })
    .catch((err) => {
      store.dispatch(setGettingSubscriptions(false));
    });
};

export const getSingleSubscription = async (subscriptionPackageId: string) => {
  const params = { subscriptionPackageId };

  await api
    .get("super-admin/subscription/single", { params })
    .then((res) => {
    })
    .catch((err) => {
    });
};

export const getSubscriptionsPaymentHistory = async (
  subscriberId?: string,
  startDate?: number,
  endDate?: number,
) => {
  store.dispatch(setGettingPaymentHistory(true));

  const params: any = {
    subscriberId,
    startDate,
    endDate,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  api
    .get("admin/subscription/payment-history", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentHistory(data?.data));
      store.dispatch(setGettingPaymentHistory(false));
    })
    .catch((err) => {
      store.dispatch(setGettingPaymentHistory(false));
    });
};

export const verifySubscriptionsPayment = async (
  transactionReference: string,
) => {
  const body = { transactionReference };

  await api
    .post("super-admin/subscription/manually-verify-paystack-payment", body)
    .then((res) => {
    })
    .catch((err) => {
    });
};

export const completeSubscriptionsPayment = async (
  transactionReference: string,
) => {
  const body = { transactionReference };

  await api
    .post(
      "super-admin/subscription/complete-paystack-payment-verification",
      body,
    )
    .then((res) => {
    })
    .catch((err) => {
    });
};
