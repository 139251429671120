import React, { FC } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";
import RenewButton from "./RenewButton";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";

const FreePlanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  // width: 600px;
  flex: 0.69;
  min-height: 200px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  ${media.phone} {
    width: 350px;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 10px;
  }

  .right-content {
    height: 100%;
    img {
      height: 130px;
    }
    ${media.phone} {
      display: none;
    }
  }
`;
const YearSubCaption = styled.div`
  display: flex;
  margin-top: 15px;
  // margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #151c4d;
`;
const RenewDate = styled.div`
  padding-top: 7px;
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  color: #151c4d;
`;
const CurrentPlanLabel = styled.div`
  padding-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #151c4d;
`;
const PlanName = styled.div`
  margin-top: -8px;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: #b603c9;
`;
const Ammount = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 700;
  font-size: 30px;
  color: #151c4d;
`;

const PlanNameAndButton = styled.div`
  display: flex;
  // justify-content:center;
  align-items: center;
  gap: 140px;

  ${media.phone} {
    gap: 70px;
  }
`;

interface FreePlan {
  yearSub: string;
  planLabel: string;
  planName: string;
  planAmount: any;
  dateRenew?: string;
  loading?: boolean;
}

const FreePlanCard: FC<FreePlan> = ({
  yearSub,
  dateRenew,
  planLabel,
  planName,
  planAmount,
  loading,
}) => {


  
  return (
    <FreePlanContainer>
      <div className="left-content">
        <YearSubCaption>{yearSub}</YearSubCaption>
        <RenewDate>{dateRenew}</RenewDate>
        <CurrentPlanLabel>{planLabel} </CurrentPlanLabel>
        <PlanName>
          {loading ? (
            <Loader size="10px" color={theme.colors.primaryColor} />
          ) : (
            planName
          )}
        </PlanName>
        <PlanNameAndButton>
          <Ammount>
            {loading ? (
              <Loader size="10px" color={theme.colors.primaryColor} />
            ) : (
              "₦ " + planAmount
            )}
          </Ammount>
          {dateRenew && (
            <RenewButton
              handleSucces={"proceed to Pay"}
              email="customeremail@gmail.com"
              amount={planAmount}
              metaData={""}
              text="Renew Plan"
              outlined
              size="small"
            />
          )}
        </PlanNameAndButton>
      </div>
      <div className="right-content">
        <img src="/assets/images/svg/currentPlan.svg" alt="" />
      </div>
    </FreePlanContainer>
  );
};

export default FreePlanCard;
