import React, { FC } from "react";
import {
  DetailItem,
  EventBanner,
  EventCardContainer,
  EventDetails,
  EventMessage,
  EventTitle,
  Icon,
  IconContainer,
} from "./style";
import moment from "moment";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setSelectedEvent } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import { deleteChurchEvent } from "../../../../Apis/AdminApis/ChurchActivities";

interface SingleEventCardI {
  title?: string;
  message?: string;
  bannerUrl?: string;
  location?: string;
  allDay?: boolean;
  repeat?: boolean;
  liveStream?: boolean;
  inviteeType?: string;
  startDate?: string;
  endDate?: string;
  item?: any;
}

const SingleEventCard: FC<SingleEventCardI> = ({
  bannerUrl,
  title,
  message,
  location,
  allDay,
  repeat,
  liveStream,
  inviteeType,
  startDate,
  endDate,
  item,
}) => {
  const dispatch = useDispatch();

  return (
    <EventCardContainer>
      <IconContainer>
        <Icon
          onClick={() => {
            dispatch(handleModal(27));
            dispatch(setSelectedEvent(item));
          }}
        >
          <FaEdit />
        </Icon>
        <Icon onClick={() => deleteChurchEvent(item?._id)}>
          <FaTrash />
        </Icon>
      </IconContainer>
      {bannerUrl && (
        <EventBanner>
          <img src={bannerUrl} alt="Event Banner" />
        </EventBanner>
      )}
      <EventTitle>{title}</EventTitle>
      <EventMessage>{message}</EventMessage>
      <EventDetails>
        {location && (
          <DetailItem>
            <strong>Location:</strong> {location}
          </DetailItem>
        )}
        {allDay !== undefined && (
          <DetailItem>
            <strong>All Day:</strong> {allDay ? "Yes" : "No"}
          </DetailItem>
        )}
        {repeat !== undefined && (
          <DetailItem>
            <strong>Repeat:</strong> {repeat ? "Yes" : "No"}
          </DetailItem>
        )}
        {liveStream !== undefined && (
          <DetailItem>
            <strong>Live Stream:</strong> {liveStream ? "Yes" : "No"}
          </DetailItem>
        )}
        {inviteeType && (
          <DetailItem>
            <strong>Invitee Type:</strong> {inviteeType}
          </DetailItem>
        )}
        {startDate && (
          <DetailItem>
            <strong>Start Date:</strong> {moment(startDate).format("LLL")}
          </DetailItem>
        )}
        {endDate && (
          <DetailItem>
            <strong>End Date:</strong> {moment(endDate).format("LLL")}
          </DetailItem>
        )}
      </EventDetails>
    </EventCardContainer>
  );
};

export default SingleEventCard;
