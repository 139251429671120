import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ChurchActivitiesState {
  gettingChurchActivities: boolean;
  churchActivities: any;
  gettingChurchEvents: boolean;
  churchEvents: any;

  // pagination
  page: number;
  pageSize: number;
}

const initialState: ChurchActivitiesState = {
  gettingChurchActivities: false,
  churchActivities: null,
  gettingChurchEvents: false,
  churchEvents: null,

  // pagination
  page: 1,
  pageSize: 30,
};

export const ChurchActivitiesSlice = createSlice({
  name: "ChurchActivities",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurchActivities: (state, action) => {
      state.gettingChurchActivities = action.payload;
    },
    setChurchActivities: (state, action) => {
      state.churchActivities = action.payload;
    },
    setGettingChurchEvents: (state, action) => {
      state.gettingChurchEvents = action.payload;
    },
    setChurchEvents: (state, action) => {
      state.churchEvents = action.payload;
    },
    getChurchActivitiesFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingChurchActivities,
  setChurchActivities,
  getChurchActivitiesFormDetails,
  setChurchEvents,
  setGettingChurchEvents,
} = ChurchActivitiesSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserChurchActivities = (state: RootState) =>
  state.userChurchActivities;

export default ChurchActivitiesSlice.reducer;
