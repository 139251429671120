import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  setAdminChurchSermons,
  setCreatingAdminChurchSermons,
  setGettingAdminChurchSermons,
} from "../../ReduxStore/features/Admin/SermonSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const getSermons = async (
  type?: string | null,
  pageId?: number,
  perPage?: number
) => {
  store.dispatch(setGettingAdminChurchSermons(true));
  const params: any = {
    type,
    pageId,
    perPage,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/sermon", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setAdminChurchSermons(data?.data));
      store.dispatch(setGettingAdminChurchSermons(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdminChurchSermons(false));
    });
};

export const createSermon = async (
  title: string,
  type: string,
  sermon: string | null,
  fileUrl?: string | null
) => {
  store.dispatch(setCreatingAdminChurchSermons(true));
  const body: any = {
    title,
    fileUrl,
    type,
    sermon,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("admin/sermon", body)
    .then(() => {
      store.dispatch(setCreatingAdminChurchSermons(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingAdminChurchSermons(false));
    });
};

export const createAudioVideoSermon = async (
  title: string,
  type: string,
  file?: any
) => {
  store.dispatch(setCreatingAdminChurchSermons(true));
  const body: any = {
    title,
    file,
    type,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("admin/church/sermon", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      store.dispatch(setCreatingAdminChurchSermons(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingAdminChurchSermons(false));
    });
};
