import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";
import OtherLastPay from "./OtherLastPay";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectSuperAdminSubscriptions } from "../../../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import { getSubscriptionHistory } from "../../../../Apis/AdminApis/subscriptions";

const LastPayContainer = styled.div`
  display: flex;
  padding: 15px 25px;
  // margin-top: 30px;
  flex-direction: column;
  // width: 600px;
  flex: 0.31;
  min-height: 200px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;

  ${media.phone} {
    width: 350px;
  }
`;
// const FirstWrap = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 30px;
//   width: 100%;
//   border-bottom: 0.1px grey;
//   box-shadow: rgba(27, 31, 35, 0.2) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
// `;
// const Basic = styled.div`
//   font-style: normal;
//   font-weight: 700;
//   font-size: 12px;
//   line-height: 18px;
//   color: #151c4d;
// `;
// const Date = styled.div`
//   font-style: normal;
//   font-weight: 700;
//   font-size: 12px;
//   line-height: 18px;
//   color: #151c4d;
// `;
// const Ammount = styled.div`
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 30px;
//   color: #b603c9;
// `;
const Caption = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #151c4d;
`;

interface LastPay {}

const LastPaymentCard: FC<LastPay> = () => {
  const { gettingSubscriptionHistory, subscriptionHistory } = useAppSelector(selectSuperAdminSubscriptions);

  useEffect(() => {
    getSubscriptionHistory();
  }, []);

  return (
    <LastPayContainer>
      <Caption>Last Payment</Caption>
      {gettingSubscriptionHistory ? (
        <Loader size="20px" color={theme.colors.primaryColor} />
      ) : (
        <>
          {subscriptionHistory?.itemsList?.length > 0 ? (
            subscriptionHistory?.itemsList?.map((sub: any) => <OtherLastPay basicPlan="Basic Plan " paydate={"1st September 2020"} paidAmmount={" ₦30,000"} />)
          ) : (
            <NoRecordDisplay src="/assets/images/svg/working-remotly.svg" />
          )}
        </>
      )}
    </LastPayContainer>
  );
};

export default LastPaymentCard;
