import { toast } from "react-toastify";
import { login } from "../../../Apis/Auth/loginApis";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../../Components/Form/inputs/TextInput";
import FormWrapper from "../../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getFormDetails,
  selectForm,
} from "../../../ReduxStore/features/formSlice";
import { loginInput } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import { AuthLogo, AuthTitle, AuthLink, AuthSmallText } from "./../style";
import { validateemail } from "./../../../Components/Helpers/Generals";
import { useState } from "react";
import ErrorMessage from "../../../Components/Reusables/MessagePopups/ErrorMessage";

const Login = () => {
  const { email, password, loggingUser } = useAppSelector(selectForm);
  const dispatch = useAppDispatch();
  const [viewPassword, setViewPassword] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();

    if (!email) {
      setErrors([...errors, "Email cannot be empty"]);
    } else if (!password) {
      setErrors([...errors, "Password cannot be empty"]);
    } else if (!validateemail(email)) {
      setErrors([...errors, "Please enter a valid email address"]);
    } else {
      login(email, password);
    }
  };

  return (
    <AuthMain placeItemsCenter={true}>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Login</AuthTitle>
      <FormWrapper>
        {loginInput?.map((input, index) => (
          <TextInput
            key={index + 1}
            placeholder={input.placeholder}
            icon={input?.ActiveIcon}
            type={
              input?.type === "password" && viewPassword ? "text" : input?.type
            }
            name={input?.name}
            handleChange={handleChange}
            altIcon={input?.InActiveIcon}
            iconState={viewPassword}
            handleIconClick={() => setViewPassword(true)}
            handleAltIconClick={() => setViewPassword(false)}
            // error={true}
            required={true}
          />
        ))}
        {errors?.length > 0 && (
          <ErrorMessage
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {errors?.map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
        <AuthLink to="/auth/reset-password">Forgot Password </AuthLink>
        <PrimaryButton
          text="Login"
          handleClick={handleLogin}
          loading={loggingUser}
          // disabled={true}
        />
      </FormWrapper>
      <AuthSmallText>
        Don't have an account?{" "}
        <AuthLink to="/auth/signup">Sign Up here</AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default Login;
