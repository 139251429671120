import { FC, useEffect, useRef, useState } from "react";
import { CenterContentContainer } from "./styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxStore/app/hooks";
import {
  getPostFormDetails,
  selectUserPost,
} from "../../../../../ReduxStore/features/User/UserPostSlice";
import SinglePostCard from "../../../../../Layouts/PostCard";
import Loader from "../../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../../theme";
import { getMyPosts } from "../../../../../Apis/UserApis/Post";
import { selectUserProfile } from "../../../../../ReduxStore/features/User/UserProfileSlice";

const MyPosts = () => {
  const {
    myPosts,
    gettingApprovedPost,
    page,
    likingAndDislikingUserPOst,
    commentingUserPost,
    updatingUserPost,
    creatingUserPost,
    allPosts,
    pageSize,
  } = useAppSelector(selectUserPost);
  const dispatch = useAppDispatch();
  const { userProfileDetails } = useAppSelector(selectUserProfile);
  const divRef = useRef(null);

  useEffect(() => {
    getMyPosts(userProfileDetails?.activeChurch, pageSize, page);
  }, [
    page,
    likingAndDislikingUserPOst,
    commentingUserPost,
    updatingUserPost,
    creatingUserPost,
  ]);

  const loadMorePosts = (direction: string) => {
    if (direction === "next" && allPosts?.totalPages > page) {
      dispatch(getPostFormDetails({ name: "page", value: page + 1 }));
    } else if (direction === "previous" && page > 1) {
      dispatch(getPostFormDetails({ name: "page", value: page - 1 }));
    }
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight }: any = divRef.current;
    // If the user scrolls to the top, load the previous page
    if (scrollTop === 0) {
      loadMorePosts("previous");
    }
    // If the user scrolls to the bottom, load the next page
    if (scrollTop + clientHeight >= scrollHeight) {
      loadMorePosts("next");
    }
  };

  return (
    <CenterContentContainer ref={divRef} onScroll={handleScroll}>
      {myPosts?.map((post: any, index: number) => (
        <SinglePostCard
          username={post?.userId?.firstName + " " + post?.userId?.lastName}
          avatar={
            "https://th.bing.com/th/id/R.4e9a9213eb6cacc05b42ead4c364aef8?rik=e%2frEilzC8bkv3g&pid=ImgRaw&r=0"
          }
          content={post?.description}
          media={post?.contentUrl}
          likes={post?.likes}
          disLikes={post?.dislikes}
          comments={post?.comments}
          hashTags={post?.hashtags}
          contentType={post?.contentType}
          title={post?.title}
          createdAt={post?.createdAt}
          postId={post?._id}
          //   postType={postType}
          key={index + 1}
        />
      ))}
      {gettingApprovedPost && (
        <Loader size={10} color={theme.colors.primaryColor} />
      )}
    </CenterContentContainer>
  );
};

export default MyPosts;
