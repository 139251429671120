import { FC } from "react";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { media } from "../../../../Screens";

interface StyleProp {
  isSender?: boolean;
}

const Container = styled.div<StyleProp>`
  display: flex;
  gap: 10px;
  width: 100%;
  background: transparent;
  justify-content: ${(props) =>
    props.isSender === true ? "flex-end" : "flex-start"};
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;

  ${media.phone} {
    width: 70%;
  }
`;

const Message = styled.p<StyleProp>`
  width: 100%;
  padding: 10px;
  height: auto;
  background: ${(props) =>
    props?.isSender ? theme.colors.primaryColor : theme.colors.bgGrey};
  color: ${(props) =>
    props?.isSender ? theme.colors.textWhite : theme.colors.textBlack};
  font-weight: 400;
  border-radius: 5px;
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TimeStamp = styled.p`
  font-size: 10px;
  color: ${theme.colors.bgBlack};
  align-self: flex-end;
`;

const Media = styled.div<StyleProp>`
  width: 50%;
  max-height: 150px;
  background: ${(props) =>
    props?.isSender ? theme.colors.primaryColor : theme.colors.bgGrey};
  img {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }

  ${media.phone} {
    width: 100%;
  }
`;

interface SingleMessageProp {
  avatar?: string;
  username?: string;
  messageText?: string;
  isSender?: boolean;
  timeStamp?: any;
  imgSrc?: string;
  videoSrc?: string;
  audioSrc?: string;
  othersSrc?: any;
}

const SingleMessage: FC<SingleMessageProp> = ({
  avatar,
  messageText,
  isSender,
  timeStamp,
  imgSrc,
  username,
}) => {
  return (
    <Container isSender={isSender}>
      {!isSender && <Avatar src={avatar} />}
      <RightContent>
        <Media isSender={isSender}>
          {imgSrc && <img src={imgSrc} alt="" />}
        </Media>
        <Message isSender={isSender}>{messageText}</Message>
        <BottomContent>
          <TimeStamp>{username}</TimeStamp>
          <TimeStamp>{timeStamp}</TimeStamp>
        </BottomContent>
      </RightContent>
    </Container>
  );
};

export default SingleMessage;
