import { PiUserGear } from "react-icons/pi";
import { store } from "../../ReduxStore/app/store";
import {
  setChurchActivities,
  setChurchEvents,
  setGettingChurchActivities,
  setGettingChurchEvents,
} from "../../ReduxStore/features/User/ChurchActivitiesSlice";
import api from "../baseUrl";

export const getUserChurchActivities = async (
  page: number,
  pageSize: number
) => {
  store.dispatch(setGettingChurchActivities(true));

  const params: any = {
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/activity", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setChurchActivities(data?.data));
      store.dispatch(setGettingChurchActivities(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchActivities(false));
    });
};

export const getUserChurchEvents = async (page: number, pageSize: number) => {
  store.dispatch(setGettingChurchEvents(true));

  const params: any = {
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/event", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setChurchEvents(data?.data));
      store.dispatch(setGettingChurchEvents(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchEvents(false));
    });
};
