import { useEffect } from "react";
// import { getChurchMembers } from "../../../Apis/AdminApis/dashboard";
import { selectSuperAdminUserManager } from "../../../ReduxStore/features/SuperAdmin/userManagerSlice";
import { usersTableCols } from "../../../Utils/SuperAdmin/tableCols";
import { userTableRow } from "../../../Utils/SuperAdmin/tableRows";
import CustomTable from "../CustomRTable";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
import { getUsers } from "../../../Apis/SuperAdminApis/userManagerApis";

const UserManagerTable = () => {
  const { gettingUsers, users } = useAppSelector(selectSuperAdminUserManager);

  const rows = userTableRow(users?.itemsList);

  const data = {
    columns: usersTableCols,
    rows,
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <CustomTable
        data={data}
        showFilter={true}
        loading={gettingUsers}
        noRecord={users?.itemsList?.length > 0 ? false : true}
        noRecordText="No User Available"
        currentPage={users?.paginator?.currentPage}
        pageCount={users?.paginator?.pageCount}
        itemCount={users?.paginator?.itemCount}
        hasNextPage={users?.paginator?.hasNextPage}
        hasPrevPage={users?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default UserManagerTable;
