import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import FormWrapper from "../../../../Layouts/FormWrapper";
import { FormLabel, TextArea } from "../../../../Components/Form/inputs/styles";
import TextInput from "../../../../Components/Form/inputs/TextInput";
import { internalSettingsInput } from "../../../../Utils/authInput";
import { useEffect } from "react";
import {
  editInternalSettings,
  getInternalSettings,
} from "./../../../../Apis/SuperAdminApis/settings";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import {
  getConfigurationsFormDetails,
  selectSuperAdminConfigurations,
} from "../../../../ReduxStore/features/SuperAdmin/ConfigurationsSlice";
import { useAppDispatch } from "./../../../../ReduxStore/app/hooks";

const InternalSettings = () => {
  const {
    internalSettings,
    updatingInternalSettings,
    defaultSignupSubscriptionPackageId,
    subscriptionPaymentMethod,
    subscriptionPaymentGracePeriodInGraceInDays,
  } = useAppSelector(selectSuperAdminConfigurations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getInternalSettings();
  }, []);

  useEffect(() => {}, [internalSettings]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getConfigurationsFormDetails({ name, value }));
  };
  return (
    <FormWrapper
      style={{
        // justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        marginTop: "20px",
      }}
    >
      {internalSettingsInput?.map((input) => (
        <>
          {input?.type === "textarea" ? (
            <TextArea
              placeholder={input.placeholder}
              name={input?.name}
              // onChange={handleTextAreaChange}
            />
          ) : (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <FormLabel>{input?.label}</FormLabel>
              <TextInput
                placeholder={input.placeholder}
                type={input?.type}
                name={input?.name}
                handleChange={handleChange}
                defaultValue={input?.defaultValue()}
                readonly={input?.readonly}
              />
            </div>
          )}
        </>
      ))}
      <PrimaryButton
        text="Update"
        handleClick={(e: any) => {
          e.preventDefault();
          editInternalSettings(
            defaultSignupSubscriptionPackageId,
            subscriptionPaymentMethod,
            subscriptionPaymentGracePeriodInGraceInDays,
            internalSettings?.defaultChurchCreatorRoleId,
          );
        }}
        loading={updatingInternalSettings}
      />
    </FormWrapper>
  );
};

export default InternalSettings;
