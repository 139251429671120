import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getWalletFormDetails,
  selectAdminWallet,
} from "../../ReduxStore/features/Admin/WalletSlice";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import { TextArea } from "../../Components/Form/inputs/styles";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { createBankAccountInput } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { addBankAccount, verifyBank } from "../../Apis/AdminApis/Wallet";
import { toast } from "react-toastify";

const CreateBankAccountModal = () => {
  const {
    accountName,
    bankName,
    accountNumber,
    // accountVisibility,
    accountDescription,
    creatingAccount,
    bankVerified,
  } = useAppSelector(selectAdminWallet);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  const handleCreateBankAccount = (e: any) => {
    e.preventDefault();
    if (
      accountNumber &&
      bankName &&
      accountName &&
      // accountVisibility &&
      accountDescription
    ) {
      addBankAccount(
        accountNumber,
        JSON.parse(bankName)?.name,
        accountName,
        // accountVisibility,
        accountDescription
      );
    } else {
      toast.error("Please fill all fields");
    }
  };

  useEffect(() => {
    if (accountNumber.length === 10 && bankName) {
      verifyBank(accountNumber, JSON.parse(bankName));
    }
  }, [accountNumber, bankName]);

  return (
    <CustomModal
      id={11}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {createBankAccountInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  value={input?.defaultValue()}
                  onChange={handleTextAreaChange}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options()}
                  name={input?.name}
                  handleSelect={handleSelect}
                  // value={input?.defaultValue()}
                />
              ) : input?.type === "text-display" ? (
                <div>{accountName}</div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    value={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={handleCreateBankAccount}
            loading={creatingAccount}
            disabled={creatingAccount || !bankVerified}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateBankAccountModal;
