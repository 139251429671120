import { useEffect } from "react";
import { CardsContainer } from "../Prayers/style";
import moment from "moment";
import PrayerRequestCard from "../../../../Layouts/PrayerRequestCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getAdminBookingDetails,
  selectAdminBooking,
} from "../../../../ReduxStore/features/Admin/BookingSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import {
  getChurchBookings,
  updateChurchBooking,
} from "../../../../Apis/AdminApis/Bookings";
import { selectUser } from "../../../../ReduxStore/features/userSlice";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const MarraigeCounselling = () => {
  const { gettingBookings, bookings, updatingBookings, page, pageSize } =
    useAppSelector(selectAdminBooking);
  const { currentUser } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getAdminBookingDetails({ name: "page", value: e.selected + 1 }));
  };

  useEffect(() => {
    currentUser &&
      getChurchBookings(
        "admin",
        "Marriage",
        currentUser?.roles[0]?.churchId?._id,
        page,
        pageSize
      );
  }, [currentUser, updatingBookings, page]);

  return (
    <>
      {gettingBookings ? (
        <Loader color={theme?.colors.primaryColor} size={20} />
      ) : (
        <>
          {bookings && bookings?.itemsList?.length > 0 ? (
            <CardsContainer>
              {bookings?.itemsList
                ?.filter((item: any) => item?.type === "Marriage")
                ?.map((booking: any) => (
                  <PrayerRequestCard
                    user={
                      booking?.userId?.lastName +
                      " " +
                      booking?.userId?.firstName
                    }
                    date={moment(booking?.bookingDate).format("MMM Do YYYY")}
                    prayerPoint={booking?.type}
                    reading={booking?.description}
                    handleApprove={() => {
                      updateChurchBooking(
                        currentUser?.churchId?._id,
                        booking?._id,
                        `active`,
                        `admin`
                      );
                    }}
                    handleDiscard={() => {
                      updateChurchBooking(
                        currentUser?.churchId?._id,
                        booking?._id,
                        `declined`,
                        `admin`
                      );
                    }}
                    status={booking?.status}
                    updating={updatingBookings}
                  />
                ))}
            </CardsContainer>
          ) : (
            <NoRecordDisplay
              message="No Bookings Yet"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}
      <Paginator
        currentPage={bookings?.paginator?.currentPage}
        pageCount={bookings?.paginator?.pageCount}
        itemCount={bookings?.paginator?.itemCount}
        hasNextPage={bookings?.paginator?.hasNextPage}
        hasPrevPage={bookings?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default MarraigeCounselling;
