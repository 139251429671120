import React, { FC } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";

const ItemsListCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 40px;
  border-radius: 5px;
  background: #f0f4ff;
  flex: 0.3;
  // align-items: center;
  justify-content: space-evenly;

  ${media.phone} {
    margin-left: 10px;
  }
`;
const TopItemsWrap = styled.div`
  border-left: 5px solid #b603c9;
  padding-left: 20px;
`;
const ItemName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #151c4d;
`;
const ChurchName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #151c4d;
`;
const SecondItemsWrap = styled.div`
  border-left: 5px solid #f6b958;
  padding-left: 20px;
  margin-top: 10px;
`;
const UserPosition = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #151c4d;
`;
const UserName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #151c4d;
`;
const ThirdItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 5px solid #03ff0b;
  padding-left: 20px;
  margin-top: 10px;
`;
const ChurchVerificationStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #151c4d;
`;
const Wrap1 = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Dot1 = styled.div`
  width: 8px;
  height: 8px;
  background: #ff2366;
  border-radius: 50%;
`;
const Status = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #151c4d;
`;
const FourthItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 5px solid #ff4747;
  padding-left: 20px;
  margin-top: 10px;
`;
const SubStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #151c4d;
`;
const Wrap2 = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Dot2 = styled.div`
  width: 8px;
  height: 8px;
  background: #058228;
  border-radius: 50%;
`;
const Daysleft = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #151c4d;
`;

interface ItemsListI {
  itemName: string;
  churchName: string;
  userPosition: string;
  userName: string;
  churchVeriStatus: string;
  dot1: string;
  status: string;
  subStatus: string;
  dot2: string;
  daysLeft: any;
}
const ItemsListCard: FC<ItemsListI> = ({ itemName, churchName, userPosition, userName, churchVeriStatus, dot1, status, subStatus, dot2, daysLeft }) => {
  return (
    <ItemsListCardContainer>
      <TopItemsWrap>
        <ItemName>{itemName}</ItemName>
        <ChurchName>{churchName}</ChurchName>
      </TopItemsWrap>

      <SecondItemsWrap>
        <UserPosition>{userPosition}</UserPosition>
        <UserName>{userName}</UserName>
      </SecondItemsWrap>

      <ThirdItemWrap>
        <ChurchVerificationStatus>{churchVeriStatus}</ChurchVerificationStatus>
        <Wrap1>
          {!status ? <Dot1>{dot1}</Dot1> : <Dot2>{dot2}</Dot2>}
          <Status>{status ? "Verified" : "Unverified"}</Status>
        </Wrap1>
      </ThirdItemWrap>

      <FourthItemsWrap>
        <SubStatus>{subStatus}</SubStatus>
        <Wrap2>
          <Dot2>{dot2}</Dot2>
          <Daysleft>{daysLeft}</Daysleft>
        </Wrap2>
      </FourthItemsWrap>
    </ItemsListCardContainer>
  );
};

export default ItemsListCard;
