import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import {
  getAdminActivitiesDetails,
  selectAdminActivities,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { AddEventMedium } from "../../Apis/AdminApis/ChurchActivities";
import { toast } from "react-toastify";

const CreateEventMedium = () => {
  const { creatingEventMedium, eventMediumName } = useAppSelector(
    selectAdminActivities
  );
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  const handleCreateMedium = (e: any) => {
    e.preventDefault();
    if (eventMediumName) {
      AddEventMedium(eventMediumName);
    } else {
      toast.error("Please enter the medium name");
    }
  };

  return (
    <CustomModal
      id={17}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextInput
              placeholder="Event Medium Name"
              type="text"
              name="eventMediumName"
              handleChange={handleChange}
            />
          </div>

          <PrimaryButton
            text="Create"
            handleClick={handleCreateMedium}
            loading={creatingEventMedium}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateEventMedium;
