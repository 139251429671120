import { useEffect, useState } from "react";
import FinancialReportTable from "../../../../Components/Tables/Admin/FinancialReportTable";
import { SummaryContainer } from "./style";
import CashSummaryCard from "../../../../Layouts/CashSummaryCard";
import { IoMdWallet } from "react-icons/io";
import { getAdminFinancialReport } from "../../../../Apis/AdminApis/Wallet";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectAdminWallet } from "../../../../ReduxStore/features/Admin/WalletSlice";

const FinancialReport = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [totalDebits, setTotalDebits] = useState(0);
  const { gettingAdminFinancialReport, adminFinancialReport } =
    useAppSelector(selectAdminWallet);

  useEffect(() => {
    const filteredWalletBalance: any = adminFinancialReport?.itemsList
      ?.map((x: any) => {
        return x.amount;
      })
      ?.reduce((prev: any, curr: any) => prev + curr, 0);
    setWalletBalance(filteredWalletBalance);

    const filteredCredits: any = adminFinancialReport?.itemsList
      ?.filter((x: any) => x?.transactionType === "credit")
      ?.map((x: any) => {
        return x.amount;
      })
      ?.reduce((prev: any, curr: any) => prev + curr, 0);
    setTotalCredits(filteredCredits);

    const filteredDebits: any = adminFinancialReport?.itemsList
      ?.filter((x: any) => x?.transactionType === "debit")
      ?.map((x: any) => {
        return x.amount;
      })
      ?.reduce((prev: any, curr: any) => prev + curr, 0);
    setTotalDebits(filteredDebits);
  }, [adminFinancialReport]);

  useEffect(() => {
    getAdminFinancialReport();
  }, []);

  return (
    <div>
      <SummaryContainer>
        <CashSummaryCard
          icon={<IoMdWallet />}
          title="Total Amount in Wallet"
          value={`₦ ${walletBalance}`}
          background="rgba(182, 3, 201, 0.25)"
          loading={gettingAdminFinancialReport}
        />
        <CashSummaryCard
          icon={<IoMdWallet />}
          title="Total Amount Credited"
          value={`₦ ${totalCredits}`}
          background="rgba(246, 185, 88,0.25)"
          loading={gettingAdminFinancialReport}
        />
        <CashSummaryCard
          icon={<IoMdWallet />}
          title="Total Amount Withdrawn"
          value={`₦ ${totalDebits}`}
          background="rgba(105, 199, 112, 0.25)"
          loading={gettingAdminFinancialReport}
        />
      </SummaryContainer>
      {/* <SearchBox /> */}
      <FinancialReportTable />
    </div>
  );
};

export default FinancialReport;
