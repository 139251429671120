import { FC, useEffect } from "react";
import NewAdButton from "./NewAdButton";
import PublishedAds from "./PublishedAds";
// import DraftAds from "./DraftAd";
import styled from "styled-components";
import { getAdverts } from "../../../../Apis/AdminApis/Adverts";
import PostAddModal from "../../../../Modals/Admin/PostAddModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import { selectAdminChurchAdvert } from "../../../../ReduxStore/features/Admin/AdvertSlice";

const IndexContainer = styled.div``;
const TopItems = styled.div`
  display: flex;
  border-bottom: 1px grey;
  box-shadow: rgba(27, 31, 35, 0.2) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  margin-top: -10px;
`;
const PublishedAd = styled.div`
  display: flex;
  color: #b603c9;
  margin-top: 50px;
  font-size: 20px;
  text-transform: uppercase;
`;

interface AdI {
  // setState: any;
}

const Index: FC<AdI> = () => {
  const { page, pageSize, creatingChurchAdvert } = useAppSelector(
    selectAdminChurchAdvert
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAdverts("", page, pageSize);
  }, [page, creatingChurchAdvert]);

  return (
    <>
      <IndexContainer>
        <TopItems>
          <NewAdButton
            text="Post a new Ad"
            handleClick={() => dispatch(handleModal(31))}
          />
        </TopItems>
        <PublishedAd>Published Adverts</PublishedAd>
        <PublishedAds />
        {/* <PublishedAd>Draft Adverts</PublishedAd>
        <DraftAds /> */}
      </IndexContainer>
      <PostAddModal />
    </>
  );
};

export default Index;
