import { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import Loader from "../Components/Loaders/MoonLoader";
import { media } from "../Screens";

interface IBG {
  bgColor: string;
}

export const Container = styled.div<IBG>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 136px;
  width: 100%;
  //   border-radius: 10px;
  filter: drop-shadow(0px 10px 20px rgba(32, 162, 160, 0.188));
  background: ${(props) => props.bgColor};
  background-opacity: 0.25;

  ${media.phone} {
    width: 100%;
    height: 100px;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 65px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.25);

  svg {
    font-size: 40px;
    color: ${theme.colors.secondaryColor};

    ${media.phone} {
      font-size: 30px;
    }
  }

  ${media.phone} {
    width: 40px;
    height: 50px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.secondaryColor};
`;

export const Value = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 20px;
  color: ${theme.colors.secondaryColor};

  ${media.phone} {
    font-size: 18px;
  }
`;

interface ICashSummaryCard {
  title: string;
  value: string;
  background: string;
  icon: any;
  loading?: boolean;
}

const CashSummaryCard: FC<ICashSummaryCard> = ({
  title,
  value,
  icon,
  background,
  loading,
}) => {
  return (
    <Container bgColor={background}>
      <LeftContent>{icon} </LeftContent>
      <RightContent>
        <Title>{title}</Title>
        {loading ? <Loader size="14px" color="#000" /> : <Value>{value}</Value>}
      </RightContent>
    </Container>
  );
};

export default CashSummaryCard;
