import styled from "styled-components";
import { media } from "./../../../../Screens";
import { theme } from "../../../../theme";

export const TopItemsWrap = styled.div`
  display: flex;
  width: 100%;
  // justify-content: space-between;
  flex-direction: column;
  margin-bottom: 50px;
  border-bottom: 1px solid ${theme.colors.lightBorderColor};
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  position: relative;
  overflow-x: auto;

  padding-bottom: 40px;

  ${media.phone} {
    width: 100%;
    gap: 20px;
    // align-items: center;
  }
`;
export const TopItems = styled.div`
  display: flex;
  gap: 20px;

  ${media.phone} {
    flex-wrap: wrap;
  }
`;
export const SermonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
  width: 100%;
  padding-bottom: 40px;
  margin-top: 10px;

  ${media.phone} {
    flex-direction: column;
    align-items: center;
    width: 320px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const SecondHeaderWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;

  ${media.phone} {
    width: 100%;
    height: 40px;
    // align-items: center;
  }
`;

export const VideoSermonCaption = styled.div`
  color: #b603c9;
  margin-top: 20px;
  width: 100%;
`;
export const AudioSermonCaption = styled.div`
  color: #b603c9;
  margin-top: 20px;
  width: 100%;
`;
export const VideoSermonCardWrap = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);

  ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NewSermonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  position: absolute;
  background: #f9f5f9;
  width: 800px;
  height: 700px;
  z-index: 1200;

  ${media.phone} {
    height: 1000px;
  }
`;
export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-left: 40px;
  width: 650px;
  background: #f3f3f3;
  padding-bottom: 20px;
  margin-left: 50px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

  ${media.phone} {
    width: 330px;
    height: 450px;
    margin-left: 20px;
    padding: 0px 10px;
  }

  .form {
    margin-top: 40px;

    ${media.phone} {
      margin-top: -50px;
    }
  }

  .titleinput {
    width: 550px;
    height: 40px;
    background: none;
    margin-top: 30px;
    border-radius: 4px;
    border: 1.5px solid grey;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding-left: 10px;

    ${media.phone} {
      width: 300px;
      // margin-left: -30px;
    }
  }

  .sermoninput {
    width: 550px;
    height: 100px;
    background: none;
    margin-top: 30px;
    border-radius: 5px;
    border: 1.5px solid grey;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding-left: 10px;
    padding-top: -100px;

    ${media.phone} {
      width: 300px;
      // margin-left: -30px;
    }
  }
`;

export const PubButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 550px;
  margin-top: 30px;

  .discard {
    color: #b603c9;
    cursor: pointer;
  }

  ${media.phone} {
    width: 300px;
  }
`;
export const PubButtonContainer = styled.div`
  display: flex;
  font-size: 10px;
  gap: 10px;
`;

export const NewSermonCaption = styled.div`
  display: flex;
  margin-left: 50px;
  margin-top: -15px;
  margin-bottom: 25px;
  color: #b603c9;
  font-weight: 500;

  ${media.phone} {
    margin-left: 20px;
  }
`;
export const UploadInstruction = styled.div`
  ${media.phone} {
    font-size: 8px;
  }
`;

export const VideoAudioWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 470px;
  margin-top: -20px;
  margin-left: 50px;
  background: black;
  position: absolute;
  z-index: 1300;
`;
export const VideoAudioContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 340px;
  height: 300px;
  background: white;

  .fatimeswrap {
    display: flex;
    position: absolute;
    border-radius: 50%;
    border: solid #383838;
    justify-content: center;
    align-items: center;
    margin-left: 360px;
    margin-top: 10px;
    cursor: pointer;
    opacity: 0.76;
    height: 15px;
    width: 15px;
  }

  .fatimes {
    height: 10px;
    width: 10px;
  }

  .input {
    margin-top: 40px;
    width: 200px;
    height: 30px;
    outline: none;
    background: none;
    border-radius: 4px;
    border: 1.5px solid grey;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  video {
    width: 100%;
    height: 100%;
  }
`;
export const VideoAudioCaption = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  color: #b603c9;
`;
