import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAppSelector } from "./ReduxStore/app/hooks";
import { selectUser } from "./ReduxStore/features/userSlice";
import AuthRoutes from "./Routes/AuthRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";
import FindChurch from "./Pages/Auth/Signup/FindChurch";
import { selectUserProfile } from "./ReduxStore/features/User/UserProfileSlice";

const Router = () => {
  const { currentUser } = useAppSelector(selectUser);
  const { userProfile } = useAppSelector(selectUserProfile);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={!currentUser?.userType  ? <AuthRoutes /> : <PrivateRoutes />}
        />
        {!userProfile && (
          <Route
            path="/auth/signup/role/member/find-church"
            element={<FindChurch />}
          />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
