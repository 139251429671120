import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setCreatingPrayerRequests,
  setCreatingTestimonies,
  setGettingPrayerRequests,
  setGettingTestimonies,
  setLikingPrayerRequests,
  setPrayerRequests,
  setTestimonies,
} from "../../ReduxStore/features/User/PrayerWallSlice";
import api from "../baseUrl";

export const createPayerRequest = async (
  churchId: string,
  userId: string,
  title: string,
  description: string
) => {
  store.dispatch(setCreatingPrayerRequests(true));
  const body: any = {
    churchId,
    userId,
    title,
    description,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("user/prayerrequest/create", body)
    .then((res) => {
      store.dispatch(setCreatingPrayerRequests(false));
      toast.success("Successful");
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingPrayerRequests(false));
    });
};

export const getAllPrayerRequest = async (
  churchId?: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingPrayerRequests(true));
  const params: any = {
    churchId,
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/prayerrequest", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setPrayerRequests(data));
      store.dispatch(setGettingPrayerRequests(false));
    })
    .catch((err) => {
      store.dispatch(setGettingPrayerRequests(false));
    });
};

export const likeOrDislikeRequest = async (prayerRequestId: string) => {
  store.dispatch(setLikingPrayerRequests(true));
  const body = {
    prayerRequestId,
  };
  await api
    .post("user/prayerrequest/likeOrDislike", body)
    .then((res: any) => {
      store.dispatch(setLikingPrayerRequests(false));
      toast.success(res?.data?.message);
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLikingPrayerRequests(false));
    });
};
export const getLikes = async (prayerRequestId: string) => {
  const params = {
    prayerRequestId,
  };
  const res = await api.get("user/prayerrequest/likes", { params });
  return res?.data;
};

export const getAllTestimonies = async (
  pageId?: number,
  perPage?: number,
  churchId?: string
) => {
  store.dispatch(setGettingTestimonies(true));
  const params: any = {
    pageId,
    perPage,
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/testimony", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setTestimonies(data));
      store.dispatch(setGettingTestimonies(false));
    })
    .catch((err) => {
      store.dispatch(setGettingTestimonies(false));
    });
};

export const createTestimony = async (
  churchId: string,
  userId: string,
  title: string,
  testimony: string
) => {
  store.dispatch(setCreatingTestimonies(true));
  const body: any = {
    churchId,
    userId,
    title,
    testimony,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("user/testimony/create", body)
    .then((res) => {
      store.dispatch(setCreatingTestimonies(false));
      toast.success("Successful");
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingTestimonies(false));
    });
};
