import { ChurchListWrap, EditButton } from "./styles";
import { FC, useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import { changeChurch } from "../../../../Apis/UserApis/userProfile";
import { findChurchToJoin } from "../../../../Apis/Auth/signupApis";
import { selectAuth } from "../../../../ReduxStore/features/authSlice";

interface IChurchList {
  setState: any;
}

const ChurchList: FC<IChurchList> = ({ setState }) => {
  const { editingUserProfile } = useAppSelector(selectUserProfile);
  const { foundChurches } = useAppSelector(selectAuth);
  const [churchId, setChurchId] = useState<any>();

  const handlwSwitch = () => {
    changeChurch(churchId);
    setState(false);
  };

  useEffect(() => {
    findChurchToJoin();
  }, []);

  return (
    <ChurchListWrap>
      <div>
        <select onChange={(e: any) => setChurchId(e.target.value)}>
          {foundChurches?.map((church: any) => (
            <option className="option" value={church?._id}>
              {church?.name}
            </option>
          ))}
        </select>
        <FaTimes
          className="fatime"
          onClick={() => setState(false)}
          color="#B603C9"
        />
      </div>
      <div>
        <EditButton onClick={handlwSwitch}>
          {editingUserProfile ? <Loader size={2} /> : "Save"}
        </EditButton>
      </div>
    </ChurchListWrap>
  );
};

export default ChurchList;
