import React, { FC } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "styled-components";
import { theme } from "../theme";
import { useAppDispatch } from "../ReduxStore/app/hooks";
import { handleModal } from "../ReduxStore/features/navSlice";
import { deleteAnnouncement } from "../Apis/AdminApis/churchAnnouncement";
import { setSelectedAnnouncements } from "../ReduxStore/features/Admin/AnnouncementSlice";
import moment from "moment";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 343px;
  height: auto;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.051));
  border-top: 3px solid ${theme.colors.primaryColor};
  padding: 20px;
  gap: 10px;
`;

const TopItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.secondaryColor};
`;
const Reading = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: ${theme.colors.secondaryColor};
`;

const Announcement = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.secondaryColor};
  margin-top: 10px;
`;

const BottonItems = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    font-size: 18px;
    color: ${theme.colors.textGreycolor};
    cursor: pointer;
  }
`;

interface IAnnouncementCard {
  details?: any;
}

const AdminAnnouncementCard: FC<IAnnouncementCard> = ({ details }) => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <TopItems>
        <Text>{details?.title}</Text>
        <Reading> To: {details?.isGeneral ? "All Members" : "Group"}</Reading>
        <Reading>
          Announced Date: {moment(details?.createdAt).format("Do MMM YYYY")}
        </Reading>
        <Announcement>{details?.message}</Announcement>
      </TopItems>
      <BottonItems>
        <FiEdit
          onClick={() => {
            dispatch(handleModal(9));
            dispatch(setSelectedAnnouncements(details));
          }}
        />
        <RiDeleteBin6Line
          onClick={() => {
            deleteAnnouncement(details?._id);
          }}
        />
      </BottonItems>
    </Container>
  );
};

export default AdminAnnouncementCard;
