import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import { media } from "../Screens";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.051));
  padding: 20px;
  border-radius: 5px;
  ${media.phone} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  gap: 10px;
`;

const TopItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const Date = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.secondaryColor};
`;
const User = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #151c4d;
`;

const Reading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #151c4d;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ReplyIcon = styled.div`
  color: #716172;
`;

const TestimonyTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #151c4d;
`;

interface ITestimonyCard {
  user: string;
  date: any;
  reading: string;
  title: string;
  replyIcon: any;
  setAdd?: any;
  setEdit?: any;
}

const TestimonyCard: FC<ITestimonyCard> = ({
  user,
  date,
  reading,
  replyIcon,
  title,
}) => {
  return (
    <Container>
      <TopItems>
        <User>{user}</User>
        <Date>{date}</Date>
      </TopItems>
      <TestimonyTitle>{title}</TestimonyTitle>
      <Reading>{reading}</Reading>
      <BottomSection>
        <ReplyIcon>{replyIcon}</ReplyIcon>
      </BottomSection>
    </Container>
  );
};

export default TestimonyCard;
