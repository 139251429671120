import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

let isRefreshing = false;
let refreshSubscribers: any[] = [];

const onRrefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

api.interceptors.request.use(
  (config: any) => {
    const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const token = user?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error: any) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          addRefreshSubscriber((token: string) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
      const refreshToken = user?.refreshToken;

      if (!refreshToken) {
        logoutUser();
        return Promise.reject(error);
      }
      const body = { refreshToken };

      try {
        const response: any = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}admin/auth/refresh`,
          body
        );

        const newToken = response.data.accessToken;
        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            ...user,
            refreshToken: newToken,
          })
        );

        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.response?.accessToken}`;
        isRefreshing = false;
        onRrefreshed(response?.data?.response?.accessToken);
        refreshSubscribers = [];

        return api(originalRequest);
      } catch (err) {
        logoutUser();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

const logoutUser = () => {
  // localStorage.clear();
  // window.location.href = "/"; // Redirect to the login page
};

export default api;
