import api from "../baseUrl";
import { toast } from "react-toastify";
import { setLoggingUser } from "../../ReduxStore/features/formSlice";
import { setUser } from "../../ReduxStore/features/userSlice";
import { store } from "../../ReduxStore/app/store";

import cryptojs from 'crypto-js'

const secretPass = "XkhZG4fW2t2W";

export const login = async (email: string, password: string) => {
 store.dispatch(setLoggingUser(true));
  const body = {
    email,
    password,
  };
  await api
    .post("user/auth/login", body)
    .then((res: any) => {
      const { data } = res;
     store.dispatch(setLoggingUser(false));
    store.dispatch(setUser(data?.data));
    window.location.href = "/"
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLoggingUser(false));
    });
};

export const loginCreateChurch = async (email: string, password: string) => {
  const body = {
    email,
    password,
  };
  await api
    .post("user/auth/login", body)
    .then((res: any) => {
      const loginDetails = JSON.parse(localStorage.getItem("signDeals") || "{}")
      const { data } = res;
      const adminCred = {
        token: data?.data?.token,
        email: email, 
        pass: cryptojs.AES.encrypt(
                JSON.stringify(password),
                secretPass
              ).toString(),
        firstName: loginDetails?.firstName,
        lastName: loginDetails?.lastName,
        phoneNo: loginDetails?.phoneNo}
      localStorage?.setItem("signDeals", JSON.stringify(adminCred));
      window.location.href = "/auth/signup/role/admin/create-church";
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const SuperAdminLogin = async (email: string, password: string) => {
  const body = {
    email,
    password,
  };
  await api
    .post("user/auth/admin-login", body)
    .then((res) => {
    })
    .catch((err) => {
    });
};

export const resetPasswordLink = async (
  email: string,
  password: string,
  hash: string
) => {
  const body = {
    email,
    password,
    hash,
  };
  await api
    .post("user/auth/password-reset-link", body)
    .then((res) => {
    })
    .catch((err) => {
    });
};
