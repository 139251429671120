import React from "react";
import Announcement from "./Announcement";

const index = () => {
  return (
    <div>
      <Announcement />
    </div>
  );
};

export default index;
