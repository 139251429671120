import { useEffect } from "react";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import { sermonConfigInnerNav } from "../../../../Utils/innerHeaderNavs";
import WallHeader from "./WallHeader";
import WrittenSermon from "./WrittenSermon";
import VideoSermon from "./VideoSermon";
import AudioSermon from "./AudioSermon";
import { IndexContainer } from "./styles";
import { getUserSermons } from "../../../../Apis/UserApis/media";
import { selectUserMedia } from "../../../../ReduxStore/features/User/MediaSlice";

const PrayerWall = () => {
  const { currentInnerNav } = useAppSelector(selectNav);
  const { page, pageSize, type } = useAppSelector(selectUserMedia);

  useEffect(() => {
    getUserSermons(type, page, pageSize);
  }, [page, type, pageSize]);

  return (
    <IndexContainer>
      <WallHeader listItems={sermonConfigInnerNav} />
      {currentInnerNav === 1 ? (
        <WrittenSermon />
      ) : currentInnerNav === 2 ? (
        <VideoSermon />
      ) : (
        <AudioSermon />
      )}
    </IndexContainer>
  );
};

export default PrayerWall;
