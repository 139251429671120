import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import { FiUsers } from "react-icons/fi";

const Container = styled.div`
  display: flex;
  align-items: center;
  //   justify-content: center;
  //   justify-content: space-between;
  padding: 10px;
  gap: 10px;
  height: 132px;
  width: 350px;
  background: ${theme.colors.bgWhite};
  gap: 20px;
  cursor: pointer;
`;

const LeftContent = styled.div`
  margin-right: 20px;
`;

const Avatar = styled.div`
  height: 66px;
  width: 66px;
  border-radius: 50%;
  background: #cfdbff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #4d79ff;
  text-transform: uppercase;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background: #9afca1;
    bottom: -5px;
    right: 10px;
  }
`;

const RightContent = styled.div`
  display: flex;
  // align-items: center;
  gap: 10px;
  flex-direction: column;
`;

const GroupName = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #383838;
`;

const GroupStatus = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  color: #383838;
  text-transform: capitalize;
`;

const MemberCount = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
`;

interface GroupCardI {
  groupName: string;
  groupStatus: string;
  memberCount: number | string;
  handleClick: any;
}

const GroupCardLayout: FC<GroupCardI> = ({
  groupName,
  groupStatus,
  memberCount,
  handleClick,
}) => {
  return (
    <Container onClick={handleClick}>
      <LeftContent>
        <Avatar>{groupName.slice(0, 2)}</Avatar>
      </LeftContent>
      <RightContent>
        <GroupName>{groupName}</GroupName>
        <GroupStatus
          className={`${groupStatus === "active" ? "text-green" : "text-red"}`}
        >
          {groupStatus}
        </GroupStatus>
        <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <FiUsers />
          <MemberCount>{memberCount} Members</MemberCount>
        </span>
      </RightContent>
    </Container>
  );
};

export default GroupCardLayout;
