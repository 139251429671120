import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface BookingState {
  gettingBookings: boolean;
  creatingBookings: boolean;
  approvingBookings: boolean;
  deletingBookings: boolean;
  updatingBookings: boolean;
  bookings: any;

  //   states

  // pagination
  page: number;
  pageSize: number;
}

const initialState: BookingState = {
  gettingBookings: false,
  creatingBookings: false,
  approvingBookings: false,
  deletingBookings: false,
  updatingBookings: false,
  bookings: null,

  //   state

  // pagination
  page: 1,
  pageSize: 30,
};

export const BookingSlice = createSlice({
  name: "Booking",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingBookings: (state, action) => {
      state.gettingBookings = action.payload;
    },
    setCreatingBookings: (state, action) => {
      state.creatingBookings = action.payload;
    },
    setApprovingBookings: (state, action) => {
      state.approvingBookings = action.payload;
    },
    setDeletingBookings: (state, action) => {
      state.deletingBookings = action.payload;
    },
    setUpdatingBookings: (state, action) => {
      state.updatingBookings = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    getAdminBookingDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  getAdminBookingDetails,
  setApprovingBookings,
  setBookings,
  setCreatingBookings,
  setDeletingBookings,
  setGettingBookings,
  setUpdatingBookings,
} = BookingSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminBooking = (state: RootState) => state.adminBooking;

export default BookingSlice.reducer;
