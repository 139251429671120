import styled from "styled-components";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import CreateEventModal from "../../../../Modals/Admin/CreateEventModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import ChurchEventTable from "../../../../Components/Tables/Admin/ChurchEventsTable";
import EditEventModal from "../../../../Modals/Admin/EditEventModal";
import { clearActivityFormData, selectAdminActivities } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import { useEffect } from "react";
import { getChurchEvents } from "../../../../Apis/AdminApis/ChurchActivities";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import { EventsContainer } from "../../User/ChurchActivity/styles";
import SingleEventCard from "./SingleEventCard";
import Pagintaor from "../../../../Components/Reusables/Pagintaor";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 0;
`;

const ManageEvents = () => {
  const dispatch = useAppDispatch();
  const { gettingChurchEvents, churchEvents, deletingEvents, creatingEvent } =
    useAppSelector(selectAdminActivities);

  useEffect(() => {
    getChurchEvents();
  }, [deletingEvents, creatingEvent]);

  return (
    <Container>
      <PrimaryButton
        text="Create Event"
        handleClick={(e: any) => {
          e.preventDefault();
          dispatch(handleModal(21));
          dispatch(clearActivityFormData())
        }}
      />
      {gettingChurchEvents ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <EventsContainer>
          {churchEvents?.itemsList?.length > 0 ? (
            <>
              {churchEvents?.itemsList?.map((event: any, index: number) => (
                <SingleEventCard
                  bannerUrl={event?.bannerUrl}
                  key={index + 1}
                  title={event?.title}
                  message={event?.message}
                  // location={event?.location}
                  allDay={event?.allDay}
                  repeat={event?.repeat}
                  liveStream={event?.liveStream}
                  inviteeType={event?.inviteeType}
                  startDate={event?.startDate}
                  endDate={event?.endDate}
                  item={event}
                />
              ))}
            </>
          ) : (
            <NoRecordDisplay
              src="/assets/images/svg/working-remotly.svg"
              message="No event at the moment"
            />
          )}
        </EventsContainer>
      )}{" "}
      <Pagintaor
        currentPage={churchEvents?.paginator?.currentPage}
        pageCount={churchEvents?.paginator?.pageCount}
        itemCount={churchEvents?.paginator?.itemCount}
        hasNextPage={churchEvents?.paginator?.hasNextPage}
        hasPrevPage={churchEvents?.paginator?.hasPrevPage}
      />
      <CreateEventModal />
      <EditEventModal />
    </Container>
  );
};

export default ManageEvents;
