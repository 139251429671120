import React, { FC } from "react";
import FaTimesButton from "../../../../Components/Form/Buttons/FaTimesButton";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { FaTimes } from "react-icons/fa";
// import PrimarySelector from "../../../../Components/Form/inputs/PrimarySelector";
// import TextInput from "../../../../Components/Form/inputs/TextInput";
import { AdsContainar, AdsContainerCaption } from "./style";
import "./styles.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import {
  getPrayerWallFormDetails,
  selectUserPrayerWall,
} from "../../../../ReduxStore/features/User/PrayerWallSlice";
import { CreatePrayerRequest } from "../../../../Utils/authInput";
import { TextArea } from "../../../../Components/Form/inputs/styles";
import TextInput from "../../../../Components/Form/inputs/TextInput";
import FormWrapper from "../../../../Layouts/FormWrapper";
import { createPayerRequest } from "../../../../Apis/UserApis/prayerRequests";
import { toast } from "react-toastify";

interface IAdd {
  setState: any;
}
const AddRequest: FC<IAdd> = ({ setState }) => {
  const { userProfile } = useAppSelector(selectUserProfile);
  const { creatingPrayerRequests, prayerTitle, prayerDescription } =
    useAppSelector(selectUserPrayerWall);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPrayerWallFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPrayerWallFormDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (prayerTitle && prayerDescription) {
      createPayerRequest(
        userProfile?.churchId?._id,
        userProfile?.userId?._id,
        prayerTitle,
        prayerDescription
      );
      setState(false);
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <AdsContainar>
      <FaTimesButton
        size="small"
        faTimes={<FaTimes />}
        handleClick={() => setState(false)}
      />
      <AdsContainerCaption>Add a Prayer Request</AdsContainerCaption>
      <FormWrapper>
        {CreatePrayerRequest?.map((input) => (
          <>
            {input?.type === "textarea" ? (
              <TextArea
                placeholder={input.placeholder}
                name={input?.name}
                onChange={handleTextAreaChange}
                value={input?.defaultValue()}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextInput
                  placeholder={input.placeholder}
                  type={input?.type}
                  name={input?.name}
                  handleChange={handleChange}
                  value={input?.defaultValue()}
                />
              </div>
            )}
          </>
        ))}
        <PrimaryButton
          text="Add"
          size="small"
          handleClick={(e: any) => {
            e.preventDefault();
            handleSubmit();
          }}
          loading={creatingPrayerRequests}
        />
      </FormWrapper>
    </AdsContainar>
  );
};

export default AddRequest;
