import styled from "styled-components";
import { media } from "./../../../../Screens";
import { PaystackButton } from "react-paystack";
import { theme } from "../../../../theme";

interface buttonI {
  outlined?: boolean;
  size?: string;
}

export const PaystackCustomButton = styled(PaystackButton)<buttonI>`
  outline: none;
  background: ${(props) =>
    props.outlined ? theme.colors.primaryColor : theme.colors.primaryColor};
  width: ${(props) =>
    props.size === "small"
      ? "139px"
      : props.size === "medium"
      ? "235px"
      : "398px"};
  height: 30px;
  color: ${(props) =>
    props.outlined ? theme.colors.bgWhite : theme.colors.textWhite};
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  border: ${(props) =>
    props.outlined ? `2px solid ${theme.colors.primaryColor}` : "none"};
  ${media.phone} {
    font-size: 14px;
    width: 90px;
    height: 30px;
  }

  &:focus {
    outline: 1px solid ${theme.colors.primaryColor};
  }
`;

export const ProPlanCardContainer = styled.div`
  display: grid;
  // grid-template-rows: 1fr 2fr 1fr;
  grid-gap: 20px;
  // flex-direction: column;
  // gap: 20px;
  padding: 20px 0px;
  width: 100%;
  // min-height: 450px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;

  ${media.phone} {
    width: 350px;
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #b603c9;
  margin: 10px 0 20px 0;
`;
export const PlanIntervalWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
export const Monthly = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #151c4d;
`;
export const Toggle = styled.div``;
export const Annual = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #151c4d;
`;
export const PlanValueWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 0px;
`;
export const PlanValue = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 35px;
  line-height: 65px;
  color: #151c4d;
`;
export const Interval = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 44px;
  color: #151c4d;
`;
export const Image = styled.img``;
export const FlexGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Features = styled.div`
  display: block;
`;
