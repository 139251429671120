import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserProfileState {
  gettingUserProfileDetails: boolean;
  gettingUserProfile: boolean;
  editingUserProfile: boolean;
  deletingUserAccount: boolean;
  userProfileDetails: any;
  userProfile: any;

  // profile State
  firstName: string;
  lastName: string;
  phoneNo: string;
  dob: string;
  gender: string;
  profileUrl: string;
  confirmDeletePassword: string;
}

const initialState: UserProfileState = {
  gettingUserProfileDetails: false,
  gettingUserProfile: false,
  editingUserProfile: false,
  deletingUserAccount: false,
  userProfileDetails: null,
  userProfile: null,

  firstName: "",
  lastName: "",
  phoneNo: "",
  dob: "",
  gender: "",
  profileUrl: "",
  confirmDeletePassword: "",
};

export const UserProfileSlice = createSlice({
  name: "UserProfile",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingUserProfileDetails: (state, action) => {
      state.gettingUserProfileDetails = action.payload;
    },
    setGettingUserProfile: (state, action) => {
      state.gettingUserProfile = action.payload;
    },
    setEditingUserProfile: (state, action) => {
      state.editingUserProfile = action.payload;
    },
    setDeletingUserAccount: (state, action) => {
      state.deletingUserAccount = action.payload;
    },
    setUserProfileDetails: (state, action) => {
      state.userProfileDetails = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    getUserProfileFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingUserProfile,
  setUserProfile,
  getUserProfileFormDetails,
  setEditingUserProfile,
  setDeletingUserAccount,
  setGettingUserProfileDetails,
  setUserProfileDetails
} = UserProfileSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserProfile = (state: RootState) => state.userProfile;

export default UserProfileSlice.reducer;
