import styled from "styled-components";
import { media } from "./../../../../Screens";
import { PaystackButton } from "react-paystack";
import { theme } from "../../../../theme";

interface buttonI {
  outlined?: boolean;
  size?: string;
}

export const PaystackCustomButton = styled(PaystackButton)<buttonI>`
  outline: none;
  background: ${(props) =>
    props.outlined ? theme.colors.primaryColor : theme.colors.primaryColor};
  width: ${(props) =>
    props.size === "small"
      ? "139px"
      : props.size === "medium"
      ? "235px"
      : "398px"};
  height: 30px;
  color: ${(props) =>
    props.outlined ? theme.colors.bgWhite : theme.colors.textWhite};
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  border: ${(props) =>
    props.outlined ? `2px solid ${theme.colors.primaryColor}` : "none"};
  ${media.phone} {
    font-size: 12px;
    width: 90px;
    height: 30px;
  }

  &:focus {
    outline: 1px solid ${theme.colors.primaryColor};
  }
`;

export const NewAdContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 1000px;
  height: 700px;
  background: #f9f5f9;
  top: 10px;

  ${media.phone} {
    width: 350px;
    height: 560px;
    margin-left: -30px;
    margin-top: -10px;
  }
`;

export const NewAdCaption = styled.div`
  display: flex;
  // margin-top: -20px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-left: 50px;
  color: #b603c9;
  z-index: 100;

  ${media.phone} {
    margin-top: 10px;
    margin-bottom: -5px;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  width: 100%;
  min-height: auto;
  padding: 20px 40px;
  gap: 5px;

  ${media.phone} {
    height: auto;
    margin-left: 0;
    padding: 10px;
  }

  label {
    margin-bottom: 10px;
  }

  .titleinput {
    width: 100%;
    height: 40px;
    background: none;
    border-radius: 4px;
    margin: 10px 0;
    border: 1.5px solid grey;
    outline: 1px solid ${theme.colors.primaryColor};
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding-left: 10px;
    outline: none;

    &:focus {
      outline: 1px solid ${theme.colors.primaryColor};
    }
  }
  .detailinput {
    width: 100%;
    height: 100px;
    background: none;
    border-radius: 5px;
    border: 1.5px solid grey;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding-left: 10px;
    outline: none;
    padding-top: -100px;

    &:focus {
      outline: 1px solid ${theme.colors.primaryColor};
    }

    ${media.phone} {
      // margin-left: -30px;
    }
  }
`;
export const UploadInstruction = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #151c4d;
`;
export const AdvertDuration = styled.div`
  display: flex;
  justify-content: space-between;
  width: 550px;
  margin-top: 20px;

  .durationcaption {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #151c4d;
  }
  .ammountdue {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #151c4d;
  }

  ${media.phone} {
    width: 300px;
  }
`;

export const AdDurationWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  .dueammount {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #151c4d;

    ${media.phone} {
      font-size: 15px;
    }
  }

  ${media.phone} {
    /* width: 300px; */
  }
`;
export const Duration = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
  .date {
    display: flex;
    border: 1.5px gray;
    padding: 10px 10px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background: transparent;
    outline: none;
    width: 100%;

    &:focus {
      outline: 1px solid ${theme.colors.primaryColor};
    }

    input {
      width: 100%;
    }
  }

  .smalldate {
    color: rgba(0, 0, 0, 0.6);

    ${media.phone} {
      font-size: 7px;
    }
  }
  ${media.tablet} {
    flex-direction: column;
  }
  label {
    margin-bottom: 10px;
  }
`;

export const PubButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 550px;
  margin-top: 30px;

  .discard {
    color: #b603c9;
    cursor: pointer;
  }

  ${media.phone} {
    width: 300px;
  }
`;

export const PubButtonContainer = styled.div`
  display: flex;
  font-size: 10px;
  gap: 10px;
`;
