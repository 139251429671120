import { useEffect, useState } from "react";
import { SermonContainer } from "./style";
import Loader from "../../../../Components/Loaders/MoonLoader";
import SermonCard from "./SermonCard";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { getSermons } from "../../../../Apis/AdminApis/Media";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectAdminChurchSermon } from "../../../../ReduxStore/features/Admin/SermonSlice";
import { theme } from "../../../../theme";

const WrittenSermon = () => {
  const {
    gettingAdminChurchSermons,
    adminChurchSermons,
    creatingChurchSermon,
  } = useAppSelector(selectAdminChurchSermon);
  const [writtenSermon, setWrittenSermon] = useState<any>();

  useEffect(() => {
    const writtenSermonFilter = adminChurchSermons?.itemsList?.filter(
      (x: any) => x.sermonType === "text"
    );
    setWrittenSermon(writtenSermonFilter);
  }, [adminChurchSermons]);

  return (
    <SermonContainer>
      {gettingAdminChurchSermons ? (
        <Loader color={theme.colors.primaryColor} size={20} />
      ) : writtenSermon?.length > 0 ? (
        writtenSermon?.map((item: any) => (
          <SermonCard
            title={item?.title}
            by="By:"
            pastor="Pastor Ken"
            sermon={item?.sermon}
          />
        ))
      ) : (
        <NoRecordDisplay
          message="Written Sermon not Available"
          src="/assets/images/svg/working-remotly.svg"
        />
      )}
    </SermonContainer>
  );
};

export default WrittenSermon;
