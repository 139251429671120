import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ManageChurchesState {
  gettingVerifiedChurches: boolean;
  verifiedChurches: any;
  gettingUnVerifiedChurches: boolean;
  unverifiedChurches: any;
  verifyingChurch: boolean;
}

const initialState: ManageChurchesState = {
  gettingVerifiedChurches: false,
  verifiedChurches: null,
  gettingUnVerifiedChurches: false,
  unverifiedChurches: null,
  verifyingChurch: false,
};

export const ManageChurchesSlice = createSlice({
  name: "ManageChurches",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingVerifiedChurches: (state, action) => {
      state.gettingVerifiedChurches = action.payload;
    },
    setVerifiedChurches: (state, action) => {
      state.verifiedChurches = action.payload;
    },
    setGettingUnVerifiedChurches: (state, action) => {
      state.gettingUnVerifiedChurches = action.payload;
    },
    setUnVerifiedChurches: (state, action) => {
      state.unverifiedChurches = action.payload;
    },
    setVerifyingChurch: (state, action) => {
      state.verifyingChurch = action.payload;
    },
  },
});

export const {
  setGettingVerifiedChurches,
  setVerifiedChurches,
  setGettingUnVerifiedChurches,
  setUnVerifiedChurches,
  setVerifyingChurch,
} = ManageChurchesSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminManageChurches = (state: RootState) =>
  state.superAdminManageChurches;

export default ManageChurchesSlice.reducer;
