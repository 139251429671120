import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setCreatingDailyScriptures,
  setDailyScriptures,
  setDeletingDailyScriptures,
  setEditingDailyScriptures,
  setGettingChurchPrayer,
  setGettingDailyScriptures,
} from "../../ReduxStore/features/User/DailyScripturesSlice";
import api from "../baseUrl";
import { handleModal } from "../../ReduxStore/features/navSlice";
import { setChurchPrayers } from "../../ReduxStore/features/Admin/ChurchPrayerWallSlice";

export const getUserDailyScriptures = async (
  pageId?: number,
  perPage?: number
) => {
  store.dispatch(setGettingDailyScriptures(true));
  const params: any = { pageId, perPage };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/dailyscripture", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setDailyScriptures(data?.data));
      store.dispatch(setGettingDailyScriptures(false));
    })
    .catch((err) => {
      store.dispatch(setGettingDailyScriptures(false));
    });
};

export const createDailyScripture = async (
  bibleName: string,
  chapter: number,
  verse: number,
  scriptureDate: string
) => {
  store.dispatch(setCreatingDailyScriptures(true));
  const body = {
    bibleName,
    chapter,
    verse,
    scriptureDate,
  };
  await api
    .post("user/church/dailyscripture", body)
    .then((res) => {
      store.dispatch(setCreatingDailyScriptures(false));
      toast.success("successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingDailyScriptures(false));
    });
};

export const deleteDailyReflection = async (scriptureId: string) => {
  store.dispatch(setDeletingDailyScriptures(true));
  const data = {
    scriptureId,
  };
  await api
    .delete("user/church/dailyscripture", { data })
    .then((res) => {
      store.dispatch(setDeletingDailyScriptures(false));
      toast.success("successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingDailyScriptures(false));
    });
};

export const editDailyScriptures = async (
  bibleName: string,
  chapter: number,
  verse: number,
  scriptureDate: string,
  scriptureId: string
) => {
  store.dispatch(setEditingDailyScriptures(true));
  const body = {
    bibleName,
    chapter,
    verse,
    scriptureDate,
    scriptureId,
  };
  await api
    .put("user/church/dailyscripture", body)
    .then((res) => {
      store.dispatch(setEditingDailyScriptures(false));
      toast.success("successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingDailyScriptures(false));
    });
};

export const getChurchPrayers = async (pageId?: number, perPage?: number) => {
  store.dispatch(setGettingChurchPrayer(true));
  const params: any = { pageId, perPage };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/dailyscripture", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setChurchPrayers(data?.data));
      store.dispatch(setGettingChurchPrayer(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchPrayer(false));
    });
};

export const createChurchPrayer = async (
  bibleName: string,
  chapter: number,
  verse: number,
  scriptureDate: string
) => {
  store.dispatch(setCreatingDailyScriptures(true));
  const body = {
    bibleName,
    chapter,
    verse,
    scriptureDate,
  };
  await api
    .post("user/church/dailyscripture", body)
    .then((res) => {
      store.dispatch(setCreatingDailyScriptures(false));
      toast.success("successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingDailyScriptures(false));
    });
};
