import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { BiSolidDislike, BiSolidLike } from "react-icons/bi";
import { FaCommentDots } from "react-icons/fa";
import styled from "styled-components";
import { commentPOst, likeAndDislikePOst } from "../Apis/UserApis/Post";
import { useAppDispatch, useAppSelector } from "../ReduxStore/app/hooks";
import { selectUser } from "../ReduxStore/features/userSlice";
import {
  getPostFormDetails,
  selectUserPost,
} from "../ReduxStore/features/User/UserPostSlice";
import { theme } from "../theme";
import Loader from "../Components/Loaders/MoonLoader";
import { BsCheck2All } from "react-icons/bs";
import { TiCancel } from "react-icons/ti";
import { IoMdMore, IoMdShare } from "react-icons/io";
import { handleModal } from "../ReduxStore/features/navSlice";
import { format } from "timeago.js";

// Define styles for the Post component using styled-components
const PostWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-height: 500px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Username = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 1tpx;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Content = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Media = styled.div`
  margin-bottom: 10px;

  img {
    width: 100%;
    border-radius: 8px;
    max-height: 500px;
  }

  video {
    width: 100%;
    border-radius: 8px;
    max-height: 500px;
  }

  audio {
    width: 100%;
    border-radius: 8px;
    max-height: 500px;
  }
`;

const HasTags = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
  font-size: 14px;
  color: #1da1f2;
  margin-bottom: 10px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 7px;
  font-size: 14px;
  cursor: pointer;
`;

const ActionIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 5px;
`;

const CommentSection = styled.div`
  margin-top: 20px;
`;

interface CommentFormProp {
  show: boolean;
}

const CommentForm = styled.form<CommentFormProp>`
  display: ${(props) => (props.show ? "block" : "none")};
  margin-top: 10px;
`;

const CommentInput = styled.input`
  width: calc(100% - 20px);
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const CommentButton = styled.button`
  padding: 8px 16px;
  background-color: ${theme.colors.primaryColor};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Post component definition

const Comment = styled.div`
  display: flex;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
`;

const CommentAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const CommentContent = styled.div`
  flex: 1;
`;

const CommentUsername = styled.div`
  font-weight: bold;
`;

const CommentDate = styled.div`
  font-size: 12px;
  color: #999;
`;

const ApproveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: flex-end;
`;
const ApproveButton = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.primaryColor};
  font-size: 18px;
  gap: 10px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: ${theme.colors.primaryColor};
  }

  span {
    text-decoration: underline;
  }
`;

const MoreVertDropdown = styled.div`
  position: relative;

  svg {
    font-size: 18px;
    cursor: pointer;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    right: 100%;
    min-width: 100px;
    /* min-height: 100px; */
    padding: 10px;
    border-radius: 5px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  }

  .option {
    cursor: pointer;
    font-size: 16px;
  }
`;

interface PostProps {
  postId: string;
  username: string;
  avatar: string;
  content: string;
  title: string;
  contentType: string;
  media: any;
  likes: any;
  disLikes: any;
  comments: any;
  hashTags: any;
  createdAt: string;
  approveFunction?: any;
  declineFunction?: any;
  postType?: string;
}

const SinglePostCard: FC<PostProps> = ({
  postId,
  username,
  avatar,
  content,
  media,
  likes,
  comments,
  contentType,
  disLikes,
  hashTags,
  title,
  createdAt,
  approveFunction,
  declineFunction,
  postType,
}) => {
  const { currentUser } = useAppSelector(selectUser);
  const [userLiked, setUserLiked] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const { comment, commentingUserPost } = useAppSelector(selectUserPost);
  const [showMoreVert, setShowMoreVert] = useState(false);
  const dispatch = useAppDispatch();

  const toggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleCommentChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(getPostFormDetails({ name, value }));
  };

  const handleSubmitComment = (e: any) => {
    e.preventDefault();
    commentPOst(postId, comment).then(() => setShowCommentInput(false));
  };

  useEffect(() => {
    const postLikedByMe = likes.filter(
      (x: any) => x.user === currentUser?.roles[0]?.userId
    );
    if (postLikedByMe?.length > 0) {
      setUserLiked(true);
    }
    const postDislikedByMe = disLikes.filter(
      (x: any) => x.user === currentUser?.roles[0]?.userId
    );
    if (postDislikedByMe?.length > 0) {
      setUserLiked(false);
    }
  }, [likes, disLikes, currentUser?.roles]);

  return (
    <PostWrapper>
      <HeaderWrapper>
        <Header>
          <Avatar src={avatar} alt="User Avatar" />
          <Username>{username}</Username>
        </Header>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>{format(createdAt)}</div>
          {postType === "myPost" && (
            <MoreVertDropdown>
              <IoMdMore onClick={() => setShowMoreVert(!showMoreVert)} />
              {showMoreVert && (
                <div className="options">
                  <p
                    className="option"
                    onClick={() => {
                      setShowMoreVert(false);
                      dispatch(
                        getPostFormDetails({ name: "postId", value: postId })
                      );
                      dispatch(handleModal(5));
                    }}
                  >
                    Edit
                  </p>
                </div>
              )}
            </MoreVertDropdown>
          )}
        </div>
      </HeaderWrapper>

      <Title>{title}</Title>
      <Content>{content}</Content>
      {media && (
        <Media>
          {contentType === "image" && <img src={media} alt="Media" />}
          {contentType === "video" && <video src={media} controls />}
          {contentType === "audio" && (
            <audio controls>
              <source src={media} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          )}
        </Media>
      )}
      <HasTags>
        {hashTags?.map((hashTag: any, index: number) => (
          <span>{hashTag}</span>
        ))}
      </HasTags>
      <Actions>
        <ActionIcon onClick={() => likeAndDislikePOst(postId, "like")}>
          <BiSolidLike color={userLiked ? "#4CAF50" : "#000"} />
          <span>{likes?.length}</span>
        </ActionIcon>
        <ActionIcon onClick={() => likeAndDislikePOst(postId, "dislike")}>
          <BiSolidDislike color={!userLiked ? "#F44336" : "#000"} />
          <span>{disLikes?.length}</span>
        </ActionIcon>

        <ActionIcon onClick={toggleCommentInput}>
          <FaCommentDots />
          <span>{comments?.length}</span>
        </ActionIcon>
        <ActionIcon onClick={toggleCommentInput}>
          <IoMdShare onClick={() => dispatch(handleModal(6))} />
        </ActionIcon>
      </Actions>

      <CommentSection>
        <h3>Comments</h3>
        <CommentForm show={showCommentInput} onSubmit={handleSubmitComment}>
          <CommentInput
            type="text"
            placeholder="Write a comment..."
            value={comment}
            name="comment"
            onChange={handleCommentChange}
          />
          <CommentButton type="submit">
            {commentingUserPost ? <Loader size={10} /> : "Submit"}
          </CommentButton>
        </CommentForm>

        {comments?.slice(0, 2)?.map((comment: any, index: any) => (
          <Comment key={index}>
            <CommentAvatar
              src={
                comment.avatar ||
                "https://th.bing.com/th?id=OIP.L8bs33mJBAUBA01wBfJnjQHaHa&w=250&h=250&c=8&rs=1&qlt=30&o=6&pid=3.1&rm=2"
              }
              alt="Commenter Avatar"
            />
            <CommentContent>
              <CommentUsername>{comment.username}</CommentUsername>
              <div>{comment.body}</div>
              <CommentDate>
                {moment(comment?.date).startOf("hour").fromNow()}
              </CommentDate>
            </CommentContent>
          </Comment>
        ))}
      </CommentSection>

      {approveFunction && declineFunction && (
        <ApproveButtonContainer>
          <ApproveButton onClick={approveFunction}>
            <BsCheck2All />
            <span>Approve</span>
          </ApproveButton>
          <ApproveButton onClick={approveFunction}>
            <TiCancel />
            <span>Decline</span>
          </ApproveButton>
        </ApproveButtonContainer>
      )}
    </PostWrapper>
  );
};

export default SinglePostCard;
