export const superAdminUserManagerTabs = [
  {
    id: 1,
    tabName: "Church Members",
  },
  {
    id: 2,
    tabName: "Church Admins",
  },
];

export const AdminChurchesManagerTabs = [
  {
    id: 1,
    tabName: "Verified Churches",
  },
  {
    id: 2,
    tabName: "Unverified Churches",
  },
];
export const AdminChurchesDonationsTabs = [
  {
    id: 1,
    tabName: "All Donations",
  },
  {
    id: 2,
    tabName: "Create Donation",
  },
];

export const AdminReportTabs = [
  {
    id: 1,
    tabName: "Financial Report",
  },
  {
    id: 2,
    tabName: "Membership Report",
  },
];

export const AdminUserManagerTabs = [
  {
    id: 1,
    tabName: "Church Admins",
  },
  // {
  //   id: 3,
  //   tabName: "Church Members",
  // },
];

export const SuperAdminReportTabs = [
  {
    id: 1,
    tabName: "Financial Report",
  },
  {
    id: 2,
    tabName: "Membership Report",
  },
];
export const SuperAdminBookingTabs = [
  {
    id: 1,
    tabName: "Thanks Giving ",
  },
  {
    id: 2,
    tabName: "Marraige Counselling",
  },
  {
    id: 3,
    tabName: "Others",
  },
];

export const churchActivitiesTabs = [
  {
    id: 1,
    tabName: "Activities/Events",
  },
  {
    id: 2,
    tabName: "Manage Activities",
  },
  {
    id: 3,
    tabName: "Manage Events",
  },
  {
    id: 4,
    tabName: "Manage Medium",
  },
  {
    id: 5,
    tabName: "LiveStreams",
  },
];


export const postsTabs = [
  {
    id: 1,
    tabName: "All",
  },
  {
    id: 2,
    tabName: "Pending",
  },
  {
    id: 3,
    tabName: "Declined",
  },
];