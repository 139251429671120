import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../../../Components/Loaders/MoonLoader";
import moment from "moment";

const Container = styled.a`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  align-items: center;
  height: auto;
  gap: 20px;
  background-color: ${theme.colors.bgWhite};
  padding: 10px;
  //   border-radius: 10px;
  border-top: 3px solid ${theme.colors.primaryColor};
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: ${theme.colors.textBlack};
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
const Title = styled.p`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0.5;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 1.05rem;
`;
const Description = styled.p``;
const Date = styled.p`
  flex: 0.5;
  font-weight: semi-bold;
  color: ${theme.colors.textGreycolor};
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  svg {
    font-size: 1rem;
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
`;
const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Bottom = styled.div`
  display: flex;
  width: 100%;
`;

interface PostCardI {
  imageUrl?: string;
  title?: string;
  link?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  handleDelete?: any;
  deleting?: any;
  customStyle?: any;
}

const PostCard: FC<PostCardI> = ({
  imageUrl,
  title,
  link,
  description,
  startDate,
  endDate,
  deleting,
  handleDelete,
  customStyle,
}) => {
  return (
    <Container href={link} style={customStyle}>
      <Top>
        <Title>{title}</Title>
        <Date>
          <span>
            {moment(startDate).format("Do MMM YYYY")} --{" "}
            {moment(endDate).format("Do MMM YYYY")}
          </span>
          {deleting ? (
            <Loader size={10} color={theme?.colors?.primaryColor} />
          ) : (
            <>
              {handleDelete && (
                <RiDeleteBin6Line className="text-red" onClick={handleDelete} />
              )}
            </>
          )}
        </Date>
      </Top>
      <Center>
        <Image src={imageUrl} />
      </Center>
      <Bottom>
        <Description>{description}</Description>
      </Bottom>
    </Container>
  );
};

export default PostCard;
