import React, { FC, useState } from "react";
import Advert from "./Advert";
import { FaCheck } from "react-icons/fa";
import { ToggleSwitch } from "./Switch";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectPayment } from "../../../../ReduxStore/features/paymentSlice";
import {
  Annual,
  Features,
  FlexGroup,
  Image,
  Interval,
  Monthly,
  PlanIntervalWrap,
  PlanValue,
  PlanValueWrap,
  ProPlanCardContainer,
  Title,
  Toggle,
} from "./style";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import PaystackButton from "../../../../Components/Paystack/PaystackButton";
import { selectAdminActivities } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";

interface ProPlan {
  details: any;
  loading: boolean;
}

const ProPlanCard: FC<ProPlan> = ({ details, loading }) => {
  const [switchDuration, setSwitchDuration] = useState(false);
  const { makingPayment } = useAppSelector(selectPayment);
  const { adminChurch } = useAppSelector(selectAdminActivities);

  return (
    <ProPlanCardContainer>
      <FlexGroup>
        <Image src="/assets/images/svg/upgrade.svg" />
        <Title>{details?.title}</Title>
      </FlexGroup>
      <FlexGroup>
        <PlanIntervalWrap>
          <Monthly>Monthly</Monthly>
          <Toggle>
            <ToggleSwitch
              switchState={switchDuration}
              handleChange={() => setSwitchDuration(!switchDuration)}
            />
          </Toggle>
          <Annual>Annually</Annual>
        </PlanIntervalWrap>
        <PlanValueWrap>
          <PlanValue>
            {loading ? (
              <Loader size="10px" color={theme.colors.primaryColor} />
            ) : (
              "₦ " +
              `${switchDuration ? 12 * details?.amount : details?.amount}`
            )}
          </PlanValue>
          /
          <Interval>
            {loading ? (
              <Loader size="10px" color={theme.colors.primaryColor} />
            ) : (
              `${switchDuration ? 12 * details?.durationInDays : details?.durationInDays}`
            )}{" "}
            Days
          </Interval>
        </PlanValueWrap>
      </FlexGroup>
      <FlexGroup>
        <Features>
          {details?.features?.map((feature: any, index: any) => (
            <Advert icon={<FaCheck />} advertType={feature} />
          ))}
        </Features>
      </FlexGroup>
      <FlexGroup>
        <PaystackButton
          amount={switchDuration ? 12 * details?.amount : details?.amount}
          btnText="Upgrade Now"
          loading={makingPayment}
          paymentType="subscription"
          email={adminChurch?.email}
        />
      </FlexGroup>
    </ProPlanCardContainer>
  );
};

export default ProPlanCard;
