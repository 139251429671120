import AuthMain from "../AuthMain";
import {
  AuthLogo,
  AuthTitle,
  AuthSubTitle,
  AuthSmallText,
  AuthLink,
} from "../style";
import FormWrapper from "../../../Layouts/FormWrapper";
import TextInput from "../../../Components/Form/inputs/TextInput";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../ReduxStore/features/authSlice";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import { verifyEmail } from "../../../Apis/Auth/signupApis";
import { useEffect, useState } from "react";
import ErrorMessage from "../../../Components/Reusables/MessagePopups/ErrorMessage";
import { resentAdminOtp } from "../../../Apis/Auth/resetPassword";
import { selectForm } from "../../../ReduxStore/features/formSlice";
const AdminVerifyOTP = () => {
  const dispatch = useAppDispatch();
  const { otp, verifyingEmail } = useAppSelector(selectAuth);
  const { registerEmail } = useAppSelector(selectForm);
  const [errors, setErrors] = useState<any>([]);

  // State for managing the resend OTP button and timer
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);

  const handleResendOTP = () => {
    resentAdminOtp(registerEmail);
    setIsResendDisabled(true); // Disable the button
    setCountdown(60); // Start countdown from 60 seconds
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  const handleSubmit = (e: any) => {
    setErrors([]);
    e.preventDefault();
    if (!registerEmail) {
      setErrors([...errors, "Email cannot be empty"]);
    } else if (!otp) {
      setErrors([...errors, "OTP cannot be empty"]);
    } else {
      verifyEmail(registerEmail, otp, "admin");
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isResendDisabled && countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => (prev ? prev - 1 : 0));
      }, 1000);
    }

    if (countdown === 0) {
      setIsResendDisabled(false);
      setCountdown(null);
    }

    return () => clearInterval(timer);
  }, [isResendDisabled, countdown]);

  return (
    <AuthMain>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Verify OTP</AuthTitle>
      <AuthSubTitle>Verify your otp to complete signup</AuthSubTitle>
      <FormWrapper>
        <TextInput
          placeholder="Enter email"
          handleChange={handleChange}
          name="registerEmail"
          type="text"
          value={registerEmail}
          disabled
        />
        <TextInput
          placeholder="Enter OTP"
          handleChange={handleChange}
          name="otp"
          type="text"
          value={otp}
        />
        {errors?.length > 0 && (
          <ErrorMessage
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {errors?.map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
        <PrimaryButton
          text="Verify"
          handleClick={handleSubmit}
          loading={verifyingEmail}
        />
      </FormWrapper>
      <AuthSmallText>
        <AuthLink
          as="button"
          onClick={handleResendOTP}
          disabled={isResendDisabled}
          style={{
            cursor: isResendDisabled ? "not-allowed" : "pointer",
            opacity: isResendDisabled ? 0.6 : 1,
            background: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          {isResendDisabled ? `Resend OTP (${countdown}s)` : "Resend OTP"}
        </AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default AdminVerifyOTP;
