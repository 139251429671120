import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  clearGroupState,
  setChurchGroups,
  setCreatingChurchGroup,
  setDeletingChurchGroup,
  setDeletingChurchGroupMember,
  setEditingChurchGroup,
  setGettingChurchGroups,
  setGroupsDetails,
  setInvitingGroupMember,
} from "../../ReduxStore/features/Admin/GroupSlice";
import api from "../baseUrl";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const createGroup = async (
  churchId: string,
  name: string,
  joinStatus: string,
  description: string,
  memberId: string[]
) => {
  store.dispatch(setCreatingChurchGroup(true));
  const body: any = {
    churchId,
    name,
    joinStatus,
    description,
    memberId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("user/church/church-group", body)
    .then((res) => {
      store.dispatch(setCreatingChurchGroup(false));
      store.dispatch(handleModal(0));
      toast.success("Successsful");
      store.dispatch(clearGroupState());
    })
    .catch((err) => {
      store.dispatch(setCreatingChurchGroup(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getGroupList = async (churchId?: string) => {
  store.dispatch(setGettingChurchGroups(true));
  const params: any = {
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/church-groups", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setChurchGroups(data?.data));
      store.dispatch(setGettingChurchGroups(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchGroups(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const updateGroup = async (
  churchGroupId: string,
  description: string,
  name: string
) => {
  store.dispatch(setEditingChurchGroup(true));
  const body: any = {
    churchGroupId,
    description,
    name,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .put("user/church/church-group", body)
    .then((res) => {
      store.dispatch(setEditingChurchGroup(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      store.dispatch(setEditingChurchGroup(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getSingleGroup = async (churchGroupId: string) => {
  // store.dispatch(setGettingGroupsDetails(true));
  const params: any = {
    churchGroupId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === (null || "")) {
      delete params[key];
    }
  });
  await api
    .get("user/church/church-group/single", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setGroupsDetails(data));
      // store.dispatch(setGettingGroupsDetails(false));
    })
    .catch((err) => {
      // store.dispatch(setGettingGroupsDetails(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const toggleGroup = async (churchGroupId: string) => {
  const body = {
    churchGroupId,
  };
  await api
    .put("church/church-group/toggle", body)
    .then((res) => {})
    .catch((err) => {});
};

export const deleteGroup = async (churchGroupId: string) => {
  store.dispatch(setDeletingChurchGroup(true));
  const params = {
    id: churchGroupId,
  };
  await api
    .delete(`admin/church-groups/${churchGroupId}`, { params })
    .then((res) => {
      store.dispatch(setDeletingChurchGroup(false));
      toast.success("Successful");
      window.location.reload();
    })
    .catch((err) => {
      store.dispatch(setDeletingChurchGroup(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const inviteMember = async (
  inviteeEmail: string,
  churchGroupId: string
) => {
  store.dispatch(setInvitingGroupMember(true));
  const body = {
    inviteeEmail,
    churchGroupId,
  };
  await api
    .post(`admin/church-groups/${churchGroupId}/invites`, body)
    .then((res) => {
      store.dispatch(setInvitingGroupMember(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      store.dispatch(setInvitingGroupMember(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const deleteGroupMember = async (
  memberId: string,
  churchGroupId: string
) => {
  store.dispatch(setDeletingChurchGroupMember(true));
  // const params = {
  //   id: churchGroupId,
  //   churchGroupId,
  //   memberId,
  // };
  await api
    .delete(`admin/church-groups/${churchGroupId}/members/${memberId}`)
    .then((res) => {
      store.dispatch(setDeletingChurchGroupMember(false));
      toast.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setDeletingChurchGroupMember(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};
