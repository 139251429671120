import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface paymentState {
  makingPayment: boolean;
  gettingWalletBalance: boolean;
  walletBalance: any;
}

const initialState: paymentState = {
  makingPayment: false,
  gettingWalletBalance: false,
  walletBalance: null,
};

export const paymentSlice = createSlice({
  name: "Payment",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMakingPayment: (state, action) => {
      state.makingPayment = action.payload;
    },
    setGettingWalletBalance: (state, action) => {
      state.gettingWalletBalance = action.payload;
    },
    setwalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
  },
});

export const { setMakingPayment, setGettingWalletBalance, setwalletBalance } =
  paymentSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPayment = (state: RootState) => state.payment;

export default paymentSlice.reducer;
