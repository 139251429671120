import styled from "styled-components";
import { media } from "../../../../../Screens";

export const LeftContentContainer = styled.div`
  flex: 0.2;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 0px 1px rgb(140 140 140/0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  position: sticky;
  top: 0;
  gap: 20px;

  ${media.phone} {
    display: none;
  }
`;

export const UserProfile = styled.div`
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  h3 {
    margin: 0;
  }
`;

export const ImageAdsContainer = styled.div`
  width: 100%;
`;
