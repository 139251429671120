import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface PostState {
 approvingAdminPost: boolean;
  gettingPendingPosts: boolean;
  pendingPosts: any;
  gettingDeclinedPosts: boolean;
  declinedPosts: any;
  gettingSinglePost: any;
  singlePost: any;

  // posts states
  postId: string;
  churchId: string;
  contentType: string;
  hashtag: string;
  title: string;
  description: string;
  hashtags: string[];
  file: any;
  action: string;
  comment: string;
  pageSize: number;
  page: number;
}

const initialState: PostState = {
  approvingAdminPost: false,
  gettingPendingPosts: false,
  pendingPosts: null,
  gettingDeclinedPosts: false,
  declinedPosts: null,
  gettingSinglePost: null,
  singlePost: null,

  // post states
  postId: "",
  churchId: "",
  contentType: "",
  hashtag: "",
  title: "",
  description: "",
  hashtags: [],
  file: null,
  action: "",
  comment: "",
  pageSize: 30,
  page: 1,
};

export const PostSlice = createSlice({
  name: "Post",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setApprovingAdminPost: (state, action) => {
      state.approvingAdminPost = action.payload;
    },
    setGettingPendingAdminPost: (state, action) => {
      state.gettingPendingPosts = action.payload;
    },
    setPendingAdminPost: (state, action) => {
      state.pendingPosts = action.payload;
    },
    setGettingDeclinedPosts: (state, action) => {
      state.gettingDeclinedPosts = action.payload;
    },
    setDeclinedPosts: (state, action) => {
      state.declinedPosts = action.payload;
    },
    setGettingSinglePost: (state, action) => {
      state.gettingSinglePost = action.payload;
    },
    setSinglePost: (state, action) => {
      state.singlePost = action.payload;
    },
    getPostFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  getPostFormDetails,
  setGettingDeclinedPosts,
  setGettingSinglePost,
  setDeclinedPosts,
  setPendingAdminPost,
  setApprovingAdminPost,
  setSinglePost,
  setGettingPendingAdminPost,
} = PostSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminPost = (state: RootState) => state.adminPosts;

export default PostSlice.reducer;
