import React from "react";
import ChurchMemberTable from "../../../../Components/Tables/SuperAdmin/ChurchMemberTable";
// import { useAppSelector } from "../../../../ReduxStore/app/hooks";
// import { selectNav } from "../../../../ReduxStore/features/navSlice";
// import TabSwitch from "./../../../../Components/Nav/TabSwitch";
// import { superAdminUserManagerTabs } from "./../../../../Utils/tabsNav";
// import ChurchAdminsTable from "./../../../../Components/Tables/Admin/ChurchAdminTable";

const UserManager = () => {
  // const { currentTab } = useAppSelector(selectNav);

  return (
    <>
      {/* <TabSwitch listItems={superAdminUserManagerTabs} />
      {currentTab === 1 ? <ChurchMemberTable /> : <ChurchAdminsTable />} */}
      <ChurchMemberTable />
    </>
  );
};

export default UserManager;
