import styled from "styled-components";
import { theme } from "../../../../theme";
import { media } from "./../../../../Screens";

export const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.phone} {
    overflow: hidden;
  }
`;

export const InnerHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 60%;
  margin-left: -20%;
  padding: 0 50px;
  gap: 30px;
  // position: absolute;
  margin-top: 4%;
  left: 0;
  border-bottom: 1px solid;
  ${media.phone} {
    margin-left: 30px;
  }
`;

export const InnerNavlink = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: ${theme.colors.secondaryColor};
  cursor: pointer;
  text-decoration: none;
  position: relative;
  width: auto;
  height: 100%;
  text-align: center;
  padding: 10px;

  &.active {
    color: ${theme.colors.primaryColor};
  }

  // &.active::after {
  //   content: "";
  //   background: ${theme.colors.primaryColor};
  //   width: 100%;
  //   height: 4px;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  // }

  ${media.phone} {
    font-size: 16px;
    padding-bottom: 10px;
  }
`;

export const VideoWrap = styled.div`
  width: 100%;
  heigh: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const PlayerContainer = styled.div`
  margin-left: -20%;
  margin-top: 5px;
  width: 370px;
  height: 200px;
  background: #ffffff;
  ${media.phone} {
    width: 300px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
`;

export const Player = styled.div`
  margin-left: 10%;
  margin-top: -50%;
  ${media.phone} {
    margin-top: -60%;
  }
`;

export const Prompt = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #b603c9;
  display: flex;
  margin-left: -24%;
`;

export const VideoGlass = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const VideoDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
  //  position: absolute;
  margin-left: 2%;
  margin-top: 40%;
`;
export const VideoCaption = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #151c4d;
  ${media.phone} {
    margin-top: 30px;
  }
`;
export const VideoAuthor = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 13px;
  color: #151c4d;
  margin-bottom: 5px;
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 650px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  font-size: 10px;
  margin-top: -150%;
  ${media.phone} {
    display: none;
  }
`;

export const VideoSermonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  div.ad {
    position: absolute;
    margin-left: 800px;
    margin-top: -1170px;
    border: 2px solid;
    height: 173px;
    width: 173px;
    border-radius: 5%;
    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }
  ${media.phone} {
    margin-left: -30px;
    overflow: hidden;
  }
`;

export const VideoSermonEventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  box-shadow: 0.5px 2px 1px 0.5px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 800px;
  margin-top: -1600px;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;
export const VideoAdText = styled.div`
  position: absolute;
  margin-left: 690px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  font-size: 10px;
  margin-top: -1320px;
  ${media.phone} {
    display: none;
  }
`;
export const VideoSermonGlass = styled.div`
  margin-left: -130px;
  display: flex;
  ${media.phone} {
    margin-left: 40px;
    /* width: ; */
  }
`;
export const VideoSponsor = styled.div`
  position: absolute;
  margin-left: 690px;
  margin-top: -1390px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;
`;

export const AudioSermonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  div.ad {
    position: absolute;
    margin-left: 800px;
    margin-top: -1170px;
    border: 2px solid;
    height: 173px;
    width: 173px;
    border-radius: 5%;

    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }
`;

export const TopItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const ActivityFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 700px;
  background: #f9f5f9;
  top: 10px;
  /* gap 20px; */

  ${media.phone} {
    width: 350px;
    height: 560px;
    margin-left: -30px;
    margin-top: -10px;
  }
`;

export const LocationMediumContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0;

  .top-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .bottom-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: ${theme.colors.primaryColor};

  ${media.phone} {
    font-size: 16px;
  }
`;

export const LocationCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  width: 100%;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr 1fr;
  }
`;

// event card
export const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 2px solid ${theme.colors.primaryColor};
  width: 350px;
  height: auto;
  background: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 3px 6px);
`;

export const EventTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
`;

export const EventMessage = styled.p`
  font-size: 16px;
  font-weight: 300;
  padding: 0 10px;
`;

export const EventBanner = styled.div`
  width: 100%;
  height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 10px 10px 10px;
`;

export const DetailItem = styled.div`
  font-size: 14px;
  color: #444;
  strong {
    color: #000;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
`;

export const Icon = styled.div`
  cursor: pointer;
  color: #333;
  font-size: 1.2em;
  transition: color 0.3s;

  &:hover {
    color: ${theme.colors.primaryColor};
  }
`;
