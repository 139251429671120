import React, { useEffect } from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { CreateDailyVerseInput } from "../../Utils/authInput";
import { selectStyles } from "../../Components/Form/inputs/styles";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  getDailyScripturesFormDetails,
  selectUserDailyScriptures,
} from "../../ReduxStore/features/User/DailyScripturesSlice";
import { createDailyScripture } from "../../Apis/UserApis/DailyScriptures";
import { toast } from "react-toastify";
import {
  getBibleFormDetails,
  selectBible,
} from "../../ReduxStore/features/BibleSlice";
import {
  getBibleBooks,
  getBibleChapters,
  getBibleReading,
  getBibleVerse,
  getBibleVersions,
} from "../../Apis/UserApis/Bible";

const CreateDailySCripturesModal = () => {
  const { creatingDailyScripture, bibleName, chapter, verse, scriptureDate } =
    useAppSelector(selectUserDailyScriptures);
  const dispatch = useDispatch();
  const {
    versionId,
    bookId,
    chapterId,
    verseId,
    bibleVersions,
    gettingBibleVersions,
    gettingBibleNames,
    bibleNames,
    bibleChapters,
    gettingBibleChapters,
    gettingBibleReading,
    bibleReading,
  } = useAppSelector(selectBible);

  useEffect(() => {
    getBibleVersions();
  }, []);

  useEffect(() => {
    versionId && getBibleBooks(versionId?.value);
  }, [versionId]);

  useEffect(() => {
    bookId && getBibleChapters(bookId?.value);
  }, [bookId]);

  useEffect(() => {
    chapterId && getBibleReading(chapterId?.value);
  }, [chapterId]);

  useEffect(() => {
    verseId && getBibleVerse(verseId?.value);
  }, [verseId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDailyScripturesFormDetails({ name, value }));
  };

  const handleSelect = (value: any, name: string) => {
    dispatch(getDailyScripturesFormDetails({ name, value: value?.value }));
    dispatch(getBibleFormDetails({ name, value }));
  };

  const handleCreate = (e: any) => {
    if (bibleName && chapter && verse && scriptureDate) {
      createDailyScripture(bibleName, chapter, verse, scriptureDate);
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <CustomModal
      id={2}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <TextInput
            type={"date"}
            name={"scriptureDate"}
            handleChange={handleChange}
            customStyle={{ width: "100%" }}
          />
          <Select
            className="modal-select"
            classNamePrefix="select"
            defaultValue={bibleVersions && bibleVersions[0]}
            isLoading={gettingBibleVersions}
            isClearable={true}
            isSearchable={true}
            options={bibleVersions && bibleVersions}
            onChange={(value) => handleSelect(value, "versionId")}
            isDisabled={bibleVersions?.length === 0 || gettingBibleVersions}
          />
          <Select
            className="modal-select"
            classNamePrefix="select"
            defaultValue={bibleNames && bibleNames[0]}
            isLoading={gettingBibleNames}
            isClearable={true}
            isSearchable={true}
            options={bibleNames && bibleNames}
            onChange={(value) => handleSelect(value, "bookId")}
            isDisabled={bibleNames?.length === 0 || gettingBibleNames}
          />
          <Select
            className="modal-select"
            classNamePrefix="select"
            defaultValue={bibleChapters && bibleChapters[0]}
            isLoading={gettingBibleChapters}
            isClearable={true}
            isSearchable={true}
            options={bibleChapters && bibleChapters}
            onChange={(value) => handleSelect(value, "chapterId")}
            isDisabled={bibleChapters?.length === 0 || gettingBibleChapters}
          />
          <Select
            className="modal-select"
            classNamePrefix="select"
            defaultValue={bibleReading && bibleReading[0]}
            isLoading={gettingBibleReading}
            isClearable={true}
            isSearchable={true}
            options={bibleReading && bibleReading}
            onChange={(value) => handleSelect(value, "verseId")}
            isDisabled={bibleReading?.length === 0 || gettingBibleReading}
          />
          <PrimaryButton
            text="Add"
            handleClick={handleCreate}
            loading={creatingDailyScripture}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateDailySCripturesModal;
