import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setAnnouncements,
  setGettingAnnouncements,
} from "../../ReduxStore/features/User/AnnouncementSlice";
import api from "../baseUrl";

export const getAllAnnouncements = async (churchId?: string, page?: number, pageSize?: number) => {
  store.dispatch(setGettingAnnouncements(true));
  const params = {
    churchId,
    page,
    pageSize
  };
  await api
    .get("user/church/announcement/user", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAnnouncements(data?.data));
      store.dispatch(setGettingAnnouncements(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAnnouncements(false));
    });
};
