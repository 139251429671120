import Loader from "../../../../Components/Loaders/MoonLoader";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Paginator from "../../../../Components/Reusables/Pagintaor";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { getSermonDetails } from "../../../../ReduxStore/features/Admin/SermonSlice";
import { selectUserMedia } from "../../../../ReduxStore/features/User/MediaSlice";
import { theme } from "../../../../theme";
import { VideoSermonContainer } from "./styles";
import VideoSermonCard from "./VideoSermonCard";
import { useState } from "react";
import { useEffect } from "react";

const VideoSermon = () => {
  const { gettingMedia, media } = useAppSelector(selectUserMedia);
  const [videoSermon, setVideoSermon] = useState<any>();
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getSermonDetails({ name: "pageId", value: e.selected + 1 }));
  };

  useEffect(() => {
    const videoSermonFilter = media?.itemsList?.filter(
      (x: any) => x.sermonType === "video"
    );
    setVideoSermon(videoSermonFilter);
  }, [media]);

  return (
    <>
      <VideoSermonContainer>
        {gettingMedia ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : videoSermon?.length > 0 ? (
          videoSermon?.map((item: any, index: number) => (
            <VideoSermonCard
              sermonTitle={item?.title}
              videoSrc={item?.link}
              _id={index}
            />
          ))
        ) : (
          <NoRecordDisplay
            message="Video Sermon not Available"
            src="/assets/images/svg/working-remotly.svg"
          />
        )}
      </VideoSermonContainer>

      <Paginator
        currentPage={media?.paginator?.currentPage}
        pageCount={media?.paginator?.pageCount}
        itemCount={media?.paginator?.itemCount}
        hasNextPage={media?.paginator?.hasNextPage}
        hasPrevPage={media?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default VideoSermon;
