import { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import styled from "styled-components";
import FreePlan from "./FreePlan";
import ItemsListCard from "./ItemsListCard";
import WeekActivityCard from "./WeekActivityCard";
import { media } from "./../../../../Screens";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import OverviewChart from "../../../../Components/Charts/Admin/OverviewChart";
import {
  getAdminChurch,
  getChurchActivities,
} from "../../../../Apis/AdminApis/ChurchActivities";
import { selectAdminActivities } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import {
  futureActivities,
  sortWeeklyActivities,
  todayActivities,
} from "../../../../Components/Helpers/sotings";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  ${media.phone} {
    height: auto;
  }
`;

const TopItemsWrap = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${media.phone} {
    flex-direction: column;
  }
`;

const SecondItemsWrap = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  height: 50vh;

  ${media.phone} {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

const WeeklyActivityCaption = styled.div`
  display: flex;
  margin-top: 40px;
  align-self: flex-start;
  justify-self: flex-start;
`;

const WeekActivityCardWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${media.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  ${media.phone} {
    display: flex;
    flex-direction: column;
  }
`;

const UpcomingActivities = styled.div`
  display: flex;
  margin-top: 40px;
  align-self: flex-start;
  justify-self: flex-start;
`;

const Dashboard = () => {
  const { churchActivities, adminChurch } = useAppSelector(
    selectAdminActivities
  );
  const [sortedActivities, setSortedActivities] = useState([]);
  const [sortedUpcomingActivities, setSortedUpcomingActivities] = useState([]);

  useEffect(() => {
    getAdminChurch();
    getChurchActivities();
  }, []);

  useEffect(() => {
    const weeklyActivities = sortWeeklyActivities(churchActivities?.itemsList);
    const upcomingActivites = futureActivities(churchActivities?.itemsList);
    setSortedActivities(weeklyActivities);
    setSortedUpcomingActivities(upcomingActivites);
  }, [churchActivities]);

  return (
    <Container>
      <TopItemsWrap>{/* <OverviewChart /> */}</TopItemsWrap>
      <SecondItemsWrap>
        {!adminChurch?.verified && (
          <ProfileCard
            caption="Complete your church profile"
            link="Click here to complete church profile"
          />
        )}
        <FreePlan
          planStatus="You are currently on the free plan Kindly "
          upgradeLink="Click here to upgrade"
        />
        <ItemsListCard
          itemName="Church Name"
          churchName={adminChurch?.name}
          userPosition="Head pastor/parish priest"
          userName={
            adminChurch?.HQID?.lastName + " " + adminChurch?.HQID?.firstName
          }
          churchVeriStatus="Church Verification Status"
          dot1=""
          status={adminChurch?.verified}
          subStatus="Subscription status"
          dot2=""
          daysLeft={"78 days left"}
        />
      </SecondItemsWrap>
      <WeeklyActivityCaption>Weekly Activities</WeeklyActivityCaption>
      <WeekActivityCardWrap>
        <WeekActivityCard
          day="Sunday"
          Activities={todayActivities(sortedActivities, 0)}
        />
        <WeekActivityCard
          day="Monday"
          Activities={todayActivities(sortedActivities, 1)}
        />
        <WeekActivityCard
          day="Tuesday"
          Activities={todayActivities(sortedActivities, 2)}
        />
        <WeekActivityCard
          day="Wednesday"
          Activities={todayActivities(sortedActivities, 3)}
        />
        <WeekActivityCard
          day="Thursday"
          Activities={todayActivities(sortedActivities, 4)}
        />
        <WeekActivityCard
          day="Friday"
          Activities={todayActivities(sortedActivities, 5)}
        />
        <WeekActivityCard
          day="Saturday"
          Activities={todayActivities(sortedActivities, 6)}
        />
      </WeekActivityCardWrap>
      <UpcomingActivities>Upcoming Events</UpcomingActivities>
      <WeekActivityCardWrap>
        <WeekActivityCard
          day="Sunday"
          Activities={todayActivities(sortedUpcomingActivities, 0)}
        />
        <WeekActivityCard
          day="Monday"
          Activities={todayActivities(sortedUpcomingActivities, 1)}
        />
        <WeekActivityCard
          day="Tuesday"
          Activities={todayActivities(sortedUpcomingActivities, 2)}
        />
        <WeekActivityCard
          day="Wednesday"
          Activities={todayActivities(sortedUpcomingActivities, 3)}
        />
        <WeekActivityCard
          day="Thursday"
          Activities={todayActivities(sortedUpcomingActivities, 4)}
        />
        <WeekActivityCard
          day="Friday"
          Activities={todayActivities(sortedUpcomingActivities, 5)}
        />
        <WeekActivityCard
          day="Saturday"
          Activities={todayActivities(sortedUpcomingActivities, 6)}
        />
      </WeekActivityCardWrap>
    </Container>
  );
};

export default Dashboard;
