import { EditableChurchContainer } from "./styles";
import { FC } from "react";

interface EdithChurch {
  user: any;
}

const EditableChurch: FC<EdithChurch> = ({ user }) => {
  return (
    <EditableChurchContainer>
      <option className="option">Dominion Church</option>
      <option className="option">Hills Church</option>
    </EditableChurchContainer>
  );
};

export default EditableChurch;
