import CreateCurrencyModal from "../../../../Modals/CreateCurrencyModal";
import WalletTable from "../../../../Components/Tables/SuperAdmin/WalletTable";
import EditCurrencyModal from "./../../../../Modals/EditCurrencyModal";

const index = () => {
  return (
    <>
      <WalletTable />
      <EditCurrencyModal />
      <CreateCurrencyModal />
    </>
  );
};

export default index;
