import { IndexWrap } from "./styles";
import UserProfile from "./UserProfile";

const User = () => {
  return (
    <IndexWrap>
      <UserProfile />
    </IndexWrap>
  );
};

export default User;
