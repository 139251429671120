import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface BibleState {
  gettingBibleVersions: boolean;
  bibleVersions: any;
  gettingBibleNames: boolean;
  bibleNames: any;
  gettingBibleChapters: boolean;
  bibleChapters: any;
  gettingBibleReading: boolean;
  bibleReading: any;
  gettingVerse: boolean;
  verse: any;

  // bible states
  versionId: any;
  verseId: any;
  bookId: any;
  chapterId: any;
}

const initialState: BibleState = {
  gettingBibleVersions: false,
  bibleVersions: null,
  gettingBibleNames: false,
  bibleNames: null,
  gettingBibleChapters: false,
  bibleChapters: null,
  gettingBibleReading: false,
  bibleReading: null,
  gettingVerse: false,
  verse: null,

  // bible states
  versionId: "",
  verseId: "",
  chapterId: "",
  bookId: "1",
};

export const BibleSlice = createSlice({
  name: "Bible",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingBibleVersions: (state, action) => {
      state.gettingBibleVersions = action.payload;
    },
    setBibleVersions: (state, action) => {
      const sorted = action?.payload?.map((x: any) => {
        return { label: x?.name, value: x?.versionId };
      });
      state.bibleVersions = sorted;
    },
    setGettingBibleNames: (state, action) => {
      state.gettingBibleNames = action.payload;
    },
    setBibleNames: (state, action) => {
      const sorted = action?.payload?.map((x: any) => {
        return { label: x?.name, value: x?.bookId };
      });
      state.bibleNames = sorted;
    },
    setGettingBibleChapters: (state, action) => {
      state.gettingBibleChapters = action.payload;
    },
    setBibleChapters: (state, action) => {
      const sorted = action?.payload?.map((x: any) => {
        return { label: x?.number, value: x?.chapterId };
      });
      state.bibleChapters = sorted;
    },
    setGettingBibleReading: (state, action) => {
      state.gettingBibleReading = action.payload;
    },
    setBibleReading: (state, action) => {
      // const sorted = action?.payload?.map((x: any) => {
      //   return { label: x?.verseId, value: x?.verseId };
      // });
      state.bibleReading = action.payload;
    },
    setGettingVerse: (state, action) => {
      state.gettingVerse = action.payload;
    },
    setVerse: (state, action) => {
      state.verse = [action.payload];
    },
    getBibleFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingBibleNames,
  setBibleNames,
  setGettingBibleChapters,
  setBibleChapters,
  setGettingBibleReading,
  setBibleReading,
  getBibleFormDetails,
  setBibleVersions,
  setGettingBibleVersions,
  setGettingVerse,
  setVerse,
} = BibleSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBible = (state: RootState) => state.Bible;

export default BibleSlice.reducer;
