import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserGroupState {
  gettingUserGroups: boolean;
  userGroups: any;
  requestingToJoinGroup: boolean;
  exitingGroup: boolean;
  gettingJoinedGroups: boolean;
  joinedGroups: any;
  sendingMessage: boolean;
  gettingMessages: boolean;
  messages: object[];

  // params
  churchGroupId: string;
}

const initialState: UserGroupState = {
  gettingUserGroups: false,
  userGroups: null,
  requestingToJoinGroup: false,
  exitingGroup: false,
  gettingJoinedGroups: false,
  joinedGroups: null,
  sendingMessage: false,
  gettingMessages: false,
  messages: [],

  // params
  churchGroupId: "",
};

export const USerCgroupSlice = createSlice({
  name: "USerCgroup",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingUserGroups: (state, action) => {
      state.gettingUserGroups = action.payload;
    },
    setUserGroups: (state, action) => {
      state.userGroups = action.payload;
    },
    setRequestingToJoinGroup: (state, action) => {
      state.requestingToJoinGroup = action.payload;
    },
    setExitingGroup: (state, action) => {
      state.exitingGroup = action.payload;
    },
    setGettingJoinedGroup: (state, action) => {
      state.gettingJoinedGroups = action.payload;
    },
    setJoinedGroups: (state, action) => {
      state.joinedGroups = action.payload;
    },
    setGettingMessages: (state, action) => {
      state.gettingMessages = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setSendingMessage: (state, action) => {
      state.sendingMessage = action.payload;
    },
    getUserGroupDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingUserGroups,
  getUserGroupDetails,
  setGettingJoinedGroup,
  setGettingMessages,
  setJoinedGroups,
  setMessages,
  setRequestingToJoinGroup,
  setUserGroups,
  setSendingMessage,
  setExitingGroup
} = USerCgroupSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.USerCgrouper.value)`
export const selectUserCgroup = (state: RootState) => state.userGroup;

export default USerCgroupSlice.reducer;
