import { FC } from "react";
import styled from "styled-components";
import { theme } from "../../../../theme";

const StyledLabel = styled.label<{ checked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 16px;
  background: ${({ checked }) =>
    !checked ? theme.colors.bgGrey : theme.colors.primaryColor};
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: ${({ checked }) => (!checked ? "6px" : "calc(55% - 1px)")};
    top: 3px;
    width: 10px;
    height: 10px;
    background: #000000;
    border-radius: 90px;
    transition: 0.3s;
  }
`;

interface ITogleSwitch {
  switchState: boolean;
  handleChange?: any;
  id?: string;
}
export const ToggleSwitch: FC<ITogleSwitch> = ({
  switchState,
  handleChange,
  id,
}) => {
  return (
    <StyledLabel htmlFor={id} checked={switchState}>
      <input
        id={id}
        type="checkbox"
        // defaultChecked={switchState}
        onChange={handleChange}
      />
    </StyledLabel>
  );
};
