import { FC } from "react";
import styled from "styled-components";
import { media } from "./../../../../Screens";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import { setCurrentTab } from "../../../../ReduxStore/features/navSlice";
import moment from "moment";

const WeekActivityCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-radius: 5px;
  border-top: 2px solid #b603c9;
  width: 100%;
  min-height: 150px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 5px;
  cursor: pointer;

  ${media.phone} {
    width: 300px;
    padding-left: 20px;
  }
`;
const DayWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const Day = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #151c4d;
`;
const FirstActivityWrap = styled.div`
  margin-left: 10px;
  margin-top: 25px;
`;
const FirstWrap1 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Dot1 = styled.div`
  width: 8px;
  height: 8px;
  background: #b603c9;
  border-radius: 50%;
`;
const PrayerMeeting = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #4c1c51;
`;
const ActivityTime1 = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4c1c51;
  margin-left: 15px;
`;
const Dot2 = styled.div`
  width: 8px;
  height: 8px;
  background: #f6b958;
  border-radius: 50%;
`;

interface WeekActivityCardI {
  day: string;
  Activities?: any;
}

const WeekActivityCard: FC<WeekActivityCardI> = ({ day, Activities }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <WeekActivityCardContainer
      onClick={() => {
        navigate(`/church-activities`);
        dispatch(setCurrentTab(2));
      }}
    >
      <DayWrap>
        <Day>{day}</Day>
      </DayWrap>
      {Activities?.length > 0 ? (
        Activities?.map((activity: any, index: number) => (
          <FirstActivityWrap>
            <FirstWrap1>
              {index % 2 === 0 ? <Dot1 /> : <Dot2 />}
              <PrayerMeeting>{activity?.title}</PrayerMeeting>
            </FirstWrap1>
            <ActivityTime1>
              {activity?.startTime} - {activity?.endTime}
            </ActivityTime1>
            <ActivityTime1>
              {moment(activity?.startDate).day() === moment(activity?.endDate).day() ? moment(activity?.startDate).format(`Do MMM YYYY`) : moment(activity?.startDate).format(`Do MMM YYYY`) + ` - ` + moment(activity?.endDate).format(`Do MMM YYYY`)}
            </ActivityTime1>
          </FirstActivityWrap>
        ))
      ) : (
        <NoRecordDisplay src="/assets/images/svg/working-remotly.svg" />
      )}
    </WeekActivityCardContainer>
  );
};

export default WeekActivityCard;
