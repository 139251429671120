import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  setAdminChurchMembers,
  setAdminChurches,
  setGettingAdminChurchMembers,
  setGettingAdminChurches,
  setDeletingChurchMember,
} from "../../ReduxStore/features/Admin/manageChurchesSlice";

export const getAdminChurches = async (churchId?: string, verified?: boolean) => {
  store.dispatch(setGettingAdminChurches(true));
  const params: any = {
    verified,
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/dashboard/manage-church", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setAdminChurches(data?.data));
      store.dispatch(setGettingAdminChurches(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdminChurches(false));
    });
};

export const getAdminChurcheMembers = async (churchId?: string) => {
  store.dispatch(setGettingAdminChurchMembers(true));
  const params: any = {
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/church-members", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setAdminChurchMembers(data?.data));
      store.dispatch(setGettingAdminChurchMembers(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdminChurchMembers(false));
    });
};

export const deleteChurchMember = async (memberId?: string) => {
  store.dispatch(setDeletingChurchMember(true));
  await api
    .delete(`admin/church-members/${memberId}`)
    .then(() => {
      store.dispatch(setDeletingChurchMember(false));
      toast.success(`Successful`);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingChurchMember(false));
    });
};
