import { useEffect } from "react";
import { ActivityContainer, EventsContainer } from "./styles";
import ChurchActivityCard from "../../../../Layouts/ChurchActivityCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getChurchActivitiesFormDetails,
  selectUserChurchActivities,
} from "../../../../ReduxStore/features/User/ChurchActivitiesSlice";
import { getUserChurchEvents } from "../../../../Apis/UserApis/ChurchActivities";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
// import Ads from "../Ads";
import moment from "moment";
import { IoMdCheckmark } from "react-icons/io";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const ChurchEvents = () => {
  const { churchEvents, gettingChurchEvents, page, pageSize } = useAppSelector(
    selectUserChurchActivities
  );
  // const [calendar, setCalendar] = useState(false);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(
      getChurchActivitiesFormDetails({ name: "page", value: e.selected + 1 })
    );
  };

  useEffect(() => {
    getUserChurchEvents(page, pageSize);
  }, [page]);

  return (
    <>
      <ActivityContainer>
        {gettingChurchEvents ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Loader color={theme?.colors.primaryColor} size={20} />
          </div>
        ) : (
          <EventsContainer>
            {churchEvents?.itemsList?.length > 0 ? (
              <>
                {churchEvents?.itemsList?.map((event: any) => (
                  <ChurchActivityCard
                    icon={<IoMdCheckmark />}
                    title={event?.eventId?.title}
                    time={
                      moment(event?.eventId?.startingDate)?.format(
                        "Do MMM YYYY"
                      ) +
                      " : " +
                      event?.eventId?.startTime +
                      " - " +
                      moment(event?.eventId?.endingDate)?.format(
                        "Do MMM YYYY"
                      ) +
                      " : " +
                      event?.eventId?.endTime
                    }
                    quote={event?.eventId?.message}
                    choice="Interested"
                    mode={event?.eventId?.liveStream}
                    allDay={event?.eventId?.allDay}
                    repeat={event?.eventId?.repeat}
                  />
                ))}
              </>
            ) : (
              <NoRecordDisplay
                src="/assets/images/svg/working-remotly.svg"
                message="No event at the moment"
              />
            )}
          </EventsContainer>
        )}
        {/* <Ads /> */}
      </ActivityContainer>

      <Paginator
        currentPage={churchEvents?.paginator?.currentPage}
        pageCount={churchEvents?.paginator?.pageCount}
        itemCount={churchEvents?.paginator?.itemCount}
        hasNextPage={churchEvents?.paginator?.hasNextPage}
        hasPrevPage={churchEvents?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default ChurchEvents;
