import moment from "moment";

export const sortWeeklyActivities = (data: any) => {
  const res = data?.filter((item: any) => moment(item?.startDate).week() === moment().week() && moment(item?.startDate).year() === moment().year());
  return res;
};

export const todayActivities = (data: any, day: number) => {
  const res = data?.filter((item: any) => moment(item?.startDate).day() === day);
  return res;
};

export const futureActivities = (data: any) => {
  const res = data?.filter((item: any) => moment(item?.startDate).week() > moment().week() && moment(item?.startDate).year() === moment().year());
  return res;
};
