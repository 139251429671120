import { useEffect } from "react";
import CustomModal from "../CustomModal";
import { HistoryContainer } from "../../Pages/Private/User/Giving/styles";
import ViewHistoryCard from "../../Pages/Private/User/Giving/ViewHistoryCard";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { selectDonations } from "../../ReduxStore/features/User/DonationSlice";
import { getTransactionInvoices } from "../../Apis/AdminApis/payments";
import Loader from "../../Components/Loaders/MoonLoader";
import moment from "moment";

const ViewDonationHistoryModal = () => {
  const { gettingTransactionInvoices, transactionInvoices } =
    useAppSelector(selectDonations);

  useEffect(() => {
    getTransactionInvoices();
  }, []);

  return (
    <CustomModal
      id={2}
      content={
        <HistoryContainer>
          {gettingTransactionInvoices ? (
            <Loader size={14} color="#B603C9" />
          ) : transactionInvoices?.itemsList?.length === 0 ? (
            ""
          ) : (
            transactionInvoices?.itemsList?.map(
              (transaction: any, index: number) => (
                <ViewHistoryCard
                  userName={transaction?.userId?.email}
                  historyType={transaction?.purpose}
                  transAmmount={transaction?.amount}
                  date={moment(transaction?.updatedAt).format(
                    "Do MMM YYYY : HH:MM:SS"
                  )}
                  status={transaction?.status}
                  reference={transaction?.reference}
                  key={index}
                />
              )
            )
          )}
        </HistoryContainer>
      }
    />
  );
};

export default ViewDonationHistoryModal;
