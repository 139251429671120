import { FC } from "react";
import styled from "styled-components";
import { useAppSelector } from "../ReduxStore/app/hooks";
import { handleModal, selectNav } from "../ReduxStore/features/navSlice";
// import { bounce } from "react-animations";
// import Radium, { StyleRoot } from "radium";
import { theme } from "./../theme";
import { useAppDispatch } from "./../ReduxStore/app/hooks";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const ModalContainer = styled(Drawer)`
  overflow-y: auto;
`;

const Close = styled.div`
  text-align: align;
  font-size: 14px;
  border: 4px solid ${theme.colors.primaryColor};
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 15px;
  color: ${theme.colors.primaryColor};
  font-weight: 600;
  align-self: flex-end;
`;

const OverFlowContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  width: auto;
  padding: 20px;
  background: ${theme.colors.bgWhite};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

// const styles = {
//   bounce: {
//     animation: "x 1s",
//     animationName: Radium.keyframes(bounce, "bounce"),
//   },
// };

interface IModal {
  content: any;
  id: number;
  closeFunctionAdded?: any;
}

const CustomModal: FC<IModal> = ({ content, id, closeFunctionAdded }) => {
  const { modal } = useAppSelector(selectNav);
  const dispatch = useAppDispatch();
  return (
    <ModalContainer
      open={id === modal ? true : false}
      onClose={() => {
        dispatch(handleModal(0));
      }}
      direction={
        window?.document.documentElement.clientWidth < 800 ? "bottom" : "right"
      }
      style={{
        height:
          window?.document.documentElement.clientWidth < 800 ? "80%" : "100%",
        width:
          window?.document.documentElement.clientWidth < 800 ? "100%" : "auto",
        zIndex: "1000",
        overflowY: "scroll",
      }}
      overlayClassName="overlay"
      className="drawar"
    >
      <OverFlowContainer>
        <Content>
          <Close
            onClick={() => {
              dispatch(handleModal(0));
              closeFunctionAdded && closeFunctionAdded();
            }}
          >
            X
          </Close>
          {/* <StyleRoot>
            <div className="test" style={styles.bounce}> */}
          {content}
          {/* </div>
          </StyleRoot> */}
        </Content>
      </OverFlowContainer>
    </ModalContainer>
  );
};
export default CustomModal;
