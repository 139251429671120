import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  setApprovingAdminPost,
  setDeclinedPosts,
  setGettingDeclinedPosts,
  setGettingPendingAdminPost,
  setGettingSinglePost,
  setPendingAdminPost,
  setSinglePost,
} from "../../ReduxStore/features/Admin/AdminPostSlice";

export const getPendingPosts = async (pageSize: number, page: number) => {
  store.dispatch(setGettingPendingAdminPost(true));
  const params = {
    page,
    pageSize,
  };
  await api
    .get(`admin/post/pending`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setPendingAdminPost(data));
      store.dispatch(setGettingPendingAdminPost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPendingAdminPost(false));
    });
};

export const getDeclinedPosts = async (pageSize: number, page: number) => {
  store.dispatch(setGettingDeclinedPosts(true));

  const params = {
    page,
    pageSize,
  };
  await api
    .get(`admin/post/declined`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setDeclinedPosts(data));
      store.dispatch(setGettingDeclinedPosts(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingDeclinedPosts(false));
    });
};

export const getAdminSinglePost = async (postId: string) => {
  store.dispatch(setGettingSinglePost(true));

  const params = {
    postId,
  };
  await api
    .get(`admin/post/byId`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setSinglePost(data));
      store.dispatch(setGettingSinglePost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSinglePost(false));
    });
};

export const approveAndDisapprovePost = async (
  postId: string,
  status: string
) => {
  store.dispatch(setApprovingAdminPost(true));

  const body = {
    postId,
    status,
  };
  await api
    .put(`admin/post/approve`, body)
    .then(() => {
      toast.success("Successful");
      store.dispatch(setApprovingAdminPost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setApprovingAdminPost(false));
    });
};
