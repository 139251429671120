import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SubscriptionsState {
  gettingSubscriptions: boolean;
  subscriptions: any;
  gettingPaymentProps: boolean;
  paymentProps: any;
  gettingPaymentHistory: boolean;
  paymentHistory: any;
  deletingSubscription: boolean;
  editingSubscription: boolean;
  creatingSubscription: boolean;
  gettingCurrentSubscription: boolean;
  currentSubscription: any;
  gettingSubscriptionHistory: boolean;
  subscriptionHistory: any;

  // subscription state
  subscriptionTitle: string;
  subscriptionAmount: number;
  subscriptionDescription: string;
  subscriptionFeatures: any;
  featuresLength: number;
  currencyId: string;
  subscriptionPackageId: string;
  durationInDays: number;
}

const initialState: SubscriptionsState = {
  gettingSubscriptions: false,
  subscriptions: null,
  gettingPaymentProps: false,
  paymentProps: null,
  gettingPaymentHistory: false,
  paymentHistory: null,
  deletingSubscription: false,
  editingSubscription: false,
  creatingSubscription: false,
  gettingCurrentSubscription: false,
  currentSubscription: null,
  gettingSubscriptionHistory: false,
  subscriptionHistory: null,

  // subscription state
  subscriptionTitle: "",
  subscriptionAmount: 0,
  subscriptionDescription: "",
  featuresLength: 1,
  subscriptionFeatures: new Array(1).fill(""),
  currencyId: "",
  subscriptionPackageId: "",
  durationInDays: 0,
};

export const SubscriptionsSlice = createSlice({
  name: "Subscriptions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingSubscriptions: (state, action) => {
      state.gettingSubscriptions = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    setGettingPaymentProps: (state, action) => {
      state.gettingPaymentProps = action.payload;
    },
    setPaymentProps: (state, action) => {
      state.paymentProps = action.payload;
    },
    setGettingPaymentHistory: (state, action) => {
      state.gettingPaymentHistory = action.payload;
    },
    setGettingCurrentSubscriptions: (state, action) => {
      state.gettingCurrentSubscription = action.payload;
    },
    setCurrentSubscription: (state, action) => {
      state.currentSubscription = action.payload;
    },
    setGettingSubscriptionHistory: (state, action) => {
      state.gettingSubscriptionHistory = action.payload;
    },
    setSubscriptionHistory: (state, action) => {
      state.subscriptionHistory = action.payload;
    },
    incrementFeature: (state) => {
      state.subscriptionFeatures.push(" ");
    },
    removeFeature: (state) => {
      state.subscriptionFeatures.pop();
    },
    setDeletingSubsription: (state, action) => {
      state.deletingSubscription = action.payload;
    },
    setEditingSubscription: (state, action) => {
      state.editingSubscription = action.payload;
    },
    setCreatingSubscription: (state, action) => {
      state.creatingSubscription = action.payload;
    },
    setPaymentHistory: (state, action) => {
      state.paymentHistory = action.payload;
    },
    setChosenSubscription: (state, action) => {
      state.subscriptionTitle = action.payload.title;
      state.subscriptionAmount = action.payload.amount;
      state.subscriptionDescription = action.payload.description;
      state.featuresLength = 0;
      state.subscriptionFeatures = action.payload.features;
      state.currencyId = action.payload.currencyId;
      state.subscriptionPackageId = action.payload._id;
      state.durationInDays = action.payload.durationInDays;
    },
    getSubscriptionFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingSubscriptions,
  setSubscriptions,
  removeFeature,
  setGettingPaymentHistory,
  setPaymentHistory,
  setDeletingSubsription,
  setEditingSubscription,
  setCreatingSubscription,
  getSubscriptionFormDetails,
  incrementFeature,
  setChosenSubscription,
  setCurrentSubscription,
  setGettingCurrentSubscriptions,
  setGettingSubscriptionHistory,
  setSubscriptionHistory,
  setGettingPaymentProps,
  setPaymentProps,
} = SubscriptionsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSuperAdminSubscriptions = (state: RootState) =>
  state.superAdminSubscriptions;

export default SubscriptionsSlice.reducer;
