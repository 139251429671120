import styled from "styled-components";
import { theme } from "../../../../theme";
import { media } from "./../../../../Screens";

export const AdsContainar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 946px;
  min-height: 300px;
  background: ${theme.colors.bgWhite};
  padding: 50px;

  div.selectContainer {
    display: flex;
    align-items: center;
    gap: 30px;
  }
`;

// Add Announcement
export const DailyAnnouncementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const AnnouncementCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 30px 0;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;
