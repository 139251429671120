import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signupAsMember } from "../../../Apis/Auth/signupApis";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import SelectBox from "../../../Components/Form/inputs/SelectBox";
import TextInput from "../../../Components/Form/inputs/TextInput";
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validatePass,
} from "../../../Components/Helpers/Generals";
import FormWrapper from "../../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getFormDetails,
  selectForm,
} from "../../../ReduxStore/features/formSlice";
import { memberSignUpInputs } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import {
  AuthLogo,
  AuthTitle,
  AuthLink,
  AuthSmallText,
  AuthSubTitle,
} from "../style";
import { IoArrowBackCircle } from "react-icons/io5";

const SignupAsMember = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    registerEmail,
    registerPassword,
    phoneNo,
    firstName,
    lastName,
    gender,
    loading,
  } = useAppSelector(selectForm);
  const [viewPassword, setViewPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "firstName" || name === "lastName") {
      if (validateName(value)) dispatch(getFormDetails({ name, value }));
    } else {
      dispatch(getFormDetails({ name, value }));
    }
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSignup = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      registerEmail &&
      registerPassword &&
      phoneNo &&
      firstName &&
      lastName &&
      gender
    ) {
      if (
        validateEmail(registerEmail) &&
        validatePass(registerPassword) &&
        validatePhoneNumber(phoneNo) &&
        validateName(firstName) &&
        validateName(lastName)
      ) {
        signupAsMember(
          firstName,
          lastName,
          registerEmail,
          phoneNo,
          registerPassword,
          gender,
          dispatch,
          navigate,
          "user"
        );
      }
    } else {
      toast.warning("Please fill all fields");
    }
  };

  return (
    <AuthMain>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>
        <Link to="/auth/signup">
          <IoArrowBackCircle />
        </Link>
        Sign Up
      </AuthTitle>
      <AuthSubTitle>Register as a church member by</AuthSubTitle>
      <FormWrapper>
        {memberSignUpInputs?.map((input: any) => (
          <>
            {input?.type !== "select" ? (
              <TextInput
                placeholder={input?.placeholder}
                icon={input?.ActiveIcon}
                type={
                  input?.type === "password" && viewPassword
                    ? "text"
                    : input?.type
                }
                name={input?.name}
                handleChange={handleChange}
                altIcon={input?.InActiveIcon}
                iconState={viewPassword}
                handleIconClick={() => setViewPassword(true)}
                handleAltIconClick={() => setViewPassword(false)}
                showStrengthSignal={input?.showStrengthSignal}
                value={input?.value()}
              />
            ) : (
              <SelectBox
                options={input?.options}
                name={input?.name}
                handleSelect={handleSelect}
              />
            )}
          </>
        ))}
        <AuthSmallText>
          By signing up you accept the{" "}
          <AuthLink
            to="https://thefaithfuls.com/terms-and-conditions"
            target="_blank"
          >
            Terms of service{" "}
          </AuthLink>{" "}
          <span> & </span>
          <AuthLink
            to="https://thefaithfuls.com/privacy-policy"
            target="_blank"
          >
            Privay Policy
          </AuthLink>
        </AuthSmallText>
        <PrimaryButton
          text="Next"
          handleClick={handleSignup}
          loading={loading}
        />
        <AuthSmallText>
          Already have an account? <AuthLink to="/">Login here</AuthLink>
        </AuthSmallText>
      </FormWrapper>
    </AuthMain>
  );
};

export default SignupAsMember;
