import { FC } from "react";
import {
  InputContainer,
  Label,
  ParentContainer,
  RegularSelect,
} from "./styles";

interface selectI {
  options: any;
  name: string | undefined;
  handleSelect?: any;
  defaultValue?: any;
  value?: any;
  label?: string;
}
const SelectBox: FC<selectI> = ({
  options,
  name,
  handleSelect,
  defaultValue,
  value,
  label,
}) => {
  return (
    <ParentContainer>
      <Label>{label || name}:</Label>
      <InputContainer>
        <RegularSelect
          name={name}
          onChange={handleSelect}
          defaultValue={defaultValue}
          value={value}
        >
          {options?.map((item: any) => (
            <option key={item?.id} value={item?.value}>
              {item?.name}
            </option>
          ))}
        </RegularSelect>
      </InputContainer>
    </ParentContainer>
  );
};

export default SelectBox;
