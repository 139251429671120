import { store } from "../../ReduxStore/app/store";
import {
  setBibleChapters,
  setBibleNames,
  setBibleReading,
  setBibleVersions,
  setGettingBibleChapters,
  setGettingBibleNames,
  setGettingBibleReading,
  setGettingBibleVersions,
  setGettingVerse,
  setVerse,
} from "../../ReduxStore/features/BibleSlice";
import api from "../baseUrl";

export const getBibleVersions = async () => {
  store.dispatch(setGettingBibleVersions(true));
  await api
    .get("user/bible/versions")
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleVersions(data?.data));
      store.dispatch(setGettingBibleVersions(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleVersions(false));
    });
};

export const getBibleBooks = async (versionId?: any, bibleId?: any) => {
  store.dispatch(setGettingBibleNames(true));
  const params: any = {
    versionId,
    bibleId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`user/bible/books/${versionId}`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleNames(data?.data));
      store.dispatch(setGettingBibleNames(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleNames(false));
    });
};

export const getBibleChapters = async (bookId?: string, bibleId?: any) => {
  store.dispatch(setGettingBibleChapters(true));
  const params: any = {
    bookId,
    bibleId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`user/bible/chapters/${bookId}`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleChapters(data?.data));
      store.dispatch(setGettingBibleChapters(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleChapters(false));
    });
};

export const getBibleReading = async (chapterId?: any, bibleId?: any) => {
  store.dispatch(setGettingBibleReading(true));
  const params: any = {
    chapterId,
    bibleId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`user/bible/chapter/verses/${chapterId}`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleReading(data?.data));
      store.dispatch(setGettingBibleReading(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleReading(false));
    });
};

export const getBibleVerse = async (verseId?: any) => {
  store.dispatch(setGettingVerse(true));
  const params: any = {
    verseId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`user/bible/verse/${verseId}`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setVerse(data?.data));
      store.dispatch(setGettingVerse(false));
    })
    .catch((err) => {
      store.dispatch(setGettingVerse(false));
    });
};
